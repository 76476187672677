import {
  useData,
  useMetaErrorMessage,
  useMetaErrorStatus,
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
} from 'modules/base'

import { UserSlice } from './user.schema'

export const useGetUserIdle = ({ user }: UserSlice) =>
  useMetaIdleStatus(user.user)

export const useGetUserLoading = ({ user }: UserSlice) =>
  useMetaLoadingStatus(user.user)

export const useGetUserSuccess = ({ user }: UserSlice) =>
  useMetaSuccessStatus(user.user)

export const useGetUserError = ({ user }: UserSlice) =>
  useMetaErrorStatus(user.user)

export const useGetUserErrorMessage = ({ user }: UserSlice) =>
  useMetaErrorMessage(user.user)

export const useGetUser = ({ user }: UserSlice) => useData(user.user)

export const useUpdateUserIdle = ({ user }: UserSlice) =>
  useMetaIdleStatus(user.updation)

export const useUpdateUserLoading = ({ user }: UserSlice) =>
  useMetaLoadingStatus(user.updation)

export const useUpdateUserSuccess = ({ user }: UserSlice) =>
  useMetaSuccessStatus(user.updation)

export const useUpdateUserError = ({ user }: UserSlice) =>
  useMetaErrorStatus(user.updation)

export const useUpdateUserErrorMessage = ({ user }: UserSlice) =>
  useMetaErrorMessage(user.updation)

export const useUpdateUser = ({ user }: UserSlice) => useData(user.updation)

export const useCreateUserIdle = ({ user }: UserSlice) =>
  useMetaIdleStatus(user.createUser)

export const useCreateUserLoading = ({ user }: UserSlice) =>
  useMetaLoadingStatus(user.createUser)

export const useCreateUserSuccess = ({ user }: UserSlice) =>
  useMetaSuccessStatus(user.createUser)

export const useCreateUserError = ({ user }: UserSlice) =>
  useMetaErrorStatus(user.createUser)

export const useCreateUserErrorMessage = ({ user }: UserSlice) =>
  useMetaErrorMessage(user.createUser)

export const useCreateUserData = ({ user }: UserSlice) => user.createUser.data

export const useAddRoleToUserIdle = ({ user }: UserSlice) =>
  useMetaIdleStatus(user.addRoleToUser)

export const useAddRoleToUserLoading = ({ user }: UserSlice) =>
  useMetaLoadingStatus(user.addRoleToUser)

export const useAddRoleToUserSuccess = ({ user }: UserSlice) =>
  useMetaSuccessStatus(user.addRoleToUser)

export const useAddRoleToUserError = ({ user }: UserSlice) =>
  useMetaErrorStatus(user.addRoleToUser)

export const useAddRoleToUserMessage = ({ user }: UserSlice) =>
  useMetaErrorMessage(user.addRoleToUser)

export const useAddRoleToUserData = ({ user }: UserSlice) =>
  user.addRoleToUser.data

export const useBlockUser = ({ user }: UserSlice) => useData(user.blockUser)

export const useBlockUserIdle = ({ user }: UserSlice) =>
  useMetaIdleStatus(user.blockUser)

export const useBlockUserLoading = ({ user }: UserSlice) =>
  useMetaLoadingStatus(user.blockUser)

export const useBlockUserSuccess = ({ user }: UserSlice) =>
  useMetaSuccessStatus(user.blockUser)

export const useBlockUserError = ({ user }: UserSlice) =>
  useMetaErrorStatus(user.blockUser)

export const useBlockUserErrorMessage = ({ user }: UserSlice) =>
  useMetaErrorMessage(user.blockUser)

export const useGetUsersByUsername = ({ user }: UserSlice) =>
  useData(user.getUserByUserName)

export const useGetUsersByUsernameIdle = ({ user }: UserSlice) =>
  useMetaIdleStatus(user.getUserByUserName)

export const useGetUsersByUsernameLoading = ({ user }: UserSlice) =>
  useMetaLoadingStatus(user.getUserByUserName)

export const useGetUsersByUsernameSuccess = ({ user }: UserSlice) =>
  useMetaSuccessStatus(user.getUserByUserName)

export const useGetUsersByUsernameError = ({ user }: UserSlice) =>
  useMetaErrorStatus(user.getUserByUserName)

export const useGetUsersByUsernameErrorMessage = ({ user }: UserSlice) =>
  useMetaErrorMessage(user.getUserByUserName)

export const useGetUserRoles = ({ user }: UserSlice) =>
  useData(user.getUserRoles)

export const useGetUserRolesIdle = ({ user }: UserSlice) =>
  useMetaIdleStatus(user.getUserRoles)

export const useGetUserRolesLoading = ({ user }: UserSlice) =>
  useMetaLoadingStatus(user.getUserRoles)

export const useGetUserRolesSuccess = ({ user }: UserSlice) =>
  useMetaSuccessStatus(user.getUserRoles)

export const useGetUserRolesError = ({ user }: UserSlice) =>
  useMetaErrorStatus(user.getUserRoles)

export const useGetUserRolesErrorMessage = ({ user }: UserSlice) =>
  useMetaErrorMessage(user.getUserRoles)

export const useUpdateUserRoleIdle = ({ user }: UserSlice) =>
  useMetaIdleStatus(user.updateRoleToUser)

export const useUpdateUserRoleLoading = ({ user }: UserSlice) =>
  useMetaLoadingStatus(user.updateRoleToUser)

export const useUpdateUserRoleSuccess = ({ user }: UserSlice) =>
  useMetaSuccessStatus(user.updateRoleToUser)

export const useUpdateUserRoleError = ({ user }: UserSlice) =>
  useMetaErrorStatus(user.updateRoleToUser)

export const useUpdateUserRoleErrorMessage = ({ user }: UserSlice) =>
  useMetaErrorMessage(user.updateRoleToUser)

export const useUpdateUserRole = ({ user }: UserSlice) => useData(user.updateRoleToUser)

export const useUpdateUserEmailIdle = ({ user }: UserSlice) =>
  useMetaIdleStatus(user.updateUserEmail)

export const useUpdateUserEmailLoading = ({ user }: UserSlice) =>
  useMetaLoadingStatus(user.updateUserEmail)

export const useUpdateUserEmailSuccess = ({ user }: UserSlice) =>
  useMetaSuccessStatus(user.updateUserEmail)

export const useUpdateUserEmailError = ({ user }: UserSlice) =>
  useMetaErrorStatus(user.updateUserEmail)

export const useUpdateUserEmailErrorMessage = ({ user }: UserSlice) =>
  useMetaErrorMessage(user.updateUserEmail)

export const useUpdateUserEmailData = ({ user }: UserSlice) => user.updateUserEmail.data

// count
export const useGetCountIdle = ({ user }: UserSlice) =>
  useMetaIdleStatus(user.getCount)

export const useGetCountLoading = ({ user }: UserSlice) =>
  useMetaLoadingStatus(user.getCount)

export const useGetCountSuccess = ({ user }: UserSlice) =>
  useMetaSuccessStatus(user.getCount)

export const useGetCountError = ({ user }: UserSlice) =>
  useMetaErrorStatus(user.getCount)

export const useGetCountErrorMessage = ({ user }: UserSlice) =>
  useMetaErrorMessage(user.getCount)

export const useGetCountData = ({ user }: UserSlice) => user.getCount.data

import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import React from 'react'

import { ReferenceList } from 'api'

import styles from './TableMasterList.module.css'
import { EnhancedTableProps, HeadCell } from './TableMasterList.props'


const headCells: readonly HeadCell[] = [
  {
    id: 'belstat_code',
    numeric: false,
    label: 'Код',
  },
  {
    id: 'vnaimk',
    numeric: true,
    label: 'Полное наименование',
  },
  {
    id: 'unp',
    numeric: true,
    label: 'УНП',
  }
]

export const EnhancedTableHead = ({
  order: sortDir,
  orderBy,
  onRequestSort,
}: EnhancedTableProps) => {
  const createSortHandler = (property: ReferenceList.SortField) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const order: 'asc' | 'desc' = sortDir === 'ASC' ? 'asc' : 'desc'
  
  return (
    <TableHead className={styles.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={styles.tableCell}
            style={{width: headCell.id === 'vnaimk' ? 800 : 'auto'}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={styles.tableCell}
            >
              <b>{headCell.label}</b>
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={styles.tableCell}>
          Юридический адрес
        </TableCell>
        <TableCell className={styles.tableCell}>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
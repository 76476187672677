import {
  useMetaIdleStatus, useMetaLoadingStatus, useMetaSuccessStatus, useMetaErrorStatus, useMetaErrorMessage,
} from 'modules/base'
  
import { AuthSlice } from './auth.schema'
  
  
export const useCreationIdle = ({ auth }: AuthSlice) => (
  useMetaIdleStatus(auth.creation)
)
  
export const useCreationLoading = ({ auth }: AuthSlice) => (
  useMetaLoadingStatus(auth.creation)
)
  
export const useCreationSuccess = ({ auth }: AuthSlice) => (
  useMetaSuccessStatus(auth.creation)
)
  
export const useCreationError = ({ auth }: AuthSlice) => (
  useMetaErrorStatus(auth.creation)
)
  
export const useCreationErrorMessage = ({ auth }: AuthSlice) => (
  useMetaErrorMessage(auth.creation)
)
  
export const useCreationData = ({ auth }: AuthSlice) => (
  auth.creation.data
)

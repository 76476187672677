import { TableCell, Tooltip } from '@mui/material'

import styles from './styles.module.css'

type Obj = {
  [key: string]: string | null;
};

export const TableMapCell = ({
  array,
  fieldName,
}: {
  array: Obj[];
  fieldName: keyof Obj;
}) => {
  const arr = array.map((element) => {

    return [element[fieldName]]
  })
 
  const str = arr.map((element, index) => index === arr.length - 1 ? element : `${element},`)

  return (
    <TableCell>
      {str !== null ? (
        <Tooltip title={str} placement='left'>
          <div key={str.join(',') } className={styles.cell_ellipsis}>
            {str}
          </div> 
        </Tooltip>          
      ) : (
        <div className={styles.cell_ellipsis}></div>  
      )}
    </TableCell>
  )
}
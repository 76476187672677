import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { ClickAwayListener, IconButton, Tooltip } from '@mui/material'
import { ReactNode, useState } from 'react'

export const CustomTooltip = ({ title, children }: { title: string, children: ReactNode }) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(!open)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div style={{width: '103%'}}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={title}
          placement='bottom'
        >
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3}}>
            {children}
            <IconButton onClick={handleTooltipOpen} style={{padding: 4}}><HelpOutlineIcon style={{width: 15, height: 15}}/></IconButton>
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

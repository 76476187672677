import { Base } from 'api'

import {
  GetBreadcrumbsAction,
  GetBreadcrumbsErrorAction,
  GetBreadcrumbsSuccessAction,
  ResetGetBreadcrumbsAcrion,
} from './breadcrumbs.action'

export const getBreadcrumbs = (payload: Base.BreadcrumbsQuery ): GetBreadcrumbsAction => ({
  type: 'breadcrumbs.get_breadcrumbs',
  payload,
})

export const getBreadcrumbsSuccess = (
  payload: GetBreadcrumbsSuccessAction['payload']
): GetBreadcrumbsSuccessAction => ({
  type: 'breadcrumbs.get_breadcrumbs.success',
  payload,
})

export const getBreadcrumbsError = (
  payload: GetBreadcrumbsErrorAction['payload']
): GetBreadcrumbsErrorAction => ({
  type: 'breadcrumbs.get_breadcrumbs.error',
  payload,
})

export const resetGetBreadcrumbs = (): ResetGetBreadcrumbsAcrion => ({
  type: 'breadcrumbs.reset_get_breadcrumbs',
  payload: undefined,
})

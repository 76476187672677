/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'components'
import { Notification } from 'components/Notification'
import { useNotification } from 'hooks'
import { manageAccess } from 'modules'

import { IManageAccess } from '../ManageAccessTable/ManageAccessTable.props'

import styles from './styles.module.css'

export interface IEditAccountFormFields {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  accessRights: [
    { id: string, name: boolean }
  ]
}

interface CreateAccountFormProps {
  defaultValues: any;
  onClose: () => void;
  record: IManageAccess;
}

export const EditAccountForm: FC<CreateAccountFormProps> = ({
  defaultValues,
  onClose,
  record
}) => {
  const dispatch = useDispatch()
  const { openNotification, notificationData } = useNotification()

  let arr = ['aControlUsers', 'aControlRoles', 'aControlUsersProfile', 'aControlPermit', 'aControlEtalon', 'sControlAdmins', 'sJournal', 'uSearch', 'uSearchAdv',
    'uRepGeneration', 'uRepSaving', 'uRepUploading']

  const baseDefaultValues = {
    name: defaultValues?.name,
    accessRights: [
      { id: 'aControlUsers', name: false },
      { id: 'aControlRoles', name: false },
      { id: 'aControlUsersProfile', name: false },
      { id: 'aControlPermit', name: false },
      { id: 'aControlEtalon', name: false },
      { id: 'sControlAdmins', name: false },
      { id: 'sJournal', name: false },
      { id: 'uSearch', name: false },
      { id: 'uSearchAdv', name: false },
      { id: 'uRepGeneration', name: false },
      { id: 'uRepSaving', name: false },
      { id: 'uRepUploading', name: false },
    ]
  }

  if (defaultValues?.accessRights) {
    let filteredArr = defaultValues.accessRights = defaultValues.accessRights.filter((elem: { id: string }) => arr.includes(elem.id))
    filteredArr = arr.filter(item => {
      return !defaultValues?.accessRights.some((accessItem: { id: string }) => accessItem.id === item)
    })

    let falseAccessRights: { id: string, name: boolean }[] = []

    filteredArr.map((element: any) => {
      return falseAccessRights.push({ id: element, name: false })
    })
    const newDefaultValues = {
      name: defaultValues.name,
      accessRights: [
        ...defaultValues.accessRights,
        ...falseAccessRights
      ]
    }
    newDefaultValues.accessRights.sort((a, b) => a.id.localeCompare(b.id))
    defaultValues = newDefaultValues


  }
  else {
    baseDefaultValues.accessRights.sort((a, b) => a.id.localeCompare(b.id))
    defaultValues = baseDefaultValues
  }

  const [checkBoxes, setCheckBoxes] = useState<Record<string, boolean>>(defaultValues.accessRights.reduce((acc: Record<string, boolean>, curr: { id: string; name: boolean }) => {
    acc[curr.id] = curr.name
    return acc
  }, {}))


  const [originalCheckBoxes, setOriginalCheckBoxes] = useState<Record<string, boolean>>({})
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  let [attrAccess, setAttrAccess] = useState(defaultValues?.accessRights)

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked: name } = event.target
    const changeAccess: [] = attrAccess?.map((el: { id: string }) => {
      return el.id === id
        ? { ...el, name }
        : el
    })
    setCheckBoxes((prevCheckBoxes) => ({
      ...prevCheckBoxes,
      [id]: name,
    }))

    setAttrAccess(changeAccess)
  }

  useEffect(() => {
    const hasChanged = Object.keys(checkBoxes).some((key) => checkBoxes[key] !== originalCheckBoxes[key])
    setIsButtonDisabled(!hasChanged)
  }, [checkBoxes, originalCheckBoxes])

  useEffect(() => {
    setOriginalCheckBoxes(checkBoxes)
  }, [])


  const updationLoading = useSelector(
    manageAccess.useManageAccessUpdateLoading
  )
  const updationSucceed = useSelector(
    manageAccess.useManageAccessUpdateSuccess
  )

  const submitFormHandler = (
    formValues: any
  ) => {
    if (record.name !== 'admin' && record.name !== 'admin_security' && record.name !== 'administrator' && record.name !== 'user') {
      const data = {
        name: defaultValues?.name,
        accessRights: [
          ...formValues
        ]
      }
      data.accessRights = data.accessRights.filter(item => item.name)
      const sControlAdminsExists = data.accessRights.some((el: { id: string }) => el.id === 'sControlAdmins')
      const sJournalExists = data.accessRights.some((el: { id: string }) => el.id === 'sJournal')
      const hasOtherAttributes = data.accessRights.some((el: { id: string }) => el.id !== 'sControlAdmins' && el.id !== 'sJournal')

      if ((sControlAdminsExists || sJournalExists) && hasOtherAttributes) {
        openNotification('Атрибуты администратора безопасности нельзя использовать совместно с другими атрибутами.', 'error')
      } else {
        dispatch(
          manageAccess.actions.updateManageAccess({ name: defaultValues?.name!, body: data })
        )
      }
    }
    if (record.name === 'admin' || record.name === 'admin_security' || record.name === 'administrator' || record.name === 'user') {
      openNotification('Роли "admin", "admin_security", "administrator", "user" запрещено редактировать.', 'error')
    }
  }

  useEffect(() => {
    if (updationSucceed) {
      onClose()
      dispatch(
        manageAccess.actions.resetUpdateManageAccess()
      )
      dispatch(
        manageAccess.actions.updateManageAccessListFilter({})
      )
    }
  }, [dispatch, updationSucceed, onClose])

  const getLabel = (attrSearch: { id: string }) => {
    if (attrSearch?.id === 'aControlUsers') {
      return 'Управление пользователями'
    } else if (attrSearch?.id === 'aControlRoles') {
      return 'Управление ролями'
    } else if (attrSearch?.id === 'aControlUsersProfile') {
      return 'Управление учетными записями'
    } else if (attrSearch?.id === 'aControlPermit') {
      return 'Управление разрешениями'
    } else if (attrSearch?.id === 'aControlEtalon') {
      return 'Управление эталонным перечнем'
    } else if (attrSearch?.id === 'sControlAdmins') {
      return 'Управление администраторами'
    } else if (attrSearch?.id === 'sJournal') {
      return 'Просмотр и поиск в журнале аудита системы'
    } else if (attrSearch?.id === 'uSearch') {
      return 'Поиск данных'
    } else if (attrSearch?.id === 'uSearchAdv') {
      return 'Поиск расширенный'
    } else if (attrSearch?.id === 'uRepGeneration') {
      return 'Избранное'
    } else if (attrSearch?.id === 'uRepSaving') {
      return 'Сохранение отчетов'
    } else if (attrSearch?.id === 'uRepUploading') {
      return 'Выгрузка отчетов'
    } else {
      return ''
    }
  }

  return (
    <>
      <div >
        <div className={styles.modal__inputsWrapper}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <div className={styles.modal__inputBlock}>
                <label className={styles.modal__inputLabel} htmlFor='name'>
                  {defaultValues?.name}
                </label>
              </div>
            </Grid>
            <Grid item sm={12} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginLeft: '5px' }}>
              {defaultValues?.accessRights?.map((accessEl: { id: string }) => {
                const attrSearch = attrAccess?.find((el: { id: string }) => el.id === accessEl.id)
                const label = getLabel(attrSearch)
                return (
                  <div className={styles.wrap__checkbox} key={accessEl.id}>
                    <FormControlLabel
                      key={accessEl.id}
                      sx={{ width: '530px' }}
                      label={label}
                      control={
                        <div className={styles.form__toggler}>
                          <Checkbox
                            id={accessEl.id}
                            checked={attrSearch?.name}
                            onChange={handleToggle}
                            icon={<span className={styles.checkboxIcon} />}
                            checkedIcon={<span className={styles.checkboxIconChecked}><img src='/assets/Vector.svg' alt='check' /></span>}
                          />
                        </div>
                      }
                    />
                  </div>
                )
              })}
            </Grid>
          </Grid>
        </div>
        <div className={styles.modal__btnsWrapper}>
          <button
            className={styles.modal__btn}
            type='button'
            disabled={isButtonDisabled}
            onClick={() => submitFormHandler(attrAccess)}
          >
            Сохранить
          </button>
          <button className={styles.modal__btn} onClick={onClose}>Отмена</button>
        </div>
      </div>
      {(updationLoading && <Loader />)}
      <Notification {...notificationData} />
    </>
  )
}

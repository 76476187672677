import { getInitialAsyncState } from 'modules/base'

import { AuditSchema } from './audit.schema'

export const initialState: AuditSchema = {
  listFilter: {
    page: 1,
    size: 20
  },
  list: getInitialAsyncState({
    number: 0,
    totalPages: 0,
    totalElements: 0,
    content: [],
  })
}

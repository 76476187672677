import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import style from '../InfoFormOrganization.module.css'
import { PropsData } from '../types'


export const OwnersBig: FC<PropsData> = ({ data }) => {
  const navigate = useNavigate()
  const info = data.foundersInfo

  const moveToOrganization = (unp: string) => navigate(`/user-space/${unp}/info`)
  return (
    <div className={style.containerComponent}>
      <p className={style.generalName}>Учредители</p>
      {info.map((e, i) => (
        <React.Fragment key={i}>
          {e.founderName ?
            <div className={style.bigFormFlexHr}>
              <p className={style.headline}>{e.founderName}</p>
              <hr />
            </div> : null}
          {e.founderUnp ? <p className={style.about}>УНП {<span className={style.link} onClick={() => moveToOrganization(e.founderUnp)}>{e.founderUnp}</span>}</p> : null}
          {e.statFundPortionPercent ?
            <>
              <p className={style.headline}>Доля в уставном фонде (%)</p>
              <p className={style.about}>{e.statFundPortionPercent}</p>
            </> : null}
          {e.statFundContributionAmountTotal && e.statFundContributionAmountTotalCurrencyName ?
            <>
              <p className={style.headline}>Доля в уставном фонде (всего)</p>
              <p className={style.about}>{e.statFundContributionAmountTotal + ' ' + e.statFundContributionAmountTotalCurrencyName}</p>
            </> : null}
          {e.statFundContributionAmountMoney ?
            <>
              <p className={style.headline}>Доля в уставном фонде (деньги)</p>
              <p className={style.about}>{e.statFundContributionAmountMoney + ' ' + e.statFundContributionAmountMoneyCurrencyName}</p>
            </> : null}
          {e.statFundContributionAmountEstate ?
            <>
              <p className={style.headline}>Доля в уставном фонде (имущество)</p>
              <p className={style.about}>{e.statFundContributionAmountEstate + ' ' + e.statFundContributionAmountEstateCurrencyName}</p>
            </> : null}
          {e.founderCountry ?
            <>
              <p className={style.headline}>Страна учредителя</p>
              <p className={style.about}>{e.founderCountry}</p>
            </> : null}
          {e.depositaryName ?
            <>
              <p className={style.headline}>Депозитарий</p>
              <p className={style.about}>{e.depositaryName} {e.depositaryUnp ? ', УНП ' + e.depositaryUnp + ',' : ''} {e.depositaryFounderType || ''}</p>
            </> : null}
          {e.stocks.length ? <p className={style.headline}>Акции</p> : null}
          {e.stocks.map(stock => (
            <div className={style.registrationFlex} style={{ width: 'max-content' }}>
              <div className={style.bigFormFlexSubs}>
                {stock.stockShareIdentificationCode ? <p className={style.headlineReg}>Идентификационный код выпуска акций</p> : null}
                {stock.stockShareStateRegNum ? <p className={style.headlineReg}>Государственный регистрационный  номер выпуска акций</p> : null}
                {stock.stockShareAmount ? <p className={style.headlineReg}>Количество акций выпуска</p> : null}
              </div>
              <div className={style.bigFormFlexSubs}>
                <p className={style.aboutReg}>{stock.stockShareIdentificationCode}</p>
                <p className={style.aboutReg}>{stock.stockShareStateRegNum}</p>
                <p className={style.aboutReg} style={{ marginTop: 29 }}>{stock.stockShareAmount}</p>
              </div>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}

import { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Notification } from 'components/Notification' 
import { useNotification } from 'hooks'
import { manageAccess } from 'modules'

import { ManageAccessTable } from './components/ManageAccessTable'
import { ManageAccessTableProps } from './components/ManageAccessTable/ManageAccessTable.props'
import {
  PAGE_DEFAULT,
} from './ManageAccessPage.const'

export const ManageAccessPage: FC = () => {
  const dispatch = useDispatch()
  const { openNotification, notificationData } = useNotification()

  const records = useSelector(
    manageAccess.useManageAccessListItems
  )
  const recordsLoading = useSelector(
    manageAccess.useManageAccessListLoading
  )
  const recordsTotal = useSelector(
    manageAccess.useManageAccessListItemsTotalCount
  )
  const recordsFilter = useSelector(
    manageAccess.useManageAccessListFilter
  )
  const updationSucceed = useSelector(
    manageAccess.useManageAccessUpdateSuccess
  )
  const updationError = useSelector(
    manageAccess.useManageAccessUpdateError
  )
  const updationErrorText = useSelector(
    manageAccess.useManageAccessUpdateErrorMessage
  )

  useEffect(() => {

    if (updationSucceed) {
      openNotification('Изменения сохранены', 'success')
    }
    if (updationError) {
      openNotification(updationErrorText, 'error')
    }
  }, [dispatch, updationSucceed, updationError, updationErrorText, openNotification])

  useEffect(() => {
    dispatch(
      manageAccess.actions.updateManageAccessListFilter({
        _page: PAGE_DEFAULT,
        _limit: 10,
      })
    )

    return () => {
      dispatch(
        manageAccess.actions.resetGetManageAccessList()
      )
    }
  }, [dispatch])

  // change page
  const handleTablePageChange = useCallback<
  ManageAccessTableProps['onPageChange']
  >(
    (_page) => {
      dispatch(
        manageAccess.actions.updateManageAccessListFilter({
          _page,
        })
      )
    },
    [dispatch]
  )

  // change page size
  const handleTablePageSizeChange = useCallback<
  ManageAccessTableProps['onPageSizeChane']
  >(
    (_limit) => {
      dispatch(
        manageAccess.actions.updateManageAccessListFilter({
          _page: PAGE_DEFAULT,
          _limit,
        })
      )
    },
    [dispatch]
  )

  // change sorting
  const handleTableSort = useCallback<
  ManageAccessTableProps['onSort']
  >(
    (direction, sortField) => {
      dispatch(
        manageAccess.actions.updateManageAccessListFilter({
          _page: PAGE_DEFAULT,
          direction,
          // sortField,

        })
      )
    },
    [dispatch]
  )

  return (
    <>
      <ManageAccessTable
        loading={recordsLoading}
        records={records}
        page={recordsFilter._page || PAGE_DEFAULT}
        pageSize={10}
        total={recordsTotal}
        // sortBy={recordsFilter.sortField}
        sortDir={recordsFilter.direction}
        onPageChange={handleTablePageChange}
        onPageSizeChane={handleTablePageSizeChange}
        onSort={handleTableSort}
      />
      <Notification {...notificationData} />
    </>
  )
}

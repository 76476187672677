import * as actions from './reports.creator'
import { reducer } from './reports.reducer'
import { saga } from './reports.saga'
import * as selectors from './reports.selector'

export const reports = {
  ...selectors,
  actions,
  reducer,
  saga,
}

import { apiBase } from 'api/base'

import { SUBPATH } from './auth.const'
import {
  AuthLogin,
} from './auth.interface'

export const login = (
  body: AuthLogin.Body,
) => (
  apiBase.post<AuthLogin.Data>(SUBPATH, body)
)

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { FormControl, MenuItem, Select } from '@mui/material'
import { FC, useState } from 'react'

import { QuantitySelectorType, organizationsQuantitySelector } from './SelectRowQuantity.const'
import styles from './SelectRowQuantity.module.css'


type Props = {
    handleChange: Function;
    rowQuantity: number | string;
    data?: QuantitySelectorType[];
    width?: number;
}

export const SelectRowQuantity: FC<Props> = ({ 
  rowQuantity, 
  handleChange, 
  data = organizationsQuantitySelector,
  width
}) => {
  const [openSelect, setOpenSelect] = useState(false)

  const handleClose = () => {
    setOpenSelect(false)
  }

  const handleOpen = () => {
    setOpenSelect(true)
  }

  return (
    <FormControl className={styles.selectControl} style={{width: width ? width : 280}}>
      <Select
        className={styles.select}
        open={openSelect}
        onClose={handleClose}
        onOpen={handleOpen}
        autoWidth
        defaultValue={rowQuantity}
        IconComponent={() => (
          !openSelect ? 
            <KeyboardArrowRightIcon className={styles.icon}/> 
            : <KeyboardArrowDownIcon className={styles.icon}/>
        )}
        value={rowQuantity}
        renderValue={(selected) => (
          <p>
            {
              data?.find(
                (x) => x.value === selected
              )?.label
            }
          </p>
        )}
        onChange={handleChange as VoidFunction}
      >
        {data.map((el) => (
          <MenuItem
            key={el.value}
            value={el.value}
            selected={el.value === rowQuantity}
            className={el.value === rowQuantity ? styles.menuItemActive : styles.menuItem}
          >
            <p>{el.label}</p>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

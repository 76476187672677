import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Notification } from 'components/Notification'
import { useNotification } from 'hooks/useNotification'
import { reference } from 'modules'
import {
  ReferenceList,
  ReferenceListProps,
  TableListProps,
  TableMasterList,
  FormListProps,
} from 'views'

import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from './Home.const'


export const EtalonPage = () => {
  const [referenceListModalOpen, setReferenceListModalOpen] = useState(false)
  const [referenceListModalData, setReferenceListModalData] = useState<FormListProps | null>(null)

  const dispatch = useDispatch()

  const items = useSelector(reference.useListItems)
  const itemsLoading = useSelector(reference.useListLoading)
  const itemsTotal = useSelector(reference.useListItemsTotalCount)
  const itemsFilter = useSelector(reference.useListFilter)

  const creationLoading = useSelector(reference.useCreationLoading)
  const creationSucceed = useSelector(reference.useCreationSuccess)
  const creationError = useSelector(reference.useCreationError)
  const creationErrorText = useSelector(reference.useCreationErrorMessage)

  const updationLoading = useSelector(reference.useUpdationLoading)
  const updationSucceed = useSelector(reference.useUpdationSuccess)
  const updationError = useSelector(reference.useUpdationError)
  const updationErrorText = useSelector(reference.useUpdationErrorMessage)

  const deletionLoading = useSelector(reference.useDeletionLoading)
  const deletionSucceed = useSelector(reference.useDeletionSuccess)
  const deletionError = useSelector(reference.useDeletionError)
  const deletionErrorText = useSelector(reference.useDeletionErrorMessage)

  const importionLoading = useSelector(reference.useImportionLoading)
  const importionSucceed = useSelector(reference.useImportionSuccess)
  const importionErrorMessage = useSelector(reference.useImportionErrorMessage)

  const { openNotification, notificationData } = useNotification()

  useEffect(() => {
    dispatch(reference.actions.updateListFilter({page: PAGE_DEFAULT, size: PAGE_SIZE_DEFAULT}))
    return () => {
      dispatch(reference.actions.resetFilter())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  const onReferenceListModalOpen = () => {
    setReferenceListModalOpen(true)
  }
  const onReferenceListModalClose = () => {
    setReferenceListModalOpen(false)
    setReferenceListModalData(null)
  }

  const handleTableDelete = useCallback<TableListProps['onDelete']>((id) => {
    dispatch(reference.actions.remove({ id }))
  }, [dispatch])

  useEffect(() => {
    if (deletionSucceed) {
      dispatch(reference.actions.resetDeletion())
      dispatch(reference.actions.updateListFilter({}))
      openNotification('Запись удалена', 'success')
    }
    if (deletionError) {
      openNotification(deletionErrorText, 'error')
      dispatch(reference.actions.resetDeletion())
    }
  }, [dispatch, openNotification, deletionSucceed, deletionError, deletionErrorText])

  const handleDetailsSubmit = useCallback<ReferenceListProps['onSubmit']>((body) => {
    if (body.id) {
      dispatch(reference.actions.update(body))
    } else {
      dispatch(reference.actions.create(body))
    }
  }, [dispatch])

  useEffect(() => {
    if (creationSucceed || updationSucceed) {
      dispatch(reference.actions[creationSucceed ? 'resetCreation' : 'resetUpdation']())
      onReferenceListModalClose()
      dispatch(reference.actions.updateListFilter({}))
      openNotification(creationSucceed ? 'Организация успешно сохранена' : 'Изменения сохранены', 'success')
    } 
    if (updationError) {
      openNotification(updationErrorText, 'error')
      dispatch(reference.actions.resetUpdation())
    }
    if(creationError) {
      openNotification(creationErrorText, 'error')
      dispatch(reference.actions.resetCreation())
    }
  }, [dispatch, openNotification, setReferenceListModalOpen, creationSucceed, updationSucceed, updationError, creationError, creationErrorText, updationErrorText])

  const handleTableFileChange = useCallback<TableListProps['onFileChange']>(async (file) => {
    dispatch(reference.actions.importSrc({ file }))
  }, [dispatch])

  useEffect(() => {
    if (importionSucceed) {
      dispatch(reference.actions.resetImportion())
      dispatch(reference.actions.updateListFilter({}))
      openNotification('Загрузка завершена', 'success')
    }
    if (importionErrorMessage) {
      openNotification(importionErrorMessage, 'error')
      dispatch(reference.actions.resetImportion())
    }
  }, [dispatch,openNotification, importionSucceed, importionErrorMessage])

  const handleTableEdit = (data: FormListProps) => {
    setReferenceListModalData(data)
    onReferenceListModalOpen()
  }

  const handleTablePageChange = useCallback<TableListProps['onPageChange']>((page) => {
    dispatch(reference.actions.updateListFilter({ page }))
  }, [dispatch])

  const handleTablePageSizeChange = useCallback<TableListProps['onPageSizeChane']>((size) => {
    dispatch(reference.actions.updateListFilter({ page: PAGE_DEFAULT, size }))
  }, [dispatch])

  const handleTableSort = useCallback<TableListProps['onSort']>((direction, sortField) => {
    dispatch(reference.actions.updateListFilter({ page: PAGE_DEFAULT, direction, sortField }))
  }, [dispatch])

  return (
    <>
      <TableMasterList
        loading={itemsLoading || deletionLoading || importionLoading}
        onEdit={handleTableEdit}
        onCreate={onReferenceListModalOpen}
        onDelete={handleTableDelete}
        rows={items as unknown as TableListProps['rows']}
        total={itemsTotal}
        page={itemsFilter.page || PAGE_DEFAULT}
        pageSize={itemsFilter.size || PAGE_SIZE_DEFAULT}
        onPageChange={handleTablePageChange}
        onPageSizeChane={handleTablePageSizeChange}
        sortBy={itemsFilter.sortField}
        sortDir={itemsFilter.direction}
        onSort={handleTableSort}
        fileError={importionErrorMessage}
        onFileChange={handleTableFileChange}
      />
      {referenceListModalOpen && <ReferenceList
        referenceListModalData={referenceListModalData}
        onSubmit={handleDetailsSubmit}
        loading={creationLoading || updationLoading}
        onClose={onReferenceListModalClose}
      />}
      <Notification {...notificationData} />
    </>
  )
}

import { getInitialAsyncState } from 'modules/base'

import { ManageRoleSchema } from './manageRole.schema'

export const initialState: ManageRoleSchema = {
  listFilter: {
    _page: 1,
    _limit: 10,
  },
  list: getInitialAsyncState({
    number: 0,
    totalPages: 0,
    totalElements: 0,
    content: [],
  }),
  creation: getInitialAsyncState(null),
  updation: getInitialAsyncState(null),
  deletion: getInitialAsyncState(null),
}

import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { HEAD_CELLS_ROLE } from './ManageAccessTable.const'
import styles from './styles.module.css'


export const ManageAccessTableHead = () => {

  return (
    <TableHead className={styles.tableHeader}>
      <TableRow>
        {HEAD_CELLS_ROLE.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={`${headCell.id === 'name' ? 'left' : 'right'}`}
            className={styles.tableCell}
          >
            <b>{headCell.label}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

import { createContext } from 'react'


export const PermissionContext = createContext({
  permissionControlUsersProfile: false,
  permissionJournal: false,
  permissionControlAdmins: false,
  permissionControlUsers: false,
  permissionControlRoles: false,
  permissionControlPermit: false,
  permissionControlEtalon: false,
  permissionSearch: false,
  permissionSearchAdv: false,
  permissionRepGeneration: false,
  permissionRepSaving: false,
  permissionRepUploading: false,
  isAdmin: false,
  isUser: false,
  isAdminSecurity: false
})

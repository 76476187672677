import { FC } from 'react'

import style from '../InfoFormOrganization.module.css'
import { PropsData } from '../types'


export const FormOwners: FC<PropsData> = ({ data }) => {
  return (
    <div className={style.containerComponent}>
      <p className={style.generalName}>Форма собственности и подчиненность</p>
      {data.ownershipTypesInfoOwnershipType ?
        <>
          <p className={style.headline}>Форма собственности</p>
          <p className={style.about}>{data.ownershipTypesInfoOwnershipType}</p>
        </> : null}
      {data.ownershipTypeFacet ?
        <>
          <p className={style.headline}>Фасет формы собственности</p>
          <p className={style.about}>{data.ownershipTypeFacet}</p>
        </> : null}
      {data.okoguName ?
        <>
          <p className={style.headline}>Государственная организация подчиненности</p>
          <p className={style.about}>{data.okoguName}</p>
        </> : null}
      {data.okoguFacet ?
        <>
          <p className={style.headline}>Фасет подчиненности </p>
          <p className={style.about}>{data.okoguFacet}</p>
        </> : null}
    </div>
  )
}

import { Base } from 'api/base'
import { TOrganizationColumnId, TOrganizationTable } from 'common/types'

import { DashboardDataFlagQuery, TDashboars, TDashboarsPayload, TMainDashboards, TStringDashboarsPayload } from './dashboards.model'

export namespace GetMainDashboards {
  export type Data = TMainDashboards
  export type Response = Base.Response<Data>
}

export namespace GetDashboards {
  export type Payload = TDashboarsPayload;
  export type StringPayload = TStringDashboarsPayload;
  export type Data = TDashboars;
  export type Response = Base.Response<Data>;
}

export namespace GetDashboardsData {
  export type SortField = TOrganizationColumnId;
  export type Query = Partial<TStringDashboarsPayload> &
    Partial<Base.PaginatedQuery> &
    Partial<Base.SortQuery<SortField>> & Partial<DashboardDataFlagQuery>
  export type DataItem = TOrganizationTable;
  export type Data = Base.PaginatedData<DataItem>;
  export type Response = Base.PaginatedResponse<DataItem>;
}

import { SubmitHandler, useForm } from 'react-hook-form'

import { SearchLoupeIcon } from 'icons'
import {
  SearchFormFields,
  SearchFormProps,
} from 'pages/SecurityPage/SecurityPage.interface'

import styles from './styles.module.css'

export const SearchForm = ({ placeholder, onSubmit }: SearchFormProps) => {
  const { register, handleSubmit, formState: { isDirty } } = useForm<SearchFormFields>({
    defaultValues: {
      username: '',
    },
  })

  const getUserHandler: SubmitHandler<SearchFormFields> = (values) => {
    onSubmit({ email: values.username, username: values.username })
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(getUserHandler)}>
      <SearchLoupeIcon />
      <input
        className={styles.form__input}
        id='username'
        type='text'
        placeholder={placeholder}
        {...register('username')}
      />
      <button className={styles.form__btn} type='submit' disabled={!isDirty}>
        Искать
      </button>
    </form>
  )
}

export const DEFAULT_STATUS = 'all'

export const PAGE_DEFAULT = 1

export const PAGE_SIZE_DEFAULT = 20

export const MOCK_RECORDS = [
  {
    role: 'admin',
    description: 'test1test',
    id: '1'
  },
  {
    role: 'admin',
    description: 'test1terrrest',
    id: '2'
  },
]
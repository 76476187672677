// @ts-nocheck
import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CustomTooltip } from 'components/CustomTooltip'
import { organizations } from 'modules'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'

import style from './CustomAutocomplete.module.css'


export const CustomAutocomplete = ({ data, label, name, tooltipText, placeholder }: { data: { name: string, value: string }[], label: string, name: keyof FormListProps, tooltipText: string, placeholder: string }) => {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const closePopper = () => setOpen(false)
  const openPopper = () => setOpen(true)

  const dataRedux = useSelector(organizations.useStateFilter)

  const setValue = (value: string[]) => {
    dispatch(
      organizations.actions.setDataFilterOrganizations({
        [name]: value
      })
    )
  }

  const isSelected = (option: string) => option === dataRedux[name]?.find(e => e === option)

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 200,
    stringify: (option: { name: string, value: string }) => option.name
  })

  return (
    <Autocomplete
      filterOptions={filterOptions}
      value={dataRedux[name] ? dataRedux[name] : []}
      isOptionEqualToValue={(option, value) => option.value === value}
      multiple
      disablePortal
      noOptionsText={'Ничего не найдено'}
      options={data}
      open={open}
      onOpen={openPopper}
      onClose={(event: React.SyntheticEvent, reason: string)=> {
        if(reason === 'blur') {
          closePopper()
        }
      }}
      onChange={(event: unknown, newValue: { name: string, value: string } | null, reason) => {
        if(reason === 'clear') {
          setValue(undefined)
        }
        if(reason !== 'clear') {
          setValue(newValue.map((option: { name: string, value: string }) => option.value || option))
        }
     
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        return (
          <li
            {...props}
            key={option.value}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              background: isSelected(option.value) ? '#F0F8FF' : 'none',
              minHeight: 55,
              borderBottom: '1px solid rgba(229, 229, 229, 0.52)',
              fontSize: 13
            }}
            className={style.MuiAutocompleteOption}
          >
            {option.name}
          </li>
        )
      }}
      renderInput={(params) =>
        <div>
          <CustomTooltip title={tooltipText}>
            <p className={style.fieldset__label}>{label}</p>
          </CustomTooltip>
          <TextField
            {...params}
            size='small'
            placeholder={!dataRedux[name]?.length ? placeholder: ''}
            sx={{
              input: { color: '#000000', fontSize: 13, '::placeholder': { fontSize: 12 }, },
              '& .MuiInputBase-root': { borderRadius: 0, height: 32, paddingTop: '4px !important', letterSpacing: 0 },
              '& .MuiOutlinedInput-notchedOutline': { border: '0.5px solid #4F4F4F', boxSizing: 'border-box', borderColor: dataRedux[name]?.length ? '#1E6EBE' : 'inherit', borderWidth: dataRedux[name]?.length ? '2px' : '0.5px' },
              '& .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {minWidth: 0}
            }}
          />
        </div>
      }
      renderTags={() => <p style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 130, height: 20}}> {dataRedux[name]?.length === 1 ? data.find(e => e.value == dataRedux[name])?.name : `Выбрано ${dataRedux[name]?.length} зн.`} </p>}
    />
  )
}

import { FC } from 'react'

import style from '../InfoFormOrganization.module.css'
import { PropsData } from '../types'
import { concatArrays } from '../utils/concatArrays'


export const General: FC<PropsData> = ({ data, mode }) => {
  // объединение 2-ух массивов с чередованием элементов
  const result = concatArrays(data.activityTypeCode, data.activityTypeType)

  return (
    <div className={style.containerComponent}>
      <p className={style.generalName}>Общая информация</p>
      {data.fullName ?
        <>
          <p className={style.headline}>Полное наименование</p>
          <p className={style.about}>{data.fullName}</p>
        </>
        : null}
      {data.shortName ?
        <>
          <p className={style.headline}>Краткое наименование</p>
          <p className={style.about}>{data.shortName}</p>
        </> : null}
      <div className={style.flex}>
        {data.unp ?
          <div>
            <p className={style.headline}>УНП</p>
            <p className={style.about}>{data.unp}</p>
          </div>
          : null}
        {data.organizationBaseInfoBelstatCode ?
          <div>
            <p className={style.headline}>Код Белстат</p>
            <p className={style.about}>{data.organizationBaseInfoBelstatCode}</p>
          </div> : null}
      </div>
      {data.egrCurrentState ?
        <>
          <p className={style.headline}>Текущее состояние ЕГР</p>
          <p className={style.aboutColor}>{data.egrCurrentState}</p>
        </> : null}
      {data.grpCurrentState ?
        <>
          <p className={style.headline}>Текущее состояние ГРП</p>
          <p className={style.aboutColor}>{data.grpCurrentState}</p>
        </> : null}
      {mode === 'full' && data.grpStateChangeDate ?
        <>
          <p className={style.headline}>Дата изменения состояния ГРП</p>
          <p className={style.about}>{data.grpStateChangeDate?.split('-').reverse().join('.')}</p>
        </>
        : null}
      {data.activityTypeCode.length ?
        <>
          <p className={style.headline}>Вид деятельности</p>
          <div>
            {result.map((e, i) => {
              if (i % 2) {
                return <p key={i} style={{ marginBottom: 15 }}>{e}</p>
              }
              return <span key={i} style={{}}>{e}</span>
            })}
          </div>
        </> : null}
      {data.fullAddress ?
        <>
          <p className={style.headline}>Юридический адрес</p>
          <p className={style.about}>{data.fullAddress}</p>
        </> : null}
      {data.phoneNumbers || data.fax || data.email ?
        <>
          <p className={style.headline}>Контакты</p>
          {data.phoneNumbers ? <p className={style.about}>Телефон {data.phoneNumbers}</p> : null}
          {data.fax ? <p className={style.about}>Факс {data.fax}</p> : null}
          {data.email ? <p className={style.about}>E-mail {data.email}</p> : null}
        </>
        : null}
    </div>
  )
}

import {
  GetDashboardsAction,
  GetDashboardsDataAction,
  GetDashboardsDataErrorAction,
  GetDashboardsDataSuccessAction,
  GetDashboardsErrorAction,
  GetDashboardsSuccessAction,
  GetMainDashboardsAction,
  GetMainDashboardsErrorAction,
  GetMainDashboardsSuccessAction,
  ResetDashboardsFilterAction,
  ResetGetDashboardsAction,
  ResetGetDashboardsDataAction,
  ResetGetMainDashboardsAction,
  UpdateDashboardsFilterAction,
} from './dashboards.actions'

// get main
export const getMainDashboards = (): GetMainDashboardsAction => ({
  type: 'dashboards.get_main',
  payload: undefined,
})

export const getMainDashboardsSuccess = (
  payload: GetMainDashboardsSuccessAction['payload']
): GetMainDashboardsSuccessAction => ({
  type: 'dashboards.get_main.success',
  payload,
})

export const getMainDashboardsError = (
  payload: GetMainDashboardsErrorAction['payload']
): GetMainDashboardsErrorAction => ({
  type: 'dashboards.get_main.error',
  payload,
})

export const resetGetMainDashboards = (): ResetGetMainDashboardsAction => ({
  type: 'dashboards.reset_get_main',
  payload: undefined,
})

// filter
export const updateFilter = (
  payload: UpdateDashboardsFilterAction['payload']
): UpdateDashboardsFilterAction => ({
  type: 'dashboards.update_filter',
  payload,
})

export const resetFilter = (): ResetDashboardsFilterAction => ({
  type: 'dashboards.reset_filter',
  payload: undefined,
})

// get
export const getDashboards = (): GetDashboardsAction => ({
  type: 'dashboards.get',
  payload: undefined,
})

export const getDashboardsSuccess = (
  payload: GetDashboardsSuccessAction['payload']
): GetDashboardsSuccessAction => ({
  type: 'dashboards.get.success',
  payload,
})

export const getDashboardsError = (
  payload: GetDashboardsErrorAction['payload']
): GetDashboardsErrorAction => ({
  type: 'dashboards.get.error',
  payload,
})

export const resetGetDashboards = (): ResetGetDashboardsAction => ({
  type: 'dashboards.reset_get',
  payload: undefined,
})

export const getDashboardsData = (
  payload: GetDashboardsDataAction['payload']
): GetDashboardsDataAction => ({
  type: 'dashboards.get_data',
  payload,
})

export const getDashboardsDataSuccess = (
  payload: GetDashboardsDataSuccessAction['payload']
): GetDashboardsDataSuccessAction => ({
  type: 'dashboards.get_data.success',
  payload,
})

export const getDashboardsDataError = (
  payload: GetDashboardsDataErrorAction['payload']
): GetDashboardsDataErrorAction => ({
  type: 'dashboards.get_data.error',
  payload,
})

export const resetGetDashboardsData = (): ResetGetDashboardsDataAction => ({
  type: 'dashboards.reset_get_data',
  payload: undefined,
})

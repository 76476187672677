import {
  useData,
  useDataItemsTotalCount,
  useMetaErrorMessage,
  useMetaErrorStatus,
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
} from 'modules/base'

import { DashboardsSlice } from './dashboards.schema'

// get main
export const useGetMain = ({ dashboards }: DashboardsSlice) =>
  useData(dashboards.getMainDashboards)

export const useGetMainIdle = ({ dashboards }: DashboardsSlice) =>
  useMetaIdleStatus(dashboards.getMainDashboards)

export const useGetMainLoading = ({ dashboards }: DashboardsSlice) =>
  useMetaLoadingStatus(dashboards.getMainDashboards)

export const useGetMainSuccess = ({ dashboards }: DashboardsSlice) =>
  useMetaSuccessStatus(dashboards.getMainDashboards)

export const useGetMainError = ({ dashboards }: DashboardsSlice) =>
  useMetaErrorStatus(dashboards.getMainDashboards)

export const useGetMainErrorMessage = ({ dashboards }: DashboardsSlice) =>
  useMetaErrorMessage(dashboards.getMainDashboards)

// filter
export const useFilter = ({ dashboards }: DashboardsSlice) => dashboards.filter

// get
export const useGet = ({ dashboards }: DashboardsSlice) =>
  useData(dashboards.getDashboards)

export const useGetIdle = ({ dashboards }: DashboardsSlice) =>
  useMetaIdleStatus(dashboards.getDashboards)

export const useGetLoading = ({ dashboards }: DashboardsSlice) =>
  useMetaLoadingStatus(dashboards.getDashboards)

export const useGetSuccess = ({ dashboards }: DashboardsSlice) =>
  useMetaSuccessStatus(dashboards.getDashboards)

export const useGetError = ({ dashboards }: DashboardsSlice) =>
  useMetaErrorStatus(dashboards.getDashboards)

export const useGetErrorMessage = ({ dashboards }: DashboardsSlice) =>
  useMetaErrorMessage(dashboards.getDashboards)


export const useGetData = ({ dashboards }: DashboardsSlice) =>
  useData(dashboards.getDashboardsData)

export const useGetDataIdle = ({ dashboards }: DashboardsSlice) =>
  useMetaIdleStatus(dashboards.getDashboardsData)

export const useGetDataLoading = ({ dashboards }: DashboardsSlice) =>
  useMetaLoadingStatus(dashboards.getDashboardsData)

export const useGetDataSuccess = ({ dashboards }: DashboardsSlice) =>
  useMetaSuccessStatus(dashboards.getDashboardsData)

export const useGetDataError = ({ dashboards }: DashboardsSlice) =>
  useMetaErrorStatus(dashboards.getDashboardsData)

export const useGetDataErrorMessage = ({ dashboards }: DashboardsSlice) =>
  useMetaErrorMessage(dashboards.getDashboardsData)

export const useGetDataTotalCount = ({ dashboards }: DashboardsSlice) =>
  useDataItemsTotalCount(dashboards.getDashboardsData)

import axios from 'axios'

// our BE
export const apiBase = axios.create({
  baseURL: window.env.REACT_APP_API_PATH,
  headers: {
    Accept: 'application/json',
    'user-name': `${
      (localStorage.getItem('user') &&
      JSON.parse(localStorage.getItem('user')!).email)
        ? JSON.parse(localStorage.getItem('user')!).email
        : ''
    }`,
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
  params: {
    'user-name': `${
      (localStorage.getItem('user') &&
      JSON.parse(localStorage.getItem('user')!).fio)
        ? JSON.parse(localStorage.getItem('user')!).fio
        : ''
    }`
  },
})

// agsr BE
export const apiBaseAgsr = axios.create({
  baseURL: `${window.env.REACT_APP_AUTH_URL}`,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    // Host: 'http://localhost:3000',
    // Origin: 'http://localhost:3000',
  },
})

// download files
export const apiBaseFiles = axios.create({
  baseURL: '/api',
  headers: {
    Accept: 'application/json',
    'user-name': `${
      (localStorage.getItem('user') &&
      JSON.parse(localStorage.getItem('user')!).email)
        ? JSON.parse(localStorage.getItem('user')!).email
        : ''
    }`,
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
  params: {
    'user-name': `${
      (localStorage.getItem('user') &&
      JSON.parse(localStorage.getItem('user')!).fio)
        ? JSON.parse(localStorage.getItem('user')!).fio
        : ''
    }`
  },
  responseType: 'arraybuffer',
})

apiBaseAgsr.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let logoutKey: Response | any
      try {
        logoutKey = await fetch(`${window.env.REACT_APP_AUTH_URL}/logout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            idToken:
              window.localStorage.getItem('idToken') ||
              window.localStorage.getItem('access_token'),
            postLogoutRedirectUri: window.env.REACT_APP_LOGOUT_REDIRECT,
          }),
        })
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('access_token')
        window.localStorage.removeItem('space')
        window.localStorage.removeItem('nav_links')
        window.localStorage.removeItem('attributes')
        window.location.href = '/'
      } catch (response) {
        logoutKey = response
      }
      if (!logoutKey.ok) {
        alert('Ошибка выхода')
      }
    }
    return Promise.reject(error)
  }
)

apiBase.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let logoutKey: Response | any
      try {
        logoutKey = await fetch(`${window.env.REACT_APP_AUTH_URL}/logout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            idToken:
              window.localStorage.getItem('idToken') ||
              window.localStorage.getItem('access_token'),
            postLogoutRedirectUri: window.env.REACT_APP_LOGOUT_REDIRECT,
          }),
        })
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('access_token')
        window.localStorage.removeItem('space')
        window.localStorage.removeItem('nav_links')
        window.localStorage.removeItem('attributes')
        window.location.href = '/'
      } catch (response) {
        logoutKey = response
      }
      if (!logoutKey.ok) {
        alert('Ошибка выхода')
      }
    }
    return Promise.reject(error)
  }
)
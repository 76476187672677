export type QuantitySelectorType = {
    value: number;
    label: string;
  };
  
export const organizationsQuantitySelector: QuantitySelectorType[] = [
  { value: 20, label: 'Показывать по 20 организаций' },
  { value: 40, label: 'Показывать по 40 организаций' },
  { value: 60, label: 'Показывать по 60 организаций' },
  { value: 100, label: 'Показывать по 100 организаций' },
]

export const accountQuantitySelector: QuantitySelectorType[] = [
  { value: 20, label: 'Выводить по 20 пользователей' },
  { value: 40, label: 'Выводить по 40 пользователей' },
  { value: 60, label: 'Выводить по 60 пользователей' },
  { value: 100, label: 'Выводить по 100 пользователей' },
]

export const auditQuantitySelector: QuantitySelectorType[] = [
  { value: 20, label: 'Выводить по 20 записей' },
  { value: 40, label: 'Выводить по 40 записей' },
  { value: 60, label: 'Выводить по 60 записей' },
  { value: 100, label: 'Выводить по 100 записей' },
]

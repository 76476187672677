import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { Notification } from 'components/Notification'
import { useNotification } from 'hooks'
import { CloseIcon } from 'icons'
import { user } from 'modules'

import { ACCOUNT_PAGE_BREADCRUMBS } from './breadcrumbs'
import { EditAccountForm } from './components/EditAccountForm'
import styles from './styles.module.css'


export const AccountPage: FC = () => {
  const { notificationData, openNotification } = useNotification()
  const dispatch = useDispatch()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const toggleEditModeHandler = () => setIsEditMode((currState) => !currState)

  const userData = JSON.parse(localStorage.getItem('user')!)

  const updateUserEmailSuccess = useSelector(user.useUpdateUserEmailSuccess)
  const updateUserEmailError = useSelector(user.useUpdateUserEmailError)

  useEffect(() => {
    if(updateUserEmailSuccess) {
      openNotification('Изменения сохранены', 'success')

      setIsEditMode(false)

      return () => {
        dispatch(user.actions.resetUpdateUserEmail())
      }
    }

    if(updateUserEmailError) {
      openNotification('Пользователь с указанными данными уже существует', 'error')

      return () => {
        dispatch(user.actions.resetUpdateUserEmail())
      }
    }
  }, [dispatch, openNotification, updateUserEmailSuccess, updateUserEmailError])

  return (
    <>
      <div className={styles.wr}>
        <CustomBreadcrumbs {...ACCOUNT_PAGE_BREADCRUMBS} />
        <section className={styles.section}>
          {!isEditMode ? (
            <>
              <div className={styles.section__header}>
                <div className={styles.section__headlineContainer}>
                  <img src='/assets/Profile-circle.svg' alt='Выйти' />
                  <h2 className={styles.section__headline}>
                  Профиль пользователя
                  </h2>
                </div>
              </div>
              <div className={styles.section__content}>
                <div className={styles.section__contentBlock}>
                  <h3 className={styles.profile__fieldName}>ФИО</h3>
                  <span className={styles.profile__fieldValue}>
                    {userData.fio || ''}
                  </span>
                </div>
                <div className={styles.section__contentBlock}>
                  <h3 className={styles.profile__fieldName}>
                  Идентификационный номер
                  </h3>
                  <span className={styles.profile__fieldValue}>
                    {userData.personalNumber || ''}
                  </span>
                </div>
                <div className={styles.section__contentBlock}>
                  <h3 className={styles.profile__fieldName}>Логин</h3>
                  <span className={styles.profile__fieldValue}>
                    {userData.username || ''}
                  </span>
                </div>
                <div className={styles.section__contentBlock}>
                  <h3 className={styles.profile__fieldName}>E-mail</h3>
                  <span className={styles.profile__fieldValue}>
                    {userData.email || ''}
                  </span>
                </div>
                <div className={styles.section__contentBlock}>
                  <h3 className={styles.profile__fieldName}>
                  Контактный телефон
                  </h3>
                  <span className={styles.profile__fieldValue}>
                    {userData.phone || ''}
                  </span>
                </div>
                <div className={styles.section__contentBlock}>
                  <h3 className={styles.profile__fieldName}>
                  Организация, к которой относится пользователь
                  </h3>
                  <span className={styles.profile__fieldValue}>
                    {userData.work ? userData.work.name : ''}
                  </span>
                </div>
                <div className={styles.section__contentBlock}>
                  <h3 className={styles.profile__fieldName}>Должность</h3>
                  <span className={styles.profile__fieldValue}>
                    {userData.work ? userData.work.position : ''}
                  </span>
                </div>
              </div>
              <button
                className={styles.section__editButton}
                type='button'
                onClick={toggleEditModeHandler}
              >
              Редактировать
              </button>
            </>
          ) : (
            <>
              <div className={styles.section__header}>
                <div className={styles.section__headlineContainer}>
                  <img src='/assets/Profile-circle.svg' alt='Выйти' />
                  <h2 className={styles.section__headline}>
                  Редактирование Профиля пользователя
                  </h2>
                </div>
                <button
                  className={styles.section__closeButton}
                  type='button'
                  onClick={toggleEditModeHandler}
                >
                  <CloseIcon />
                </button>
              </div>
              <EditAccountForm
                defaultValues={{
                  email: userData.email,
                  phone: userData.phone,
                }}
              />
            </>
          )}
        </section>
      </div>
      <Notification {...notificationData} />
    </>
  )
}

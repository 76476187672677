import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { TOrganizationColumnId } from 'common/types'

import { CustomTableHeadProps } from './CustomTableHead.props'
import styles from './styles.module.css'

export const CustomTableHead = ({
  isSelected,
  orderBy,
  order: sortDir,
  onRequestSort,
  selectAllRows,
  columns,
  onDragEnd,
}: CustomTableHeadProps) => {
  const createSortHandler =
    (property: TOrganizationColumnId) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  const order: 'asc' | 'desc' = sortDir === 'ASC' ? 'asc' : 'desc'

  return (
    <TableHead className={styles.tableHeader}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable' direction='horizontal'>
          {(provided) => (
            <TableRow ref={provided.innerRef} {...provided.droppableProps}>
              <TableCell className={`${styles.cell_checkbox} ${styles.header__cell}`}>
                <Checkbox checked={isSelected} onChange={selectAllRows} />
              </TableCell>
              {columns.map((headCell, index) => headCell.id === 'shortName' ? (
                <TableCell
                  className={styles.header__cell}
                  key={headCell.id}
                  align={'left'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sortable ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      <b>{headCell.label}</b>
                    </TableSortLabel>
                  ) : (
                    <b>{headCell.label}</b>
                  )}
                </TableCell>
              ) : (
                <Draggable
                  key={headCell.id}
                  draggableId={headCell.id}
                  index={index}
                >
                  {(provided) => (
                    <TableCell
                      key={headCell.id}
                      className={styles.header__cell}
                      align={'left'}
                      sortDirection={orderBy === headCell.id ? order : false}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {headCell.sortable ? (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}
                        >
                          <b>{headCell.label}</b>
                        </TableSortLabel>
                      ) : (
                        <b>{headCell.label}</b>
                      )}
                    </TableCell>
                  )}
                </Draggable>
              )
              )}
            </TableRow>
          )}
        </Droppable>
      </DragDropContext>
    </TableHead>
  )
}

import { RoleAction } from './role.actions'
import { RoleSchema } from './role.schema'
import { initialState } from './role.state'

export const reducer = (
  state = initialState,
  action: RoleAction | void = undefined
): RoleSchema => {
  switch (action?.type) {
    case 'role.get_user_names_by_role_name': {
      return {
        ...state,
        getUserNamesByRoleName: {
          ...state.getUserNamesByRoleName,
          meta: {
            ...state.getUserNamesByRoleName.meta,
            status: 'loading',
          },
          data: initialState.getUserNamesByRoleName.data,
        },
      }
    }
    case 'role.get_user_names_by_role_name.success': {
      return {
        ...state,
        getUserNamesByRoleName: {
          ...state.getUserNamesByRoleName,
          meta: {
            ...initialState.getUserNamesByRoleName.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'role.get_user_names_by_role_name.error': {
      return {
        ...state,
        getUserNamesByRoleName: {
          ...state.getUserNamesByRoleName,
          meta: {
            ...state.getUserNamesByRoleName.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'role.reset.get_user_names_by_role_name': {
      return {
        ...state,
        getUserNamesByRoleName: {
          ...initialState.getUserNamesByRoleName,
        },
      }
    }
    default:
      return state
  }
}

import { apiBase, apiBaseAgsr } from './base.api'

export namespace Base {
  export type Entity = {
    id?: number | string;
  };

  export type SearchParam = {
    searchParam?: string;
  };

  export type UserIdParam = {
    userId: number;
  };

  export type UserIdParamFilters = {
    'user_id': number;
  };

  export type ReportFilterParam = {
    startDate: string;
    endDate: string;
    fio: string;
    name: string;
    organizationUnp: string;
    organizationName: string;
  };

  export type UnpParam = {
    unp: string;
  };

  export type SortDirection = 'DESC' | 'ASC';

  export type SortQuery<SortField> = {
    sortField: SortField;
    direction: SortDirection;
  };

  export type GraphQuery = {
    unp: string;
  };

  export type AuditQuery = {
    startDate?: Date | null | string
    endDate?: Date | null | string
    executorFio?: string
  }

  export type ManageRoleQuery = {
    name: string;
  };

  export type UserQuery = {
    username: string;
    email?: string;
  };

  export type AdminQuery = {
    role: 'admin' | 'security-admin';
    status: 'all' | 'active';
  };
  export type RoleQuery = {
    name?: string;
  };

  export type OrganizationFullInfoQuery = {
    unp: string;
  };

  export type BreadcrumbsQuery = {
    unp: string;
    chain?: string;
  };

  export type PaginatedQuery = {
    page: number;
    size: number;
  };

  export type PaginatedQueryAgsr = {
    _page: number;
    _limit: number;
    direction: SortDirection;
  };

  export type Info<Data> = {
    success?: boolean;
    data?: Data;
    meta?: string;
    errorCode?: string;
  };

  export type Response<Data> = Awaited<
    ReturnType<typeof apiBase.request<Info<Data>>>
  >;

  export type ResponseAgsr<Data> = Awaited<
    ReturnType<typeof apiBase.request<Data>>
  >;
  export type ResponseAgsrData<Data> = Awaited<
    ReturnType<typeof apiBaseAgsr.request<Data>>
  >;

  export type PaginatedData<DataItem> = {
    number: number;
    totalPages: number;
    totalElements: number;
    content: DataItem[];
  };

  export type PaginatedResponse<DataItem> = Response<PaginatedData<DataItem>>;
  export type PaginatedResponseAgsr<DataItem> = ResponseAgsrData<
    PaginatedData<DataItem>
  >;

  export type Error =
    | undefined
    | {
        code: number;
        message: string;
      };

  export enum ErrorMessage {
    UNKNOWN = 'Неизвестная ошибка.', // eslint-disable-line no-unused-vars
  }
}

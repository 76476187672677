import * as actions from './graph.creator'
import { reducer } from './graph.reducer'
import { saga } from './graph.saga'
import * as selectors from './graph.selector'

export const graph = {
  ...selectors,
  actions,
  reducer,
  saga,
}
import { DatesAction } from './statisticsDates.action'
import { DatesSchema } from './statisticsDates.schema'
import { initialState } from './statisticsDates.state'

export const reducer = (
  state = initialState,
  action: DatesAction | void = undefined
): DatesSchema => {
  switch (action?.type) {
    case 'dates.get_dates': {
      return {
        ...state,
        date: {
          ...state.date,
          meta: {
            ...state.date.meta,
            status: 'loading',
          },
          data: initialState.date.data,
        },
      }
    }
    case 'dates.get_dates.success': {
      return {
        ...state,
        date: {
          ...state.date,
          meta: {
            ...initialState.date.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'dates.get_dates.error': {
      return {
        ...state,
        date: {
          ...state.date,
          meta: {
            ...state.date.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    default:
      return state
  }
}

import { call, put, spawn, takeEvery } from 'redux-saga/effects'

import { Base, GetUserNamesByRoleName, role } from 'api'

import { GetUserNamesByRoleNameAction } from './role.actions'
import * as creator from './role.creator'

function* getUserNamesByRoleNameWorker(action: GetUserNamesByRoleNameAction) {
  let errorMessage = ''

  try {
    const data: GetUserNamesByRoleName.Response = yield call(
      role.getUserNamesByRoleName,
      action.payload
    )

    if (data?.status === 200) {
      yield put(creator.getUserNamesByRoleNameSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.getUserNamesByRoleNameError(errorMessage))
    }
  }
}

function* getUserNamesByRoleNameWatcher() {
  yield takeEvery<GetUserNamesByRoleNameAction>(
    'role.get_user_names_by_role_name' as GetUserNamesByRoleNameAction['type'],
    getUserNamesByRoleNameWorker
  )
}

export function* saga() {
  yield spawn(getUserNamesByRoleNameWatcher)

}

import { Header } from 'components'
import { Footer } from 'components/Footer'
import { ScrollButton } from 'components/ScrollButton'

import { BaseProps } from './Base.props'
import styles from './styles.module.css'

export const Base = ({ children = null }: BaseProps) => {
  return (
    <>
      <Header />
      <main className={styles.main}>
        {children}
        <ScrollButton />
      </main>
      <Footer />
    </>
  )
}

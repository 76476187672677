import { AxiosError } from 'axios'
import { call, put, select, spawn, takeEvery } from 'redux-saga/effects'

import {
  Base,
  ReportsAddToList,
  ReportsDownloadFile,
  ReportsGetList,
  ReportsRemoveFromList,
  reports,
} from 'api'

import {
  ReportsAddToListAction,
  ReportsDownloadFileAction,
  UpdateReportsListFilterAction,
  ReportsRemoveFromListAction,
} from './reports.actions'
import * as creator from './reports.creator'
import * as selector from './reports.selector'

// get list
function* updateListFilterWorker() {
  let errorMessage = undefined

  try {
    const filter: ReturnType<typeof selector.useListFilter> =
      yield select(selector.useListFilter)

    yield put(creator.getList())

    const {
      data: { data, meta, errorCode },
    }: ReportsGetList.Response = yield call(reports.getList, filter)

    if (data) {
      yield put(creator.getListSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  }  finally {
    if (errorMessage) {
      yield put(creator.getListError(errorMessage))
    }
  }
}

function* updateListFilterWatcher() {
  yield takeEvery<UpdateReportsListFilterAction>(
    'reports.update_list_filter' as UpdateReportsListFilterAction['type'],
    updateListFilterWorker
  )
}

// add to list
function* addToListWorker(action: ReportsAddToListAction) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: ReportsAddToList.Response = yield call(
      reports.addToList,
      action.payload
    )

    if (data) {
      yield put(creator.addToListSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.addToListError(errorMessage))
    }
  }
}

function* addToListWatcher() {
  yield takeEvery<ReportsAddToListAction>(
    'reports.add_to_list' as ReportsAddToListAction['type'],
    addToListWorker
  )
}

// remove from list
function* removeFromListWorker(action: ReportsRemoveFromListAction) {
  let errorMessage = undefined

  try {
    const response: ReportsRemoveFromList.Response = yield call(
      reports.removeFromList,
      action.payload
    )

    const { meta, errorCode } = response.data

    if (response.status === 204) {
      yield put(creator.removeFromListSuccess())
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.removeFromListError(errorMessage))
    }
  }
}

function* removeFromListWatcher() {
  yield takeEvery<ReportsRemoveFromListAction>(
    'reports.remove_from_list' as ReportsRemoveFromListAction['type'],
    removeFromListWorker
  )
}

// download file
function* downloadFileWorker(action: ReportsDownloadFileAction) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: ReportsDownloadFile.Response = yield call(
      reports.downloadFile,action.payload
    )

    if (data) {
      yield put(creator.downloadFileSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.downloadFileError(errorMessage))
    }
  }
}

function* downloadFileWatcher() {
  yield takeEvery<ReportsDownloadFileAction>(
    'reports.download_file' as ReportsDownloadFileAction['type'],
    downloadFileWorker
  )
}

export function* saga() {
  yield spawn(updateListFilterWatcher)
  yield spawn(addToListWatcher)
  yield spawn(removeFromListWatcher)
  yield spawn(downloadFileWatcher)
}

import { apiBaseAgsr } from 'api/base'

import { SUBPATH } from './manageAccess.const'
import { ManageAccessList, ManageAccessUpdate } from './manageAccess.interface'
import { TManageAccessRequest } from './manageAccess.model'

export const getManageAccessList = (
  params: ManageAccessList.Query
) => apiBaseAgsr.get<ManageAccessList.Data>(SUBPATH, { params })


export const updateManageAccess = ({
  name,
  body
}: TManageAccessRequest) =>
  apiBaseAgsr.put<ManageAccessUpdate.Data>(`${SUBPATH}/${name}`, body)


import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required('Введите код')
    .matches(/^\d+$/, 'Используйте только цифры')
    .max(5, 'Длина не более 5 символов '),
  fullName: Yup.string()
    .required('Введите имя')
    .max(250, 'Длина не должна превышать 250 символов'),
  unp: Yup.string()
    .required('Введите УНП')
    .matches(/^\d+$/, 'Используйте только цифры')
    .length(9, ({ length }) => `Введите ${length} цифр`),
})

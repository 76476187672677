import {
  UpdateListFilter,
  ResetListFilter,
  GetList,
  GetListSuccess,
  GetListError,
  ResetList
} from './audit.action'

export const updateListFilter = (
  payload: UpdateListFilter['payload']
): UpdateListFilter => ({
  type: 'audit.update_list_filter',
  payload,
})

export const resetFilter = (): ResetListFilter => ({
  type: 'audit.reset_list_filter',
  payload: undefined,
})

export const getList = (): GetList => ({
  type: 'audit.get_list',
  payload: undefined,
})

export const getListSuccess = (
  payload: GetListSuccess['payload']
): GetListSuccess => ({
  type: 'audit.get_list.success',
  payload,
})

export const getListError = (
  payload: GetListError['payload']
): GetListError => ({
  type: 'audit.get_list.error',
  payload,
})

export const resetList = (): ResetList => ({
  type: 'audit.reset_list',
  payload: undefined,
})

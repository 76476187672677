import { Base } from 'api'

import {
  GetGraphAction,
  GetGraphErrorAction,
  GetGraphSuccessAction,
  ResetGetGraphAction,
} from './graph.action'

export const getGraph = (payload: Base.GraphQuery): GetGraphAction => ({
  type: 'graph.get_graph',
  payload,
})

export const getGraphSuccess = (
  payload: GetGraphSuccessAction['payload']
): GetGraphSuccessAction => ({
  type: 'graph.get_graph.success',
  payload,
})

export const getGraphError = (
  payload: GetGraphErrorAction['payload']
): GetGraphErrorAction => ({
  type: 'graph.get_graph.error',
  payload,
})

export const resetGetGraph = (): ResetGetGraphAction => ({
  type: 'graph.reset_get_graph',
  payload: undefined,
})

import { HeadCellRole } from './ManageAccessTable.props'

export const HEAD_CELLS_ROLE: readonly HeadCellRole[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Роль',
  },
  {
    id: 'edit',
    numeric: true,
    label: '',
  },
]

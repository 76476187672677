import React from 'react'
import { createRoot } from 'react-dom/client'

import 'styles/index.css'
import { Main } from 'Main'


const root = document.querySelector('#root')

if (root) {
  createRoot(root).render(
    <React.StrictMode>
      <Main />
    </React.StrictMode>
  )
}

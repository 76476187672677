import { FormControl, FormGroup, IconButton, Modal } from '@mui/material'
import { useEffect, useReducer, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Directory } from 'api'
import { Notification } from 'components/Notification'
import { TextInput } from 'components/TextInput'
import { useNotification } from 'hooks'
import { CloseIcon } from 'icons'
import { organizations } from 'modules'
import { CustomAutocomplete } from 'views/OrganizationListFilter/components/CustomAutocomplete/CustomAutocomplete'
import { DatePickerRange } from 'views/OrganizationListFilter/components/DatePickerRange'
import { InputsRange } from 'views/OrganizationListFilter/components/InputsRange'
import { Radio } from 'views/OrganizationListFilter/components/Radio'

import { initialState, reducer } from './accordionStore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ModalCreateFilter,
  Values,
} from './components'
import { ModalData } from './modalData'
import styles from './styles.module.css'

type OrganizationFilterModalProps = {
  disabledSubmitBtn: boolean;
  onSubmit: () => void;
  onReset: () => void;
  onClose: () => void;
  directoriesList: Directory | null;
};

export const OrganizationFilterModal = ({
  disabledSubmitBtn,
  onSubmit,
  onReset,
  onClose,
  directoriesList,
}: OrganizationFilterModalProps) => {
  const [accordionState, dispatch] = useReducer(reducer, initialState)
  const dispatchHook = useDispatch()
  const { openNotification, notificationData } = useNotification()

  const modifiedDataModal = ModalData()

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      dispatch({
        type: panel,
        payload: { panel: panel, isExpanded: isExpanded },
      })
    }

  const submitFormHandler = () => {
    onSubmit()
    onClose()
  }

  useEffect(() => {
    for (const panel in modifiedDataModal) {
      modifiedDataModal[panel as keyof typeof modifiedDataModal].forEach(
        (input) => {
          if (input !== undefined) {
            dispatch({
              type: panel,
              payload: { panel: panel, isExpanded: true },
            })
          }
        }
      )
    }
  }, [])

  const { id: userId } = JSON.parse(localStorage.getItem('user')!)
  const [isOpenModalCreate, setOpenModalCreate] = useState(false)
  const filters = useSelector(organizations.useStateFilter)

  const handleCreateFilter = (fields: Values) => {
    const req = {
      user_id: userId,
      name: fields.nameFilter,
      comment: fields.commentFilter,
      body: filters,
    }
    dispatchHook(organizations.actions.create(req))
  }

  const creationSucceed = useSelector(organizations.useCreationSuccess)
  const creationError = useSelector(organizations.useCreationError)
  const creationErrorText = useSelector(organizations.useCreationErrorMessage)

  useEffect(() => {
    if (creationSucceed) {
      dispatchHook(organizations.actions.resetCreation())
      setOpenModalCreate(false)
      dispatchHook(
        organizations.actions.filters({
          user_id: userId,
        })
      )
      openNotification('Фильтр успешно сохранен', 'success')
    }
    if (creationError) {
      openNotification(creationErrorText, 'error')
      dispatchHook(organizations.actions.resetCreation())
    }
  }, [
    dispatchHook,
    openNotification,
    creationSucceed,
    creationError,
    creationErrorText,
    userId,
  ])

  return (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Modal className={styles.organizationsFieldsModal} open onClose={onClose}>
        <FormControl
          className={styles.form}
          component='fieldset'
          variant='standard'
        >
          <ModalCreateFilter
            onSubmit={handleCreateFilter}
            onCancel={() => setOpenModalCreate(false)}
            isOpen={isOpenModalCreate}
          />
          <div className={styles.form__header}>
            <h2 className={styles.form__headerTitle}>Расширенный поиск</h2>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <FormGroup className={styles.form__main}>
            <div className={styles.form__fieldsGroupWrapper}>
              <div className={styles.form__fieldsGroup}>
                <h3
                  className={styles.textMain}
                  style={{
                    background: 'rgba(30, 110, 190, 0.25)',
                    marginBottom: 15,
                  }}
                >
                  Общая информация
                </h3>
                <div className={styles.form__fields}>
                  <TextInput
                    label='УНП или наименование'
                    name='genUnp'
                    placeholder='Введите УНП или наименование'
                    tooltipText='Вводится УНП или наименование искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                  />

                  {directoriesList && (
                    <CustomAutocomplete
                      data={directoriesList.economicActivityTypes}
                      label='Основной вид деятельности'
                      name='genTypeWork'
                      tooltipText='Вид деятельности, который должен быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                      placeholder='Введите код или наименование'
                    />
                  )}
                  <TextInput
                    label='Код государственной организации в Белстат'
                    name='genCodeOrg'
                    placeholder='Введите код'
                    tooltipText='Вводится код государственной организации в Белстат, который должен быть у искомой(ых) организации(ий).'
                    type='number'
                  />
                  {directoriesList && (
                    <CustomAutocomplete
                      data={directoriesList.propertyTypes}
                      label='Форма собственности'
                      name='genFormOwnership'
                      tooltipText='Форма собственности, которая должна быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                      placeholder='Введите текстовое значение'
                    />
                  )}
                  {directoriesList && (
                    <CustomAutocomplete
                      data={directoriesList.legalOrganizationTypes}
                      label='Организационно-правовая форма'
                      name='genFormLaw'
                      placeholder='Введите текстовое значение'
                      tooltipText='Организационно-правовая форма, которая должна быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                    />
                  )}
                  <TextInput
                    label='УНП или наименование руководителя'
                    name='genUnpOwner'
                    placeholder='Введите УНП или наименование'
                    tooltipText='Вводится УНП или наименование руководителя искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                  />
                  {directoriesList && (
                    <CustomAutocomplete
                      data={directoriesList.stateSubject}
                      name='genStateGRP'
                      placeholder='Введите наименование'
                      label='Состояние субъекта (ГРП)'
                      tooltipText='Состояние по данным ГРП, которое должно быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                    />
                  )}
                  {directoriesList && (
                    <CustomAutocomplete
                      data={directoriesList.stateTypes}
                      name='genStateEGR'
                      placeholder='Введите текстовое значение'
                      label='Состояние субъекта (ЕГР)'
                      tooltipText='Состояние по данным ЕГР, которое должно быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                    />
                  )}
                  {/*<InputsRange
                label='Доля участия государства'
                name1='genShareGovernmentfrom'
                name2='genShareGovernmentto'
              />*/}
                </div>
              </div>
              <div className={styles.form__fieldsGroup}>
                <Accordion
                  expanded={accordionState.panel1 === 'panel1'}
                  onChange={handleChange('panel1')}
                >
                  <AccordionSummary className={styles.textMain}>
                    <h3 className={styles.textMain}>
                      Подчиненность государственной организации
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.form__fields}>
                      <Radio
                        name='subordGO'
                        label='Подчиненные ГО'
                        tooltipText='Обязательность наличия связей подчиненности с ГО у искомой(ых) организации(ий). Выбирается одно из доступных значений: «Да», «Нет». Для отмены выбора значения повторно нажимается выбранное значение.'
                      />
                      <TextInput
                        label='Код ГО или наименование'
                        name='subordCodeGO'
                        placeholder='Введите код или наименование'
                        tooltipText='Вводится код Белстат или наименование ГО, которой должна(ы) подчиняться искомая(ые) организация(ии). Допускается ввод неполного наименования.'
                      />
                      {directoriesList && (
                        <CustomAutocomplete
                          data={directoriesList.managementLevels}
                          name='subordLvlGO'
                          placeholder='Введите текстовое значение'
                          label='Уровень подчиненности ГО'
                          tooltipText='Уровень подчиненности, который должен быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                        />
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={styles.form__fieldsGroup}>
                <Accordion
                  expanded={accordionState.panel2 === 'panel2'}
                  onChange={handleChange('panel2')}
                >
                  <AccordionSummary className={styles.textMain}>
                    <h3 className={styles.textMain}>
                      Сведения о регистрации и учете
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.form__fields}>
                      <DatePickerRange
                        label='Дата постановки на учет'
                        name1='infoRegDate1From'
                        name2='infoRegDate1To'
                        tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата постановки на учет у искомой(ых) организации(ий).'
                      />
                      <TextInput
                        label='Номер решения о регистрации (ЕГР)'
                        name='infoRegNumberEGR'
                        placeholder='Введите номер'
                        tooltipText='Номер решения о регистрации (ЕГР), который должен быть у искомой(ых) организации(ий).'
                      />
                      <TextInput
                        label='Зарегистрировавший орган (ЕГР)'
                        name='infoRegOrganOfReg'
                        placeholder='Введите код или наименование'
                        tooltipText='Вводится код или наименование органа, который зарегистрировал искомую(ые) организацию(ии). Допускается ввод неполного наименования.'
                      />
                      <DatePickerRange
                        label='Дата регистрации (ГРП)'
                        name1='infoRegDate2From'
                        name2='infoRegDate2To'
                        tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата регистрации в ГРП у искомой(ых) организации(ий).'
                      />
                      <TextInput
                        label='Орган учета (ЕГР)'
                        name='infoRegOrgan'
                        placeholder='Введите код или наименование'
                        tooltipText='Вводится код или наименование органа учета ЕГР, который должен быть у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                      <TextInput
                        label='ИМНС (ГРП)'
                        name='infoRegIMNS'
                        placeholder='Введите код или наименование'
                        tooltipText='Вводится код или наименование ИМНС, которая должна быть у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={styles.form__fieldsGroup}>
                <Accordion
                  expanded={accordionState.panel3 === 'panel3'}
                  onChange={handleChange('panel3')}
                >
                  <AccordionSummary className={styles.textMain}>
                    <h3 className={styles.textMain}>Уставной фонд</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.form__fields}>
                      {directoriesList && (
                        <CustomAutocomplete
                          data={directoriesList.directoryFunds}
                          name='capitalType'
                          placeholder='Введите текстовое значение'
                          label='Тип уставного фонда'
                          tooltipText='Тип уставного фонда, который должен быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                        />
                      )}
                      {directoriesList && (
                        <CustomAutocomplete
                          data={directoriesList.currencies}
                          name='capitalValue'
                          placeholder='Введите код или наименование'
                          label='Валюта уставного фонда'
                          tooltipText='Валюта уставного фонда, которая должна быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                        />
                      )}
                      <InputsRange
                        label='Размер уставного фонда (валюта)'
                        name1='capitalSizeFrom'
                        name2='capitalSizeTo'
                        tooltipText='Указываются значения «с.. по..», в диапазон которых должен попадать уставной фонд у искомой(ых) организации(ий).'
                      />
                      <InputsRange
                        label='Доля государства (валюта)'
                        name1='capitalShareGosFrom'
                        name2='capitalShareGosTo'
                        tooltipText='Указываются значения «с.. по..», в диапазон которых должна попадать сумма доли государства в уставном фонде у искомой(ых) организации(ий).'
                      />
                      {/*<InputsRange
                label='Доля государства (%)'
                name1='capitalShareGovFrom'
                name2='capitalShareGovTo'
              />*/}
                      <InputsRange
                        label='Доля иностранных инвестиций (валюта)'
                        name1='capitalShareForeignValueFrom'
                        name2='capitalShareForeignValueTo'
                        tooltipText='Указываются значения «с.. по..», в диапазон которых должна попадать сумма доли иностранных инвестиций в уставном фонде у искомой(ых) организации(ий).'
                      />
                      <InputsRange
                        label='Доля иностранных инвестиций (%)'
                        name1='capitalShareForeignProcFrom'
                        name2='capitalShareForeignProcTo'
                        tooltipText='Указываются значения «с.. по..», в диапазон которых должен попадать процент доли иностранных инвестиций в уставном фонде у искомой(ых) организации(ий).'
                      />
                      <InputsRange
                        label='Доля белорусских инвестиций (валюта)'
                        name1='capitalShareBelValueFrom'
                        name2='capitalShareBelValueTo'
                        tooltipText='Указываются значения «с.. по..», в диапазон которых должна попадать сумма доли белорусских инвестиций в уставном фонде у искомой(ых) организации(ий).'
                      />
                      <InputsRange
                        label='Доля белорусских инвестиций (%)'
                        name1='capitalShareBelProcFrom'
                        name2='capitalShareBelProcTo'
                        tooltipText='Указываются значения «с.. по..», в диапазон которых должен попадать процент доли белорусских инвестиций в уставном фонде у искомой(ых) организации(ий).'
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={styles.form__fieldsGroup}>
                <Accordion
                  expanded={accordionState.panel4 === 'panel4'}
                  onChange={handleChange('panel4')}
                >
                  <AccordionSummary className={styles.textMain}>
                    <h3 className={styles.textMain}>Адрес</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.form__fields}>
                      {directoriesList && (
                        <CustomAutocomplete
                          data={directoriesList.countriesList}
                          label={'Страна'}
                          name='addressCountry'
                          tooltipText='Страна, которая должна быть в адресе у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                          placeholder='Введите код или наименование'
                        />
                      )}
                      <TextInput
                        label='Область'
                        name='addressArea'
                        placeholder='Введите наименование'
                        tooltipText='Вводится наименование области, которая должна быть в адресе у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                      <TextInput
                        label='Район'
                        name='addressDistrict'
                        placeholder='Введите наименование'
                        tooltipText='Вводится наименование района, который должен быть в адресе искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                      <TextInput
                        label='Населенный пункт'
                        name='addressCity'
                        placeholder='Введите наименование'
                        tooltipText='Вводится наименование населенного пункта, который должен быть в адресе у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={styles.form__fieldsGroup}>
                <Accordion
                  expanded={accordionState.panel5 === 'panel5'}
                  onChange={handleChange('panel5')}
                >
                  <AccordionSummary className={styles.textMain}>
                    <h3 className={styles.textMain}>Учредители</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.form__fields}>
                      <TextInput
                        label='УНП или наименование'
                        name='foundersUNP'
                        placeholder='Введите УНП или наименование'
                        tooltipText='Вводится УНП или наименование учредителя/акционера искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                      {directoriesList && (
                        <CustomAutocomplete
                          data={directoriesList.currencies}
                          name='foundersValue'
                          placeholder='Введите код или наименование'
                          label='Валюта'
                          tooltipText='Валюта уставного фонда, которая должна быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                        />
                      )}
                      <InputsRange
                        label='Доля в уставном фонде (валюта)'
                        name1='foundersShareValueFrom'
                        name2='foundersShareValueTo'
                        tooltipText='Указываются значения «с.. по..», в диапазон которых должна попадать сумма доли учредителя/акционера в уставном фонде у искомой(ых) организации(ий).'
                      />
                      <InputsRange
                        label='Доля в уставном фонде (%)'
                        name1='foundersShareProcFrom'
                        name2='foundersShareProcTo'
                        tooltipText='Указываются значения «с.. по..», в диапазон которых должен попадать процент доли учредителя/акционера в уставном фонде у искомой(ых) организации(ий).'
                      />
                      {directoriesList && (
                        <CustomAutocomplete
                          data={directoriesList.countriesList}
                          label='Страна инвестиций'
                          name='foundersCountry'
                          tooltipText='Страна, которая должна быть по учредителям/акционерам у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                          placeholder='Введите код или наименование'
                        />
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={styles.form__fieldsGroup}>
                <Accordion
                  expanded={accordionState.panel6 === 'panel6'}
                  onChange={handleChange('panel6')}
                >
                  <AccordionSummary className={styles.textMain}>
                    <h3 className={styles.textMain}>Дочерние организации</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.form__fields}>
                      <TextInput
                        label='УНП или наименование'
                        name='subsidiariesUnp'
                        placeholder='Введите УНП или наименование'
                        tooltipText='Вводится УНП или наименование дочерней организации у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                      {directoriesList && (
                        <CustomAutocomplete
                          data={directoriesList.currencies}
                          name='subsidiariesValue'
                          placeholder='Введите код или наименование'
                          label='Валюта'
                          tooltipText='Валюта уставного фонда, которая должна быть у дочерней организации искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                        />
                      )}
                      <InputsRange
                        label='Доля в уставном фонде (валюта)'
                        name1='subsidiariesShareValueFrom'
                        name2='subsidiariesShareValueTo'
                        tooltipText='Указываются значения «с.. по..», в диапазон которых должна попадать сумма доли в уставном фонде у дочерней организации у искомой(ых) организации(ий).'
                      />
                      <InputsRange
                        label='Доля в уставном фонде (%)'
                        name1='subsidiariesShareProcFrom'
                        name2='subsidiariesShareProcTo'
                        tooltipText='Указываются значения «с.. по..», в диапазон которых должен попадать процент доли в уставном фонде у дочерней организации у искомой(ых) организации(ий).'
                      />
                      {directoriesList && (
                        <CustomAutocomplete
                          data={directoriesList.countriesList}
                          label='Страна инвестиций'
                          name='subsidiariesCounty'
                          tooltipText='Страна, которая должна быть у дочерней организации у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                          placeholder='Введите код или наименование'
                        />
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={styles.form__fieldsGroup}>
                <Accordion
                  expanded={accordionState.panel7 === 'panel7'}
                  onChange={handleChange('panel7')}
                >
                  <AccordionSummary className={styles.textMain}>
                    <h3 className={styles.textMain}>
                      Правопреемники и предшественники
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.form__fields}>
                      <Radio
                        name='assignees'
                        label='Правопреемники'
                        tooltipText='Обязательность наличия правопреемника у искомой(ых) организации(ий). Выбирается одно из доступных значений: «Да», «Нет». Для отмены выбора значения повторно нажимается выбранное значение.'
                      />
                      <TextInput
                        label='УНП или наименование правопреемника'
                        name='assigneesUnp'
                        placeholder='Введите УНП или наименование'
                        tooltipText='Вводится УНП или наименование правопреемника у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                      <Radio
                        name='predecessors'
                        label='Предшественники'
                        tooltipText='Обязательность наличия предшественника у искомой(ых) организации(ий). Выбирается одно из доступных значений: «Да», «Нет». Для отмены выбора значения повторно нажимается выбранное значение.'
                      />
                      <TextInput
                        label='УНП или наименование предшественника'
                        name='predecessorsUnp'
                        placeholder='Введите УНП или наименование'
                        tooltipText='Вводится УНП или наименование предшественника у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={styles.form__fieldsGroup}>
                <Accordion
                  expanded={accordionState.panel8 === 'panel8'}
                  onChange={handleChange('panel8')}
                >
                  <AccordionSummary className={styles.textMain}>
                    <h3 className={styles.textMain}>
                      Обособленные подразделения
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.form__fields}>
                      <Radio
                        name='divisionsBranch'
                        label='Наличие филиалов'
                        tooltipText='Обязательность наличия филиала у искомой(ых) организации(ий). Выбирается одно из доступных значений: «Да», «Нет». Для отмены выбора значения повторно нажимается выбранное значение.'
                      />
                      <TextInput
                        label='УНП или наименование'
                        name='divisionsUnp'
                        placeholder='Введите УНП или наименование'
                        tooltipText='Вводится УНП или наименование филиала у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                      <TextInput
                        label='ИМНС филиала'
                        name='divisionsIMNS'
                        placeholder='Введите код или наименование'
                        tooltipText='Вводится код или наименование ИМНС, которая должна быть у филиала искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                      {directoriesList && (
                        <CustomAutocomplete
                          data={directoriesList.stateSubject}
                          name='divisionsState'
                          placeholder='Введите наименование'
                          label='Состояние филиала'
                          tooltipText='Состояние по данным ГРП, которое должно быть у филиала искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                        />
                      )}
                      <DatePickerRange
                        label='Дата регистрации филиала'
                        name1='divisionsDateBranchRegFrom'
                        name2='divisionsDateBranchRegTo'
                        tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата регистрации в ГРП филиала искомой(ых) организации(ий).'
                      />
                      <DatePickerRange
                        label='Дата ликвидации филиала'
                        name1='divisionsDateBranchDelFrom'
                        name2='divisionsDateBranchDelTo'
                        tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата ликвидации в ГРП филиала искомой(ых) организации(ий).'
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={styles.form__fieldsGroup}>
                <Accordion
                  expanded={accordionState.panel9 === 'panel9'}
                  onChange={handleChange('panel9')}
                >
                  <AccordionSummary className={styles.textMain}>
                    <h3 className={styles.textMain}>
                      исключение юридического лица из ЕГР и ликвидация
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.form__fields}>
                      <DatePickerRange
                        label='Дата исключения из ЕГР'
                        name1='eliminationDateFrom'
                        name2='eliminationDateTo'
                        tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата исключения из ЕГР у искомой(ых) организации(ий).'
                      />
                      <TextInput
                        label='Исключивший орган'
                        name='eliminationOrgan'
                        placeholder='Введите код или наименование'
                        tooltipText='Вводится код или наименование органа, который исключил из ГРП искомую(ые) организацию(ии). Допускается ввод неполного наименования.'
                      />
                      <TextInput
                        label='Причина ликвидации'
                        name='eliminationReason'
                        placeholder='Введите код или наименование'
                        tooltipText='Вводится код или наименование причины ликвидации, которая должна быть у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                      <TextInput
                        label='Hомер решения об исключении'
                        name='eliminationNumber'
                        placeholder='Введите номер решения'
                        tooltipText='Номер решения об исключении (ЕГР), который должен быть у искомой(ых) организации(ий).'
                      />
                      <DatePickerRange
                        label='Дата ликвидации'
                        name1='eliminationDateDelFrom'
                        name2='eliminationDateDelTo'
                        tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата ликвидации у искомой(ых) организации(ий).'
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={styles.form__fieldsGroup}>
                <Accordion
                  expanded={accordionState.panel10 === 'panel10'}
                  onChange={handleChange('panel10')}
                >
                  <AccordionSummary className={styles.textMain}>
                    <h3 className={styles.textMain}>Ценные бумаги</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.form__fields}>
                      <TextInput
                        label='УНП или наименование депозитария'
                        name='securitiesUnp'
                        placeholder='Введите УНП или наименование'
                        tooltipText='Вводится УНП или наименование депозитария выпуска акций искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                      <TextInput
                        label='Идентификационный код выпуска акций'
                        name='securitiesCode'
                        placeholder='Введите код'
                        tooltipText='Вводится код выпуска, который должен быть у акций у искомой(ых) организации(ий).'
                      />
                      <TextInput
                        label='УНП или наименование владельца акций'
                        name='securitiesUnpShare'
                        placeholder='Введите УНП или наименование'
                        tooltipText='Вводится УНП или наименование владельца акций искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                      />
                      <TextInput
                        label='Государственный регистрационный номер выпуска акций'
                        name='securitiesNumber'
                        placeholder='Введите номер'
                        tooltipText='Вводится регистрационный номер выпуска, который должен быть у акций искомой(ых) организации(ий).'
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </FormGroup>
          <div className={styles.form__btnsGroup}>
            <div>
              <button
                className={styles.btnSubmit}
                onClick={() => setOpenModalCreate(true)}
                disabled={disabledSubmitBtn}
              >
                Сохранить фильтр
              </button>
            </div>
            <div>
              <button
                type='reset'
                className={styles.btnReset}
                onClick={onReset}
              >
                Сброс параметров поиска
              </button>
              <button
                type='submit'
                className={styles.btnSubmit}
                onClick={submitFormHandler}
                disabled={disabledSubmitBtn}
              >
                Начать поиск
              </button>
            </div>
          </div>
          <Notification {...notificationData} />
        </FormControl>
      </Modal>
    </div>
  )
}

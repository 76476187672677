import { Base } from 'api/base'

import {
  ReportsListFilters,
  ReportsListDataItem,
  ReportsListSortField,
  ReportsAddToListBody,
  ReportsAddToListData,
  ReportsDownloadFileData,
  ReportsDownloadFilePayload,
  ReportsRemoveFromListBData,
  ReportsRemoveFromListBody,
  ReportsDownloadFileTablePayload,
  ReportsDownloadFileTableData,
  ReportsDownloadFileFromReportsPayload,
  ReportsDownloadFileFromReportsData,
} from './reports.model'

export namespace ReportsGetList {
  export type SortField = ReportsListSortField;
  export type Query = Partial<ReportsListFilters> & Partial<Base.PaginatedQuery> & Partial<Base.SortQuery<SortField>>;
  export type DataItem = ReportsListDataItem;
  export type Data = Base.PaginatedData<DataItem>;
  export type Response = Base.PaginatedResponse<DataItem>;
}

export namespace ReportsAddToList {
  export type Body = ReportsAddToListBody;
  export type Data = ReportsAddToListData;
  export type Response = Base.Response<Data>;
}

export namespace ReportsRemoveFromList {
  export type Body = ReportsRemoveFromListBody;
  export type Data = ReportsRemoveFromListBData;
  export type Response = Base.Response<Data>;
}

export namespace ReportsDownloadFile {
  export type Payload = ReportsDownloadFilePayload
  export type Data = ReportsDownloadFileData;
  export type Response = Base.Response<Data>;
}

export namespace ReportsDownloadFileFromReports {
  export type Payload = ReportsDownloadFileFromReportsPayload
  export type Data = ReportsDownloadFileFromReportsData;
  export type Response = Base.Response<Data>;
}

export namespace ReportsDownloadFileTable {
  export type Payload = ReportsDownloadFileTablePayload
  export type Data = ReportsDownloadFileTableData;
  export type Response = Base.Response<Data>;
}

export namespace ReportsDownloadFileTableFromReports {
  export type Payload = ReportsDownloadFileTablePayload
  export type Data = ReportsDownloadFileTableData;
  export type Response = Base.Response<Data>;
}

import {
  CreateManageRole,
  CreateManageRoleError,
  CreateManageRoleSuccess,
  DeleteManageRole,
  DeleteManageRoleError,
  DeleteManageRoleSuccess,
  GetManageRoleList,
  GetManageRoleListError,
  GetManageRoleListSuccess,
  ResetCreateManageRole,
  ResetDeleteManageRole,
  ResetGetManageRoleList,
  ResetManageRoleListFilter,
  ResetUpdateManageRole,
  UpdateManageRole,
  UpdateManageRoleError,
  UpdateManageRoleListFilter,
  UpdateManageRoleSuccess,
} from './manageRole.action'

// filter
export const updateManageRoleListFilter = (
  payload: UpdateManageRoleListFilter['payload']
): UpdateManageRoleListFilter => ({
  type: 'manageRole.update_list_filter',
  payload,
})

export const resetManageRoleListFilter =
  (): ResetManageRoleListFilter => ({
    type: 'manageRole.reset_list_filter',
    payload: undefined,
  })

// list
export const getManageRoleList =
  (): GetManageRoleList => ({
    type: 'manageRole.get_list',
    payload: undefined,
  })

export const getManageRoleListSuccess = (
  payload: GetManageRoleListSuccess['payload']
): GetManageRoleListSuccess => ({
  type: 'manageRole.get_list_success',
  payload,
})

export const getManageRoleListError = (
  payload: GetManageRoleListError['payload']
): GetManageRoleListError => ({
  type: 'manageRole.get_list_error',
  payload,
})

export const resetGetManageRoleList =
  (): ResetGetManageRoleList => ({
    type: 'manageRole.reset_get_list',
    payload: undefined,
  })

// create
export const createManageRole = (
  payload: CreateManageRole['payload']
): CreateManageRole => ({
  type: 'manageRole.create',
  payload,
})

export const createManageRoleSuccess = (
  payload: CreateManageRoleSuccess['payload']
): CreateManageRoleSuccess => ({
  type: 'manageRole.create.success',
  payload,
})

export const createManageRoleError = (
  payload: CreateManageRoleError['payload']
): CreateManageRoleError => ({
  type: 'manageRole.create.error',
  payload,
})

export const resetCreateManageRole =
  (): ResetCreateManageRole => ({
    type: 'manageRole.reset_create',
    payload: undefined,
  })

// update
export const updateManageRole = (
  payload: UpdateManageRole['payload']
): UpdateManageRole => ({
  type: 'manageRole.update',
  payload,
})

export const updateManageRoleSuccess = (
  payload: UpdateManageRoleSuccess['payload']
): UpdateManageRoleSuccess => ({
  type: 'manageRole.update.success',
  payload,
})

export const updateManageRoleError = (
  payload: UpdateManageRoleError['payload']
): UpdateManageRoleError => ({
  type: 'manageRole.update.error',
  payload,
})

export const resetUpdateManageRole =
  (): ResetUpdateManageRole => ({
    type: 'manageRole.reset_update',
    payload: undefined,
  })

// delete

export const deleteManageRole = (
  payload: DeleteManageRole['payload']
): DeleteManageRole => ({
  type: 'manageRole.remove',
  payload,
})

export const deleteManageRoleSuccess =
  (): DeleteManageRoleSuccess => ({
    type: 'manageRole.remove.success',
    payload: undefined,
  })

export const deleteManageRoleError = (
  payload: DeleteManageRoleError['payload']
): DeleteManageRoleError => ({
  type: 'manageRole.remove.error',
  payload,
})

export const resetDeleteManageRole =
  (): ResetDeleteManageRole => ({
    type: 'manageRole.reset_remove',
    payload: undefined,
  })

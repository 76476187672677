import { AxiosError } from 'axios'
import { call, put, spawn, takeEvery } from 'redux-saga/effects'

import { Base, GetDates, dates } from 'api'

import { GetDatesAction } from './statisticsDates.action'
import * as creator from './statisticsDates.creator'

function* getDatesWorker() {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: GetDates.Response = yield call(dates.getDates)

    if (data) {
      yield put(creator.getDatesSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getDatesError(errorMessage))
    }
  }
}

function* getDatesWatcher() {
  yield takeEvery<GetDatesAction>(
    'dates.get_dates' as GetDatesAction['type'],
    getDatesWorker
  )
}

export function* saga() {
  yield spawn(getDatesWatcher)
}

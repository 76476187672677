import { FC } from 'react'

import style from '../InfoFormOrganization.module.css'
import { PropsData } from '../types'


export const Fond: FC<PropsData> = ({ data, mode }) => {
  return (
    <div className={style.containerComponent}>
      <p className={style.generalName}>Уставной фонд</p>
      {data.statFundType ?
        <>
          <p className={style.headline}>Тип уставного фонда</p>
          <p className={style.about}>{data.statFundType}</p>
        </> : null}
      {mode === 'full' ?
        <>
          {data.sumTypeName ?
            <>
              <p className={style.headline}>Вид суммы</p>
              <p className={style.about}>{data.sumTypeName}</p>
            </> : null}
          {data.statFundSizeTotal || data.statFundSizeEstate || data.statFundSizeMoney ?
            <>
              <p className={style.headline}>Размер уставного фонда</p>
              <div>
                <div>
                  {data.statFundSizeTotal ? <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                    <p className={style.headlineReg}>Всего</p>
                    <p className={style.aboutReg}>{data.statFundSizeTotal + ' ' + data.statFundSizeTotalCurrencyName}</p>
                  </div> : null}
                  {data.statFundSizeEstate ? <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                    <p className={style.headlineReg}>Имущество</p>
                    <p className={style.aboutReg}>{data.statFundSizeEstate + ' ' + data.statFundSizeEstateCurrencyName}</p>
                  </div> : null}
                  {data.statFundSizeMoney ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                      <p className={style.headlineReg}>Деньги</p>
                      <p className={style.aboutReg}>{data.statFundSizeMoney + ' ' + data.statFundSizeMoneyCurrencyName}</p>
                    </div> : null}
                </div>
              </div>
            </> : null}
          {data.statePortionTotal || data.statePortionEstate || data.statePortionMoney ?
            <>
              <p className={style.headline}>Доля государства</p>
              <div>
                <div>
                  {data.statePortionTotal ? <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                    <p className={style.headlineReg}>Всего</p>
                    <p className={style.aboutReg}>{data.statePortionTotal + ' ' + data.statePortionTotalCurrencyName}</p>
                  </div> : null}
                  {data.statePortionEstate ? <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                    <p className={style.headlineReg}>Имущество</p>
                    <p className={style.aboutReg}>{data.statePortionEstate + ' ' + data.statePortionEstateCurrencyName}</p>
                  </div> : null}
                  {data.statePortionMoney ? <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                    <p className={style.headlineReg}>Деньги</p>
                    <p className={style.aboutReg}>{data.statePortionMoney + ' ' + data.statePortionMoneyCurrencyName}</p>
                  </div> : null}
                </div>
              </div>
            </> : null}
          {data.foreignPortionInvestments ?
            <>
              <p className={style.headline}>Доля иностранных инвестиций</p>
              <p className={style.about}>{data.foreignPortionInvestments + ' ' + data.foreignPortionInvestmentsCurrencyName}</p>
            </> : null}
          {data.foreignPortionInvestmentsPercent ?
            <>
              <p className={style.headline}>Доля иностранных инвестиций (%)</p>
              <p className={style.about}>{data.foreignPortionInvestmentsPercent + '%'}</p>
            </> : null}
          {data.belPortionInvestments ?
            <>
              <p className={style.headline}>Доля белорусских инвестиций</p>
              <p className={style.about}>{data.belPortionInvestments + ' ' + data.belPortionInvestmentsCurrencyName}</p>
            </> : null}
          {data.belPortionInvestmentsPercent ?
            <>
              <p className={style.headline}>Доля белорусских инвестиций (%)</p>
              <p className={style.about}>{data.belPortionInvestmentsPercent && data.belPortionInvestmentsPercent + '%'}</p>
            </> : null}
          {data.stockAmount || data.stockPrice ?
            <>
              <p className={style.headline}>Акции</p>
              <div className={style.registrationFlex}>
                {data.stockAmount || data.stockPrice ?
                  <div className={style.bigRormFlex}>
                    {data.stockAmount ? <p className={style.headlineReg}>Количество акций</p> : null}
                    {data.stockPrice ? <p className={style.headlineReg}>Стоимость акций</p> : null}
                  </div> : null}
                <div className={style.bigRormFlex}>
                  <p className={style.aboutReg}>{data.stockAmount}</p>
                  <p className={style.aboutReg}>{data.stockPrice}</p>
                </div>
              </div>
            </> : null}
        </>
        : <>
          {data.statFundSizeTotal || data.statePortionTotal ?
            <div className={style.fondFlex}>
              {data.statFundSizeTotal ?
                <div>
                  <p className={style.headline}>Размер уставного фонда</p>
                  <p className={style.about}>{data.statFundSizeTotal + ' ' + data.statFundSizeTotalCurrencyName}</p>
                </div> : null}
              {data.statePortionTotal ?
                <div>
                  <p className={style.headline}>Доля государства</p>
                  <p className={style.about}>{data.statePortionTotal + ' ' + data.statePortionTotalCurrencyName}</p>
                </div> : null}
            </div> : null}
          {data.foreignPortionInvestmentsPercent || data.belPortionInvestmentsPercent ?
            <div className={style.fondFlex}>
              {data.foreignPortionInvestmentsPercent ?
                <div>
                  <p className={style.headline}>Доля иностранных инвестиций</p>
                  <p className={style.about}>{data.foreignPortionInvestmentsPercent + '%'}</p>
                </div> : null}
              {data.belPortionInvestmentsPercent ?
                <div>
                  <p className={style.headline}>Доля белорусских инвестиций</p>
                  <p className={style.about}>{data.belPortionInvestmentsPercent + '%'}</p>
                </div> : null}
            </div> : null}
        </>
      }
    </div>
  )
}

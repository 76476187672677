import { useDispatch, useSelector } from 'react-redux'

import { CustomTooltip } from 'components/CustomTooltip'
import { organizations } from 'modules'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'

import styles from './TextInput.module.css'

type Props = {
  label: string;
  name: keyof FormListProps;
  placeholder: string;
  tooltipText: string;
  type?: string;
};

export const TextInput = ({ label, name, placeholder, tooltipText, type }: Props) => {
  const dispatch = useDispatch()

  const data = useSelector(organizations.useStateFilter)

  const setValue = (value: string) => {
    dispatch(
      organizations.actions.setDataFilterOrganizations({
        [name]: value === '' ? undefined : value,
      })
    )
  }
  return (
    <div className={styles.fieldset}>
      <CustomTooltip title={tooltipText}>
        <label className={styles.fieldset__label} htmlFor={name}>
          {label}
        </label>
      </CustomTooltip>
      <input
        className={styles.fieldset__input}
        style={{borderColor: data[name] ? '#1E6EBE' : 'inherit', borderWidth: data[name] ? '2px' : '0.5px'}}
        placeholder={placeholder}
        type={type ? type : 'text'}
        value={data[name] || ''}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  )
}

import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Chips } from 'components/Chips'
import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { Notification } from 'components/Notification'
import { SearchBar } from 'components/SearchBar'
import { useNotification } from 'hooks'
import { organizations, reports } from 'modules'
import { DeleteConfirmationModal } from 'pages/UserSpacePage/components/DeleteConfirmation'
import { ROUTE_PATH } from 'routes'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'

import { ReportsTable } from './components/ReportsTable/ReportsTable'
import { SearchFormReports } from './components/SearchFormReports/SearchFormReports'
import {
  PAGE_DEFAULT,
  PAGE_SIZE_DEFAULT,
  REPORTS_PAGE_BREADCRUMBS,
} from './ReportsPage.const'
import { ReportsTableProps, SearchFormReportsProps } from './ReportsPage.props'
import styles from './styles.module.css'

export const ReportsPage = ({ setFlag, flag } : { setFlag: Dispatch<SetStateAction<boolean>>, flag: boolean }) => {
  const { notificationData, openNotification } = useNotification()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const items = useSelector(reports.useListItems)
  const itemsLoading = useSelector(reports.useListLoading)
  const itemsError = useSelector(reports.useListError)
  const itemsTotal = useSelector(reports.useListItemsTotalCount)
  const itemsFilter = useSelector(reports.useListFilter)

  useEffect(() => {
    dispatch(
      reports.actions.updateListFilter({
        page: PAGE_DEFAULT,
        size: PAGE_SIZE_DEFAULT,
      })
    )
  }, [dispatch])

  // filter
  const handleTableFilter = useCallback<SearchFormReportsProps['onFilter']>(
    (data) => {
      dispatch(
        reports.actions.updateListFilter({
          filters: data,
          page: PAGE_DEFAULT,
        })
      )
    },
    [dispatch]
  )

  // page
  const handleTablePageChange = useCallback<ReportsTableProps['onPageChange']>(
    (page) => {
      dispatch(reports.actions.updateListFilter({ page }))
    },
    [dispatch]
  )

  // page size
  const handleTablePageSizeChange = useCallback<
    ReportsTableProps['onPageSizeChange']
  >(
    (size) => {
      dispatch(
        reports.actions.updateListFilter({
          page: PAGE_DEFAULT,
          size,
        })
      )
    },
    [dispatch]
  )

  // sort
  const handleTableSort = useCallback<ReportsTableProps['onSort']>(
    (direction, sortField) => {
      dispatch(
        reports.actions.updateListFilter({
          page: PAGE_DEFAULT,
          direction,
          sortField,
        })
      )
    },
    [dispatch]
  )

  // remove report
  const removeSuccess = useSelector(reports.useRemoveFromListSuccess)
  const removeError = useSelector(reports.useRemoveFromListError)

  const removeFromListHandler = useCallback<ReportsTableProps['onRemove']>(
    (ids) => {
      if (ids.length === 0) {
        openNotification('Не выбраны отчеты', 'error')
        return
      }
      dispatch(reports.actions.removeFromList({ ids }))
    },
    [dispatch, openNotification]
  )

  // detailed search
  const handleTableFiltersChange = useCallback<
    ReportsTableProps['onFiltersChange']
  >(
    (filters) => {
      const copyFilters = Object.assign({}, filters)

      for (const filter in copyFilters) {
        if (typeof copyFilters[filter as keyof typeof copyFilters] === 'object' && !Array.isArray(copyFilters[filter as keyof typeof copyFilters])) {
          // @ts-ignore
          copyFilters[filter as keyof typeof copyFilters] = new Date(copyFilters[filter as unknown]).toLocaleDateString()
        }
        if(Array.isArray(copyFilters[filter as keyof typeof copyFilters])) {
          // @ts-ignore
          copyFilters[filter as keyof typeof copyFilters] = copyFilters[filter].join(',')
        }
      }

      dispatch(
        organizations.actions.updateDetailedSearchFilter({
          ...copyFilters,
          page: PAGE_DEFAULT,
        })
      )

      dispatch(
        organizations.actions.setDataFilterOrganizations({
          ...filters
        })
      )
      setFlag(true)
      navigate(ROUTE_PATH.userSpace)
    },
    [dispatch, navigate]
  )

  useEffect(() => {
    if (removeSuccess) {
      openNotification('Выбранные отчеты успешно удалены', 'success')
      dispatch(reports.actions.updateListFilter({}))
      dispatch(reports.actions.resetRemoveFromList())
    }

    if (removeError) {
      openNotification('Не удалось удалить выбранные отчеты', 'error')
      dispatch(reports.actions.resetRemoveFromList())
    }

    if (itemsError) {
      openNotification('По вашему запросу ничего не найдено', 'error')
      dispatch(reports.actions.resetRemoveFromList())
    }
  }, [dispatch, openNotification, removeSuccess, removeError, itemsError])

  // search organizations
  const handleSubmitSearch = useCallback(
    (searchParam: string) => {
      dispatch(
        organizations.actions.updateListFilter({
          searchParam,
          page: PAGE_DEFAULT,
        })
      )

      navigate(ROUTE_PATH.userSpace)
    },
    [dispatch, navigate]
  )

  const handleSearchFilters = (filters: FormListProps) => {
    dispatch(
      organizations.actions.setDataFilterOrganizations({
        ...filters
      })
    )

    const copyFilters = Object.assign({}, filters)

    for (const filter in copyFilters) {
      // @ts-ignore
      if (copyFilters[filter as unknown] && copyFilters[filter as unknown].includes('-')) {
        // @ts-ignore
        copyFilters[filter as keyof typeof copyFilters] = copyFilters[filter as unknown].split('-').reverse().join('.')
      }
    }
    dispatch(
      organizations.actions.updateDetailedSearchFilter({
        ...copyFilters,
        page: PAGE_DEFAULT,
      })
    )
    setFlag(true)
    navigate(ROUTE_PATH.userSpace)
  }

  const { id: userId } = JSON.parse(localStorage.getItem('user')!)

  const filters = useSelector(organizations.useGetFilters)

  useEffect(() => {
    dispatch(
      organizations.actions.filters({
        'user_id': userId
      })
    )
  }, [dispatch, navigate, userId])

  const deletionLoading = useSelector(organizations.useDeletionLoading)
  const deletionSucceed = useSelector(organizations.useDeletionSuccess)
  const deletionError = useSelector(organizations.useDeletionError)
  const deletionErrorText = useSelector(organizations.useDeletionErrorMessage)

  const handleDelete = (id: number) => {
    setDeletionId(id)
  }

  useEffect(() => {
    if (deletionSucceed) {
      dispatch(organizations.actions.resetDeletion())
      dispatch(
        organizations.actions.filters({
          'user_id': userId
        })
      )
      openNotification('Фильтр удален', 'success')
    }
    if (deletionError) {
      openNotification(deletionErrorText, 'error')
      dispatch(organizations.actions.resetDeletion())
    }
  }, [dispatch, openNotification, deletionSucceed, deletionError, deletionErrorText, userId])

  const [deletionId, setDeletionId] = useState<number | undefined>()

  const handleDeletionAccept = useCallback((id: number) => {
    dispatch(organizations.actions.remove({ userId, id }))
    setDeletionId(undefined)
  }, [setDeletionId, userId, dispatch])

  const handleDeletionCancel = useCallback(() => {
    setDeletionId(undefined)
  }, [setDeletionId])

  return (
    <>
      <section className={styles.section}>
        <DeleteConfirmationModal
          id={deletionId}
          onAccept={handleDeletionAccept}
          onCancel={handleDeletionCancel}
        />
        <SearchBar onSearch={handleSubmitSearch} />
        <Chips
          filters={filters}
          handleDelete={handleDelete}
          handleSearchFilters={handleSearchFilters}
        />
        <CustomBreadcrumbs {...REPORTS_PAGE_BREADCRUMBS} />
        <div>
          <SearchFormReports onFilter={handleTableFilter} />
          <ReportsTable
            loading={itemsLoading || deletionLoading}
            rows={items as unknown as ReportsTableProps['rows']}
            total={itemsTotal}
            page={itemsFilter.page || PAGE_DEFAULT}
            pageSize={itemsFilter.size || PAGE_SIZE_DEFAULT}
            sortBy={itemsFilter.sortField}
            sortDir={itemsFilter.direction}
            onPageChange={handleTablePageChange}
            onPageSizeChange={handleTablePageSizeChange}
            onSort={handleTableSort}
            onRemove={removeFromListHandler}
            onFiltersChange={handleTableFiltersChange}
            setFlag={setFlag}
            flag={flag}
          />
        </div>
      </section>
      <Notification {...notificationData} />
    </>
  )
}

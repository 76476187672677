import { ChangeEvent, MouseEvent, useState } from 'react'

import { TOrganizationTable } from 'common/types'

export type Props = {
  rows: TOrganizationTable[];
};

export const useSelectRows = ({rows}: Props) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const selectAllRowsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const unpArray = rows.map((row) => row.unp)
      setSelectedRows(unpArray)
      return
    } else {
      setSelectedRows([])
    }
  }

  const selectRowHandler = (event: MouseEvent<unknown>, unp: string) => {
    const selectedIndex = selectedRows.indexOf(unp)

    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, unp)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1))
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      )
    }

    setSelectedRows(newSelected)
  }

  const isSelected = (unp: string) => selectedRows.includes(unp)

  const resetSelectedRows = () => setSelectedRows([])

  return {selectedRows, selectAllRowsHandler, selectRowHandler, isSelected, resetSelectedRows}
}

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { useCallback, useState } from 'react'

import { Loader } from 'components'
import { CustomPagination } from 'components/CustomPagination/CustomPagination'
import { SelectRowQuantity } from 'components/SelectRowQuantity/SelectRowQuantity'
import { auditQuantitySelector } from 'components/SelectRowQuantity/SelectRowQuantity.const'
import { getActionDate } from 'pages/AuditPage/audit.helpers'

import styles from '../../styles.module.css'

import { TableListProps } from './CustomTable.props'
import { EnhancedTableHead } from './EnhancedTableHead'


export const CustomTable = ({
  loading,
  rows,
  total,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange
}: TableListProps) => {
  const [rowQuantity, setRowQuantity] = useState<string | number>(20)

  const handlePageChange = (event: unknown, newPage: number) => {
    onPageChange(newPage)
  }

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setRowQuantity(event.target.value)
      onPageSizeChange(parseInt(event.target.value, 10))
    },
    [setRowQuantity, onPageSizeChange]
  )

  const totalPages = Math.ceil(total / pageSize)


  return (
    <div>
      <SelectRowQuantity
        rowQuantity={rowQuantity}
        handleChange={handleChange}
        data={auditQuantitySelector}
        width={235}
      />
      <Paper className={styles.tableContainer}>
        <TableContainer>
          <Table>
            <EnhancedTableHead />
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  key={row.date}
                  className={styles.tableRow}
                >
                  <TableCell className={styles.headCellDate}>
                    {row ? getActionDate(row.date) : ''}
                  </TableCell>
                  <TableCell align='left' className={styles.headCell}>{row.name}</TableCell>
                  <TableCell align='left' className={styles.headCell}>{row ? row.action : ''}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && <Loader />}
      </Paper>
      <div className={styles.pagination}>
        {!!total && (
          <div className={styles.pagination}>
            <CustomPagination
              totalPages={totalPages}
              page={page}
              rowQuantity={rowQuantity}
              handlePageChange={handlePageChange}
              totalItems={total}
            />
          </div>
        )}
      </div>
    </div>
  )
}

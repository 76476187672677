import { UserAction } from './user.action'
import { UserSchema } from './user.schema'
import { initialState } from './user.state'

export const reducer = (
  state = initialState,
  action: UserAction | void = undefined
): UserSchema => {
  switch (action?.type) {
    case 'user.get_user': {
      return {
        ...state,
        user: {
          ...state.user,
          meta: {
            ...state.user.meta,
            status: 'loading',
          },
          data: initialState.user.data,
        },
      }
    }
    case 'user.get_user.success': {
      return {
        ...state,
        user: {
          ...state.user,
          meta: {
            ...initialState.user.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'user.get_user.error': {
      return {
        ...state,
        user: {
          ...state.user,
          meta: {
            ...state.user.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'user.reset_get_user': {
      return {
        ...state,
        user: {
          ...initialState.user,
        },
      }
    }

    case 'user.update': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...state.updation.meta,
            status: 'loading',
          },
          data: action.payload.body,
        },
      }
    }

    case 'user.update.success': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...initialState.updation.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }

    case 'user.update.error': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...state.updation.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }

    case 'user.reset_updation': {
      return {
        ...state,
        updation: {
          ...initialState.updation,
        },
      }
    }

    case 'user.create_user': {
      return {
        ...state,
        createUser: {
          ...state.createUser,
          meta: {
            ...state.createUser.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'user.create_user.success': {
      return {
        ...state,
        createUser: {
          ...state.createUser,
          meta: {
            ...initialState.createUser.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'user.create_user.error': {
      return {
        ...state,
        createUser: {
          ...state.createUser,
          meta: {
            ...state.createUser.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'user.reset_create_user': {
      return {
        ...state,
        createUser: {
          ...initialState.createUser,
        },
      }
    }

    case 'user.add_role': {
      return {
        ...state,
        addRoleToUser: {
          ...state.addRoleToUser,
          meta: {
            ...state.addRoleToUser.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'user.add_role.success': {
      return {
        ...state,
        addRoleToUser: {
          ...state.addRoleToUser,
          meta: {
            ...initialState.addRoleToUser.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'user.add_role.error': {
      return {
        ...state,
        addRoleToUser: {
          ...state.addRoleToUser,
          meta: {
            ...state.addRoleToUser.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'user.reset_add_role': {
      return {
        ...state,
        addRoleToUser: {
          ...initialState.addRoleToUser,
        },
      }
    }
    
    case 'user.block_user': {
      return {
        ...state,
        blockUser: {
          ...state.blockUser,
          meta: {
            ...state.blockUser.meta,
            status: 'loading',
          },
          data: initialState.blockUser.data,
        },
      }
    }
    case 'user.block_user.success': {
      return {
        ...state,
        blockUser: {
          ...state.blockUser,
          meta: {
            ...initialState.blockUser.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'user.block_user.error': {
      return {
        ...state,
        blockUser: {
          ...state.blockUser,
          meta: {
            ...state.blockUser.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'user.reset_block_user': {
      return {
        ...state,
        blockUser: {
          ...initialState.blockUser,
        },
      }
    }
    
    case 'user.get_users_by_usernames': {
      return {
        ...state,
        getUserByUserName: {
          ...state.getUserByUserName,
          meta: {
            ...state.getUserByUserName.meta,
            status: 'loading',
          },
          data: initialState.getUserByUserName.data,
        },
      }
    }
    case 'user.get_users_by_usernames.success': {
      return {
        ...state,
        getUserByUserName: {
          ...state.getUserByUserName,
          meta: {
            ...initialState.getUserByUserName.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'user.get_users_by_usernames.error': {
      return {
        ...state,
        getUserByUserName: {
          ...state.getUserByUserName,
          meta: {
            ...state.getUserByUserName.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'user.reset_get_users_by_usernames': {
      return {
        ...state,
        getUserByUserName: {
          ...initialState.getUserByUserName,
        },
      }
    }

    case 'user.get_user_roles': {
      return {
        ...state,
        getUserRoles: {
          ...state.getUserRoles,
          meta: {
            ...state.getUserRoles.meta,
            status: 'loading',
          },
          data: initialState.getUserRoles.data,
        },
      }
    }
    case 'user.get_user_roles.success': {
      return {
        ...state,
        getUserRoles: {
          ...state.getUserRoles,
          meta: {
            ...initialState.getUserRoles.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'user.get_user_roles.error': {
      return {
        ...state,
        getUserRoles: {
          ...state.getUserRoles,
          meta: {
            ...state.getUserRoles.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'user.reset_get_user_roles': {
      return {
        ...state,
        getUserRoles: {
          ...initialState.getUserRoles,
        },
      }
    }

    case 'user.update_role': {
      return {
        ...state,
        updateRoleToUser: {
          ...state.updateRoleToUser,
          meta: {
            ...state.updateRoleToUser.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'user.update_role.success': {
      return {
        ...state,
        updateRoleToUser: {
          ...state.updateRoleToUser,
          meta: {
            ...initialState.updateRoleToUser.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'user.update_role.error': {
      return {
        ...state,
        updateRoleToUser: {
          ...state.updateRoleToUser,
          meta: {
            ...state.updateRoleToUser.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'user.reset_update_role': {
      return {
        ...state,
        updateRoleToUser: {
          ...initialState.updateRoleToUser,
        },
      }
    }


    case 'user.update_user_email': {
      return {
        ...state,
        updateUserEmail: {
          ...state.updateUserEmail,
          meta: {
            ...state.updateUserEmail.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'user.update_user_email.success': {
      return {
        ...state,
        updateUserEmail: {
          ...state.updateUserEmail,
          meta: {
            ...initialState.updateUserEmail.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'user.update_user_email.error': {
      return {
        ...state,
        updateUserEmail: {
          ...state.updateUserEmail,
          meta: {
            ...state.updateUserEmail.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'user.reset_update_user_email': {
      return {
        ...state,
        updateUserEmail: {
          ...initialState.updateUserEmail,
        },
      }
    }
    // count
    case 'user.get_count': {
      return {
        ...state,
        getCount: {
          ...state.getCount,
          meta: {
            ...state.getCount.meta,
            status: 'loading',
          },
        },
      }
    }
    case 'user.get_count.success': {
      return {
        ...state,
        getCount: {
          ...state.getCount,
          meta: {
            ...initialState.getCount.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'user.get_count.error': {
      return {
        ...state,
        getCount: {
          ...state.getCount,
          meta: {
            ...state.getCount.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'user.reset_get_count': {
      return {
        ...state,
        getCount: {
          ...initialState.getCount,
        },
      }
    }

    default:
      return state
  }
}

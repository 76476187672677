export const getFio = (fullFIO: string) => {
  if(fullFIO.includes('fio_')) {
    return fullFIO
  }
  let array = fullFIO?.split(' ')
  let result = ''
  if (array[0]) {
    result = `${array[0]}`
  }
  if (array[1]) {
    result += ` ${array[1][0]}.`
  }
  if (array[2]) {
    result += ` ${array[2][0]}.`
  }
  return result
}

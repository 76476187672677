import { configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import { all, spawn } from 'redux-saga/effects'

import * as modules from 'modules'

const history = createBrowserHistory()

const { reducer, sagas } = Object.entries(modules)
  .reduce(
    (acc, [key, module]) => ({
      reducer: {
        ...acc.reducer,
        [key]: module.reducer
      },
      sagas: [
        ...acc.sagas,
        ...(module.saga
          ? [module.saga] as typeof acc.sagas
          : []
        ),
      ],
    }),
    {
      reducer: {
        router: connectRouter(history),
      },
      sagas: [],
    },
  )

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => ([
    ...getDefaultMiddleware({immutableCheck: false,
      serializableCheck: false,}),
    routerMiddleware(history),
    sagaMiddleware,
  ]),
})

sagaMiddleware.run(function* rootSaga() {
  yield all(sagas.map(spawn))
})

import { Base } from 'api/base'

import { ManageAccess, ManageAccessQuery, TAccessBody } from './manageAccess.model'

export namespace ManageAccessList {
    export type SortField = keyof ManageAccess
    export type Query = Partial<Base.PaginatedQueryAgsr>
    export type DataItem = ManageAccess
    export type Data = Base.PaginatedData<DataItem>
    export type Response = Base.PaginatedResponseAgsr<DataItem>   
}

export namespace ManageAccessUpdate {
    export type Query = ManageAccessQuery
    export type Body = TAccessBody
    export type Data = ManageAccess
    export type Response = Base.ResponseAgsr<Data>
}

import {
  useDataCurrPage,
  useDataItems,
  useDataItemsTotalCount,
  useData,
  useDataLastPage,
  useMetaErrorMessage,
  useMetaErrorStatus,
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
} from 'modules/base'

import { OrganizationsSlice } from './organizations.schema'

export const useStateOrganizationFilter = ({
  organizations,
}: OrganizationsSlice) => organizations.openOrganizationFilter

export const useStateOrganizationFilterModal = ({
  organizations,
}: OrganizationsSlice) => organizations.openOrganizationFilterModal

export const useStateFilter = ({
  organizations,
}: OrganizationsSlice) => organizations.dataOrganizationFilter

// list
export const useListFilter = ({ organizations }: OrganizationsSlice) =>
  organizations.listFilter

export const useListIdle = ({ organizations }: OrganizationsSlice) =>
  useMetaIdleStatus(organizations.list)

export const useListLoading = ({ organizations }: OrganizationsSlice) =>
  useMetaLoadingStatus(organizations.list)

export const useListSuccess = ({ organizations }: OrganizationsSlice) =>
  useMetaSuccessStatus(organizations.list)

export const useListError = ({ organizations }: OrganizationsSlice) =>
  useMetaErrorStatus(organizations.list)

export const useListErrorMessage = ({ organizations }: OrganizationsSlice) =>
  useMetaErrorMessage(organizations.list)

export const useListCurrPage = ({ organizations }: OrganizationsSlice) =>
  useDataCurrPage(organizations.list)

export const useListLastPage = ({ organizations }: OrganizationsSlice) =>
  useDataLastPage(organizations.list)

export const useListItemsTotalCount = ({ organizations }: OrganizationsSlice) =>
  useDataItemsTotalCount(organizations.list)

export const useListItems = ({ organizations }: OrganizationsSlice) =>
  useDataItems(organizations.list)

// subsidiaries
export const useSubsidiariesListFilter = ({
  organizations,
}: OrganizationsSlice) => organizations.subsidiariesListFilter

export const useListWithSubsidiaries = ({
  organizations,
}: OrganizationsSlice) => useData(organizations.listSubsidiaries)

export const useListWithSubsidiariesLoading = ({
  organizations,
}: OrganizationsSlice) => useMetaLoadingStatus(organizations.listSubsidiaries)

export const useListWithSubsidiariesSuccess = ({
  organizations,
}: OrganizationsSlice) => useMetaSuccessStatus(organizations.listSubsidiaries)

export const useListWithSubsidiariesError = ({
  organizations,
}: OrganizationsSlice) => useMetaErrorStatus(organizations.listSubsidiaries)

export const useListWithSubsidiariesErrorMessage = ({
  organizations,
}: OrganizationsSlice) => useMetaErrorMessage(organizations.listSubsidiaries)

export const useListWithSubsidiariesCurrPage = ({
  organizations,
}: OrganizationsSlice) => useDataCurrPage(organizations.listSubsidiaries)

export const useListWithSubsidiariesLastPage = ({
  organizations,
}: OrganizationsSlice) => useDataLastPage(organizations.listSubsidiaries)

export const useListWithSubsidiariesItemsTotalCount = ({
  organizations,
}: OrganizationsSlice) =>
  useDataItemsTotalCount(organizations.listSubsidiaries)

export const useListWithSubsidiariesItems = ({
  organizations,
}: OrganizationsSlice) => useDataItems(organizations.listSubsidiaries)

// info
export const useGetOrganizationInfoIdle = ({
  organizations,
}: OrganizationsSlice) => useMetaIdleStatus(organizations.organization)

export const useGetOrganizationInfoLoading = ({
  organizations,
}: OrganizationsSlice) => useMetaLoadingStatus(organizations.organization)

export const useGetOrganizationInfoSuccess = ({
  organizations,
}: OrganizationsSlice) => useMetaSuccessStatus(organizations.organization)

export const useGetOrganizationInfoError = ({
  organizations,
}: OrganizationsSlice) => useMetaErrorStatus(organizations.organization)

export const useGetOrganizationInfoErrorMessage = ({
  organizations,
}: OrganizationsSlice) => useMetaErrorMessage(organizations.organization)

export const useGetOrganizationInfo = ({ organizations }: OrganizationsSlice) =>
  useData(organizations.organization)

// detailed search
export const useDetailedSearchListFilter = ({
  organizations,
}: OrganizationsSlice) => organizations.detailedSearchListFilter

export const useDetailedSearch = ({ organizations }: OrganizationsSlice) =>
  useMetaIdleStatus(organizations.detailedSearch)

export const useDetailedSearchLoading = ({
  organizations,
}: OrganizationsSlice) => useMetaLoadingStatus(organizations.detailedSearch)

export const useDetailedSearchSuccess = ({
  organizations,
}: OrganizationsSlice) => useMetaSuccessStatus(organizations.detailedSearch)

export const useDetailedSearchError = ({ organizations }: OrganizationsSlice) =>
  useMetaErrorStatus(organizations.detailedSearch)

export const useDetailedSearchErrorMessage = ({
  organizations,
}: OrganizationsSlice) => useMetaErrorMessage(organizations.detailedSearch)

export const useDetailedSearchCurrPage = ({
  organizations,
}: OrganizationsSlice) => useDataCurrPage(organizations.detailedSearch)

export const useDetailedSearchLastPage = ({
  organizations,
}: OrganizationsSlice) => useDataLastPage(organizations.detailedSearch)

export const useDetailedSearchItemsTotalCount = ({
  organizations,
}: OrganizationsSlice) => useDataItemsTotalCount(organizations.detailedSearch)

export const useDetailedSearchItems = ({ organizations }: OrganizationsSlice) =>
  useDataItems(organizations.detailedSearch)


export const useGetDirectoriesIdle = ({ organizations }: OrganizationsSlice) =>
  useMetaIdleStatus(organizations.directories)

export const useGetDirectoriesLoading = ({ organizations }: OrganizationsSlice) =>
  useMetaLoadingStatus(organizations.directories)

export const useGetDirectoriesSuccess = ({ organizations }: OrganizationsSlice) =>
  useMetaSuccessStatus(organizations.directories)

export const useGetDirectoriesError = ({ organizations }: OrganizationsSlice) =>
  useMetaErrorStatus(organizations.directories)

export const useGetDirectoriesErrorMessage = ({ organizations }: OrganizationsSlice) =>
  useMetaErrorMessage(organizations.directories)

export const useGetDirectories = ({ organizations }: OrganizationsSlice) => useData(organizations.directories)



export const useGetFiltersSuccess = ({ organizations }: OrganizationsSlice) =>
  useMetaSuccessStatus(organizations.filters)

export const useGetFiltersError = ({ organizations }: OrganizationsSlice) =>
  useMetaErrorStatus(organizations.filters)

export const useGetFiltersErrorMessage = ({ organizations }: OrganizationsSlice) =>
  useMetaErrorMessage(organizations.filters)

export const useGetFilters = ({ organizations }: OrganizationsSlice) => useData(organizations.filters)


export const useDeletionIdle = ({ organizations }: OrganizationsSlice) =>
  useMetaIdleStatus(organizations.deletion)

export const useDeletionLoading = ({ organizations }: OrganizationsSlice) =>
  useMetaLoadingStatus(organizations.deletion)

export const useDeletionSuccess = ({ organizations }: OrganizationsSlice) =>
  useMetaSuccessStatus(organizations.deletion)

export const useDeletionError = ({ organizations }: OrganizationsSlice) =>
  useMetaErrorStatus(organizations.deletion)

export const useDeletionErrorMessage = ({ organizations }: OrganizationsSlice) =>
  useMetaErrorMessage(organizations.deletion)

export const useDeletionData = ({ organizations }: OrganizationsSlice) =>
  organizations.deletion.data


export const useCreationIdle = ({ organizations }: OrganizationsSlice) =>
  useMetaIdleStatus(organizations.creation)

export const useCreationLoading = ({ organizations }: OrganizationsSlice) =>
  useMetaLoadingStatus(organizations.creation)

export const useCreationSuccess = ({ organizations }: OrganizationsSlice) =>
  useMetaSuccessStatus(organizations.creation)

export const useCreationError = ({ organizations }: OrganizationsSlice) =>
  useMetaErrorStatus(organizations.creation)

export const useCreationErrorMessage = ({ organizations }: OrganizationsSlice) =>
  useMetaErrorMessage(organizations.creation)

export const useCreationData = ({ organizations }: OrganizationsSlice) =>
  organizations.creation.data

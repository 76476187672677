import { all, call, put, spawn, takeEvery } from 'redux-saga/effects'

import {
  Base,
  GetUser,
  CreateUser,
  UserUpdate,
  user,
  AddRoleToUser,
  BlockUser,
  GetUsersByUsername,
  GetUserRoles,
  UserUpdateRole,
  UserUpdateEmail,
  GetUserCount
} from 'api'

import {
  AddRoleToUserAction,
  BlockUserAction,
  CreateUserAction,
  GetUserAction,
  GetUserRolesAction,
  GetUsersByUsernameAction,
  UpdateUser,
  UpdateRoleToUserAction,
  UpdateUserEmailAction,
  GetUsersCountAction
} from './user.action'
import * as creator from './user.creator'

function* getUserWorker(action: GetUserAction) {
  let errorMessage = ''

  try {
    const data: GetUser.Response = yield call(user.getUser, action.payload)
    if (data?.status === 200) {
      yield put(creator.getUserSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.getUserError(errorMessage))
    }
  }
}

function* getUserWatcher() {
  yield takeEvery<GetUserAction>(
    'user.get_user' as GetUserAction['type'],
    getUserWorker
  )
}

function* updateWorker(action: UpdateUser) {
  let errorMessage = ''

  try {
    const data: UserUpdate.Response = yield call(
      user.updateUser,
      action.payload
    )
    if (data?.status === 200) {
      yield put(creator.updateSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.updateError(errorMessage))
    }
  }
}

function* updateWatcher() {
  yield takeEvery<UpdateUser>(
    'user.update' as UpdateUser['type'],
    updateWorker
  )
}

function* createUserWorker(action: CreateUserAction) {
  let errorMessage = ''

  try {
    const data: CreateUser.Response = yield call(
      user.createUser,
      action.payload
    )

    if (data?.status === 201) {
      yield put(creator.createUserSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.createUserError(errorMessage))
    }
  }
}

function* createUserWatcher() {
  yield takeEvery<CreateUserAction>(
    'user.create_user' as CreateUserAction['type'],
    createUserWorker
  )
}

function* addRoleToUserWorker(action: AddRoleToUserAction) {
  let errorMessage = ''

  try {
    const data: AddRoleToUser.Response = yield call(
      user.addRoleToUser,
      action.payload
    )

    if (data?.status === 201) {
      yield put(creator.addRoleToUserSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.addRoleToUserError(errorMessage))
    }
  }
}

function* addRoleToUserWatcher() {
  yield takeEvery<AddRoleToUserAction>(
    'user.add_role' as AddRoleToUserAction['type'],
    addRoleToUserWorker
  )
}

function* blockUserWorker(action: BlockUserAction) {
  let errorMessage = ''

  try {
    const data: BlockUser.Response = yield call(user.blockUser, action.payload)

    if (data?.status === 200) {
      yield put(creator.blockUserSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.blockUserError(errorMessage))
    }
  }
}

function* blockUserWatcher() {
  yield takeEvery<BlockUserAction>(
    'user.block_user' as BlockUserAction['type'],
    blockUserWorker
  )
}

function* getUsersByUsernameWorker(action: GetUsersByUsernameAction) {
  let errorMessage = ''

  try {
    const responses: GetUsersByUsername.Response = yield all(
      action.payload.map((username) => call(user.getUser, { username }))
    )

    if (responses) {
      const data = responses.map((response) => response.data)

      yield put(creator.getUsersByUsernameSuccess(data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.getUsersByUsernameError(errorMessage))
    }
  }
}

function* getUsersByUsernameWatcher() {
  yield takeEvery<GetUsersByUsernameAction>(
    'user.get_users_by_usernames' as GetUsersByUsernameAction['type'],
    getUsersByUsernameWorker
  )
}

function* getUserRolesWorker(action: GetUserRolesAction) {
  let errorMessage = ''

  try {
    const data: GetUserRoles.Response = yield call(
      user.getUserRoles,
      action.payload
    )

    if (data?.status === 200) {
      yield put(creator.getUserRolesSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.getUserRolesError(errorMessage))
    }
  }
}

function* getUserRolesWatcher() {
  yield takeEvery<GetUserRolesAction>(
    'user.get_user_roles' as GetUserRolesAction['type'],
    getUserRolesWorker
  )
}

function* updateRoleToUserWorker(action: UpdateRoleToUserAction) {
  let errorMessage = ''

  try {
    const data: UserUpdateRole.Response = yield call(
      user.updateUserRole,
      action.payload
    )
    if (data?.status === 200) {
      yield put(creator.updateRoleToUserSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.updateRoleToUserError(errorMessage))
    }
  }
}

function* updateRoleToUserWatcher() {
  yield takeEvery<UpdateRoleToUserAction>(
    'user.update_role' as UpdateRoleToUserAction['type'],
    updateRoleToUserWorker
  )
}


function* updateUserEmailWorker(action: UpdateUserEmailAction) {
  let errorMessage = ''

  try {
    const data: UserUpdateEmail.Response = yield call(
      user.updateUserEmail,
      action.payload
    )

    if (data?.status === 200) {
      yield put(creator.updateUserEmailSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.updateUserEmailError(errorMessage))
    }
  }
}

function* updateUserEmailWatcher() {
  yield takeEvery<UpdateUserEmailAction>(
    'user.update_user_email' as UpdateUserEmailAction['type'],
    updateUserEmailWorker
  )
}
// count
function* getCountWorker() {
  let errorMessage = ''

  try {
    const data: GetUserCount.Response = yield call(
      user.getUsersCount
    )

    if (data?.status === 200) {
      yield put(creator.getCountSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.getCountError(errorMessage))
    }
  }
}

function* getCountWatcher() {
  yield takeEvery<GetUsersCountAction>(
    'user.get_count' as GetUsersCountAction['type'],
    getCountWorker
  )
}


export function* saga() {
  yield spawn(getUserWatcher)
  yield spawn(updateWatcher)
  yield spawn(createUserWatcher)
  yield spawn(addRoleToUserWatcher)
  yield spawn(blockUserWatcher)
  yield spawn(getUsersByUsernameWatcher)
  yield spawn(getUserRolesWatcher)
  yield spawn(updateRoleToUserWatcher)
  yield spawn(updateUserEmailWatcher)
  yield spawn(getCountWatcher)
}

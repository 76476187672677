import { Panel } from 'reactflow'

import { CloseIcon } from 'icons'
import { LegendProps } from 'pages/NewGraphPage/NewGraphPage.props'

import styles from '../../styles.module.css'

export const Legend = ({onClose}: LegendProps) => {
  return (
    <Panel position='top-right'>
      <div className={styles.info}>
        <button
          className={styles.section__closeButton}
          type='button'
          onClick={onClose}
        >
          <CloseIcon />
        </button>
        <div className={styles.info__blockWr}>
          <div
            className={`${styles.info__block} ${styles.info__block_darkBlue}`}
          />
          <p className={styles.info__text}>
            Организации выше уровнем от искомой, у которых нет
            учредителей/акционеров
          </p>
        </div>
        <div className={styles.info__blockWr}>
          <div className={`${styles.info__block} ${styles.info__block_blue}`} />
          <p className={styles.info__text}>
            Организации выше уровнем от искомой, у которых есть
            учредители/акционеры
          </p>
        </div>
        <div className={styles.info__blockWr}>
          <div className={`${styles.info__block} ${styles.info__block_gray}`} />
          <p className={styles.info__text}>Искомая организация</p>
        </div>
        <div className={styles.info__blockWr}>
          <div
            className={`${styles.info__block} ${styles.info__block_green}`}
          />
          <p className={styles.info__text}>
            Организации ниже уровнем от искомой, у которых есть
            дочерние/подчиненные
          </p>
        </div>
        <div className={styles.info__blockWr}>
          <div className={`${styles.info__block} ${styles.info__block_red}`} />
          <p className={styles.info__text}>
            Организации ниже уровнем от искомой, у которых нет
            дочерних/подчиненных
          </p>
        </div>
      </div>
    </Panel>
  )
}

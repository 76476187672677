import { AxiosError } from 'axios'
import { call, put, select, spawn, takeEvery } from 'redux-saga/effects'

import { Base, GetDashboards, GetMainDashboards, dashboards, GetDashboardsData } from 'api'
import { convertDate } from 'utils/convertDate'

import { GetMainDashboardsAction, UpdateDashboardsFilterAction, GetDashboardsDataAction } from './dashboards.actions'
import * as creator from './dashboards.creator'
import * as selector from './dashboards.selector'

function* getMainDashboardsWorker() {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: GetMainDashboards.Response = yield call(dashboards.getMainDashBoards,)
    
    if (data) {
      yield put(creator.getMainDashboardsSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getMainDashboardsError(errorMessage))
    }
  }
}

function* getMainDashboardsWatcher() {
  yield takeEvery<GetMainDashboardsAction>(
    'dashboards.get_main' as GetMainDashboardsAction['type'],
    getMainDashboardsWorker
  )
}


function* updateFilterWorker() {
  let errorMessage = undefined

  try {
    const filter: ReturnType<typeof selector.useFilter> = yield select(
      selector.useFilter
    )

    yield put(creator.getDashboards())

    const dates = convertDate(filter.startDate, filter.endDate)

    const {
      data: { data, meta, errorCode },
    }: GetDashboards.Response = yield call(dashboards.getDashboards, dates)
    
    if (data) {
      yield put(creator.getDashboardsSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getDashboardsError(errorMessage))
    }
  }
}

function* updateFilterWatcher() {
  yield takeEvery<UpdateDashboardsFilterAction>(
    'dashboards.update_filter' as UpdateDashboardsFilterAction['type'],
    updateFilterWorker
  )
}

function* getDashboardsDataWorker(action: GetDashboardsDataAction) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: GetDashboardsData.Response = yield call(
      dashboards.getDashboardsData,
      action.payload
    )

    if (data) {
      yield put(creator.getDashboardsDataSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getDashboardsDataError(errorMessage))
    }
  }
}

function* getDashboardsDataWatcher() {
  yield takeEvery<GetDashboardsDataAction>(
    'dashboards.get_data' as GetDashboardsDataAction['type'],
    getDashboardsDataWorker
  )
}

export function* saga() {
  yield spawn(getMainDashboardsWatcher)
  yield spawn(updateFilterWatcher)
  yield spawn(getDashboardsDataWatcher)
}

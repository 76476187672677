import * as actions from './organizations.creator'
import { reducer } from './organizations.reducer'
import { saga } from './organizations.saga'
import * as selectors from './organizations.selector'

export const organizations = {
  ...selectors,
  actions,
  reducer,
  saga,
}

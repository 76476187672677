import { Alert, Snackbar } from '@mui/material'

export type NotificationProps = {
  isOpen: boolean;
  message: string;
  type: 'success' | 'error';
};

export const Notification = ({ isOpen, message, type }: NotificationProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
    >
      <Alert
        variant='filled'
        severity={type}
        sx={{
          width: '100%',
          border: 'none',
          boxShadow: '0px 4px 8px rgba(30, 110, 190, 0.25)',
          textAlign: 'center',
          fontSize: 18,
          color: 'white',
          '.MuiAlert-icon': {
            marginTop: '4px'
          }
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

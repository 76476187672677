import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, MenuItem, Switch } from '@mui/material'
import Select from '@mui/material/Select'
import { ChangeEvent, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { user } from 'modules'
import {
  CreateFormProps,
  CreateUserFormFields,
} from 'pages/SecurityPage/SecurityPage.interface'

import { CreateFormSchema } from './CreateForm.schema'
import styles from './styles.module.css'

export const CreateForm = ({ roleCustom, onSubmit, onCancel, roles, setSelectRole, selectRole }: CreateFormProps) => {
  const createUserSuccess = useSelector(user.useCreateUserSuccess)
  const dispatch = useDispatch()

  const {
    formState: { defaultValues, errors, isDirty, isValid },
    register,
    handleSubmit,
    getValues
  } = useForm<CreateUserFormFields>({
    defaultValues: {
      enabled: true,
      fio: '',
      email: '',
      phone: '',
      personalNumber: '',
    },
    resolver: yupResolver(CreateFormSchema),
    mode: 'all',
  })

  const createUserHandler: SubmitHandler<CreateUserFormFields> = (payload) => {
    onSubmit(payload)
  }

  useEffect(() => {
    if (createUserSuccess) {
      onCancel()
      setSelectRole('')
      dispatch(user.actions.resetCreateUser())
    }
  }, [dispatch, onCancel, createUserSuccess, setSelectRole])

  const handleChangeSelect = (event: ChangeEvent<{ value: string }>) => {
    setSelectRole(event.target.value as string)
  }

  return (
    <form className='' onSubmit={handleSubmit(createUserHandler)}>
      <div className={styles.modal__switcherWrapper}>
        <Switch
          id='enabled'
          defaultChecked={defaultValues?.enabled}
          {...register('enabled')}
        />
        <p className=''>{getValues('enabled') ? 'Активный' : 'Не aктивный'}</p>
      </div>
      <div className={styles.modal__inputsWrapper}>
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <div className={styles.modal__inputBlock}>
              <label className={styles.modal__inputLabel} htmlFor='fullname'>
                ФИО
              </label>
              <input
                className={`${styles.modal__input} ${errors.fio && styles.modal__input_error
                }`}
                type='text'
                id='fio'
                placeholder='Введите ФИО'
                {...register('fio')}
              />
              {errors.fio && (
                <span className={styles.modal__error}>
                  {errors.fio.message}
                </span>
              )}
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={styles.modal__inputBlock}>
              <label className={styles.modal__inputLabel} htmlFor='role'>
                Роль
              </label>
              {roleCustom !== 'user' ?
                <input
                  className={styles.modal__input}
                  type='text'
                  id='role'
                  value={'Администратор'}
                  disabled={true}
                />
                :
                <Select
                  value={selectRole}
                  className={styles.selectForm}
                  onChange={handleChangeSelect as VoidFunction}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                >
                  <MenuItem value=''>
                    <em>Пусто</em>
                  </MenuItem>
                  {roles.map(e => <MenuItem value={e.name} key={e.name}>{e.name}</MenuItem>)}
                </Select>}
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={styles.modal__inputBlock}>
              <label className={styles.modal__inputLabel} htmlFor='email'>
                E-mail организации
              </label>
              <input
                className={`${styles.modal__input} ${errors.email && styles.modal__input_error
                }`}
                type='email'
                id='email'
                placeholder='Введите е-mail'
                {...register('email')}
              />
              {errors.email && (
                <span className={styles.modal__error}>
                  {errors.email.message}
                </span>
              )}
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={styles.modal__inputBlock}>
              <label className={styles.modal__inputLabel} htmlFor='phone'>
                Телефон
              </label>
              <input
                className={`${styles.modal__input} ${errors.phone && styles.modal__input_error
                }`}
                type='tel'
                id='phone'
                placeholder='+375 (__) ___-__-__'
                {...register('phone')}
              />
              {errors.phone && (
                <span className={styles.modal__error}>
                  {errors.phone.message}
                </span>
              )}
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={styles.modal__inputBlock}>
              <label className={styles.modal__inputLabel} htmlFor='id'>
                Идентификационный номер / логин
              </label>
              <input
                className={`${styles.modal__input} ${errors.personalNumber && styles.modal__input_error
                }`}
                type='text'
                id='id'
                placeholder='Введите идентификационный номер / логин'
                {...register('personalNumber')}
              />
              {errors.personalNumber && (
                <span className={styles.modal__error}>
                  {errors.personalNumber.message}
                </span>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={styles.modal__btnsWrapper}>
        <button
          className={styles.modal__btn}
          type='submit'
          disabled={!isDirty || !isValid}
        >
          Сохранить
        </button>
        <button className={styles.modal__btn} onClick={onCancel}>
          Отмена
        </button>
      </div>
    </form>
  )
}

import { TableCell, TableRow } from '@mui/material'
import { FC, useRef, useState } from 'react'

import { CustomEditModal } from '../CustomEditModal'

import { IManageAccess } from './ManageAccessTable.props'
import styles from './styles.module.css'

interface ManageAccessTableRowProps {
  record: IManageAccess;
}

export const ManageAccessTableRow: FC<
  ManageAccessTableRowProps
> = ({ record }) => {
  const toolsModal = useRef<HTMLDivElement | null>(null)

  const [editModalIsOpened, setEditModalIsOpened] = useState<boolean>(false)

  const toggleEditModalIsOpened = () => {
    setEditModalIsOpened((currState) => !currState)
  }

  return (
    <>
      <TableRow>
        <TableCell>{record.name}</TableCell>
        
        <TableCell>
          <div className={styles.tools__wraper}>
            <div ref={toolsModal}>
              <button
                className={styles.tools__popupBtn}
                onClick={toggleEditModalIsOpened}
              >
                <img src='/assets/icon-change.svg' alt='change'/>
              </button>
            </div>
          </div>
        </TableCell>
        
      </TableRow>
      <CustomEditModal
        defaultValues={{
          name: record.name,
          accessRights: record.accessRights,
        }}
        modalIsOpened={editModalIsOpened}
        closeModal={toggleEditModalIsOpened}
        record={record}
      />
    </>
  )
}

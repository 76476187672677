import { createSelector } from '@reduxjs/toolkit'

import { Base as BaseApi } from 'api'

import { Base } from './base.schema'

export const useMetaIdleStatus = <Data>(field: Base.AsyncField<Data>) =>
  field.meta.status === 'idle'

export const useMetaLoadingStatus = <Data>(field: Base.AsyncField<Data>) =>
  field.meta.status === 'loading'

export const useMetaSuccessStatus = <Data>(field: Base.AsyncField<Data>) =>
  field?.meta.status === 'success'

export const useMetaErrorStatus = <Data>(field: Base.AsyncField<Data>) =>
  field?.meta.status === 'error'

export const useMetaErrorMessage = <Data>(field: Base.AsyncField<Data>) =>
  field.meta.error

export const useDataCurrPage = <DataItem>(
  field: Base.AsyncField<BaseApi.PaginatedData<DataItem>>
) => field.data.number

export const useDataLastPage = <DataItem>(
  field: Base.AsyncField<BaseApi.PaginatedData<DataItem>>
) => field.data.number >= field.data.totalPages

export const useDataItemsTotalCount = <DataItem>(
  field: Base.AsyncField<BaseApi.PaginatedData<DataItem>>
) => field.data.totalElements

export const useDataItems = <DataItem>(
  field: Base.AsyncField<BaseApi.PaginatedData<DataItem>>
) => field.data.content

export const useData = <Data>(field: Base.AsyncField<Data>) => field.data

export const newSelector = createSelector

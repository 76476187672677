import {
  GetDatesAction,
  GetDatesSuccessAction,
  GetDatesErrorAction,
} from './statisticsDates.action'

export const getDates = (): GetDatesAction => ({
  type: 'dates.get_dates',
  payload: undefined,
})

export const getDatesSuccess = (
  payload: GetDatesSuccessAction['payload']
): GetDatesSuccessAction => ({
  type: 'dates.get_dates.success',
  payload,
})

export const getDatesError = (
  payload: GetDatesErrorAction['payload']
): GetDatesErrorAction => ({
  type: 'dates.get_dates.error',
  payload,
})

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'

// eslint-disable-next-line
export const AccordionSummary = styled((props: any) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  border: 'none',
  marginLeft: 0,
  justifyContent: 'normal',
  paddingLeft: '0 !important',
  background: 'rgba(30, 110, 190, 0.25) !important',
  paddingRight: 10,
  margin: 0,
  minHeight: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    webkitFlexGrow: '0.03px',
    flexGrow: 0.03,
    margin: 0
  },
}))

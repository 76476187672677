import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Link } from 'react-router-dom'

import styles from './AuthModal.module.css'
import {
  AuthModalProps,
  EmailFormData,
} from './AuthModal.props'
import { authSchema } from './AuthModal.schema'

export const AuthModal = ({
  step,
  loginStart,
  sendOtpToEmail,
  loginWithOtp,
}: AuthModalProps) => {
  const [isShowEmail, setShowEmail] = useState(false)

  const handleShowEmail = () => {
    setShowEmail((currState) => !currState)
  }

  const {
    formState: { errors, isDirty, isValid },
    register,
    handleSubmit,
  } = useForm<EmailFormData>({
    resolver: yupResolver(authSchema),
    mode: 'onBlur',
  })

  const sendEmailHandler: SubmitHandler<EmailFormData> = ({ email }) => sendOtpToEmail(email)

  const sendPasscodeHandler: SubmitHandler<EmailFormData> = ({
    email,
    otpNum,
  }) => loginWithOtp(email, otpNum)

  return (
    <Box className={styles.wrapper}>
      <div className={styles.title}>
        Уважаемый пользователь АИС «Госактивы»!
      </div>
      <div className={styles.subtitleWrap}>
        <span className={styles.subtitle}>
          Для прохождения авторизации при помощи ЕС ИФЮЛ на Вашем ПЭВМ должны
          быть установлены программы и устройства в следующих сочетаниях:
        </span>
      </div>
      <div className={styles.backgroundText}>
        Авторизации с помощью идентификационной карты (ID-карта):
      </div>
      <p className={styles.subText}>
        клиентская программа BY.БФИД.10244-01;
        <br />
        универсальный считыватель;
        <br />
        ID-карта.
      </p>
      <div className={styles.backgroundText}>
        Для авторизации на ПЭВМ (операционная система Windows) с помощью
        носителя (USB-устройства):
      </div>
      <div className={styles.subText}>
        клиентская программа BY.БФИД.10244-01;
        <br />
        программа криптопровайдера (
        <Link
          to='https://nces.by/service/po/#%D0%9A%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D1%81%D0%BA%D0%BE%D0%B9%20%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D1%8B%20(%D0%9A%D0%9F'
          target='_blank'
        >
          NTCrypto БФИД.10186-01
        </Link>{' '}
        или{' '}
        <Link to='https://www.avest.by/crypto/csp.htm' target='_blank'>
          Avest CSP BIGN РБ.ЮСКИ.12005-02 «AvPKISetup2.exe»
        </Link>
        );
        <br />
        носитель (USB-устройство) с импортированным личным сертификатом ГосСУОК.
      </div>
      <div className={styles.infoText}>
        Предоставление и использование программного обеспечения (клиентская
        программа и программа криптопровайдера) осуществляется в соответствии с
        условиями и соглашениями&nbsp;
        <Link to='https://nces.by/pki/' target='_blank'>
          оператора ГосСУОК
        </Link>.
      </div>
      <div className={styles.buttonStart}>
        <button className={styles.loginBtn} onClick={loginStart}>
          Вход
        </button>
      </div>
      <div className={styles.line}>
        <img
          src='/assets/HiddenButton.png'
          alt=''
          style={{ maxWidth: '100%' }}
        />
      </div>
      <div className={styles.wrapShowEmail}>
        <button className={styles.secretBtn} onClick={handleShowEmail}></button>
      </div>
      {isShowEmail && (
        <div className={styles.formWrap}>
          {step === 1 && (
            <form className={styles.form} onSubmit={handleSubmit(sendEmailHandler)}>
              <div className={styles.form__field}>
                <label className={styles.form__label}>E-mail</label>
                <input
                  className={`${styles.form__input} ${
                    errors.email && styles.form__input_error
                  }`}
                  type='text'
                  id='email'
                  placeholder='Введите е-mail'
                  {...register('email')}
                />
                {errors.email && (
                  <span className={styles.form__error}>
                    {errors.email.message}
                  </span>
                )}
              </div>
              <button
                className={styles.sendBtn}
                type='submit'
                disabled={!isDirty || !isValid}
              >
                  Отправить пароль на e-mail
              </button>
            </form>
          )}
          {step === 2 && (
            <form className={styles.form} onSubmit={handleSubmit(sendPasscodeHandler)}>
              <div className={styles.form__field}>
                <label className={styles.form__label}>
                    Введите пароль, отправленный на e-mail
                </label>
                <input
                  className={`${styles.form__input} ${
                    errors.otpNum && styles.form__input_error
                  }`}
                  type='text'
                  id='passcode'
                  placeholder='Введите пароль'
                  {...register('otpNum')}
                />
                {errors.otpNum && (
                  <span className={styles.form__error}>
                    {errors.otpNum.message}
                  </span>
                )}
              </div>
              <button
                className={styles.sendBtn}
                type='submit'
                disabled={!isDirty || !isValid}
              >
                  Ввод
              </button>
            </form>
          )}
        </div>
      )}
    </Box>
  )
}

import { Base } from 'api/base'

import { SecurityAdministrator } from './securityAdministrator.model'

export namespace SecurityAdministratorList {
    export type SortField = keyof SecurityAdministrator
    export type Query = Partial<Base.PaginatedQuery> & Partial<Base.SortQuery<SortField>> & Partial<Base.AdminQuery>
    export type DataItem = SecurityAdministrator
    export type Data = Base.PaginatedData<DataItem>
    export type Response = Base.PaginatedResponse<DataItem>
}

export namespace SecurityAdministratorCreate {
    export type Body = Omit<SecurityAdministrator, keyof Pick<SecurityAdministrator, 'id'>>
    export type Data = SecurityAdministrator
    export type Response = Base.Response<Data>
}

export namespace SecurityAdministratorUpdate {
    export type Body = SecurityAdministrator
    export type Data = SecurityAdministrator
    export type Response = Base.Response<Data>
}

export namespace SecurityAdministratorRemove {
    export type Query = Pick<SecurityAdministrator, 'id'>
    export type Data = null
    export type Response = Base.Response<Data>
}
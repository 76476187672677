import { getInitialAsyncState } from 'modules/base'

import { GraphSchema } from './graph.schema'

export const initialState: GraphSchema = {
  graph:getInitialAsyncState(null)
  // graph: getInitialAsyncState({
  //   name: '',
  //   unp: '',
  //   parents: [],
  //   children: [],
  // }),
}

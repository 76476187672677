import { FC } from 'react'

import style from '../InfoFormOrganization.module.css'
import { PropsData } from '../types'
import { concatArrays } from '../utils/concatArrays'


export const Leaders: FC<PropsData> = ({ data }) => {
  const result = concatArrays(data.managerName, data.managerUnp)
  
  return (
    <div className={style.containerComponent}>
      <p className={style.generalName}>Руководители</p>
      {result.map((e, i) => {
        if (i % 2) {
          return <p key={i} style={{ marginBottom: 15 }}>{e}</p>
        }
        return <span key={i} style={{}}>{e}</span>
      })}
    </div>
  )
}

import {
  useData,
  useMetaErrorMessage,
  useMetaErrorStatus,
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
} from 'modules/base'

import { GraphSlice } from './graph.schema'

export const useGetGraphIdle = ({ graph }: GraphSlice) =>
  useMetaIdleStatus(graph.graph)

export const useGetGraphLoading = ({ graph }: GraphSlice) =>
  useMetaLoadingStatus(graph.graph)

export const useGetGraphSuccess = ({ graph }: GraphSlice) =>
  useMetaSuccessStatus(graph.graph)

export const useGetGraphError = ({ graph }: GraphSlice) =>
  useMetaErrorStatus(graph.graph)

export const useGetGraphErrorMessage = ({ graph }: GraphSlice) =>
  useMetaErrorMessage(graph.graph)

export const useGetGraph = ({ graph }: GraphSlice) => useData(graph.graph)

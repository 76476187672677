import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'

import { ReportsGetList } from 'api'
import { REPORTS_TABLE_COLUMNS } from 'pages/ReportsPage/ReportsPage.const'
import { ReportsTableHeadProps } from 'pages/ReportsPage/ReportsPage.props'

import styles from '../../styles.module.css'

export const ReportsTableHead = ({
  isSelected,
  orderBy,
  order: sortDir,
  selectAllRows,
  onRequestSort,
}: ReportsTableHeadProps) => {
  const createSortHandler = (property: ReportsGetList.SortField) => () => {
    onRequestSort(property)
  }

  const order: 'asc' | 'desc' = sortDir === 'ASC' ? 'asc' : 'desc'

  return (
    <TableHead className={styles.table__head}>
      <TableRow className={styles.table__headeRow}>
        <TableCell
          className={`${styles.table__headCell} ${styles.cell_checkbox}`}
        >
          <Checkbox checked={isSelected} onChange={selectAllRows} />
        </TableCell>
        {REPORTS_TABLE_COLUMNS.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={styles.tableCell}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className={styles.tableCell}
              >
                <b className={styles.tableLabel}>{headCell.label}</b>
              </TableSortLabel>
            ) : (
              <b className={styles.tableLabel}>{headCell.label}</b>
            )}
          </TableCell>
        ))}
        <TableCell className={styles.table__headCell}></TableCell>
      </TableRow>
    </TableHead>
  )
}

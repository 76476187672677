import { Base } from 'api/base'
import { TOrganizationColumnId, TOrganizationTable } from 'common/types'

import {
  CreateResponseBody,
  CreateResponseData,
  RemoveResponseBody,
  RemoveResponseData,
} from './comparison.model'

export namespace ComparisonList {
  export type SortField = TOrganizationColumnId;
  export type Query = Partial<Base.UserIdParam> &
    Partial<Base.PaginatedQuery> &
    Partial<Base.SortQuery<SortField>>;
  export type DataItem = TOrganizationTable;
  export type Data = Base.PaginatedData<DataItem>;
  export type Response = Base.PaginatedResponse<DataItem>;
}

export namespace ComparisonCreate {
  export type Body = CreateResponseBody;
  export type Data = CreateResponseData;
  export type Response = Base.Response<Data>;
}

export namespace ComparisonRemove {
  export type Body = RemoveResponseBody;
  export type Data = RemoveResponseData;
  export type Response = Base.Response<Data>;
}

import { ROUTE_PATH } from 'routes'

export const ADMIN_MANAGEMENT_BREADCRUMBS = {
  prevPages: [{ name: 'Кабинет администратора', url: ROUTE_PATH.admin }],
  currentPageName: 'Управление учетными данными пользователей',
}

export const SECURITY_ADMIN_MANAGEMENT_BREADCRUMBS = {
  prevPages: [{ name: 'Кабинет администратора безопасности', url: ROUTE_PATH.securityAdminSpace  }],
  currentPageName: 'Управление учетными данными администраторов',
}

import * as actions from './user.creator'
import { reducer } from './user.reducer'
import { saga } from './user.saga'
import * as selectors from './user.selector'

export const user = {
  ...selectors,
  actions,
  reducer,
  saga,
}

import { Base } from 'api/base'

import { Item } from './audit.model'

export namespace AuditList {
  export type Query = Partial<Base.PaginatedQuery> &
  Partial<Base.SortQuery<'id'>> & Partial<Base.AuditQuery>

  export type DataItem = Item

  export type Data = Base.PaginatedData<DataItem>

  export type Response = Base.PaginatedResponse<DataItem>
}

import { OrganizationsAction } from './organizations.action'
import { OrganizationSchema } from './organizations.schema'
import { initialState } from './organizations.state'

export const reducer = (
  state = initialState,
  action: OrganizationsAction | void = undefined
): OrganizationSchema => {
  switch (action?.type) {
    // list
    case 'organization.update_list_filter': {
      return {
        ...state,
        listFilter: {
          ...state.listFilter,
          ...action.payload,
        },
      }
    }
    case 'organization.get_list': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            status: 'loading',
          },
          data: initialState.list.data,
        },
      }
    }
    case 'organization.get_list.success': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...initialState.list.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'organization.get_list.error': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'organization.reset_list': {
      return {
        ...state,
        list: {
          ...initialState.list,
        },
      }
    }
    // subsidiaries
    case 'organization.update_subsidiaries_list_filter': {
      return {
        ...state,
        subsidiariesListFilter: {
          ...state.subsidiariesListFilter,
          ...action.payload,
        },
      }
    }
    case 'organization.get_list_subsidiaries': {
      return {
        ...state,
        listSubsidiaries: {
          ...state.listSubsidiaries,
          meta: {
            ...state.listSubsidiaries.meta,
            status: 'loading',
          },
          data: initialState.listSubsidiaries.data,
        },
      }
    }
    case 'organization.get_list_subsidiaries.success': {
      return {
        ...state,
        listSubsidiaries: {
          ...state.listSubsidiaries,
          meta: {
            ...initialState.listSubsidiaries.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'organization.get_list_subsidiaries.error': {
      return {
        ...state,
        listSubsidiaries: {
          ...state.listSubsidiaries,
          meta: {
            ...state.listSubsidiaries.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'organization.reset_list_subsidiaries': {
      return {
        ...state,
        listSubsidiaries: {
          ...initialState.listSubsidiaries,
        },
      }
    }
    // full info
    case 'organization.get_organization_info': {
      return {
        ...state,
        organization: {
          ...state.organization,
          meta: {
            ...state.organization.meta,
            status: 'loading',
          },
          data: initialState.organization.data,
        },
      }
    }
    case 'organization.get_organization_info.success': {
      return {
        ...state,
        organization: {
          ...state.organization,
          meta: {
            ...initialState.organization.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'organization.get_organization_info.error': {
      return {
        ...state,
        organization: {
          ...state.organization,
          meta: {
            ...state.organization.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'organization.reset_organization_info': {
      return {
        ...state,
        organization: {
          ...initialState.organization,
        },
      }
    }
    // detailed search
    case 'organizations.open_filter': {
      return {
        ...state,
        openOrganizationFilter: true,
      }
    }
    case 'organizations.close_filter': {
      return {
        ...state,
        openOrganizationFilter: false,
      }
    }
    case 'organizations.open_filter_modal': {
      return {
        ...state,
        openOrganizationFilterModal: true,
      }
    }
    case 'organizations.close_filter_modal': {
      return {
        ...state,
        openOrganizationFilterModal: false,
      }
    }
    case 'organizations.set_data_filter': {
      return {
        ...state,
        dataOrganizationFilter: {
          ...state.dataOrganizationFilter,
          ...action.payload,
        }
      }
    }
    case 'organizations.reset_data_filter': {
      return {
        ...state,
        dataOrganizationFilter: {
          ...initialState.dataOrganizationFilter
        }
      }
    }
    case 'organization.update_detailed_search_filter': {
      return {
        ...state,
        detailedSearchListFilter: {
          ...state.detailedSearchListFilter,
          ...action.payload,
        },
      }
    }
    case 'organization.reset_detailed_search_filter': {
      return {
        ...state,
        detailedSearchListFilter: {
          ...initialState.detailedSearchListFilter,
        },
      }
    }
    case 'organization.detailed_search': {
      return {
        ...state,
        detailedSearch: {
          ...state.detailedSearch,
          meta: {
            ...state.detailedSearch.meta,
            status: 'loading',
          },
          data: initialState.detailedSearch.data,
        },
      }
    }
    case 'organization.detailed_search.success': {
      return {
        ...state,
        detailedSearch: {
          ...state.detailedSearch,
          meta: {
            ...initialState.detailedSearch.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'organization.detailed_search.error': {
      return {
        ...state,
        detailedSearch: {
          ...state.detailedSearch,
          meta: {
            ...state.detailedSearch.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'organization.reset_detailed_search': {
      return {
        ...state,
        detailedSearch: {
          ...initialState.detailedSearch,
        },
      }
    }
    case 'organization.get_directories': {
      return {
        ...state,
        directories: {
          ...state.directories,
          meta: {
            ...state.directories.meta,
            status: 'loading',
          },
          data: initialState.directories.data,
        },
      }
    }
    case 'organization.get_directories.success': {
      return {
        ...state,
        directories: {
          ...state.directories,
          meta: {
            ...initialState.directories.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'organization.get_directories.error': {
      return {
        ...state,
        directories: {
          ...state.directories,
          meta: {
            ...state.directories.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'organization.reset_directories': {
      return {
        ...state,
        directories: {
          ...initialState.directories,
        },
      }
    }
    case 'organization.get_filters': {
      return {
        ...state,
        filters: {
          ...state.filters,
          meta: {
            ...state.filters.meta,
            status: 'loading',
          },
          data: initialState.filters.data,
        },
      }
    }
    case 'organization.get_filters.success': {
      return {
        ...state,
        filters: {
          ...state.filters,
          meta: {
            ...initialState.filters.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'organization.get_filters.error': {
      return {
        ...state,
        filters: {
          ...state.filters,
          meta: {
            ...state.filters.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'organization.reset_filters': {
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      }
    }
    case 'organization.filter_delete': {
      return {
        ...state,
        deletion: {
          ...state.deletion,
          meta: {
            ...state.deletion.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }

    case 'organization.filter_delete.success': {
      return {
        ...state,
        deletion: {
          ...state.deletion,
          meta: {
            ...initialState.deletion.meta,
            status: 'success',
          },
        },
      }
    }

    case 'organization.filter_delete.error': {
      return {
        ...state,
        deletion: {
          ...state.deletion,
          meta: {
            ...state.deletion.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }

    case 'organization.filter_reset_deletion': {
      return {
        ...state,
        deletion: {
          ...initialState.deletion,
        },
      }
    }

    
    case 'organization.filter_create': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...state.creation.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }

    case 'organization.filter_create.success': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...initialState.creation.meta,
            status: 'success',
          },
        },
      }
    }

    case 'organization.filter_create.error': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...state.creation.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }

    case 'organization.filter_reset_creation': {
      return {
        ...state,
        creation: {
          ...initialState.creation,
        },
      }
    }

    default:
      return state
  }
}

import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { Flag } from 'api/dashboards'
import { downloadAll } from 'api/organizations/organizations.api'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'common/const'
import { TOrganizationColumnId } from 'common/types'
import { Chips } from 'components/Chips'
import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { Dashboards } from 'components/Dashboards'
import { Notification } from 'components/Notification'
import { SearchBar } from 'components/SearchBar'
import { useNotification } from 'hooks'
import { comparison, dashboards, organizations, reports } from 'modules'
import { ROUTE_PATH } from 'routes'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'
import { DeleteConfirmationModal } from 'views/TableMasterList/DeleteConfirmation'

import { OrganizationTable } from './components/OrganizationTable'
import { OrganizationTableProps } from './components/OrganizationTable/OrganizationTable.props'

type SortDirection = 'DESC' | 'ASC';

export const prevPageUrl = { name: 'Кабинет пользователя', url: ROUTE_PATH.userSpace }


export const UserSpaceDashboard = ({ setFlag, flag }: { setFlag: Dispatch<SetStateAction<boolean>>, flag: boolean }) => {
  const { notificationData, openNotification } = useNotification()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const filters = useSelector(dashboards.useFilter)
  const [page, setPage] = useState(PAGE_DEFAULT)
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT)
  const [dirSort, setDirSort] = useState<SortDirection>('ASC')
  const [orderField, setOrderField] = useState<TOrganizationColumnId>('unp')
  const { id: userId, fio: executorFio } = JSON.parse(localStorage.getItem('user')!)

  const items = useSelector(dashboards.useGetData)
  const itemsLoading = useSelector(dashboards.useGetDataLoading)
  const itemsTotal = useSelector(dashboards.useGetDataTotalCount)
  const itemsSuccess = useSelector(dashboards.useGetDataSuccess)
  const itemsError = useSelector(dashboards.useGetDataError)

  const addToComparisonSuccess = useSelector(comparison.useAddToListSuccess)
  const addToComparisonError = useSelector(comparison.useAddToListError)

  const saveToReportsSuccess = useSelector(reports.useAddToListSuccess)
  const saveToReportsError = useSelector(reports.useAddToListError)

  const filterStartDate = filters.startDate.toLocaleDateString('en-CA')
  const filterEndDate = filters.endDate.toLocaleDateString('en-CA')
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      dispatch(
        dashboards.actions.getDashboardsData({
          startDate: filterStartDate,
          endDate: filterEndDate,
          flag: id,
          sortField: orderField,
          direction: dirSort,
          page: page,
          size: pageSize
        })
      )
    }
    //eslint-disable-next-line
  }, [dispatch, id])

  const handleSubmitSearch = useCallback(
    (searchParam: string) => {
      dispatch(
        organizations.actions.updateListFilter({
          searchParam,
          page: PAGE_DEFAULT,
        })
      )

      navigate(ROUTE_PATH.userSpace)
    },
    [dispatch, navigate]
  )

  const handleTableSort = useCallback<OrganizationTableProps['onSort']>(
    (direction, sortField) => {
      setDirSort(direction)
      setOrderField(sortField)
      dispatch(
        dashboards.actions.getDashboardsData({
          startDate: filterStartDate,
          endDate: filterEndDate,
          flag: id,
          sortField,
          direction,
          page: page,
          size: pageSize
        })
      )
    },
    //eslint-disable-next-line
    [dispatch, id]
  )

  const handleTablePageSizeChange = useCallback<
    OrganizationTableProps['onPageSizeChange']
  >(
    (size) => {
      setPageSize(size)
      dispatch(
        dashboards.actions.getDashboardsData({
          startDate: filterStartDate,
          endDate: filterEndDate,
          flag: id,
          sortField: orderField,
          direction: dirSort,
          page: page,
          size
        })
      )
    },
    //eslint-disable-next-line
    [dispatch, id]
  )

  const handleTablePageChange = useCallback<
    OrganizationTableProps['onPageChange']
  >(
    (page) => {
      setPage(page)
      dispatch(
        dashboards.actions.getDashboardsData({
          startDate: filterStartDate,
          endDate: filterEndDate,
          flag: id,
          sortField: orderField,
          direction: dirSort,
          page,
          size: pageSize
        })
      )
    },
    //eslint-disable-next-line
    [dispatch, id]
  )

  const handleTableFiltersChange = useCallback<
    OrganizationTableProps['onFiltersChange']
  >(
    (filters) => {
      dispatch(
        organizations.actions.updateDetailedSearchFilter({
          ...filters,
          page: PAGE_DEFAULT,
        })
      )
      setFlag(true)
      navigate(ROUTE_PATH.userSpace)
    },
    [dispatch, navigate]
  )

  // add to comparison
  const handleTableAddToComparison = useCallback<
    OrganizationTableProps['onAddToComparison']
  >(
    (unps) => {
      if (unps.length === 0) {
        openNotification('Не выбраны организации', 'error')
      } else {
        dispatch(
          comparison.actions.addToComparisonList({
            userId: userId,
            unps: unps,
          })
        )
      }
    },
    [dispatch, openNotification, userId]
  )

  // save to reports
  const handleTableSaveToReports = useCallback<
    OrganizationTableProps['onSave']
  >(
    (unps, fields) => {
      if (unps.length === 0) {
        openNotification('Не выбраны организации', 'error')
      } else {
        dispatch(
          reports.actions.addToList({
            executorFio,
            reportType: 'table',
            unps,
            fields,
          })
        )
      }
    },
    [dispatch, openNotification, executorFio]
  )

  // notifications
  useEffect(() => {
    if (itemsError) {
      openNotification('По вашему запросу ничего не найдено.', 'error')
    }
    if (addToComparisonSuccess) {
      openNotification(
        'Организации добавлены на страницу Избранное',
        'success'
      )
      dispatch(comparison.actions.resetAddToComparisonList())
    }
    if (addToComparisonError) {
      openNotification(
        'Не удалось добавить организации на страницу Избранное',
        'error'
      )
      dispatch(comparison.actions.resetAddToComparisonList())
    }
    if (saveToReportsSuccess) {
      openNotification(
        'Отчет добавлен на страницу Сохраненные отчеты',
        'success'
      )
      dispatch(reports.actions.resetToList())
    }

    if (saveToReportsError) {
      openNotification(
        'Не удалось добавить отчет на страницу Сохраненные отчеты',
        'error'
      )
      dispatch(reports.actions.resetToList())
    }
  }, [
    dispatch,
    openNotification,
    itemsError,
    addToComparisonSuccess,
    addToComparisonError,
    saveToReportsSuccess,
    saveToReportsError,
  ])

  let chain

  switch (id) {
    case Flag.STATE_END_DATE:
      chain = 'Общее количество госактивов'
      break
    case Flag.STATE_START_DATE:
      chain = 'Общее количество госактивов'
      break
    case Flag.STATE_ELEVATED:
      chain = 'Общее количество госактивов'
      break
    case Flag.STATE_REDUCED:
      chain = 'Общее количество госактивов'
      break
    case Flag.LIQUIDATION_START_DATE:
      chain = 'Количество госактивов в стадии ликвидации'
      break
    case Flag.LIQUIDATION_END_DATE:
      chain = 'Количество госактивов в стадии ликвидации'
      break
    case Flag.LIQUIDATION_ELEVATED:
      chain = 'Количество госактивов в стадии ликвидации'
      break
    case Flag.LIQUIDATION_REDUCED:
      chain = 'Количество госактивов в стадии ликвидации'
      break
    case Flag.CONTROLLING_STAKE_STATE_DATE:
      chain = 'Количество госактивов (контрольный пакет акций)'
      break
    case Flag.CONTROLLING_STAKE_END_DATE:
      chain = 'Количество госактивов (контрольный пакет акций)'
      break
    case Flag.CONTROLLING_STAKE_ELEVATED:
      chain = 'Количество госактивов (контрольный пакет акций)'
      break
    case Flag.CONTROLLING_STAKE_REDUCED:
      chain = 'Количество госактивов (контрольный пакет акций)'
      break
    default:
      chain = ''
  }

  const handleSearchFilters = (filters: FormListProps) => {
    dispatch(
      organizations.actions.setDataFilterOrganizations({
        ...filters
      })
    )

    const copyFilters = Object.assign({}, filters)

    for (const filter in copyFilters) {
      if (typeof copyFilters[filter as keyof typeof copyFilters] === 'object' && !Array.isArray(copyFilters[filter as keyof typeof copyFilters])) {
        // @ts-ignore
        copyFilters[filter as keyof typeof copyFilters] = new Date(copyFilters[filter as unknown]).toLocaleDateString()
      }
      if(Array.isArray(copyFilters[filter as keyof typeof copyFilters])) {
        // @ts-ignore
        copyFilters[filter as keyof typeof copyFilters] = copyFilters[filter].join(',')
      }
    }
    dispatch(
      organizations.actions.updateDetailedSearchFilter({
        ...copyFilters,
        page: PAGE_DEFAULT,
      })
    )
    setFlag(true)
    navigate(ROUTE_PATH.userSpace)
  }

  const filtersSearch = useSelector(organizations.useGetFilters)

  useEffect(() => {
    dispatch(
      organizations.actions.filters({
        'user_id': userId
      })
    )
  }, [dispatch, navigate, userId])

  const deletionSucceed = useSelector(organizations.useDeletionSuccess)
  const deletionError = useSelector(organizations.useDeletionError)
  const deletionErrorText = useSelector(organizations.useDeletionErrorMessage)

  const handleDelete = (id: number) => {
    setDeletionId(id)
  }

  useEffect(() => {
    if (deletionSucceed) {
      dispatch(organizations.actions.resetDeletion())
      dispatch(
        organizations.actions.filters({
          'user_id': userId
        })
      )
      openNotification('Фильтр удален', 'success')
    }
    if (deletionError) {
      openNotification(deletionErrorText, 'error')
      dispatch(organizations.actions.resetDeletion())
    }
  }, [dispatch, openNotification, deletionSucceed, deletionError, deletionErrorText, userId])

  const [deletionId, setDeletionId] = useState<number | undefined>()

  const handleDeletionAccept = useCallback((id: number) => {
    dispatch(organizations.actions.remove({ userId, id }))
    setDeletionId(undefined)
  }, [setDeletionId, userId, dispatch])

  const handleDeletionCancel = useCallback(() => {
    setDeletionId(undefined)
  }, [setDeletionId])

  const downloadFiles = () => {
    if (id) {
      downloadAll({
        flagDownload: 'dashboards',
        datesPeriod: {
          startDate: filterStartDate,
          endDate: filterEndDate
        },
        flag: id
      })
        .then((response) => {
          const headerContent = response.headers['content-disposition']
          const filename = decodeURI(headerContent.slice(headerContent.lastIndexOf('=') + 1))
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => openNotification(
          'Ошибка загрузки',
          'error'
        ))
    }
  }

  return (
    <div>
      <div style={{ marginBottom: 50 }}>
        <Dashboards />
        <DeleteConfirmationModal
          id={deletionId}
          // @ts-ignore
          onAccept={handleDeletionAccept}
          onCancel={handleDeletionCancel}
        />
      </div>
      <SearchBar onSearch={handleSubmitSearch} />
      {itemsSuccess ?
        <OrganizationTable
          loading={itemsLoading}
          rows={items.content as unknown as OrganizationTableProps['rows']}
          total={itemsTotal}
          page={page}
          pageSize={pageSize}
          onPageChange={handleTablePageChange}
          onPageSizeChange={handleTablePageSizeChange}
          onSort={handleTableSort}
          onFiltersChange={handleTableFiltersChange}
          onAddToComparison={handleTableAddToComparison}
          onSave={handleTableSaveToReports}
          sortDir={dirSort}
          sortBy={orderField}
          setFlag={setFlag}
          flag={flag}
          downloadFiles={downloadFiles}
        >
          <Chips
            filters={filtersSearch}
            handleDelete={handleDelete}
            handleSearchFilters={handleSearchFilters}
          />
          <CustomBreadcrumbs
            currentPageName={chain}
            prevPages={[prevPageUrl]}
          />
        </OrganizationTable> : null}
      <Notification {...notificationData} />
    </div>
  )
}

import { ReferenceAction } from './reference.action'
import { ReferenceSchema } from './reference.schema'
import { initialState } from './reference.state'

export const reducer = (
  state = initialState,
  action: ReferenceAction | void = undefined
): ReferenceSchema => {
  switch (action?.type) {
    case 'reference.update_list_filter': {
      return {
        ...state,
        listFilter: {
          ...state.listFilter,
          ...action.payload,
        },
      }
    }

    case 'reference.get_list': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            status: 'loading',
          },
          data: initialState.list.data,
        },
      }
    }

    case 'reference.get_list.success': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...initialState.list.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }

    case 'reference.get_list.error': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }

    case 'reference.reset_list': {
      return {
        ...state,
        list: {
          ...initialState.list,
        },
      }
    }

    case 'reference.create': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...state.creation.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }

    case 'reference.create.success': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...initialState.creation.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }

    case 'reference.create.error': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...state.creation.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }

    case 'reference.reset_creation': {
      return {
        ...state,
        creation: {
          ...initialState.creation,
        },
      }
    }

    case 'reference.update': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...state.updation.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }

    case 'reference.update.success': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...initialState.updation.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }

    case 'reference.update.error': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...state.updation.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }

    case 'reference.reset_updation': {
      return {
        ...state,
        updation: {
          ...initialState.updation,
        },
      }
    }

    case 'reference.delete': {
      return {
        ...state,
        deletion: {
          ...state.deletion,
          meta: {
            ...state.deletion.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }

    case 'reference.delete.success': {
      return {
        ...state,
        deletion: {
          ...state.deletion,
          meta: {
            ...initialState.deletion.meta,
            status: 'success',
          },
        },
      }
    }

    case 'reference.delete.error': {
      return {
        ...state,
        deletion: {
          ...state.deletion,
          meta: {
            ...state.deletion.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }

    case 'reference.reset_deletion': {
      return {
        ...state,
        deletion: {
          ...initialState.deletion,
        },
      }
    }

    case 'reference.import': {
      return {
        ...state,
        importion: {
          ...state.importion,
          meta: {
            ...state.importion.meta,
            status: 'loading',
          },
        },
      }
    }

    case 'reference.import.success': {
      return {
        ...state,
        importion: {
          ...state.importion,
          meta: {
            ...initialState.importion.meta,
            status: 'success',
          },
        },
      }
    }

    case 'reference.import.error': {
      return {
        ...state,
        importion: {
          ...state.importion,
          meta: {
            ...state.importion.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }

    case 'reference.reset_importion': {
      return {
        ...state,
        importion: {
          ...initialState.importion,
        },
      }
    }

    default:
      return state
  }
}

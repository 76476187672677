import * as actions from './dashboards.creator'
import { reducer } from './dashboards.reducer'
import { saga } from './dashboards.saga'
import * as selectors from './dashboards.selector'

export const dashboards = {
  ...selectors,
  actions,
  reducer,
  saga,
}

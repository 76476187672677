import * as yup from 'yup'

import { emailRegExp } from 'regExp'

export const authSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegExp, 'Введите корректный e-mail')
    .required('Введите e-mail'),
  otpNum: yup
    .string()
    .matches(/^\d+$/, 'Используйте только цифры')
    .max(6, 'Длина не должна превышать 6 символов'),
})

import { AxiosError } from 'axios'
import { call, put, spawn, takeEvery, select } from 'redux-saga/effects'

import {
  Base,
  organizations,
  OrganizationList,
  OrganizationWithSubsidiariesList,
  OrganizationFullInfo,
  DetailedSearch,
  Directories,
  FiltersSearch,
  FilterRemove,
  FilterCreate
} from 'api'

import {
  Create,
  Delete,
  GetDirectoriesAction,
  GetFiltersAction,
  GetOrganizationFullInfo,
  UpdateDetailedSearchFilterAction,
  UpdateListFilter,
  UpdateSubsidiariesListFilter,
} from './organizations.action'
import * as creator from './organizations.creator'
import * as selector from './organizations.selector'


function* updateListFilterWorker() {
  let errorMessage = undefined

  try {
    const filter: ReturnType<typeof selector.useListFilter> = yield select(
      selector.useListFilter
    )

    yield put(creator.getList())

    const {
      data: { data, meta, errorCode },
    }: OrganizationList.Response = yield call(organizations.list, filter)

    if (!errorCode && data) {
      yield put(creator.getListSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getListError(errorMessage))
    }
  }
}

function* updateListFilterWatcher() {
  yield takeEvery<UpdateListFilter>(
    'organization.update_list_filter' as UpdateListFilter['type'],
    updateListFilterWorker
  )
}

function* updateSubsidiariesListFilterWorker() {
  let errorMessage = undefined

  try {
    const filter: ReturnType<typeof selector.useSubsidiariesListFilter> = yield select(
      selector.useSubsidiariesListFilter
    )

    yield put(creator.getList())

    const {
      data: { data, meta, errorCode },
    }: OrganizationWithSubsidiariesList.Response = yield call(
      organizations.organizationSubsidiaries, filter
    )

    if (data) {
      yield put(creator.getListSubsidiariesSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getListSubsidiariesError(errorMessage))
    }
  }
}

function* updateSubsidiariesListFilterWatcher() {
  yield takeEvery<UpdateSubsidiariesListFilter>(
    'organization.update_subsidiaries_list_filter' as UpdateSubsidiariesListFilter['type'],
    updateSubsidiariesListFilterWorker
  )
}

function* getOrganizationInfoWorker(action: GetOrganizationFullInfo) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: OrganizationFullInfo.Response = yield call(
      organizations.organizationFullInfo,
      action.payload
    )

    if (data) {
      yield put(creator.getOrganizationFullInfoSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getOrganizationFullInfoError(errorMessage))
    }
  }
}

function* getOrganizationInfoWatcher() {
  yield takeEvery<GetOrganizationFullInfo>(
    'organization.get_organization_info' as GetOrganizationFullInfo['type'],
    getOrganizationInfoWorker
  )
}

function* updateDetailedSearchListFilterWorker() {
  let errorMessage = undefined

  try {
    const filter: ReturnType<typeof selector.useDetailedSearchListFilter> = yield select(
      selector.useDetailedSearchListFilter
    )

    yield put(creator.detailedSearch())

    const {
      data: { data, meta, errorCode },
    }: DetailedSearch.Response = yield call(
      organizations.detailedSearch, filter
    )

    if (data) {
      yield put(creator.detailedSearchSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.detailedSearchError(errorMessage))
    }
  }
}

function* updateDetailedSearchListFilterWatcher() {
  yield takeEvery<UpdateDetailedSearchFilterAction>(
    'organization.update_detailed_search_filter' as UpdateDetailedSearchFilterAction['type'],
    updateDetailedSearchListFilterWorker
  )
}


function* updateDirectoriesListWorker() {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: Directories.Response = yield call(organizations.directories)

    if (data) {
      yield put(creator.directoriesSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.directoriesError(errorMessage))
    }
  }
}

function* updateDirectoriesListWatcher() {
  yield takeEvery<GetDirectoriesAction>(
    'organization.get_directories' as GetDirectoriesAction['type'],
    updateDirectoriesListWorker
  )
}

function* updateFiltersListWorker(action: GetFiltersAction) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: FiltersSearch.Response = yield call(organizations.filters, action.payload)

    if (data) {
      yield put(creator.filtersSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.filtersError(errorMessage))
    }
  }
}

function* updateFiltersListWatcher() {
  yield takeEvery<GetFiltersAction>(
    'organization.get_filters' as GetFiltersAction['type'],
    updateFiltersListWorker
  )
}

function* deleteWorker(action: Delete) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: FilterRemove.Response = yield call(organizations.removeFilter, action.payload)

    if (!data) {
      yield put(creator.removeSuccess())
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.removeError(errorMessage))
    }
  }
}

function* deleteWatcher() {
  yield takeEvery<Delete>('organization.filter_delete' as Delete['type'], deleteWorker)
}

function* createWorker(action: Create) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: FilterCreate.Response = yield call(organizations.createFilter, action.payload)

    if (data) {
      yield put(creator.createSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.createError(errorMessage))
    }
  }
}

function* createWatcher() {
  yield takeEvery<Create>('organization.filter_create' as Create['type'], createWorker)
}

export function* saga() {
  yield spawn(updateListFilterWatcher)
  yield spawn(updateSubsidiariesListFilterWatcher)
  yield spawn(getOrganizationInfoWatcher)
  yield spawn(updateDetailedSearchListFilterWatcher)
  yield spawn(updateDirectoriesListWatcher)
  yield spawn(updateFiltersListWatcher)
  yield spawn(deleteWatcher)
  yield spawn(createWatcher)
}

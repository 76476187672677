import { useSelector } from 'react-redux'

import { organizations } from 'modules'

export const ModalData = () => {
  const data = useSelector(organizations.useStateFilter)
  const newData = {
    panel1: [data.subordGO, data.subordCodeGO, data.subordLvlGO],
    panel2: [data.infoRegDate1From, data.infoRegDate1To, data.infoRegNumberEGR, data.infoRegOrganOfReg, data.infoRegDate2From, data.infoRegDate2To, data.infoRegOrgan, data.infoRegIMNS],
    panel3: [data.capitalType, data.capitalValue, data.capitalSizeFrom, data.capitalSizeTo, data.capitalShareGosFrom, data.capitalShareGosTo, data.capitalShareForeignValueFrom, data.capitalShareForeignValueTo, data.capitalShareForeignProcFrom, data.capitalShareForeignProcTo, data.capitalShareBelValueFrom, data.capitalShareBelValueTo, data.capitalShareBelProcFrom, data.capitalShareBelProcTo],
    panel4: [data.addressCountry, data.addressArea, data.addressDistrict, data.addressCity],
    panel5: [data.foundersUNP, data.foundersValue, data.foundersShareValueFrom, data.foundersShareValueTo, data.foundersShareProcFrom, data.foundersShareProcTo, data.foundersCountry],
    panel6: [data.subsidiariesUnp, data.subsidiariesValue, data.subsidiariesShareValueFrom, data.subsidiariesShareValueTo, data.subsidiariesShareProcFrom, data.subsidiariesShareProcTo, data.subsidiariesCounty],
    panel7: [data.assignees, data.assigneesUnp, data.predecessors, data.predecessorsUnp],
    panel8: [data.divisionsBranch, data.divisionsUnp, data.divisionsIMNS, data.divisionsState, data.divisionsDateBranchRegFrom, data.divisionsDateBranchRegTo, data.divisionsDateBranchDelFrom, data.divisionsDateBranchDelTo],
    panel9: [data.eliminationDateFrom, data.eliminationDateTo, data.eliminationOrgan, data.eliminationReason, data.eliminationNumber, data.eliminationDateDelFrom, data.eliminationDateDelTo],
    panel10: [data.securitiesUnp, data.securitiesCode, data.securitiesUnpShare, data.securitiesNumber]
  }
  return (
    newData
  )
}

import { Position } from 'reactflow'

export const DEFAULT_ZOOM = 0
export const DEFAULT_SCREEN_GAP_X = 250
export const DEFAULT_SCREEN_GAP_Y = 100
export const DEFAULT_VIEWPORT = {
  x: DEFAULT_SCREEN_GAP_X,
  y: DEFAULT_SCREEN_GAP_Y,
  zoom: DEFAULT_ZOOM,
}
  
export const BLOCK_HEIGHT = 100
export const BLOCK_WIDTH = 300
export const VERTICAL_GAP =  100
export const HORIZONTAL_GAP = 100
export const TARGET_X = 650
export const TARGET_Y = 250

export const NODE_DEFAULTS = {
  sourcePosition: Position.Bottom,
  targetPosition: Position.Top,
}

export const PROP_OPTIONS = { 
  hideAttribution: true 
}
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Base } from 'layouts'
import { ROUTE_PATH } from 'routes'

import { PrivateProps } from './Private.props'


export const Private = ({ ...baseProps }: PrivateProps) => {
  const navigate = useNavigate()
  const token = localStorage.getItem('access_token')

  if(!token) {
    return (
      <div className='noRole'>
        <p>Для просмотра данной страницы необходима </p>
        <Button onClick={() => navigate(ROUTE_PATH.auth)}>авторизация</Button>
      </div>
    )
  }

  return (
    <>
      <Base {...baseProps}/>
    </>
  )
}

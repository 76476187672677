import {
  useDataCurrPage,
  useDataItems,
  useDataItemsTotalCount,
  useDataLastPage,
  useMetaErrorMessage,
  useMetaErrorStatus,
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
} from 'modules/base'

import { ReportsSlice } from './reports.schema'

// filter
export const useListFilter = ({ reports }: ReportsSlice) =>
  reports.listFilter

// list
export const useListIdle = ({ reports }: ReportsSlice) =>
  useMetaIdleStatus(reports.list)

export const useListLoading = ({ reports }: ReportsSlice) =>
  useMetaLoadingStatus(reports.list)

export const useListSuccess = ({ reports }: ReportsSlice) =>
  useMetaSuccessStatus(reports.list)

export const useListError = ({ reports }: ReportsSlice) =>
  useMetaErrorStatus(reports.list)

export const useListErrorMessage = ({ reports }: ReportsSlice) =>
  useMetaErrorMessage(reports.list)

export const useListCurrPage = ({ reports }: ReportsSlice) =>
  useDataCurrPage(reports.list)

export const useListLastPage = ({ reports }: ReportsSlice) =>
  useDataLastPage(reports.list)

export const useListItemsTotalCount = ({ reports }: ReportsSlice) =>
  useDataItemsTotalCount(reports.list)

export const useListItems = ({ reports }: ReportsSlice) =>
  useDataItems(reports.list)

// add to list
export const useAddToListIdle = ({ reports }: ReportsSlice) =>
  useMetaIdleStatus(reports.addToList)

export const useAddToListLoading = ({ reports }: ReportsSlice) =>
  useMetaLoadingStatus(reports.addToList)

export const useAddToListSuccess = ({ reports }: ReportsSlice) =>
  useMetaSuccessStatus(reports.addToList)

export const useAddToListError = ({ reports }: ReportsSlice) =>
  useMetaErrorStatus(reports.addToList)

export const useAddToListErrorMessage = ({ reports }: ReportsSlice) =>
  useMetaErrorMessage(reports.addToList)

export const useAddToListData = ({ reports }: ReportsSlice) =>
  reports.addToList.data

// remove from list
export const useRemoveFromListIdle = ({ reports }: ReportsSlice) =>
  useMetaIdleStatus(reports.removeFromList)

export const useRemoveFromListLoading = ({ reports }: ReportsSlice) =>
  useMetaLoadingStatus(reports.removeFromList)

export const useRemoveFromListSuccess = ({ reports }: ReportsSlice) =>
  useMetaSuccessStatus(reports.removeFromList)

export const useRemoveFromListError = ({ reports }: ReportsSlice) =>
  useMetaErrorStatus(reports.removeFromList)

export const useRemoveFromListErrorMessage = ({ reports }: ReportsSlice) =>
  useMetaErrorMessage(reports.removeFromList)

export const useRemoveFromListData = ({ reports }: ReportsSlice) =>
  reports.removeFromList.data

// download file

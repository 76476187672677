export const convertDate = (start: Date, end: Date) => {
  const startDay = start.getDate()
  const startMonth = start.getMonth() + 1
  const startYear = start.getFullYear()

  const startDate = `${startYear}-${
    startMonth.toString().length === 1 ? `0${startMonth}` : startMonth
  }-${startDay.toString().length === 1 ? `0${startDay}` : startDay}`

  const endDay = end.getDate()
  const endMonth = end.getMonth() + 1
  const endYear = end.getFullYear()


  const endDate = `${endYear}-${
    endMonth.toString().length === 1 ? `0${endMonth}` : endMonth
  }-${endDay.toString().length === 1 ? `0${endDay}` : endDay}`

  return {
    startDate,
    endDate,
  }
}
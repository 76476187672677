import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { downloadAll } from 'api/organizations/organizations.api'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'common/const'
import { Chips } from 'components/Chips'
import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { Dashboards } from 'components/Dashboards'
import { Notification } from 'components/Notification'
import { useNotification } from 'hooks'
import { breadcrumbs, comparison, organizations, reports } from 'modules'
import { ROUTE_PATH } from 'routes'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'

import { DeleteConfirmationModal } from './components/DeleteConfirmation'
import { OrganizationTable } from './components/OrganizationTable'
import { OrganizationTableProps } from './components/OrganizationTable/OrganizationTable.props'

export const UserSpaceOrganizationPage = ({ setFlag, flag }: { setFlag: Dispatch<SetStateAction<boolean>>, flag: boolean }) => {
  const navigate = useNavigate()
  const { openNotification, notificationData } = useNotification()
  const dispatch = useDispatch()
  const { unp } = useParams()
  const [searchParams] = useSearchParams()

  // breadcrumbs
  const chain = searchParams.get('chain')
  const { id: userId, fio: executorFio } = JSON.parse(localStorage.getItem('user')!)

  const currentBreadcrumbs = useSelector(breadcrumbs.useGetBreadcrumbs)

  useEffect(() => {
    if (unp) {
      dispatch(
        breadcrumbs.actions.getBreadcrumbs({
          unp,
          chain: chain || undefined,
        })
      )
    }

    return () => {
      dispatch(breadcrumbs.actions.resetGetBreadcrumbs())
    }
  }, [dispatch, unp, chain])

  // data
  const items = useSelector(organizations.useListWithSubsidiariesItems)
  const itemsLoading = useSelector(organizations.useListWithSubsidiariesLoading)
  const itemsTotal = useSelector(organizations.useListWithSubsidiariesItemsTotalCount)
  const itemsFilter = useSelector(organizations.useSubsidiariesListFilter)

  const addToComparisonSuccess = useSelector(comparison.useAddToListSuccess)
  const addToComparisonError = useSelector(comparison.useAddToListError)

  const saveToReportsSuccess = useSelector(reports.useAddToListSuccess)
  const saveToReportsError = useSelector(reports.useAddToListError)

  useEffect(() => {
    dispatch(
      organizations.actions.updateSubsidiariesListFilter({
        unp: unp!,
        page: PAGE_DEFAULT,
        size: PAGE_SIZE_DEFAULT,
      })
    )
  }, [dispatch, unp])

  // page
  const handleTablePageChange = useCallback<
    OrganizationTableProps['onPageChange']
  >(
    (page) => {
      dispatch(organizations.actions.updateSubsidiariesListFilter({ page }))
    },
    [dispatch]
  )

  // page size
  const handleTablePageSizeChange = useCallback<
    OrganizationTableProps['onPageSizeChange']
  >(
    (size) => {
      dispatch(
        organizations.actions.updateSubsidiariesListFilter({ page: PAGE_DEFAULT, size })
      )
    },
    [dispatch]
  )

  // sort
  const handleTableSort = useCallback<OrganizationTableProps['onSort']>(
    (direction, sortField) => {
      dispatch(
        organizations.actions.updateSubsidiariesListFilter({
          page: PAGE_DEFAULT,
          direction,
          sortField,
        })
      )
    },
    [dispatch]
  )

  // search
  const handleTableSearch = useCallback<OrganizationTableProps['onSearch']>(
    (searchParam) => {
      dispatch(
        organizations.actions.updateListFilter({
          searchParam,
          page: PAGE_DEFAULT,
        })
      )
      navigate(ROUTE_PATH.userSpace)
    },
    [dispatch, navigate]
  )

  // detailed search
  const handleTableFiltersChange = useCallback<
    OrganizationTableProps['onFiltersChange']
  >(
    (filters) => {
      const copyFilters = Object.assign({}, filters)

      for (const filter in copyFilters) {
        if (typeof copyFilters[filter as keyof typeof copyFilters] === 'object' && !Array.isArray(copyFilters[filter as keyof typeof copyFilters])) {
          // @ts-ignore
          copyFilters[filter as keyof typeof copyFilters] = new Date(copyFilters[filter as unknown]).toLocaleDateString()
        }
        if(Array.isArray(copyFilters[filter as keyof typeof copyFilters])) {
          // @ts-ignore
          copyFilters[filter as keyof typeof copyFilters] = copyFilters[filter].join(',')
        }
      }

      dispatch(
        organizations.actions.updateDetailedSearchFilter({
          ...copyFilters,
          page: PAGE_DEFAULT,
        })
      )

      dispatch(
        organizations.actions.setDataFilterOrganizations({
          ...filters
        })
      )
      setFlag(true)
      navigate(ROUTE_PATH.userSpace)
    },
    [dispatch, navigate]
  )

  // add to comparison
  const handleTableAddToComparison = useCallback<OrganizationTableProps['onAddToComparison']>((unps) => {
    dispatch(comparison.actions.addToComparisonList({
      userId: userId,
      unps: unps
    }))
  }, [dispatch, userId])

  // save to reports
  const handleTableSaveToReports = useCallback<OrganizationTableProps['onSave']>((unps, fields) => {
    dispatch(reports.actions.addToList({
      executorFio,
      reportType: 'table',
      unps,
      fields,
    }))
  }, [dispatch, executorFio])

  useEffect(() => {
    if (addToComparisonSuccess) {
      openNotification('Организации добавлены на страницу Избранное', 'success')

      return () => {
        dispatch(comparison.actions.resetAddToComparisonList())
      }
    }
    if (addToComparisonError) {
      openNotification('Не удалось добавить организации на страницу Избранное', 'error')

      return () => {
        dispatch(comparison.actions.resetAddToComparisonList())
      }
    }
    if (saveToReportsSuccess) {
      openNotification('Отчет добавлен на страницу Сохраненные отчеты', 'success')

      return () => {
        dispatch(reports.actions.resetToList())
      }
    }

    if (saveToReportsError) {
      openNotification('Не удалось добавить отчет на страницу Сохраненные отчеты', 'error')

      return () => {
        dispatch(reports.actions.resetToList())
      }
    }
  }, [dispatch, openNotification, addToComparisonSuccess, addToComparisonError, saveToReportsSuccess, saveToReportsError])


  useEffect(() => {
    if (items.length > 0) {
      window.scrollTo(0, 500)
    }
  }, [items])


  const filters = useSelector(organizations.useGetFilters)

  useEffect(() => {
    dispatch(
      organizations.actions.filters({
        'user_id': userId
      })
    )
  }, [dispatch, navigate, userId])

  const handleSearchFilters = (filters: FormListProps) => {
    dispatch(
      organizations.actions.setDataFilterOrganizations({
        ...filters
      })
    )

    const copyFilters = Object.assign({}, filters)

    for (const filter in copyFilters) {
      // @ts-ignore
      if (copyFilters[filter as unknown] && copyFilters[filter as unknown].includes('-')) {
        // @ts-ignore
        copyFilters[filter as keyof typeof copyFilters] = copyFilters[filter as unknown].split('-').reverse().join('.')
      }
    }
    dispatch(
      organizations.actions.updateDetailedSearchFilter({
        ...copyFilters,
        page: PAGE_DEFAULT,
      })
    )
    setFlag(true)
    navigate(ROUTE_PATH.userSpace)
  }


  const deletionLoading = useSelector(organizations.useDeletionLoading)
  const deletionSucceed = useSelector(organizations.useDeletionSuccess)
  const deletionError = useSelector(organizations.useDeletionError)
  const deletionErrorText = useSelector(organizations.useDeletionErrorMessage)

  const handleDelete = (id: number) => {
    setDeletionId(id)
  }

  useEffect(() => {
    if (deletionSucceed) {
      dispatch(organizations.actions.resetDeletion())
      dispatch(
        organizations.actions.filters({
          'user_id': userId
        })
      )
      openNotification('Фильтр удален', 'success')
    }
    if (deletionError) {
      openNotification(deletionErrorText, 'error')
      dispatch(organizations.actions.resetDeletion())
    }
  }, [dispatch, openNotification, deletionSucceed, deletionError, deletionErrorText, userId])

  const [deletionId, setDeletionId] = useState<number | undefined>()

  const handleDeletionAccept = useCallback((id: number) => {
    dispatch(organizations.actions.remove({ userId, id }))
    setDeletionId(undefined)
  }, [setDeletionId, userId, dispatch])

  const handleDeletionCancel = useCallback(() => {
    setDeletionId(undefined)
  }, [setDeletionId])

  const downloadFiles = () => {
    downloadAll({
      flagDownload: 'subsidiriesInfo',
      unp: unp!
    })
      .then((response) => {
        const headerContent = response.headers['content-disposition']
        const filename = decodeURI(headerContent.slice(headerContent.lastIndexOf('=') + 1))
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => openNotification(
        'Ошибка загрузки',
        'error'
      ))
  }

  return (
    <>
      <Dashboards />
      <DeleteConfirmationModal
        id={deletionId}
        onAccept={handleDeletionAccept}
        onCancel={handleDeletionCancel}
      />
      {items.length > 0 &&
        <OrganizationTable
          loading={itemsLoading || deletionLoading}
          parentRow={items[0].parent}
          rows={items[0].children as unknown as OrganizationTableProps['rows']}
          total={itemsTotal}
          page={itemsFilter.page || PAGE_DEFAULT}
          pageSize={itemsFilter.size || PAGE_SIZE_DEFAULT}
          sortBy={itemsFilter.sortField}
          sortDir={itemsFilter.direction}
          onFiltersChange={handleTableFiltersChange}
          onPageChange={handleTablePageChange}
          onPageSizeChange={handleTablePageSizeChange}
          onSort={handleTableSort}
          onSearch={handleTableSearch}
          onAddToComparison={handleTableAddToComparison}
          onSave={handleTableSaveToReports}
          downloadFiles={downloadFiles}
        >
          <Chips
            filters={filters}
            handleDelete={handleDelete}
            handleSearchFilters={handleSearchFilters}
          />
          {currentBreadcrumbs && (
            <CustomBreadcrumbs
              prevPages={currentBreadcrumbs.prevPages}
              currentPageName={currentBreadcrumbs.currentPageName}
            />
          )}
        </OrganizationTable>
      }
      <Notification {...notificationData} />
    </>
  )
}

import { getInitialAsyncState } from 'modules/base'

import { ManageAccessSchema } from './manageAccess.schema'

export const initialState: ManageAccessSchema = {
  listFilter: {
    _page: 1,
    _limit: 10,
  },
  list: getInitialAsyncState({
    number: 0,
    totalPages: 0,
    totalElements: 0,
    content: [],
  }),
  updation: getInitialAsyncState(null),
}

import { DashboardsAction } from './dashboards.actions'
import { DashboardsSchema } from './dashboards.schema'
import { initialState } from './dashboards.state'

export const reducer = (
  state = initialState,
  action: DashboardsAction | void = undefined
): DashboardsSchema => {
  switch (action?.type) {
    // get main
    case 'dashboards.get_main': {
      return {
        ...state,
        getMainDashboards: {
          ...state.getMainDashboards,
          meta: {
            ...state.getMainDashboards.meta,
            status: 'loading',
          },
          data: initialState.getMainDashboards.data,
        },
      }
    }
    case 'dashboards.get_main.success': {
      return {
        ...state,
        getMainDashboards: {
          ...state.getMainDashboards,
          meta: {
            ...initialState.getMainDashboards.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'dashboards.get_main.error': {
      return {
        ...state,
        getMainDashboards: {
          ...state.getMainDashboards,
          meta: {
            ...state.getMainDashboards.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'dashboards.reset_get_main': {
      return {
        ...state,
        getMainDashboards: {
          ...initialState.getMainDashboards,
        },
      }
    }
    // filter
    case 'dashboards.update_filter': {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      }
    }
    // get
    case 'dashboards.get': {
      return {
        ...state,
        getDashboards: {
          ...state.getDashboards,
          meta: {
            ...state.getDashboards.meta,
            status: 'loading',
          },
          data: initialState.getDashboards.data,
        },
      }
    }
    case 'dashboards.get.success': {
      return {
        ...state,
        getDashboards: {
          ...state.getDashboards,
          meta: {
            ...initialState.getDashboards.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'dashboards.get.error': {
      return {
        ...state,
        getDashboards: {
          ...state.getDashboards,
          meta: {
            ...state.getDashboards.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'dashboards.reset_get': {
      return {
        ...state,
        getDashboards: {
          ...initialState.getDashboards,
        },
      }
    }
    case 'dashboards.get_data': {
      return {
        ...state,
        getDashboardsData: {
          ...state.getDashboardsData,
          meta: {
            ...state.getDashboardsData.meta,
            status: 'loading',
          },
          data: initialState.getDashboardsData.data,
        },
      }
    }
    case 'dashboards.get_data.success': {
      return {
        ...state,
        getDashboardsData: {
          ...state.getDashboardsData,
          meta: {
            ...initialState.getDashboardsData.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'dashboards.get_data.error': {
      return {
        ...state,
        getDashboardsData: {
          ...state.getDashboardsData,
          meta: {
            ...state.getDashboardsData.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'dashboards.reset_get_data': {
      return {
        ...state,
        getDashboardsData: {
          ...initialState.getDashboardsData,
        },
      }
    }
    default:
      return state
  }
}

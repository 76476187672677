import {
  ReportsAddToListAction,
  ReportsAddToListErrorAction,
  ReportsAddToListSuccessAction,
  ReportsDownloadFileAction,
  ReportsDownloadFileErrorAction,
  ReportsDownloadFileSuccessAction,
  ReportsGetListAction,
  ReportsGetListErrorAction,
  ReportsGetListSuccessAction,
  ReportsRemoveFromListAction,
  ReportsRemoveFromListErrorAction,
  ReportsRemoveFromListSuccessAction,
  ResetReportsAddToListAction,
  ResetReportsDownloadFileAction,
  ResetReportsGetListAction,
  ResetReportsListFilterAction,
  ResetReportsRemoveFromListAction,
  UpdateReportsListFilterAction,
} from './reports.actions'

// filter
export const updateListFilter = (
  payload: UpdateReportsListFilterAction['payload']
): UpdateReportsListFilterAction => ({
  type: 'reports.update_list_filter',
  payload,
})

export const resetListFilter = (): ResetReportsListFilterAction => ({
  type: 'reports.update_list_filter',
  payload: undefined,
})

// list
export const getList = (): ReportsGetListAction => ({
  type: 'reports.get_list',
  payload: undefined,
})

export const getListSuccess = (
  payload: ReportsGetListSuccessAction['payload']
): ReportsGetListSuccessAction => ({
  type: 'reports.get_list.success',
  payload,
})

export const getListError = (
  payload: ReportsGetListErrorAction['payload']
): ReportsGetListErrorAction => ({
  type: 'reports.get_list.error',
  payload,
})

export const resetList = (): ResetReportsGetListAction => ({
  type: 'reports.reset_get_list',
  payload: undefined,
})

// add to list
export const addToList = (
  payload: ReportsAddToListAction['payload']
): ReportsAddToListAction => ({
  type: 'reports.add_to_list',
  payload,
})

export const addToListSuccess = (
  payload: ReportsAddToListSuccessAction['payload']
): ReportsAddToListSuccessAction => ({
  type: 'reports.add_to_list.success',
  payload,
})

export const addToListError = (
  payload: ReportsAddToListErrorAction['payload']
): ReportsAddToListErrorAction => ({
  type: 'reports.add_to_list.error',
  payload,
})

export const resetToList = (): ResetReportsAddToListAction => ({
  type: 'reports.reset_add_to_list',
  payload: undefined,
})

// remove from list
export const removeFromList = (
  payload: ReportsRemoveFromListAction['payload']
): ReportsRemoveFromListAction => ({
  type: 'reports.remove_from_list',
  payload,
})

export const removeFromListSuccess = (
): ReportsRemoveFromListSuccessAction => ({
  type: 'reports.remove_from_list.success',
  payload: undefined,
})

export const removeFromListError = (
  payload: ReportsRemoveFromListErrorAction['payload']
): ReportsRemoveFromListErrorAction => ({
  type: 'reports.remove_from_list.error',
  payload,
})

export const resetRemoveFromList = (): ResetReportsRemoveFromListAction => ({
  type: 'reports.reset_remove_from_list',
  payload: undefined,
})

// download file
export const downloadFile = (
  payload: ReportsDownloadFileAction['payload']
): ReportsDownloadFileAction => ({
  type: 'reports.download_file',
  payload,
})

export const downloadFileSuccess = (
  payload: ReportsDownloadFileSuccessAction['payload']
): ReportsDownloadFileSuccessAction => ({
  type: 'reports.download_file.success',
  payload,
})

export const downloadFileError = (
  payload: ReportsDownloadFileErrorAction['payload']
): ReportsDownloadFileErrorAction => ({
  type: 'reports.download_file.error',
  payload,
})

export const resetDownloadFile = (): ResetReportsDownloadFileAction => ({
  type: 'reports.reset_download_file',
  payload: undefined,
})

import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { TUser } from 'api'
import { user } from 'modules'
import { editAccountFormValidationSchema } from 'pages/AccountPage/utils/validation'

import { IEditAccountFormFields } from '../../interfaces'

import styles from './styles.module.css'

interface EditAccountFormProps {
  defaultValues: {
    email: string;
    phone: string;
  };
}

export const EditAccountForm: FC<EditAccountFormProps> = ({
  defaultValues,
}) => {
  const dispatch = useDispatch()
  const {
    formState: { errors, isDirty, isValid },
    register,
    handleSubmit,
  } = useForm<IEditAccountFormFields>({
    defaultValues: defaultValues,
    resolver: yupResolver(editAccountFormValidationSchema),
    mode: 'all',
  })

  const userData: TUser = JSON.parse(localStorage.getItem('user')!)

  const submitFormHandler: SubmitHandler<IEditAccountFormFields> = (
    formValues
  ) => {
    const body = {
      email: formValues.email || '',
      phone: formValues.phone || '',
      emailVerified: true,
      fio: userData.fio
    }
    dispatch(user.actions.updateUserEmail(body))
    
    const {
      username,
      enabled,
      fio, 
      personalNumber, 
      emailVerified, 
      isNew, 
      work: { unp, name, unitName, position } 
    } = userData

    const dataForLocal = {
      username,
      enabled,
      fio,
      personalNumber,
      email: formValues.email,
      emailVerified,
      isNew,
      phone: formValues.phone,
      work: {
        unp,
        name,
        unitName,
        position
      }
    }
    localStorage.setItem('user', JSON.stringify(dataForLocal))
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(submitFormHandler)}>
      <div className={styles.form__field}>
        <label className={styles.form__label} htmlFor='phone'>
          Телефон
        </label>
        <input
          className={`${styles.form__input} ${errors.phone && styles.form__input_error
          }`}
          type='tel'
          id='phone'
          placeholder='+375 (__) ___-__-__'
          {...register('phone')}
        />
        {errors.phone && (
          <span className={styles.form__error}>{errors.phone.message}</span>
        )}
      </div>
      <div className={styles.form__field}>
        <label className={styles.form__label} htmlFor='email'>
          E-mail
        </label>
        <input
          className={`${styles.form__input} ${errors.email && styles.form__input_error
          }`}
          type='email'
          id='email'
          placeholder='Введите е-mail'
          {...register('email')}
        />
        {errors.email && (
          <span className={styles.form__error}>{errors.email.message}</span>
        )}
      </div>
      <button
        className={styles.form__submitBtn}
        type='submit'
        disabled={!isDirty || !isValid}
      >
        Cохранить
      </button>
    </form>
  )
}

import { apiBaseAgsr } from 'api/base'

import { SUBPATH } from './role.const'
import { GetUserNamesByRoleName } from './role.interface'

export const getUserNamesByRoleName = (
  userRole: GetUserNamesByRoleName.RoleNamePath
) =>
  apiBaseAgsr.get<GetUserNamesByRoleName.Data>(`${SUBPATH}/${userRole}/users`)


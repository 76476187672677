import {
  GetComparisonListAction,
  GetComparisonListErrorAction,
  GetComparisonListSuccessAction,
  ResetComparisonListFilterAction,
  ResetGetComparisonListAction,
  UpdateComparisonListFilterAction,
  AddToComparisonListAction,
  AddToComparisonListSuccessAction,
  AddToComparisonListErrorAction,
  ResetAddToComparisonListAction,
  RemoveFromComparisonListAction,
  RemoveFromComparisonListSuccessAction,
  RemoveFromComparisonListErrorAction,
  ResetRemoveFromComparisonListAction,
} from './comparison.action'

// filter
export const updateComparisonListFilter = (
  payload: UpdateComparisonListFilterAction['payload']
): UpdateComparisonListFilterAction => ({
  type: 'comparison.update_list_filter',
  payload,
})

export const resetComparisonListFilter =
  (): ResetComparisonListFilterAction => ({
    type: 'comparison.reset_list_filter',
    payload: undefined,
  })

// list
export const getComparisonList = (): GetComparisonListAction => ({
  type: 'comparison.get_list',
  payload: undefined,
})

export const getComparisonListSuccess = (
  payload: GetComparisonListSuccessAction['payload']
): GetComparisonListSuccessAction => ({
  type: 'comparison.get_list.success',
  payload,
})

export const getComparisonListError = (
  payload: GetComparisonListErrorAction['payload']
): GetComparisonListErrorAction => ({
  type: 'comparison.get_list.error',
  payload,
})

export const resetGetComparisonList = (): ResetGetComparisonListAction => ({
  type: 'comparison.reset_get_list',
  payload: undefined,
})

// add
export const addToComparisonList = (
  payload: AddToComparisonListAction['payload']
): AddToComparisonListAction => ({
  type: 'comparison.add_to_list',
  payload: payload,
})

export const addToComparisonListSuccess = (
  payload: AddToComparisonListSuccessAction['payload']
): AddToComparisonListSuccessAction => ({
  type: 'comparison.add_to_list.success',
  payload,
})

export const addToComparisonListError = (
  payload: AddToComparisonListErrorAction['payload']
): AddToComparisonListErrorAction => ({
  type: 'comparison.add_to_list.error',
  payload,
})

export const resetAddToComparisonList = (): ResetAddToComparisonListAction => ({
  type: 'comparison.reset_add_to_list',
  payload: undefined,
})

// remove
export const removeFromComparisonList = (
  payload: RemoveFromComparisonListAction['payload']
): RemoveFromComparisonListAction => ({
  type: 'comparison.remove_from_list',
  payload: payload,
})

export const removeFromComparisonListSuccess = (
): RemoveFromComparisonListSuccessAction => ({
  type: 'comparison.remove_from_list.success',
  payload: undefined,
})

export const removeFromComparisonListError = (
  payload: RemoveFromComparisonListErrorAction['payload']
): RemoveFromComparisonListErrorAction => ({
  type: 'comparison.remove_from_list.error',
  payload,
})

export const resetRemoveFromComparisonList =
  (): ResetRemoveFromComparisonListAction => ({
    type: 'comparison.reset_remove_from_list',
    payload: undefined,
  })

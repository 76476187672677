import * as actions from './statisticsDates.creator'
import { reducer } from './statisticsDates.reducer'
import { saga } from './statisticsDates.saga'
import * as selectors from './statisticsDates.selector'

export const dates = {
  ...selectors,
  actions,
  reducer,
  saga,
}

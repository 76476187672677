import * as Yup from 'yup'

import { emailRegExp, phoneRegExp } from 'regExp'

export const CreateFormSchema = Yup.object().shape({
  enabled: Yup.boolean().required('Обязательное поле'),
  fio: Yup.string().required('Введите ФИО'),
  email: Yup.string().matches(emailRegExp, 'Введите действительный адрес элeктронной почты'),
  phone: Yup.string().matches(phoneRegExp, 'Введите действительный номер телефона'),
  personalNumber: Yup.string().required('Введите идентификационный номер / логин '),
})

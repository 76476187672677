import { useNavigate } from 'react-router-dom'

import { ROUTE_PATH } from 'routes'

import styles from './styles.module.css'

export const NotFoundPage = () => {
  const navigate = useNavigate()

  const moveToPrevPageHandler = () => navigate(-1)

  const moveToHomePageHandler = () => navigate(ROUTE_PATH.home)

  return (
    <div className={styles.modal__container}>
      <div className={styles.modal}>
        <h2 className={styles.modal__status}>404</h2>
        <h2 className={styles.modal__headline}>Страница не найдена</h2>
        <h2 className={styles.modal__subheadline}>
          Страница, на которую вы пытаетесь попасть, не существует или была удалена.
        </h2>
        <div className={styles.modal__btnsWrapper}>
          <button className={styles.modal__btn} onClick={moveToPrevPageHandler}>
          Назад
          </button>
          <button className={styles.modal__btn} onClick={moveToHomePageHandler}>
          На главную страницу
          </button>
        </div>
      </div>
    </div>
  )
}

import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'

import {
  OpenOrganizationFilter,
  CloseOrganizationFilter,
  OpenOrganizationFilterModal,
  CloseOrganizationFilterModal,
  SetDataOrganizationFilter,
  GetList,
  GetListSuccess,
  GetListError,
  UpdateListFilter,
  ResetListFilter,
  ResetList,
  GetListSubsidiaries,
  GetListSubsidiariesSuccess,
  GetListSubsidiariesError,
  ResetListSubsidiaries,
  GetOrganizationFullInfo,
  GetOrganizationFullInfoSuccess,
  GetOrganizationFullInfoError,
  ResetOrganizationFullInfo,
  UpdateSubsidiariesListFilter,
  UpdateDetailedSearchFilterAction,
  ResetUpdateDetailedSearchFilterAction,
  DetailedSearchAction,
  DetailedSearchSuccessAction,
  DetailedSearchErrorAction,
  ResetDetailedSearchAction,
  ResetDataOrganizationFilter,
  GetDirectoriesAction,
  GetDirectoriesSuccessAction,
  GetDirectoriesErrorAction,
  ResetDirectoriesAction,
  GetFiltersAction,
  GetFiltersErrorAction,
  GetFiltersSuccessAction,
  ResetFiltersAction,
  Delete,
  DeleteError,
  DeleteSuccess,
  ResetDeletion,
  Create,
  CreateError,
  CreateSuccess,
  ResetCreation,
} from './organizations.action'

// get list
export const updateListFilter = (
  payload: UpdateListFilter['payload']
): UpdateListFilter => ({
  type: 'organization.update_list_filter',
  payload,
})

export const resetFilter = (): ResetListFilter => ({
  type: 'organization.reset_list_filter',
  payload: undefined,
})

export const getList = (): GetList => ({
  type: 'organization.get_list',
  payload: undefined,
})

export const getListSuccess = (
  payload: GetListSuccess['payload']
): GetListSuccess => ({
  type: 'organization.get_list.success',
  payload,
})

export const getListError = (
  payload: GetListError['payload']
): GetListError => ({
  type: 'organization.get_list.error',
  payload,
})

export const resetList = (): ResetList => ({
  type: 'organization.reset_list',
  payload: undefined,
})

// subsidiries
export const updateSubsidiariesListFilter = (
  payload: UpdateSubsidiariesListFilter['payload']
): UpdateSubsidiariesListFilter => ({
  type: 'organization.update_subsidiaries_list_filter',
  payload,
})

export const getListSubsidiaries = (
  payload: GetListSubsidiaries['payload']
): GetListSubsidiaries => ({
  type: 'organization.get_list_subsidiaries',
  payload,
})

export const getListSubsidiariesSuccess = (
  payload: GetListSubsidiariesSuccess['payload']
): GetListSubsidiariesSuccess => ({
  type: 'organization.get_list_subsidiaries.success',
  payload,
})

export const getListSubsidiariesError = (
  payload: GetListSubsidiariesError['payload']
): GetListSubsidiariesError => ({
  type: 'organization.get_list_subsidiaries.error',
  payload,
})

export const resetListSubsidiaries = (): ResetListSubsidiaries => ({
  type: 'organization.reset_list_subsidiaries',
  payload: undefined,
})

// full info
export const getOrganizationFullInfo = (
  payload: GetOrganizationFullInfo['payload']
): GetOrganizationFullInfo => ({
  type: 'organization.get_organization_info',
  payload,
})

export const getOrganizationFullInfoSuccess = (
  payload: GetOrganizationFullInfoSuccess['payload']
): GetOrganizationFullInfoSuccess => ({
  type: 'organization.get_organization_info.success',
  payload,
})

export const getOrganizationFullInfoError = (
  payload: GetOrganizationFullInfoError['payload']
): GetOrganizationFullInfoError => ({
  type: 'organization.get_organization_info.error',
  payload,
})

export const resetOrganizationFullInfo = (): ResetOrganizationFullInfo => ({
  type: 'organization.reset_organization_info',
  payload: undefined,
})

// detailed search
export const openFilter = (): OpenOrganizationFilter => ({
  type: 'organizations.open_filter',
  payload: undefined,
})

export const closeFilter = (): CloseOrganizationFilter => ({
  type: 'organizations.close_filter',
  payload: undefined,
})

export const openFilterModal = (): OpenOrganizationFilterModal => ({
  type: 'organizations.open_filter_modal',
  payload: undefined,
})

export const closeFilterModal = (): CloseOrganizationFilterModal => ({
  type: 'organizations.close_filter_modal',
  payload: undefined,
})

export const setDataFilterOrganizations = (
  data: FormListProps
): SetDataOrganizationFilter => ({
  type: 'organizations.set_data_filter',
  payload: data,
})
export const resetDataFilterOrganizations = (
): ResetDataOrganizationFilter => ({
  type: 'organizations.reset_data_filter',
  payload: undefined,
})

export const updateDetailedSearchFilter = (
  payload: UpdateDetailedSearchFilterAction['payload']
): UpdateDetailedSearchFilterAction => ({
  type: 'organization.update_detailed_search_filter',
  payload,
})

export const resetDetailedSearchFilter = (): ResetUpdateDetailedSearchFilterAction => ({
  type: 'organization.reset_detailed_search_filter',
  payload: undefined,
})

export const detailedSearch = (): DetailedSearchAction => ({
  type: 'organization.detailed_search',
  payload: undefined,
})

export const detailedSearchSuccess = (
  payload: DetailedSearchSuccessAction['payload']
): DetailedSearchSuccessAction => ({
  type: 'organization.detailed_search.success',
  payload,
})

export const detailedSearchError = (
  payload: DetailedSearchErrorAction['payload']
): DetailedSearchErrorAction => ({
  type: 'organization.detailed_search.error',
  payload,
})

export const resetDetailedSearch = (): ResetDetailedSearchAction => ({
  type: 'organization.reset_detailed_search',
  payload: undefined,
})

export const directories = (): GetDirectoriesAction => ({
  type: 'organization.get_directories',
  payload: undefined,
})

export const directoriesSuccess = (
  payload: GetDirectoriesSuccessAction['payload']
): GetDirectoriesSuccessAction => ({
  type: 'organization.get_directories.success',
  payload,
})

export const directoriesError = (
  payload: GetDirectoriesErrorAction['payload']
): GetDirectoriesErrorAction => ({
  type: 'organization.get_directories.error',
  payload,
})

export const resetDirectories = (): ResetDirectoriesAction => ({
  type: 'organization.reset_directories',
  payload: undefined,
})

export const filters = (payload: GetFiltersAction['payload']): GetFiltersAction => ({
  type: 'organization.get_filters',
  payload,
})

export const filtersSuccess = (
  payload: GetFiltersSuccessAction['payload']
): GetFiltersSuccessAction => ({
  type: 'organization.get_filters.success',
  payload,
})

export const filtersError = (
  payload: GetFiltersErrorAction['payload']
): GetFiltersErrorAction => ({
  type: 'organization.get_filters.error',
  payload,
})

export const resetFilters = (): ResetFiltersAction => ({
  type: 'organization.reset_filters',
  payload: undefined,
})

export const remove = (payload: Delete['payload']): Delete => ({
  type: 'organization.filter_delete',
  payload,
})

export const removeSuccess = (): DeleteSuccess => ({
  type: 'organization.filter_delete.success',
  payload: undefined,
})

export const removeError = (payload: DeleteError['payload']): DeleteError => ({
  type: 'organization.filter_delete.error',
  payload,
})

export const resetDeletion = (): ResetDeletion => ({
  type: 'organization.filter_reset_deletion',
  payload: undefined,
})


export const create = (payload: Create['payload']): Create => ({
  type: 'organization.filter_create',
  payload,
})

export const createSuccess = (payload: CreateSuccess['payload']): CreateSuccess => ({
  type: 'organization.filter_create.success',
  payload,
})

export const createError = (payload: CreateError['payload']): CreateError => ({
  type: 'organization.filter_create.error',
  payload,
})

export const resetCreation = (): ResetCreation=> ({
  type: 'organization.filter_reset_creation',
  payload: undefined,
})

import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/system'
import { useState, MouseEvent, useCallback, useRef } from 'react'

import { Reference } from 'api'
import { Loader } from 'components'
import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { CustomPagination } from 'components/CustomPagination/CustomPagination'
import { SelectRowQuantity } from 'components/SelectRowQuantity/SelectRowQuantity'
import { useClickOutside } from 'hooks/useClickOutside'

import { DeleteConfirmationModal } from './DeleteConfirmation'
import { EnhancedTableHead } from './EnhancedTableHead'
import { HeadData } from './HeadData'
import { ETALON_BREADCRUMBS } from './TableMasterList.const'
import styles from './TableMasterList.module.css'
import { DeleteConfirmationModalProps, TableListProps } from './TableMasterList.props'


export const TableMasterList = ({
  loading,
  rows,
  total,
  page,
  pageSize,
  sortDir,
  sortBy,
  fileError,
  onEdit,
  onPageChange,
  onPageSizeChane,
  onSort,
  onCreate,
  onDelete,
  onFileChange,
}: TableListProps) => {
  const [deletionId, setDeletionId] = useState<DeleteConfirmationModalProps['id']>()
  const [isOpenSubmenu, setOpenSubmenu] = useState<string | number | undefined>()
  const [rowQuantity, setRowQuantity] = useState<string | number>(20)
  
  const submenuRef = useRef(null)
 
  const handleOpenSubmenu = (id: string | number | undefined) => {
    isOpenSubmenu ? setOpenSubmenu(undefined) : setOpenSubmenu(id)
  }

  const handleEditRow = (row: Reference) => {
    onEdit(row)
    setOpenSubmenu(undefined)
  }

  const handleDeleteRow = (code: string | number | undefined) => {
    setDeletionId(code)
    setOpenSubmenu(undefined)
  }

  const clickOutsidehandler = () => {
    setOpenSubmenu(undefined)
  }

  useClickOutside(submenuRef, clickOutsidehandler)

  const handleDeletionAccept = useCallback<DeleteConfirmationModalProps['onAccept']>((code) => {
    onDelete(code)
    setDeletionId(undefined)
  }, [onDelete, setDeletionId])

  const handleDeletionCancel = useCallback<DeleteConfirmationModalProps['onCancel']>(() => {
    setDeletionId(undefined)
  }, [setDeletionId])

  const handleSort = (
    event: MouseEvent<unknown>,
    property: typeof sortBy,
  ) => {
    if (property) {
      const isAsc = sortBy === property && sortDir === 'ASC'

      onSort(isAsc ? 'DESC' : 'ASC', property)
    }
  }

  const handlePageChange = (event: unknown, newPage: number) => {
    onPageChange(newPage)
  }

  const StyledTableCell = styled(TableCell)({
    paddingTop: 4,
    paddingBottom: 4,
  })


  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setRowQuantity(event.target.value)
      onPageSizeChane(parseInt(event.target.value, 10))
    },
    [setRowQuantity, onPageSizeChane]
  )

  const totalPages = Math.ceil(total / pageSize)
  
  return (
    <Box>
      <CustomBreadcrumbs {...ETALON_BREADCRUMBS}/>
      <DeleteConfirmationModal
        id={deletionId}
        onAccept={handleDeletionAccept}
        onCancel={handleDeletionCancel}
      />
      <SelectRowQuantity 
        rowQuantity={rowQuantity}
        handleChange={handleChange}
        width={285}
      />
      <Paper className={loading ? styles.containerTableHeightLoading : styles.containerTable}>
        <TableContainer>
          <Table
          >
            <EnhancedTableHead
              order={sortDir}
              orderBy={sortBy}
              onRequestSort={handleSort}
            />
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  key={row.id}
                  style={{ height: 75 }}
                >
                  <StyledTableCell
                  >
                    {row.code}
                  </StyledTableCell>
                  <StyledTableCell align='left'>{row.fullName}</StyledTableCell>
                  <StyledTableCell align='left'>{row.unp}</StyledTableCell>
                  <StyledTableCell align='left'>{row.legalAddress}</StyledTableCell>
                  <StyledTableCell align='left'>
                    <div className={styles.containerSubmenu}>
                      <button onClick={() => handleOpenSubmenu(row.id)}>...</button>
                      {isOpenSubmenu === row.id ?
                        <div className={styles.submenu} ref={submenuRef}>
                          <IconButton onClick={() => handleEditRow(row)}>
                            <img src='/assets/icon-change.svg' className='pointer' alt='icon change'/> &nbsp;&nbsp;
                            Редактировать запись
                          </IconButton>

                          <IconButton onClick={() => handleDeleteRow(row.code)}>
                            <img src='/assets/icon-delete.svg' className='pointer' alt='icon delete'/>
                            Удалить запись
                          </IconButton>
                        </div>
                        : null}
                    </div>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && <Loader />}
      </Paper>
      <div className={styles.containerFooter}>
        <HeadData
          fileError={fileError}
          onFileChange={onFileChange}
          onCreate={onCreate}
        />
        {!!total && (
          <div className={styles.pagination}>
            <CustomPagination
              totalPages={totalPages}
              page={page}
              rowQuantity={rowQuantity}
              handlePageChange={handlePageChange}
              totalItems={total}
            />
          </div>
        )}
      </div>
    </Box>
  )
}

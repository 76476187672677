import * as actions from './role.creator'
import { reducer } from './role.reducer'
import { saga } from './role.saga'
import * as selectors from './role.selector'

export const role = {
  ...selectors,
  actions,
  reducer,
  saga,
}

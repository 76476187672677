import {
  useData,
  useMetaErrorMessage,
  useMetaErrorStatus,
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
} from 'modules/base'

import { BreadcrumbsSlice } from './breadcrumbs.schema'

export const useGetBreadcrumbs = ({ breadcrumbs }: BreadcrumbsSlice) =>
  useData(breadcrumbs.breadcrumbs)

export const useGetBreadcrumbsIdle = ({ breadcrumbs }: BreadcrumbsSlice) =>
  useMetaIdleStatus(breadcrumbs.breadcrumbs)

export const useGetBreadcrumbsLoading = ({ breadcrumbs }: BreadcrumbsSlice) =>
  useMetaLoadingStatus(breadcrumbs.breadcrumbs)

export const useGetBreadcrumbsSuccess = ({ breadcrumbs }: BreadcrumbsSlice) =>
  useMetaSuccessStatus(breadcrumbs.breadcrumbs)

export const useGetBreadcrumbsError = ({ breadcrumbs }: BreadcrumbsSlice) =>
  useMetaErrorStatus(breadcrumbs.breadcrumbs)

export const useGetBreadcrumbsErrorMessage = ({
  breadcrumbs,
}: BreadcrumbsSlice) => useMetaErrorMessage(breadcrumbs.breadcrumbs)

import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@mui/icons-material/Close'
import {
  Modal,
  IconButton,
} from '@mui/material'
import { useForm } from 'react-hook-form'

import { ErrorMessage, Loader } from 'components'

import styles from './ReferenceList.module.css'
import { ReferenceListProps } from './ReferenceList.props'
import { validationSchema } from './ReferenceList.schema'
import { FormListProps } from './ReferenceList.types'

export const ReferenceList = ({
  onSubmit,
  onClose,
  referenceListModalData,
  loading,
}: ReferenceListProps) => {
  const { register, handleSubmit, formState: { errors, isDirty } } = useForm<FormListProps>({
    defaultValues: referenceListModalData ?? {
      id: undefined,
      code: '',
      fullName: '',
      unp: ''
    },
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal className={styles.referenceListModal} onClose={onClose} open>
      <form className={styles.modalForm} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.iconButton}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.fieldsContainer}>
          <h3 className={styles.head}>{referenceListModalData?.code ? 'Редактирование записи' : 'Добавление новой записи'}</h3>
          <div className={styles.wrapperFields}>
            <div className={styles.field}>
              <p>Код</p>
              <input {...register('code')} placeholder='Введите код' />
              <ErrorMessage message={errors?.code?.message} />
            </div>
            <div className={styles.field}>
              <p>УНП</p>
              <input {...register('unp')} placeholder='Введите УНП' />
              <ErrorMessage message={errors?.unp?.message} />
            </div>
          </div>
          <div className={styles.field}>
            <p>Полное наименование</p>
            <input {...register('fullName')} placeholder='Введите наименование' />
            <ErrorMessage message={errors?.fullName?.message} />
          </div>
        </div>
        {referenceListModalData?.code ?
          <div className={styles.address}>
            <p>Юридический адрес</p>
            <p>{referenceListModalData.legalAddress}</p>
          </div> : null}
        <div className={styles.btns}>
          <button type='submit' disabled={!isDirty}>Сохранить</button>
          <button onClick={onClose}>Отмена</button>
        </div>
        {loading && <Loader />}
      </form>
    </Modal>
  )
}

import {
  GetUserAction,
  GetUserSuccessAction,
  GetUserErrorAction,
  ResetGetUserAction,
  UpdateUser,
  UpdateUserSuccess,
  UpdateUserError,
  ResetUserUpdation,
  CreateUserAction,
  CreateUserSuccessAction,
  CreateUserErrorAction,
  ResetCreateUserAction,
  AddRoleToUserAction,
  AddRoleToUserSuccessAction,
  AddRoleToUserErrorAction,
  ResetAddRoleToUserAction,
  BlockUserAction,
  BlockUserSuccessAction,
  BlockUserErrorAction,
  ResetBlockUserAction,
  ResetGetUsersByUsernameAction,
  GetUsersByUsernameErrorAction,
  GetUsersByUsernameSuccessAction,
  GetUsersByUsernameAction,
  GetUserRolesAction,
  GetUserRolesSuccessAction,
  GetUserRolesErrorAction,
  ResetGetUserRolesAction,
  UpdateRoleToUserAction,
  UpdateRoleToUserSuccessAction,
  UpdateRoleToUserErrorAction,
  ResetUpdateRoleToUserAction,
  UpdateUserEmailAction,
  UpdateUserEmailuccessAction,
  UpdateUserEmailErrorAction,
  ResetUpdateUserEmailAction,
  GetUsersCountAction,
  GetUsersCountSuccessAction,
  GetUsersCountErrorAction,
  ResetGetUsersCountEmailAction
} from './user.action'

export const getUser = (payload: GetUserAction['payload']): GetUserAction => ({
  type: 'user.get_user',
  payload,
})

export const getUserSuccess = (
  payload: GetUserSuccessAction['payload']
): GetUserSuccessAction => ({
  type: 'user.get_user.success',
  payload,
})

export const getUserError = (
  payload: GetUserErrorAction['payload']
): GetUserErrorAction => ({
  type: 'user.get_user.error',
  payload,
})

export const resetGetUser = (): ResetGetUserAction => ({
  type: 'user.reset_get_user',
  payload: undefined,
})

export const update = (payload: UpdateUser['payload']): UpdateUser => ({
  type: 'user.update',
  payload,
})

export const updateSuccess = (
  payload: UpdateUserSuccess['payload']
): UpdateUserSuccess => ({
  type: 'user.update.success',
  payload,
})

export const updateError = (
  payload: UpdateUserError['payload']
): UpdateUserError => ({
  type: 'user.update.error',
  payload,
})

export const resetUpdation = (): ResetUserUpdation => ({
  type: 'user.reset_updation',
  payload: undefined,
})

export const createUser = (
  payload: CreateUserAction['payload']
): CreateUserAction => ({
  type: 'user.create_user',
  payload,
})

export const createUserSuccess = (
  payload: CreateUserSuccessAction['payload']
): CreateUserSuccessAction => ({
  type: 'user.create_user.success',
  payload,
})

export const createUserError = (
  payload: CreateUserErrorAction['payload']
): CreateUserErrorAction => ({
  type: 'user.create_user.error',
  payload,
})

export const resetCreateUser = (): ResetCreateUserAction => ({
  type: 'user.reset_create_user',
  payload: undefined,
})

export const addRoleToUser = (
  payload: AddRoleToUserAction['payload']
): AddRoleToUserAction => ({
  type: 'user.add_role',
  payload,
})

export const addRoleToUserSuccess = (
  payload: AddRoleToUserSuccessAction['payload']
): AddRoleToUserSuccessAction => ({
  type: 'user.add_role.success',
  payload,
})

export const addRoleToUserError = (
  payload: AddRoleToUserErrorAction['payload']
): AddRoleToUserErrorAction => ({
  type: 'user.add_role.error',
  payload,
})

export const resetAddRoleToUser = (): ResetAddRoleToUserAction => ({
  type: 'user.reset_add_role',
  payload: undefined,
})

export const blockUser = (
  payload: BlockUserAction['payload']
): BlockUserAction => ({
  type: 'user.block_user',
  payload,
})

export const blockUserSuccess = (
  payload: BlockUserSuccessAction['payload']
): BlockUserSuccessAction => ({
  type: 'user.block_user.success',
  payload,
})

export const blockUserError = (
  payload: BlockUserErrorAction['payload']
): BlockUserErrorAction => ({
  type: 'user.block_user.error',
  payload,
})

export const blockUserReset = (): ResetBlockUserAction => ({
  type: 'user.reset_block_user',
  payload: undefined,
})

export const getUsersByUsername = (
  payload: GetUsersByUsernameAction['payload']
): GetUsersByUsernameAction => ({
  type: 'user.get_users_by_usernames',
  payload,
})

export const getUsersByUsernameSuccess = (
  payload: GetUsersByUsernameSuccessAction['payload']
): GetUsersByUsernameSuccessAction => ({
  type: 'user.get_users_by_usernames.success',
  payload,
})

export const getUsersByUsernameError = (
  payload: GetUsersByUsernameErrorAction['payload']
): GetUsersByUsernameErrorAction => ({
  type: 'user.get_users_by_usernames.error',
  payload,
})

export const getUsersByUsernameReset = (): ResetGetUsersByUsernameAction => ({
  type: 'user.reset_get_users_by_usernames',
  payload: undefined,
})

export const getUserRoles = (
  payload: GetUserRolesAction['payload']
): GetUserRolesAction => ({
  type: 'user.get_user_roles',
  payload,
})

export const getUserRolesSuccess = (
  payload: GetUserRolesSuccessAction['payload']
): GetUserRolesSuccessAction => ({
  type: 'user.get_user_roles.success',
  payload,
})

export const getUserRolesError = (
  payload: GetUserRolesErrorAction['payload']
): GetUserRolesErrorAction => ({
  type: 'user.get_user_roles.error',
  payload,
})

export const getUserRolesReset = (): ResetGetUserRolesAction => ({
  type: 'user.reset_get_user_roles',
  payload: undefined,
})

export const updateRoleToUser = (
  payload: UpdateRoleToUserAction['payload']
): UpdateRoleToUserAction => ({
  type: 'user.update_role',
  payload,
})

export const updateRoleToUserSuccess = (
  payload: UpdateRoleToUserSuccessAction['payload']
): UpdateRoleToUserSuccessAction => ({
  type: 'user.update_role.success',
  payload,
})

export const updateRoleToUserError = (
  payload: UpdateRoleToUserErrorAction['payload']
): UpdateRoleToUserErrorAction => ({
  type: 'user.update_role.error',
  payload,
})

export const resetUpdateRoleToUser = (): ResetUpdateRoleToUserAction => ({
  type: 'user.reset_update_role',
  payload: undefined,
})

export const updateUserEmail = (
  payload: UpdateUserEmailAction['payload']
): UpdateUserEmailAction => ({
  type: 'user.update_user_email',
  payload,
})

export const updateUserEmailSuccess = (
  payload: UpdateUserEmailuccessAction['payload']
): UpdateUserEmailuccessAction => ({
  type: 'user.update_user_email.success',
  payload,
})

export const updateUserEmailError = (
  payload: UpdateUserEmailErrorAction['payload']
): UpdateUserEmailErrorAction => ({
  type: 'user.update_user_email.error',
  payload,
})

export const resetUpdateUserEmail= (): ResetUpdateUserEmailAction => ({
  type: 'user.reset_update_user_email',
  payload: undefined,
})

// count
export const getCount = (): GetUsersCountAction => ({
  type: 'user.get_count',
  payload: undefined,
})

export const getCountSuccess = (
  payload: GetUsersCountSuccessAction['payload']
): GetUsersCountSuccessAction => ({
  type: 'user.get_count.success',
  payload,
})

export const getCountError = (
  payload: GetUsersCountErrorAction['payload']
): GetUsersCountErrorAction => ({
  type: 'user.get_count.error',
  payload,
})

export const resetGetCount= (): ResetGetUsersCountEmailAction => ({
  type: 'user.reset_get_count',
  payload: undefined,
})

import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { Notification } from 'components/Notification'
import { useNotification } from 'hooks'
import { audit } from 'modules'

import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from './Audit.const'
import { CustomTable } from './components/CustomTable/CustomTable'
import { TableListProps } from './components/CustomTable/CustomTable.props'
import { SearchAuditForm } from './components/SearchAuditForm/SearchAuditForm'
import { AuditFormProps } from './components/SearchAuditForm/SearchAuditForm.props'
import styles from './styles.module.css'


export const AuditPage = () => {
  const dispatch = useDispatch()
  const { openNotification, notificationData } = useNotification()

  const items = useSelector(audit.useListItems)
  const itemsLoading = useSelector(audit.useListLoading)
  const itemsTotal = useSelector(audit.useListItemsTotalCount)
  const itemsFilter = useSelector(audit.useListFilter)
  const itemsError = useSelector(audit.useListError)

  useEffect(() => {
    dispatch(audit.actions.updateListFilter({ page: PAGE_DEFAULT, size: PAGE_SIZE_DEFAULT, direction: 'DESC', sortField: 'id', executorFio: '', endDate: '', startDate: '' }))
    return () => {
      dispatch(audit.actions.resetFilter())
      dispatch(audit.actions.resetList())
    }
  }, [dispatch])

  // filter
  const handleTableFilterChange = useCallback<AuditFormProps['onFilter']>((filters) => {
    dispatch(audit.actions.updateListFilter({ page: PAGE_DEFAULT, ...filters }))
  }, [dispatch])

  // page
  const handleTablePageChange = useCallback<TableListProps['onPageChange']>((page) => {
    dispatch(audit.actions.updateListFilter({ page }))
  }, [dispatch])

  // page size
  const handleTablePageSizeChange = useCallback<TableListProps['onPageSizeChange']>((size) => {
    dispatch(audit.actions.updateListFilter({ page: PAGE_DEFAULT, size }))
  }, [dispatch])

  // notifications
  useEffect(() => {
    if (itemsError) {
      openNotification('По вашему запросу ничего не найдено.', 'error')
    }
  }, [
    dispatch,
    openNotification,
    itemsError
  ])

  return (
    <section className={styles.section}>
      <CustomBreadcrumbs
        currentPageName={'Просмотр и поиск в журнале аудита системы'}
        prevPages={[{ name: 'Кабинет администратора безопасности', url: '/security-administrator-space' }]}
      />
      <SearchAuditForm onFilter={handleTableFilterChange} />
      <CustomTable
        loading={itemsLoading}
        rows={items as unknown as TableListProps['rows']}
        total={itemsTotal}
        page={itemsFilter.page || PAGE_DEFAULT}
        pageSize={itemsFilter.size || PAGE_SIZE_DEFAULT}
        onPageChange={handleTablePageChange}
        onPageSizeChange={handleTablePageSizeChange}
      />
      <Notification {...notificationData} />
    </section>
  )
}

import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Toolbar,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'

import { Container } from 'components/Container'
import { Notification } from 'components/Notification'
import { PermissionContext } from 'context'
import { useNotification } from 'hooks/useNotification'
import { auth, dates } from 'modules'
import { metadataLoginCallbackRs } from 'pages'
import { ROUTE_PATH } from 'routes'
import { getFio } from 'utils'

import {
  NAV_LINKS,
  NAV_LINKS_AUTH_ADMIN,
  NAV_LINKS_NO_AUTH,
  NAV_LINKS_AUTH_ADMIN_SECURITY,
} from './Header.const'
import styles from './Header.module.css'

const isActiveStyle = {
  color: '#1E6EBE',
}

export const Header: React.FC = () => {
  const creationSucceed = useSelector(auth.useCreationSuccess)
  const { isAdmin, isUser, isAdminSecurity } = useContext(PermissionContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { notificationData, openNotification } = useNotification()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorRingEl, setAnchorRingEl] = useState<null | HTMLElement>(null)

  const spaceLocal = JSON.parse(localStorage.getItem('space')!)

  const [navLinks, setNavLinks] =
    useState<{ label: string; route: string }[]>()
  const [space, setSpace] = useState(
    spaceLocal ||
      (isUser && 'Кабинет пользователя') ||
      (isAdmin && 'Кабинет администратора') ||
      (isAdminSecurity && 'Кабинет администратора безопасности')
  )

  const dataDates = useSelector(dates.useGetDates)
  const datesSuccess = useSelector(dates.useGetDatesSuccess)
  const datesError = useSelector(dates.useGetDatesError)
  const datesErrorText = useSelector(dates.useGetDatesErrorMessage)
  const datesLoading = useSelector(dates.useGetDatesLoading)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuRingOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorRingEl(event.currentTarget)
  }

  useEffect(() => {
    if (anchorRingEl) {
      dispatch(dates.actions.getDates())
    }
  }, [dispatch, anchorRingEl])

  const handleCloseMenuRing = () => {
    setAnchorRingEl(null)
  }

  const { pathname } = useLocation()
  const [scroll, setScroll] = useState(0)

  const handleScroll = () => {
    setScroll(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const token = window.localStorage.getItem('access_token')

  const logout = async () => {
    if (!metadataLoginCallbackRs.access_token && !token) {
      openNotification('Нет access_token', 'error')
      return
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let logoutKey: Response | any
      try {
        logoutKey = await fetch(
          `${window.env.REACT_APP_AUTH_URL}/logout`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify({
              idToken: window.localStorage.getItem('idToken') || token,
              postLogoutRedirectUri: window.env.REACT_APP_LOGOUT_REDIRECT,
            }),
          }
        )
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('access_token')
        window.localStorage.removeItem('space')
        window.localStorage.removeItem('nav_links')
        window.localStorage.removeItem('attributes')
        dispatch(auth.actions.resetCreation())
        if (window.localStorage.getItem('idToken') !== 'undefined') {
          const logoutKeyRes: {
            logout_url: string;
          } = await logoutKey.json()
          if (logoutKeyRes?.logout_url) {
            window.location.replace(logoutKeyRes.logout_url)
          }
        } else {
          navigate('/')
        }
      } catch (response) {
        logoutKey = response
      }
    }
    setAnchorEl(null)
  }

  const storageKey = localStorage.getItem('user')
  const userObjKey = storageKey ? JSON.parse(storageKey) : null
  const isStorageDataKey = userObjKey?.fio

  useEffect(() => {
    if (!storageKey) {
      setNavLinks(NAV_LINKS_NO_AUTH)
      localStorage.setItem('nav_links', JSON.stringify(NAV_LINKS_NO_AUTH))
    }
    if (storageKey && !isUser && !isAdmin && !isAdminSecurity) {
      setNavLinks(NAV_LINKS_NO_AUTH)
      localStorage.setItem('nav_links', JSON.stringify(NAV_LINKS_NO_AUTH))
    }

    if (storageKey && (isUser || isAdmin || isAdminSecurity)) {
      if (space === 'Кабинет пользователя') {
        setNavLinks(NAV_LINKS)
        localStorage.setItem('nav_links', JSON.stringify(NAV_LINKS))
        localStorage.setItem('space', JSON.stringify('Кабинет пользователя'))
      }
      if (space === 'Кабинет администратора') {
        setNavLinks(NAV_LINKS_AUTH_ADMIN)
        localStorage.setItem('nav_links', JSON.stringify(NAV_LINKS_AUTH_ADMIN))
        localStorage.setItem('space', JSON.stringify('Кабинет администратора'))
      }
      if (space === 'Кабинет администратора безопасности') {
        setNavLinks(NAV_LINKS_AUTH_ADMIN_SECURITY)
        localStorage.setItem(
          'nav_links',
          JSON.stringify(NAV_LINKS_AUTH_ADMIN_SECURITY)
        )
        localStorage.setItem(
          'space',
          JSON.stringify('Кабинет администратора безопасности')
        )
      }
    }
  }, [
    storageKey,
    creationSucceed,
    space,
    setSpace,
    isUser,
    isAdmin,
    isAdminSecurity,
  ])

  const handleChangeSpace = (space: string, route: string) => {
    setSpace(space)
    localStorage.setItem('space', JSON.stringify(space))
    handleMenuClose()
    navigate(route)
  }

  let headerRole = ''

  if (isAdminSecurity) {
    headerRole = 'Администратор безопасности'
  } else if (isAdmin) {
    headerRole = 'Администратор'
  } else if (isUser) {
    headerRole = 'Пользователь'
  } else {
    headerRole = 'Гость'
  }

  return (
    <>
      <AppBar
        className={`${styles.header} ${
          pathname === ROUTE_PATH.home && scroll === 0 && styles.header_home
        }`}
      >
        <Container>
          <Toolbar className={styles.toolbar}>
            <div className={styles.linkFlex}>
              <Link className={styles.linkFlex} to='/'>
                <img src='/assets/Logo.svg' alt='Госактивы' />
              </Link>
              <div className={styles.userInfo}>
                <nav>
                  {navLinks !== NAV_LINKS_NO_AUTH
                    ? navLinks?.map(({ label, route }) => (
                      <NavLink
                        className={styles.link}
                        style={({ isActive }) =>
                          isActive ? isActiveStyle : {}
                        }
                        to={route}
                        key={label}
                      >
                        {label}
                      </NavLink>
                    ))
                    : navLinks?.map(({ label, route }) => (
                      <a className={styles.link} href={route} key={label}>
                        {label}
                      </a>
                    ))}
                </nav>
              </div>
            </div>
            <div className={styles.buttonLogout}>
              {creationSucceed || isStorageDataKey ? (
                <div className={styles.link}>
                  {isAdmin || isUser || isAdminSecurity ? (
                    <IconButton
                      edge='end'
                      aria-label='menu'
                      aria-controls='menu-appbar'
                      aria-haspopup='true'
                      onClick={handleMenuRingOpen}
                      color='inherit'
                      disableRipple={true}
                      className={styles.btnRing}
                    >
                      <img src='/assets/icon-ring.svg' alt='ring' />
                    </IconButton>
                  ) : null}

                  <Popover
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    anchorEl={anchorRingEl}
                    onClose={handleCloseMenuRing}
                    open={Boolean(anchorRingEl)}
                  >
                    <div className={styles.popperRing}>
                      {datesLoading && (
                        <p className={styles.loadingDates}>Загрузка...</p>
                      )}
                      {!datesError && datesSuccess ? (
                        <>
                          <div className={styles.headerPopperRing}>
                            <span>Источник данных</span>
                            <span>Дата обновления</span>
                          </div>
                          {dataDates.egrDate ? (
                            <div className={styles.itemPopperRing}>
                              <span>Обновление ЕГР</span>
                              <span>
                                {dataDates.egrDate
                                  .split('-')
                                  .reverse()
                                  .join('.')}
                              </span>
                            </div>
                          ) : null}
                          {dataDates.grpDate ? (
                            <div className={styles.itemPopperRing}>
                              <span>Обновление ГРП</span>
                              <span>
                                {dataDates.grpDate
                                  .split('-')
                                  .reverse()
                                  .join('.')}
                              </span>
                            </div>
                          ) : null}
                          {dataDates.asorDate ? (
                            <div className={styles.itemPopperRing}>
                              <span>Обновление АСОР</span>
                              <span>
                                {dataDates.asorDate
                                  .split('-')
                                  .reverse()
                                  .join('.')}
                              </span>
                            </div>
                          ) : null}
                          {dataDates.belstatDate ? (
                            <div className={styles.itemPopperRing}>
                              <span>Обновление Белстат</span>
                              <span>
                                {dataDates.belstatDate
                                  .split('-')
                                  .reverse()
                                  .join('.')}
                              </span>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <p>{datesErrorText}</p>
                      )}
                    </div>
                  </Popover>
                  <IconButton
                    edge='end'
                    aria-label='menu'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleMenuOpen}
                    color='inherit'
                    disableRipple={true}
                  >
                    <img src='/assets/Profile-circle.svg' alt='Выйти' />
                    <div className={styles.logout}>
                      <h4>
                        {isStorageDataKey ? getFio(isStorageDataKey) : ''}
                      </h4>
                      <p>{headerRole}</p>
                    </div>
                  </IconButton>
                  <Menu
                    sx={{ mt: '75px' }}
                    id='menu-appbar'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleMenuClose}>
                      <Link className={styles.linkMenu} to={ROUTE_PATH.account}>
                        <img src='/assets/Profile.svg' alt='Профиль' />
                        Профиль пользователя
                      </Link>
                    </MenuItem>
                    {isUser && spaceLocal !== 'Кабинет пользователя' ? (
                      <MenuItem
                        onClick={() =>
                          handleChangeSpace(
                            'Кабинет пользователя',
                            ROUTE_PATH.userSpace
                          )
                        }
                      >
                        <img src='/assets/svg/user-space.svg' alt='Профиль' />
                        Кабинет пользователя
                      </MenuItem>
                    ) : null}
                    {isAdmin && spaceLocal !== 'Кабинет администратора' ? (
                      <MenuItem
                        onClick={() =>
                          handleChangeSpace('Кабинет администратора', '/admin')
                        }
                      >
                        <img src='/assets/svg/admin-space.svg' alt='Профиль' />
                        Кабинет администратора
                      </MenuItem>
                    ) : null}
                    {isAdminSecurity &&
                    spaceLocal !== 'Кабинет администратора безопасности' ? (
                        <MenuItem
                          onClick={() =>
                            handleChangeSpace(
                              'Кабинет администратора безопасности',
                              ROUTE_PATH.securityAdminSpace
                            )
                          }
                        >
                          <img
                            src='/assets/svg/admin-security-space.svg'
                            alt='Профиль'
                          />
                        Кабинет администратора безопасности
                        </MenuItem>
                      ) : null}
                    <MenuItem
                      onClick={logout}
                      sx={{ '&:hover': { backgroundColor: '#F5F9FA' } }}
                    >
                      <img src='/assets/Logout.svg' alt='Выход' />
                      Выйти
                    </MenuItem>
                  </Menu>
                </div>
              ) : (
                <Link className={styles.link} to='/auth'>
                  <img src='/assets/Login.svg' alt='Вход' />
                  <h4 className={styles.login}>Войти</h4>
                </Link>
              )}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <Notification {...notificationData} />
    </>
  )
}

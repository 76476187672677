import { BaseAction } from './base.action'

export namespace Base {
  export type Slice<Key extends string, Schema> = Record<Key, Schema>

  export type AsyncStatus = 'idle' | 'loading' | 'success' | 'error'

  export type AsyncError = string

  export type AsyncMeta = {
    status: AsyncStatus
    error: AsyncError
  }

  export type AsyncField<Data = undefined> = {
    meta: AsyncMeta
    data: Data
  }

  export type Action<Type extends string, Payload = undefined> = BaseAction<Type, Payload>
}

import { Checkbox, TableCell, TableRow } from '@mui/material'
import { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { DYNAMIC_COLUMN } from 'common/const'
import { TDynamicColumn } from 'common/types'
import { TableMapCell } from 'components/TableMapCell'
import { useClickOutside } from 'hooks/useClickOutside'
import { ReportsTableRowProps } from 'pages/ComparisonPage/ComparisonPage.props'
import { ROUTE_PATH } from 'routes'

import styles from '../../styles.module.css'

export const ComparisonTableRow = ({
  data,
  isSelected,
  selectRow,
}: ReportsTableRowProps) => {
  const modalRowRef = useRef<HTMLDivElement>(null)

  const [modalIsOpened, setModalIsOpened] = useState<boolean>(false)

  const openModalHandler = () => setModalIsOpened(true)

  const clickOutsidehandler = () => setModalIsOpened(false)

  useClickOutside(modalRowRef, clickOutsidehandler)

  const columns = localStorage.getItem('reports-columns')

  const visibleColumns: TDynamicColumn[] = (
    columns ? JSON.parse(columns) : DYNAMIC_COLUMN
  ).filter((column: TDynamicColumn) => column.visible)

  return (
    <TableRow className={styles.row_children}>
      <TableCell>
        <Checkbox
          checked={isSelected}
          onClick={(event) => selectRow(event, data.unp)}
        />
      </TableCell>
      {visibleColumns.map((visibleColumn) => {
        switch (visibleColumn.id) {
          case 'shortName': {
            return (
              <TableCell>
                <div className={styles.cell__wrapper_horizontal}>
                  <div className={styles.cell__wrapper}>
                    <span className={styles.table__cellText_body}>
                      {data[visibleColumn.id]}
                    </span>
                    <span className={styles.link} onClick={openModalHandler}>
                      Подробнее
                      {modalIsOpened && (
                        <div
                          className={styles.containerModalRow}
                          ref={modalRowRef}
                        >
                          <NavLink
                            to={`${ROUTE_PATH.userSpace}/${data.unp}/info`}
                          >
                            Открыть информацию
                          </NavLink>
                          <NavLink
                            to={`${ROUTE_PATH.userSpace}/${data.unp}/graph`}
                          >
                            Открыть граф
                          </NavLink>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </TableCell>
            )
          }
          case 'registrationAndCurrentAccountingGrpRegDate': {
            return (
              <TableCell key={visibleColumn.id}>
                {data.registrationAndCurrentAccountingGrpRegDate?.split('-').reverse().join('.')}
              </TableCell>
            )
          }
          case 'registrationAndCurrentAccountingEgrRegDate': {
            return (
              <TableCell key={visibleColumn.id}>
                {data.registrationAndCurrentAccountingEgrRegDate?.split('-').reverse().join('.')}
              </TableCell>
            )
          }
          case 'activityTypeType': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.activityTypeInfo}
                fieldName='activityTypeType'
              />
            )
          }
          case 'founderUnp': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.foundersInfo}
                fieldName='founderUnp'
              />
            )
          }
          case 'founderName': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.foundersInfo}
                fieldName='founderName'
              />
            )
          }
          case 'regNumSubjectFounder': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.subsidiariesInfo}
                fieldName='regNumSubjectFounder'
              />
            )
          }
          case 'subsidiaryName': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.subsidiariesInfo}
                fieldName='subsidiaryName'
              />
            )
          }
          case 'managerName': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.managersInfo}
                fieldName='managerName'
              />
            )
          }
          case 'managerUnp': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.managersInfo}
                fieldName='managerUnp'
              />
            )
          }
          case 'branchName': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.branchesInfo}
                fieldName='branchName'
              />
            )
          }
          case 'branchUnp': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.branchesInfo}
                fieldName='branchUnp'
              />
            )
          }
          case 'stockShareIdentificationCode': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.asorData}
                fieldName='stockShareIdentificationCode'
              />
            )
          }
          case 'stockOwnerUnp': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.asorData}
                fieldName='stockOwnerUnp'
              />
            )
          }
          case 'stockOwnerFullName': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.asorData}
                fieldName='stockOwnerFullName'
              />
            )
          }
          case 'stockOwnerType': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.asorData}
                fieldName='stockOwnerType'
              />
            )
          }
          case 'stockOwnerAddress': {
            return (
              <TableMapCell
                key={visibleColumn.id}
                array={data.asorData}
                fieldName='stockOwnerAddress'
              />
            )
          }
          default: {
            return (
              <TableCell key={visibleColumn.id}>
                {data[visibleColumn.id]}
              </TableCell>
            )
          }
        }
      })}
    </TableRow>
  )
}

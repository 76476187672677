import { useCallback } from 'react'

import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'

import { AIS_SETTINGS_BREADCRUMBS } from './AisSettingsPage.const'
import { AisSettingFormProps } from './AisSettingsPage.props'
import { AisSettingsForm } from './components/AisSettingsForm'

export const AisSettingsPage = () => {
  const submitFormHandler = useCallback<
    AisSettingFormProps['onSubmit']
  >(() => {}, [])
  
  return (
    <>
      <CustomBreadcrumbs {...AIS_SETTINGS_BREADCRUMBS} />
      <AisSettingsForm onSubmit={submitFormHandler} />
    </>
  )
}

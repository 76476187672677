import * as actions from './audit.creator'
import { reducer } from './audit.reducer'
import { saga } from './audit.saga'
import * as selectors from './audit.selector'

export const audit = {
  ...selectors,
  actions,
  reducer,
  saga,
}

import { getInitialAsyncState } from 'modules/base'

import { DatesSchema } from './statisticsDates.schema'

export const initialState: DatesSchema = {
  date: getInitialAsyncState({
    egrDate: null,
    grpDate: null,
    asorDate: null,
    belstatDate: null
  }),
}

import CloseIcon from '@mui/icons-material/Close'
import {
  Modal,
  IconButton,
  FormControl,
  FormGroup,

} from '@mui/material'
import { useCallback, useState } from 'react'

import { COLUMNS_MODAL_CONTENT, COLUMNS_MODAL_SECTIONS } from 'common/const'
import { FieldsModalSection } from 'components/FieldsModalSection/FieldsModalSection'
import { FieldsModalSectionProps } from 'components/FieldsModalSection/FieldsModalSection.props'

import { ReportsTableColumnsModalProps } from '../../ComparisonPage.props'

import styles from './styles.module.css'

export const FieldModal = ({
  storageName,
  data,
  onClose,
}: ReportsTableColumnsModalProps) => {
  const [columns, setColumns] = useState(data)

  const selectAllInSectionHandler = useCallback<
  FieldsModalSectionProps['selectAll']
>(
  (event) => {
    const { id, checked: visible } = event.target

    let keys: string[] = []

    COLUMNS_MODAL_SECTIONS.forEach((curr) => {
      if (curr.title === id) {
        keys = curr.keys
      }
    })

    const nextColumns = columns.map((column) => {
      return keys.find((key) => key === column.id)
        ? { ...column, visible }
        : column
    })

    setColumns(nextColumns)
  },
  [columns]
)

  const toggleTargetHandler = useCallback<
  FieldsModalSectionProps['selectTarget']
>(
  (event) => {
    const { id, checked: visible } = event.target

    const nextColumns = columns.map((column) =>
      column.id === id ? { ...column, visible } : column
    )

    setColumns(nextColumns)
  },
  [columns]
)

  const selectAllHandler = () => {
    const nextColumns = columns.map((column) => ({ ...column, visible: true }))

    setColumns(nextColumns)
  }

  const resetAllHandler = () => {
    const nextColumns = columns.map((column) =>
      column.id === 'shortName' ? column : { ...column, visible: false }
    )

    setColumns(nextColumns)
  }

  const handleSave = () => {
    localStorage.setItem(storageName, JSON.stringify(columns))
    onClose()
  }

  return (
    <Modal className={styles.organizationsFieldsModal} open onClose={onClose}>
      <FormControl
        className={styles.form}
        component='fieldset'
        variant='standard'
      >
        <div className={styles.form__header}>
          <h2 className={styles.form__headerTitle}>{COLUMNS_MODAL_CONTENT.TITLE}</h2>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <FormGroup className={styles.form__main}>
          <div className={styles.form__sectionsContainer}>
            {COLUMNS_MODAL_SECTIONS.map((section) => (
              <FieldsModalSection
                key={section.title}
                columns={columns}
                section={section}
                selectAll={selectAllInSectionHandler}
                selectTarget={toggleTargetHandler}
              />))}        
          </div>
        </FormGroup>
        <div className={styles.form__footer}>
          <button className={styles.form__saveBtn} onClick={handleSave}>
            {COLUMNS_MODAL_CONTENT.SAVE}
          </button>
          <div className={styles.form__footerBtnsWr}>
            <button className={styles.form__saveBtn} onClick={selectAllHandler}>
              {COLUMNS_MODAL_CONTENT.SELECT_ALL}
            </button>
            <button className={styles.form__saveBtn} onClick={resetAllHandler}>
              {COLUMNS_MODAL_CONTENT.RESET_ALL}
            </button>
          </div>
        </div>
      </FormControl>
    </Modal>
  )
}

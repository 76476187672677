import * as actions from './manageAccess.creator'
import { reducer } from './manageAccess.reducer'
import { saga } from './manageAccess.saga'
import * as selectors from './manageAccess.selector'

export const manageAccess = {
  ...selectors,
  actions,
  reducer,
  saga,
}
import { AxiosError } from 'axios'
import { spawn, call, put, takeEvery } from 'redux-saga/effects'

import { Base, GetGraph, graph } from 'api'

import { GetGraphAction } from './graph.action'
import * as creator from './graph.creator'

function* getGraphWorker(action:GetGraphAction) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: GetGraph.Response = yield call(graph.getGraph, action.payload)

    if (!errorCode && data) {
      yield put(creator.getGraphSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getGraphError(errorMessage))
    }
  }
}

function* getGraphWatcher() {
  yield takeEvery<GetGraphAction>(
    'graph.get_graph' as GetGraphAction['type'],
    getGraphWorker
  )
}

export function* saga() {
  yield spawn(getGraphWatcher)
}

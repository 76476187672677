import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { FileType } from 'api'
import { downloadFile } from 'api/reports/reports.api'
import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { Notification } from 'components/Notification'
import { PermissionContext } from 'context'
import { useNotification } from 'hooks'
import { breadcrumbs, comparison, organizations, reports } from 'modules'

import { ExcludeEGR } from './components/ExcludeEGR'
import { Fond } from './components/Fond'
import { FormOwners } from './components/FormOwners'
import { General } from './components/General'
import { Leaders } from './components/Leaders'
import { Owners } from './components/Owners'
import { OwnersBig } from './components/OwnersBig'
import { Parts } from './components/Parts'
import { Predecessors } from './components/Predecessors'
import { Registration } from './components/Registration'
import { Subsidiaries } from './components/Subsidiaries'
import { SubsidiariesBig } from './components/SubsidiariesBig'
import { Successors } from './components/Successors'
import style from './InfoFormOrganization.module.css'

export const InfoFormOrganization = () => {
  const { openNotification, notificationData } = useNotification()
  const dispatch = useDispatch()
  const {
    permissionRepGeneration,
    permissionRepSaving,
    permissionRepUploading,
  } = useContext(PermissionContext)
  const { unp } = useParams()

  const { id: userId, fio: executorFio } = JSON.parse(
    localStorage.getItem('user')!
  )

  const [searchParams] = useSearchParams()
  const chain = searchParams.get('chain')

  const currentBreadcrumbs = useSelector(breadcrumbs.useGetBreadcrumbs)
  const infoOrganization = useSelector(organizations.useGetOrganizationInfo)

  const addToComparisonSuccess = useSelector(comparison.useAddToListSuccess)
  const addToComparisonError = useSelector(comparison.useAddToListError)
  const addToComparisonErrorMessage = useSelector(comparison.useAddToListErrorMessage)

  const saveToReportsSuccess = useSelector(reports.useAddToListSuccess)
  const saveToReportsError = useSelector(comparison.useAddToListError)

  useEffect(() => {
    if (unp) {
      dispatch(
        breadcrumbs.actions.getBreadcrumbs({
          unp,
          chain: chain || undefined,
        })
      )
    }

    return () => {
      dispatch(breadcrumbs.actions.resetGetBreadcrumbs())
    }
  }, [unp, chain, dispatch])

  useEffect(() => {
    if (unp) {
      dispatch(organizations.actions.getOrganizationFullInfo({ unp: unp }))
    }
  }, [unp, dispatch])

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const navigate = useNavigate()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  const moveToGraphPage = () =>
    navigate(`/user-space/${unp}/graph${chain ? `?chain=${chain}` : ''}`)

  const modeLocal = localStorage.getItem('modeInfoOrganization')

  useEffect(() => {
    if (!modeLocal) {
      localStorage.setItem('modeInfoOrganization', 'short')
    }
  }, [modeLocal])

  const [mode, setMode] = useState<'short' | 'full' | string>(modeLocal || 'short')

  const toggleModHandler = () => {
    setMode((curr) => (curr === 'short' ? 'full' : 'short'))

  }

  useEffect(() => {
    localStorage.setItem('modeInfoOrganization', mode)
  }, [mode])

  // download report
  const handleTableDownloadFile = (type: FileType) => {
    downloadFile({
      unp: [unp!],
      type,
      template: mode === 'short' ? 'report-short' : 'report-detailed',
    }).then((response) => {
      const headerContent = response.headers['content-disposition']
      const filename = decodeURI(headerContent.slice(headerContent.lastIndexOf('\'') + 1))
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })

    handleToggle()
  }

  // add to comparison list
  const addToComparisonHandler = () => {
    dispatch(
      comparison.actions.addToComparisonList({
        userId: userId,
        unps: [unp as string],
      })
    )
  }

  // save report
  const saveToReportsHandler = () => {
    dispatch(
      reports.actions.addToList({
        executorFio,
        reportType: mode === 'short' ? 'report-short' : 'report-detailed',
        unps: [unp as string],
      })
    )
  }

  useEffect(() => {
    if (addToComparisonSuccess) {
      openNotification(
        'Организации добавлены на страницу Избранное',
        'success'
      )

      return () => {
        dispatch(comparison.actions.resetAddToComparisonList())
      }
    }

    if (addToComparisonError) {
      openNotification(
        addToComparisonErrorMessage,
        'error'
      )

      return () => {
        dispatch(comparison.actions.resetAddToComparisonList())
      }
    }

    if (saveToReportsSuccess) {
      openNotification(
        'Отчет добавлен на страницу Сохраненные отчеты',
        'success'
      )

      return () => {
        dispatch(reports.actions.resetToList())
      }
    }

    if (saveToReportsError) {
      openNotification(
        'Не удалось добавить отчет на страницу Сохраненные отчеты',
        'error'
      )

      return () => {
        dispatch(reports.actions.resetToList())
      }
    }
  }, [
    dispatch,
    openNotification,
    addToComparisonSuccess,
    addToComparisonError,
    addToComparisonErrorMessage,
    saveToReportsSuccess,
    saveToReportsError,
  ])

  return (
    <>
      <div className={style.containerShortForm}>
        <div className={style.containerRoutes}>
          {currentBreadcrumbs && (
            <CustomBreadcrumbs
              prevPages={currentBreadcrumbs.prevPages}
              currentPageName={`${currentBreadcrumbs.currentPageName} (просмотр информации)`}
            />
          )}
        </div>
        <div className={style.containerHeader}>
          <div className={style.name}>
            <span>{infoOrganization?.fullName}</span>
          </div>
          <div className={style.btns}>
            <Button
              variant='contained'
              disabled={!permissionRepGeneration}
              onClick={addToComparisonHandler}
            >
              Добавить в избранное
            </Button>
            <Button variant='contained' onClick={moveToGraphPage}>
              Граф организации
            </Button>
            <Button
              ref={anchorRef}
              id='composition-button'
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup='true'
              onClick={handleToggle}
              variant='contained'
              endIcon={
                !open ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />
              }
              style={{ width: 223 }}
              disabled={!permissionRepSaving}
            >
              Выгрузить информацию
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement='bottom-end'
              transition
              disablePortal
              style={{ width: 222 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id='composition-menu'
                        aria-labelledby='composition-button'
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          disabled={!permissionRepUploading}
                          onClick={() => handleTableDownloadFile('pdf')}
                        >
                          Выгрузить в PDF
                        </MenuItem>
                        <MenuItem
                          disabled={!permissionRepUploading}
                          onClick={() => handleTableDownloadFile('xlsx')}
                        >
                          Выгрузить в XLS
                        </MenuItem>
                        <MenuItem
                          disabled={!permissionRepUploading}
                          onClick={() => handleTableDownloadFile('docx')}
                        >
                          Выгрузить в DOC
                        </MenuItem>
                        <MenuItem
                          disabled={!permissionRepSaving}
                          onClick={saveToReportsHandler}
                        >
                          Сохранить отчет
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
        <button className={style.typeBtn} type='button' onClick={toggleModHandler}>
          {mode === 'full' ? 'Открыть краткую' : 'Развернуть  подробную'}{' '}
          форму
        </button>
        <div className={style.containerComponents}>
          {mode === 'short' && infoOrganization ? (
            <>
              <div>
                {infoOrganization.fullName ||
                  infoOrganization.shortName ||
                  infoOrganization.unp ||
                  infoOrganization.organizationBaseInfoBelstatCode ||
                  infoOrganization.egrCurrentState ||
                  infoOrganization.grpCurrentState ||
                  infoOrganization.activityTypeCode.length ||
                  infoOrganization.phoneNumbers ||
                  infoOrganization.fax ||
                  infoOrganization.email ?
                  <General data={infoOrganization} mode={mode} />
                  : null}

                {infoOrganization.foundersInfo.length ? (
                  <Owners data={infoOrganization} />
                ) : null}

                {infoOrganization.subsidiariesInfo.length ? (
                  <Subsidiaries data={infoOrganization} />
                ) : null}

              </div>
              <div>
                {infoOrganization.registrationAndCurrentAccountingEgrRegDate ||
                  infoOrganization.decisionNumber ||
                  infoOrganization.regAuthorityName ||
                  infoOrganization.registrationAndCurrentAccountingGrpRegDate ||
                  infoOrganization.egrAccountingAuthorityName ||
                  infoOrganization.imnsName ?
                  <Registration data={infoOrganization} />
                  : null}

                {infoOrganization.statFundType ||
                  infoOrganization.statFundSizeTotal ||
                  infoOrganization.statePortionTotal ||
                  infoOrganization.foreignPortionInvestmentsPercent ||
                  infoOrganization.belPortionInvestmentsPercent ?
                  <Fond data={infoOrganization} mode={mode} />
                  : null}

                {infoOrganization.branchesInfo.length ? (
                  <Parts data={infoOrganization} mode={mode} />
                ) : null}

                {infoOrganization.managerName.length || infoOrganization.managerUnp.length ? <Leaders data={infoOrganization} /> : null}
              </div>
            </>
          ) : null}


          {mode === 'full' && infoOrganization ? (
            <>
              <div>
                {infoOrganization.fullName ||
                  infoOrganization.shortName ||
                  infoOrganization.unp ||
                  infoOrganization.organizationBaseInfoBelstatCode ||
                  infoOrganization.egrCurrentState ||
                  infoOrganization.grpCurrentState ||
                  infoOrganization.grpStateChangeDate ||
                  infoOrganization.activityTypeCode.length ||
                  infoOrganization.phoneNumbers ||
                  infoOrganization.fax ||
                  infoOrganization.email ?
                  <General data={infoOrganization} mode={mode} />
                  : null}

                {infoOrganization.ownershipTypesInfoOwnershipType ||
                  infoOrganization.ownershipTypeFacet ||
                  infoOrganization.okoguName ||
                  infoOrganization.okoguFacet ?
                  <FormOwners data={infoOrganization} />
                  : null}

                {infoOrganization.managerName.length || infoOrganization.managerUnp.length ? <Leaders data={infoOrganization} /> : null}

                {infoOrganization.successorsInfo.length ?
                  <Successors data={infoOrganization} />
                  : null}

                {infoOrganization.predecessorsInfo.length ? (
                  <Predecessors data={infoOrganization} />
                ) : null}

                {infoOrganization.branchesInfo.length ? (
                  <Parts data={infoOrganization} mode={mode} />
                ) : null}

                {infoOrganization.subsidiariesInfo.length ? (
                  <SubsidiariesBig data={infoOrganization} />
                ) : null}

              </div>
              <div>

                {infoOrganization.registrationAndCurrentAccountingEgrRegDate ||
                  infoOrganization.decisionNumber ||
                  infoOrganization.regAuthorityName ||
                  infoOrganization.registrationAndCurrentAccountingGrpRegDate ||
                  infoOrganization.egrAccountingAuthorityName ||
                  infoOrganization.imnsName ?
                  <Registration data={infoOrganization} />
                  : null}

                {infoOrganization.statFundType ||
                  infoOrganization.sumTypeName ||
                  infoOrganization.statFundSizeTotal ||
                  infoOrganization.statFundSizeEstate ||
                  infoOrganization.statFundSizeMoney ||
                  infoOrganization.statePortionTotal ||
                  infoOrganization.statePortionEstate ||
                  infoOrganization.statePortionMoney ||
                  infoOrganization.foreignPortionInvestments ||
                  infoOrganization.foreignPortionInvestmentsPercent ||
                  infoOrganization.belPortionInvestments ||
                  infoOrganization.belPortionInvestmentsPercent ||
                  infoOrganization.stockAmount ||
                  infoOrganization.stockPrice ? <Fond data={infoOrganization} mode={mode} /> : null}

                {infoOrganization.excludeStartDate ||
                  infoOrganization.excludeAuthorityName ||
                  infoOrganization.exclusionDecisionNumber ||
                  infoOrganization.liquidationReasonName ?
                  <ExcludeEGR data={infoOrganization} />
                  : null}

                {infoOrganization.foundersInfo.length ? (
                  <OwnersBig data={infoOrganization} />
                ) : null}

              </div>
            </>
          ) : null}
        </div>
      </div>
      <Notification {...notificationData} />
    </>
  )
}

import {
  Table,
  TableBody,
} from '@mui/material'

import { Loader } from 'components'
import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { CustomPagination } from 'components/CustomPagination/CustomPagination'
import { prevPageUrl } from 'views/admin/Admin.const'

import { CustomEditModal } from '../CustomEditModal'

import { ManageRoleTableProps } from './ManageRoleTable.props'
import { ManageRoleTableHead } from './ManageRoleTableHead'
import { ManageRoleTableRow } from './ManageRoleTableRow'
import styles from './styles.module.css'

export const ManageRoleTable = ({
  loading,
  records,
  page,
  pageSize,
  total,
  onPageChange,
  createModalIsOpened,
  toggleCreateModalIsOpened,
}: ManageRoleTableProps) => {
  // change page
  const handlePageChange = (event: unknown, newPage: number) => {
    onPageChange(newPage)
  }

  const totalPages = Math.ceil(total / pageSize)

  return (
    <>
      <CustomBreadcrumbs
        currentPageName={'Управление ролями'}
        prevPages={[prevPageUrl]} 
      />
      <div className={styles.containerTable}>
        <Table>
          <ManageRoleTableHead
          />
          <TableBody>
            {records.map((record) => (
              <ManageRoleTableRow record={record} key={record.id} />
            ))}
          </TableBody>
        </Table>
      </div>
      {loading && <Loader />}
      <div className={styles.containerFooter}>
        <button className={styles.createBtn} onClick={toggleCreateModalIsOpened}>
        Создать роль
        </button>
        <div className={styles.pagination}>
          <CustomPagination
            totalPages={totalPages}
            page={page}
            rowQuantity={10}
            handlePageChange={handlePageChange}
            totalItems={total}
          />
        </div>
      </div>
      <CustomEditModal
        mode='create'
        modalIsOpened={createModalIsOpened}
        closeModal={toggleCreateModalIsOpened}
      />
    </>
  )
}

import { useNavigate, useParams } from 'react-router-dom'

import { ROUTE_PATH } from 'routes'

export const useTableNavigation = () => {
  const navigate = useNavigate()
  const { unp } = useParams()

  const moveToPrevOrganizationHandler = () => {
    const chain = localStorage.getItem('chain') as string
    let newChain = ''
    let unpX = ''

    if (!chain || chain === '') {
      newChain = ''
      unpX = ''
    } else if (chain && !chain.includes('-')) {
      newChain = ''
      unpX = chain
    } else {
      newChain = chain.slice(0, chain.lastIndexOf('-'))
      unpX = chain.slice(chain.lastIndexOf('-') + 1)
    }

    navigate(
      `${ROUTE_PATH.userSpace}/${unpX}${
        newChain.length > 0 ? `?chain=${newChain}` : ''
      }`
    )

    if (newChain === '') {
      localStorage.removeItem('chain')
    } else {
      localStorage.setItem('chain', newChain)
    }
  }

  const moveToNextOrganizationHandler = (newUnp: string) => {
    const chain = localStorage.getItem('chain')
    let newChain = ''

    if (chain && unp) {
      newChain = `${chain}-${unp}`
    } else if (!chain && unp) {
      newChain = `${unp}`
    }

    navigate(
      `${ROUTE_PATH.userSpace}/${newUnp}${
        newChain.length > 0 ? `?chain=${newChain}` : ''
      }`
    )

    localStorage.setItem('chain', newChain)
  }

  return {moveToPrevOrganizationHandler, moveToNextOrganizationHandler}
}

type SourceBase = Record<string, string | Blob>

export const convertToFormData = <Source extends SourceBase>(
  source: Source,
) => Object
    .entries(source)
    .reduce(
      (formData, [key, value]) => {
        formData.append(key, value)

        return formData
      },
      new FormData(),
    )

import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import 'styles/index.css'
import { apiBaseAgsr } from 'api'
import { Loader } from 'components'
import {
  adminRoleAttributes,
  adminSecurityRoleAttributes,
  userRoleAttributes,
} from 'components/Header/Header.const'
import { PermissionContext } from 'context'
import { Private, Public } from 'layouts'
import { HomeLayout } from 'layouts/HomeLayout'
import { Auth, EtalonPage, NotFoundPage } from 'pages'
import { AccountPage } from 'pages/AccountPage'
import { AisSettingsPage } from 'pages/AisSettingsPage'
import { AuditPage } from 'pages/AuditPage/AuditPage'
import { ComparisonPage } from 'pages/ComparisonPage'
import { MainPage } from 'pages/MainPage'
import { ManageAccessPage } from 'pages/ManageAccessPage'
import { ManageRolePage } from 'pages/ManageRolePage'
import { NewGraphPage } from 'pages/NewGraphPage'
import { ReportsPage } from 'pages/ReportsPage'
import { SecurityAdminPage } from 'pages/SecurityAdminPage/SecurityAdminPage'
import { SecurityPage } from 'pages/SecurityPage'
import { UserSpaceDashboard } from 'pages/UserSpaceDashboard/UserSpaceDashboard'
import { UserSpacePage, UserSpaceOrganizationPage } from 'pages/UserSpacePage'
import { ROUTE_PATH } from 'routes'
import { store } from 'store'
import { Permission } from 'utils/permissions'
import { ScrollToTop } from 'utils/scrollToTop'
import { Admin } from 'views/admin'
import { InfoFormOrganization } from 'views/InfoFormOrganization/InfoFormOrganization'

type attr = {
  id: string;
  name: string;
};

export const Main = () => {
  const token = localStorage.getItem('access_token')

  const [attributes, setAttributes] = useState<attr[]>([])
  const [attributesLoading, setAttributesLoading] = useState(false)
  const [flag, setFlag] = useState(false)

  const getAttributes = async () => {
    try {
      const res = await apiBaseAgsr.get('/profile/accessRights')

      if (res.status === 200) {
        setAttributes(res.data)
        setAttributesLoading(true)
      } else {
        setAttributes([])
        setAttributesLoading(true)
      }
    } catch (response) {
      setAttributes([])
      setAttributesLoading(true)
    }
  }

  const checkPermissions = (attributesData: string[]) =>
    attributes.some((e: attr) => attributesData.includes(e.id))

  const isAdmin = checkPermissions(adminRoleAttributes)
  const isUser = checkPermissions(userRoleAttributes)
  const isAdminSecurity = checkPermissions(adminSecurityRoleAttributes)

  useEffect(() => {
    if (token) {
      getAttributes()
    }
  }, [token])

  useEffect(() => {
    if (!token) {
      setAttributesLoading(true)
    }
  }, [token])

  const hasPermission = (attribute: Permission) =>
    attributes.some((e: attr) => e.id.includes(attribute))

  const dataPermissions = {
    permissionControlUsersProfile: hasPermission('aControlUsersProfile'),
    permissionJournal: hasPermission('sJournal'),
    permissionControlAdmins: hasPermission('sControlAdmins'),
    permissionControlUsers: hasPermission('aControlUsers'),
    permissionControlRoles: hasPermission('aControlRoles'),
    permissionControlPermit: hasPermission('aControlPermit'),
    permissionControlEtalon: hasPermission('aControlEtalon'),
    permissionSearch: hasPermission('uSearch'),
    permissionSearchAdv: hasPermission('uSearchAdv'),
    permissionRepGeneration: hasPermission('uRepGeneration'),
    permissionRepSaving: hasPermission('uRepSaving'),
    permissionRepUploading: hasPermission('uRepUploading'),
    isAdmin: checkPermissions(adminRoleAttributes),
    isUser: checkPermissions(userRoleAttributes),
    isAdminSecurity: checkPermissions(adminSecurityRoleAttributes),
  }

  return (
    <Provider store={store}>
      <PermissionContext.Provider value={dataPermissions}>
        {attributesLoading ? (
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route
                path={ROUTE_PATH.home}
                element={
                  <HomeLayout>
                    <MainPage />
                  </HomeLayout>
                }
              />
              <Route
                path={ROUTE_PATH.auth}
                element={
                  <Public>
                    <Auth />
                  </Public>
                }
              />
              <Route
                path={ROUTE_PATH.aisSettings}
                element={
                  <Private>
                    <AisSettingsPage />
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.etalon}
                element={
                  <Private>
                    {isAdmin ? (
                      <EtalonPage />
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.comparison}
                element={
                  <Private>
                    {isUser && hasPermission('uRepGeneration') ? (
                      <ComparisonPage setFlag={setFlag} flag={flag} />
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.reportsOrganizationInfo}
                element={
                  <Private>
                    {isUser ? (
                      <InfoFormOrganization />
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.reports}
                element={
                  <Private>
                    {isUser && hasPermission('uRepSaving') ? (
                      <ReportsPage setFlag={setFlag} flag={flag} />
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.admin}
                element={
                  <Private>
                    {isAdmin ? (
                      <Admin />
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.role_management}
                element={
                  <Private>
                    <ManageRolePage />
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.access_management}
                element={
                  <Private>
                    <ManageAccessPage />
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.account_management}
                element={
                  <Private>
                    {isAdmin ? (
                      <SecurityPage roleCustom='user' />
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.account}
                element={
                  <Private>
                    <AccountPage />
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.securityAdminSpace}
                element={
                  <Private>
                    {isAdminSecurity ? (
                      <SecurityAdminPage />
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.audit}
                element={
                  <Private>
                    {isAdminSecurity ? (
                      <AuditPage />
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.securitySpace}
                element={
                  <Private>
                    {isAdminSecurity ? (
                      <SecurityPage roleCustom='admin' />
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.userSpace}
                element={
                  <Private>
                    {isUser ? (
                      <UserSpacePage setFlag={setFlag} flag={flag}/>
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.userSpaceOrganization}
                element={
                  <Private>
                    {isUser ? (
                      <UserSpaceOrganizationPage setFlag={setFlag} flag={flag}/>
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.userSpaceDashboard}
                element={
                  <Private>
                    {isUser ? (
                      <UserSpaceDashboard setFlag={setFlag} flag={flag}/>
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.userSpaceOrganizationInfo}
                element={
                  <Private>
                    {isUser ? (
                      <InfoFormOrganization />
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.userSpaceOrganizationGraph}
                element={
                  <Private>
                    {isUser ? (
                      <NewGraphPage />
                    ) : (
                      <div className='noRole'>
                        У вас нет прав для просмотра этой страницы
                      </div>
                    )}
                  </Private>
                }
              />
              <Route
                path={ROUTE_PATH.notFound}
                element={
                  <Public>
                    <NotFoundPage />
                  </Public>
                }
              />
            </Routes>
          </BrowserRouter>
        ) : (
          <Loader />
        )}
      </PermissionContext.Provider>
    </Provider>
  )
}

import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'components'
import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { Notification } from 'components/Notification/Notification'
import { PermissionContext } from 'context'
import { useNotification } from 'hooks/useNotification'
import { manageRole, user, role } from 'modules'

import { CreateForm } from './components/CreateForm/CreateForm'
import { CustomTable } from './components/CustomTable/CustomTable'
import { ModalWindow } from './components/ModalWindow/ModalWindow'
import { SearchForm } from './components/SearchForm/SearchForm'
import {
  ADMIN_MANAGEMENT_BREADCRUMBS,
  SECURITY_ADMIN_MANAGEMENT_BREADCRUMBS,
} from './SecurityPage.const'
import {
  CreateUserFormFields,
  EditFormFields,
  SearchFormFields,
  SecurityPageProps,
} from './SecurityPage.interface'
import styles from './styles.module.css'


export const SecurityPage = ({ roleCustom }: SecurityPageProps) => {
  const { notificationData, openNotification } = useNotification()
  const dispatch = useDispatch()
  const { permissionControlUsersProfile } = useContext(PermissionContext)

  const targetUser = useSelector(user.useGetUser)
  const targetUserLoading = useSelector(user.useGetUserLoading)
  const targetUserSuccess = useSelector(user.useGetUserSuccess)
  const targetUserError = useSelector(user.useGetUserError)

  const getTargetUserRoles = useSelector(user.useGetUserRoles)
  const getTargetUserRolesSuccess = useSelector(user.useGetUserRolesSuccess)

  const createdUser = useSelector(user.useCreateUserData)
  const createUserSuccess = useSelector(user.useCreateUserSuccess)
  const createUserError = useSelector(user.useCreateUserError)

  const updatedUser = useSelector(user.useUpdateUser)
  const updateUserSuccess = useSelector(user.useUpdateUserSuccess)
  const updateUserError = useSelector(user.useUpdateUserError)

  const blockUser = useSelector(user.useBlockUser)
  const blockUserSuccess = useSelector(user.useBlockUserSuccess)
  const blockUserError = useSelector(user.useBlockUserError)

  const [selectRole, setSelectRole] = useState('')
  const [selectRoleEdit, setSelectRoleEdit] = useState<{id: string, name: string}>({id: '', name: ''})

  const getUserHandler = (values: SearchFormFields) => {
    dispatch(user.actions.getUser(values))
  }

  const rolesWithoutDefaultRole = getTargetUserRoles?.filter(e => e.name !== 'default-roles-own.agsr.by')

  useEffect(() => {
    if (getTargetUserRolesSuccess) {
      
      setSelectRoleEdit({id: rolesWithoutDefaultRole?.length ? rolesWithoutDefaultRole[0].id : '', name: rolesWithoutDefaultRole?.length ? rolesWithoutDefaultRole[0].name : ''})
    }
  }, [dispatch, getTargetUserRoles, getTargetUserRolesSuccess])

  useEffect(() => {
    if (targetUser && targetUserSuccess) {
      dispatch(user.actions.getUserRoles({ username: targetUser.username }))
      setSelectRoleEdit({id: rolesWithoutDefaultRole?.length ? rolesWithoutDefaultRole[0].id : '', name: rolesWithoutDefaultRole?.length ? rolesWithoutDefaultRole[0].name : ''})
    }
  }, [dispatch, targetUser, targetUserSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

  const editUserHandler = (payload: EditFormFields) => {
    dispatch(
      user.actions.update({ username: targetUser.username, body: payload })
    )
  }

  useEffect(() => {
    if (updateUserSuccess) {
      dispatch(user.actions.resetUpdation())
    }
  }, [dispatch, updateUserSuccess])

  const blockUserHandler = () => {
    dispatch(
      user.actions.blockUser({
        username: targetUser.username,
        body: { enabled: !targetUser.enabled },
      })
    )
  }

  useEffect(() => {
    if (blockUserSuccess) {
      dispatch(user.actions.blockUserReset())
    }
  }, [dispatch, blockUserSuccess])

  const createUserHandler = (body: CreateUserFormFields) => {
    dispatch(user.actions.createUser({ ...body, emailVerified: true }))
  }

  useEffect(() => {
    if (createUserSuccess && createdUser) {
      dispatch(
        user.actions.addRoleToUser({
          username: createdUser.username as string,
          rolename: roleCustom === 'admin' ? roleCustom : selectRole,
        })
      )
    }

    if (blockUserSuccess || (updatedUser && updateUserSuccess)) {
      getUserHandler({ username: targetUser.username })
    }
  }, [ dispatch, createdUser, createUserSuccess, updatedUser, updateUserSuccess, blockUserSuccess, role, targetUser.username, roleCustom, getUserHandler ]) // eslint-disable-line react-hooks/exhaustive-deps

  const [createModalIsOpened, setCreateModalIsOpened] =
    useState<boolean>(false)

  const toggleCreateModalIsOpenedHandler = () =>
    setCreateModalIsOpened((currState) => !currState)

  useEffect(() => {
    if (updateUserSuccess && roleCustom !== 'admin') {
      dispatch(
        user.actions.updateRoleToUser({
          username: updatedUser?.username as string,
          body: {roles: [selectRoleEdit]}
        })
      )
      openNotification('Изменения сохранены', 'success')
      return () => {
        dispatch(user.actions.resetUpdation())
      }
    }
  }, [updateUserSuccess, dispatch, openNotification, roleCustom]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (targetUserError) {
      openNotification('Поиск не дал результатов', 'error')

      return () => {
        dispatch(user.actions.resetGetUser())
      }
    }
    if (updateUserSuccess && roleCustom === 'admin') {
      openNotification('Изменения сохранены', 'success')

      return () => {
        dispatch(user.actions.resetUpdation())
      }
    }

    if (updateUserError) {
      openNotification('Изменения не сохранены', 'error')

      return () => {
        dispatch(user.actions.resetUpdation())
      }
    }

    if (createUserSuccess) {
      openNotification(`${roleCustom === 'user' ? 'Пользователь' : 'Администратор'} добавлен`, 'success')

      return () => {
        dispatch(user.actions.resetCreateUser())
      }
    }

    if (createUserError) {
      openNotification(`${roleCustom === 'user' ? 'Пользователь' : 'Администратор'} с указанными данными уже существует` , 'error')

      return () => {
        dispatch(user.actions.resetCreateUser())
      }
    }

    if (blockUserSuccess) {
      openNotification(`${roleCustom === 'user' ? 'Пользователь' : 'Администратор'} ${blockUser?.enabled ? 'разблокирован' : 'заблокирован'}`, 'success')

      return () => {
        dispatch(user.actions.blockUserReset())
      }
    }
    if (blockUserError) {
      openNotification(`Не удалось ${blockUser?.enabled ? 'заблокировать' : 'разблокировать'} ${roleCustom === 'user' ? 'пользователя' : 'администратора'}`, 'error')

      return () => {
        dispatch(user.actions.blockUserReset())
      }
    }
  }, [
    dispatch,
    openNotification,
    targetUserError,
    createUserSuccess,
    createUserError,
    updateUserSuccess,
    updateUserError,
    blockUser,
    blockUserSuccess,
    blockUserError,
    roleCustom
  ])

  useEffect(() => {
    dispatch(
      manageRole.actions.updateManageRoleListFilter({
        _page: 1,
        _limit: 100,
      })
    )

    return () => {
      dispatch(
        manageRole.actions.resetGetManageRoleList()
      )
    }
  }, [dispatch])

  const records = useSelector(
    manageRole.useManageRoleListItems
  )
 
  const roles = records.filter(e => e.name !== 'admin' && e.name !== 'admin_security' && e.name !== 'administrator')

  return (
    <>
      <section className={styles.section}>
        <CustomBreadcrumbs
          {...(roleCustom === 'user'
            ? ADMIN_MANAGEMENT_BREADCRUMBS
            : SECURITY_ADMIN_MANAGEMENT_BREADCRUMBS)}
        />
        <SearchForm
          placeholder={`Введите username или email ${
            roleCustom === 'user' ? 'пользователя' : 'администратора'
          }`}
          onSubmit={getUserHandler}
        />
        <CustomTable
          roleCustom={roleCustom}
          data={targetUserSuccess ? targetUser : null}
          roleTargetUser={getTargetUserRoles}
          onEditUser={editUserHandler}
          onBlockUser={blockUserHandler}
          permissionControlUsersProfile={permissionControlUsersProfile}
          selectRoleEdit={selectRoleEdit}
          setSelectRoleEdit={setSelectRoleEdit}
          roles={roles}
          rolesWithoutDefaultRole={rolesWithoutDefaultRole}
        />
        <button
          className={styles.section__btn}
          type='button'
          onClick={toggleCreateModalIsOpenedHandler}
          disabled={roleCustom === 'user' ? !permissionControlUsersProfile : false}
        >
          Создать {roleCustom === 'user' ? 'пользователя' : 'администратора'}
        </button>
      </section>
      <ModalWindow
        isOpened={createModalIsOpened}
        headerText={`Создание учетной записи ${
          roleCustom === 'user' ? 'пользователя' : 'администратора'
        }`}
        onClose={toggleCreateModalIsOpenedHandler}
      >
        <CreateForm
          roleCustom={roleCustom}
          onSubmit={createUserHandler}
          onCancel={toggleCreateModalIsOpenedHandler}
          roles={roles}
          selectRole={selectRole}
          setSelectRole={setSelectRole}
        />
      </ModalWindow>
      <Notification {...notificationData} />
      {targetUserLoading && <Loader />}
    </>
  )
}

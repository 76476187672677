import { apiBaseAgsr } from 'api/base'

import { SUBPATH } from './user.const'
import {
  GetUser,
  CreateUser,
  UserUpdate,
  AddRoleToUser,
  BlockUser,
  GetUserRoles,
  UserUpdateRole,
  UserUpdateEmail,
  GetUserCount,
} from './user.interface'
import {
  TUserCreateWithPath,
  TUserUpdateEmail,
  TUserUpdateRoleWithPath,
} from './user.model'

export const getUser = (params: GetUser.Query) =>
  apiBaseAgsr.get<GetUser.Data>(SUBPATH, { params })

export const updateUser = ({ username, body }: TUserCreateWithPath) =>
  apiBaseAgsr.put<UserUpdate.Data>(`${SUBPATH}/${username}`, body)

export const createUser = (body: CreateUser.Body) =>
  apiBaseAgsr.post(SUBPATH, body)

export const addRoleToUser = ({ username, rolename }: AddRoleToUser.Path) =>
  apiBaseAgsr.post(`${SUBPATH}/${username}/roles/${rolename}`)

export const blockUser = ({ username, body }: BlockUser.Payload) =>
  apiBaseAgsr.patch(`${SUBPATH}/${username}`, body)

export const getUserRoles = ({ username }: GetUserRoles.Payload) =>
  apiBaseAgsr.get(`${SUBPATH}/${username}/roles`)

export const updateUserRole = ({ username, body }: TUserUpdateRoleWithPath) =>
  apiBaseAgsr.put<UserUpdateRole.Data>(`${SUBPATH}/${username}/roles`, body)

export const updateUserEmail = (body: TUserUpdateEmail) =>
  apiBaseAgsr.put<UserUpdateEmail.Data>('/profile', body)

export const getUsersCount = () =>
  apiBaseAgsr.get<GetUserCount.Data>(`${SUBPATH}/count`, {
    headers: {
      Authorization: undefined,
    }
  })

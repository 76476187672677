import { AuthAction } from './auth.action'
import { AuthSchema } from './auth.schema'
import { initialState } from './auth.state'


export const reducer = (
  state = initialState,
  action: AuthAction | void = undefined,
): AuthSchema => {
  switch (action?.type) {

    case 'auth.login': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...state.creation.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }

    case 'auth.login.success': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...initialState.creation.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }

    case 'auth.login.error': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...state.creation.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }

    case 'auth.reset_creation': {
      return {
        ...state,
        creation: {
          ...initialState.creation,
        },
      }
    }

    default:
      return state
  }
}

import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import style from '../InfoFormOrganization.module.css'
import { PropsData } from '../types'


export const Owners: FC<PropsData> = ({ data }) => {
  const navigate = useNavigate()
  const info = data.foundersInfo

  const moveToOrganization = (unp: string) => navigate(`/user-space/${unp}/info`)
  return (
    <div className={style.containerComponent}>
      <p className={style.generalName}>Учредители</p>
      {info.map((e, i) => (
        <React.Fragment key={i}>
          {e.founderName && <p className={style.headline}>{e.founderName}</p>}
          {e.founderUnp ? <p className={style.about}>{<span className={style.link} onClick={() => moveToOrganization(e.founderUnp)}>{e.founderUnp}</span>}</p> : null}
        </React.Fragment>
      ))}
    </div>
  )
}

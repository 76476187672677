import {
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
  useMetaErrorStatus,
  useMetaErrorMessage,
  useDataCurrPage,
  useDataLastPage,
  useDataItemsTotalCount,
  useDataItems,
} from 'modules/base'

import { AuditSlice } from './audit.schema'

export const useListFilter = ({ audit }: AuditSlice) =>
  audit.listFilter

export const useListIdle = ({ audit }: AuditSlice) =>
  useMetaIdleStatus(audit.list)

export const useListLoading = ({ audit }: AuditSlice) =>
  useMetaLoadingStatus(audit.list)

export const useListSuccess = ({ audit }: AuditSlice) =>
  useMetaSuccessStatus(audit.list)

export const useListError = ({ audit }: AuditSlice) =>
  useMetaErrorStatus(audit.list)

export const useListErrorMessage = ({ audit }: AuditSlice) =>
  useMetaErrorMessage(audit.list)

export const useListCurrPage = ({ audit }: AuditSlice) =>
  useDataCurrPage(audit.list)

export const useListLastPage = ({ audit }: AuditSlice) =>
  useDataLastPage(audit.list)

export const useListItemsTotalCount = ({ audit }: AuditSlice) =>
  useDataItemsTotalCount(audit.list)

export const useListItems = ({ audit }: AuditSlice) =>
  useDataItems(audit.list)


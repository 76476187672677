import { useCallback, useEffect, useState } from 'react'

export interface NotificationState {
  isOpen: boolean;
  message: string;
  type: 'success' | 'error';
}

export const useNotification = () => {
  const [notificationData, setNotificationData] = useState<NotificationState>({
    isOpen: false,
    message: '',
    type: 'success',
  })

  const openNotification = useCallback((
    message: string,
    type: NotificationState['type']
  ) => setNotificationData({ isOpen: true, message, type }),[])

  useEffect(() => {
    if (notificationData.isOpen) {
      const closeInterval = setInterval(
        () =>
          setNotificationData({...notificationData,
            isOpen: false,
          }),
        3000
      )

      return () => clearInterval(closeInterval)
    }
  }, [openNotification, notificationData])

  return {
    notificationData,
    openNotification,
  }
}

import { call, put, select, spawn, takeEvery } from 'redux-saga/effects'

import {
  Base,
  ManageAccessList,
  ManageAccessUpdate,
  manageAccess,
} from 'api'

import {
  UpdateManageAccess,
  UpdateManageAccessListFilter,
} from './manageAccess.action'
import * as creator from './manageAccess.creator'
import * as selector from './manageAccess.selector'

// list
function* updateManageAccessListFilterWorker() {
  let errorMessage = ''

  try {
    const filter: ReturnType<
      typeof selector.useManageAccessListFilter
    > = yield select(selector.useManageAccessListFilter)

    yield put(creator.getManageAccessList())

    const data:ManageAccessList.Response = yield call(
      manageAccess.getManageAccessList,
      filter
    )
    
    if (data.status === 200 ) {
      yield put(creator.getManageAccessListSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.getManageAccessListError(errorMessage))
    }
  }
}

function* updateManageAccessListFilterWatcher() {
  yield takeEvery<UpdateManageAccessListFilter>(
    'manageAccess.update_list_filter' as UpdateManageAccessListFilter['type'],
    updateManageAccessListFilterWorker
  )
}


// update
function* updateManageAccessWorker(
  action: UpdateManageAccess
) {
  let errorMessage = ''

  try {
    const data: ManageAccessUpdate.Response = yield call(
      manageAccess.updateManageAccess,
      action.payload
    )

    if (data?.status === 200) {
      yield put(creator.updateManageAccessSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.updateManageAccessError(errorMessage))
    }
  }
}

function* updateManageAccessWatcher() {
  yield takeEvery<UpdateManageAccess>(
    'manageAccess.update' as UpdateManageAccess['type'],
    updateManageAccessWorker
  )
}


export function* saga() {
  yield spawn(updateManageAccessListFilterWatcher)
  yield spawn(updateManageAccessWatcher)
}

import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useRef, useState } from 'react'

import { Notification } from 'components/Notification/Notification'
import { useNotification } from 'hooks'
import { useClickOutside } from 'hooks/useClickOutside'
import { CustomTableProps } from 'pages/SecurityPage/SecurityPage.interface'

import { EditForm } from '../EditForm/EditForm'
import { ModalWindow } from '../ModalWindow/ModalWindow'

import styles from './styles.module.css'

export const CustomTable = ({
  roleCustom,
  data,
  onEditUser,
  onBlockUser,
  permissionControlUsersProfile,
  roleTargetUser,
  setSelectRoleEdit,
  selectRoleEdit,
  roles,
  rolesWithoutDefaultRole
}: CustomTableProps) => {
  const { notificationData, openNotification } = useNotification()
  const toolsPopup = useRef<HTMLDivElement | null>(null)

  const [toolsPopupIsOpened, setToolsPopupIsOpened] = useState<boolean>(false)

  const userIsAdmin = roleTargetUser?.some(targetUserRole => targetUserRole.name === 'admin' || targetUserRole.name === 'admin_security' || targetUserRole.name === 'administrator')

  const openToolsPopupIsOpenedHandler = () => {
    if ((roleCustom === 'user' && !userIsAdmin) || (roleCustom === 'admin' && userIsAdmin)) {
      setToolsPopupIsOpened(true)
    }

    if (roleCustom === 'user' && userIsAdmin) {
      openNotification('Пользователь является администратором, вы не можете его редактировать.', 'error')
    }

    if (roleCustom === 'admin' && !userIsAdmin) {
      openNotification('Пользователь не является администратором, вы не можете его редактировать.', 'error')
    }
  }

  const closeToolsPopupIsOpenedHandler = () => setToolsPopupIsOpened(false)

  useClickOutside(toolsPopup, closeToolsPopupIsOpenedHandler)

  const [editModaIsOpened, setEditModalIsOpened] = useState<boolean>(false)

  const toggleEditModalIsOpenedHandler = () => setEditModalIsOpened((currState) => !currState)

  let defaultValues

  if (data) {
    const { fio, email, phone, personalNumber } = data

    defaultValues = {
      fio,
      email,
      phone,
      personalNumber,
    }
  }

  return (
    <>
      <Table>
        <TableHead className={styles.table__header}>
          <TableRow>
            <TableCell>
              <span className={styles.table__cell_header}>Активность</span>
            </TableCell>
            <TableCell>
              <span className={styles.table__cell_header}>
                ФИО {roleCustom === 'user' ? 'пользователя' : 'администратора'}
              </span>
            </TableCell>
            <TableCell>
              <span className={styles.table__cell_header}>
                Идентификационный номер/логин
              </span>
            </TableCell>
            {roleCustom === 'user' && (
              <TableCell>
                <span className={styles.table__cell_header}>Роль</span>
              </TableCell>
            )}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            <TableRow>
              <TableCell>
                <div
                  className={`${styles.status} ${data.enabled && styles.status_active
                  }`}
                />
              </TableCell>
              <TableCell>{data.fio}</TableCell>
              <TableCell>{data.personalNumber}</TableCell>
              {roleCustom === 'user' && (
                <TableCell>
                  {roleTargetUser?.filter(e => e.name !== 'default-roles-own.agsr.by').map((e) => (
                    <p key={e.name}>{e.name}</p>
                  ))}
                </TableCell>
              )}

              <TableCell>
                <div className={styles.tools__wraper}>
                  <button
                    className={styles.tools__btn}
                    style={{
                      cursor: ((roleCustom === 'user' && permissionControlUsersProfile) || (roleCustom === 'admin'))
                        ? 'pointer'
                        : 'initial',
                    }}
                    onClick={
                      ((roleCustom === 'user' && permissionControlUsersProfile) || (roleCustom === 'admin'))
                        ? openToolsPopupIsOpenedHandler
                        : () => { }
                    }
                  >
                    ...
                  </button>
                  {toolsPopupIsOpened && (
                    <div className={styles.tools__popupBlock} ref={toolsPopup}>
                      <button
                        className={styles.tools__popupBtn}
                        onClick={toggleEditModalIsOpenedHandler}
                      >
                        <img src='/assets/icon-change.svg' alt='change' />
                        Редактировать запись
                      </button>
                      <button
                        className={styles.tools__popupBtn}
                        onClick={onBlockUser}
                      >
                        {data.enabled ?
                          <LockPersonIcon />
                          : <LockOpenIcon />}
                        {data.enabled
                          ? 'Деактивировать профиль'
                          : 'Активировать профиль'}
                      </button>
                    </div>
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      {defaultValues && (
        <ModalWindow
          isOpened={editModaIsOpened}
          headerText={`Просмотр и редактирование учетной записи ${roleCustom === 'user' ? 'пользователя' : 'администратора'
          }`}
          onClose={toggleEditModalIsOpenedHandler}
        >
          <EditForm
            roleCustom={roleCustom}
            defaultValues={defaultValues}
            onSubmit={onEditUser}
            onCancel={toggleEditModalIsOpenedHandler}
            setSelectRoleEdit={setSelectRoleEdit}
            selectRoleEdit={selectRoleEdit}
            roles={roles}
            roleTargetUser={roleTargetUser}
            rolesWithoutDefaultRole={rolesWithoutDefaultRole}
          />
        </ModalWindow>
      )}
      <Notification {...notificationData} />
    </>
  )
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Background,
  BackgroundVariant,
  MiniMap,
  ReactFlow,
  useNodesState,
} from 'reactflow'

import { getNodeColor } from 'pages/NewGraphPage/getNodeColor'
import { PROP_OPTIONS } from 'pages/NewGraphPage/NewGraphPage.const'
import { GraphProps } from 'pages/NewGraphPage/NewGraphPage.props'

import styles from '../../styles.module.css'
import 'reactflow/dist/base.css'
import { CustomControls } from '../CustomControl/CustomControl'
import { GraphNode } from '../GraphNode/GraphNode'

export const Graph = ({ initialNodes, initialEdges }: GraphProps) => {
  const nodeTypes = useMemo(() => ({ graphNode: GraphNode }), [])

  const [nodes, , onNodesChange] = useNodesState(initialNodes)

  const [nodesDraggable, setNodesDraggable] = useState<boolean>(true)

  const toggleNodesDraggableHandler = () => setNodesDraggable((curr) => !curr)

  const defaultFullScreen = localStorage.getItem('fullscreenGraph') === 'true' ? true : false

  const [fullScreen, setFullScreen] = useState<boolean>(defaultFullScreen)

  const changeFullScreenHandler = useCallback(() => {
    setFullScreen((currState) => !currState)
  }, [])

  useEffect(() => {
    localStorage.setItem('fullscreenGraph', String(fullScreen))
  }, [fullScreen])

  return (
    <div className={`${styles.wrapper} ${fullScreen && styles.wrapper_full}`}>
      <ReactFlow
        className={styles.reactFlow}
        proOptions={PROP_OPTIONS}
        fitView
        nodeTypes={nodeTypes}
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={initialEdges}
        nodesConnectable={false}
        nodesDraggable={nodesDraggable}
      >
        <Background variant={BackgroundVariant.Lines} />
        <CustomControls
          draggable={nodesDraggable}
          fullscreen={fullScreen}
          onChangeDraggability={toggleNodesDraggableHandler}
          onChangeFullscreen={changeFullScreenHandler}
        />
        <MiniMap nodeColor={getNodeColor} zoomable pannable zoomStep={1} />
      </ReactFlow>
    </div>
  )
}

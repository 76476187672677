import {
  GetManageAccessList,
  GetManageAccessListError,
  GetManageAccessListSuccess,
  ResetGetManageAccessList,
  ResetManageAccessListFilter,
  ResetUpdateManageAccess,
  UpdateManageAccess,
  UpdateManageAccessError,
  UpdateManageAccessListFilter,
  UpdateManageAccessSuccess,
} from './manageAccess.action'

// filter
export const updateManageAccessListFilter = (
  payload: UpdateManageAccessListFilter['payload']
): UpdateManageAccessListFilter => ({
  type: 'manageAccess.update_list_filter',
  payload,
})

export const resetManageAccessListFilter =
  (): ResetManageAccessListFilter => ({
    type: 'manageAccess.reset_list_filter',
    payload: undefined,
  })

// list
export const getManageAccessList =
  (): GetManageAccessList => ({
    type: 'manageAccess.get_list',
    payload: undefined,
  })

export const getManageAccessListSuccess = (
  payload: GetManageAccessListSuccess['payload']
): GetManageAccessListSuccess => ({
  type: 'manageAccess.get_list_success',
  payload,
})

export const getManageAccessListError = (
  payload: GetManageAccessListError['payload']
): GetManageAccessListError => ({
  type: 'manageAccess.get_list_error',
  payload,
})

export const resetGetManageAccessList =
  (): ResetGetManageAccessList => ({
    type: 'manageAccess.reset_get_list',
    payload: undefined,
  })


// update
export const updateManageAccess = (
  payload: UpdateManageAccess['payload']
): UpdateManageAccess => ({
  type: 'manageAccess.update',
  payload,
})

export const updateManageAccessSuccess = (
  payload: UpdateManageAccessSuccess['payload']
): UpdateManageAccessSuccess => ({
  type: 'manageAccess.update.success',
  payload,
})

export const updateManageAccessError = (
  payload: UpdateManageAccessError['payload']
): UpdateManageAccessError => ({
  type: 'manageAccess.update.error',
  payload,
})

export const resetUpdateManageAccess =
  (): ResetUpdateManageAccess => ({
    type: 'manageAccess.reset_update',
    payload: undefined,
  })

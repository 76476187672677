import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { Notification } from 'components/Notification'
import { useNotification } from 'hooks'
import { manageRole } from 'modules'


import { IManageRole } from '../ManageRoleTable/ManageRoleTable.props'

import { editAccountFormValidationSchema } from './editAccountFormValidationSchema'
import styles from './styles.module.css'

export interface IEditAccountFormFields {
  name: string;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
}

interface CreateAccountFormProps {
  mode: 'create' | 'update';
  defaultValues?: IEditAccountFormFields;
  onClose: () => void;
  record?: IManageRole;
}

export const EditAccountForm: FC<CreateAccountFormProps> = ({
  mode,
  defaultValues,
  onClose,
  record
}) => {
  const { openNotification, notificationData } = useNotification()
  const dispatch = useDispatch()

  const {
    formState: { errors, isDirty, isValid },
    register,
    handleSubmit,
  } = useForm<IEditAccountFormFields>({
    defaultValues: defaultValues || {
      name: '',
      description: '',
    },
    resolver: yupResolver(editAccountFormValidationSchema),
    mode: 'all',
  })

  const submitFormHandler: SubmitHandler<IEditAccountFormFields> = (
    formValues
  ) => {
    if (record?.name !== 'admin' && record?.name !== 'admin_security' && record?.name !== 'administrator' && record?.name !== 'user') {
      if (mode === 'create') {
        dispatch(
          manageRole.actions.createManageRole(formValues)
        )
      } else {
        dispatch(
          manageRole.actions.updateManageRole({ name: defaultValues?.name!, body: formValues })
        )
      }
    }
    if (record?.name === 'admin' || record?.name === 'admin_security' || record?.name === 'administrator' || record?.name === 'user') {
      openNotification('Роли "admin", "admin_security", "administrator",  "user" запрещено редактировать.', 'error')
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(submitFormHandler)}>
        <div className={styles.modal__inputsWrapper}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <div className={styles.modal__inputBlock}>
                <label className={styles.modal__inputLabel} htmlFor='name'>
                  Наименование
                </label>
                <input
                  className={`${styles.modal__input} ${errors.name && styles.modal__input_error
                  }`}
                  type='text'
                  id='name'
                  placeholder='Введите наименование'
                  {...register('name')}
                />
                {errors.name && (
                  <span className={styles.modal__error}>
                    {errors.name.message}
                  </span>
                )}
              </div>
            </Grid>
            <Grid item sm={6}>
              <div className={styles.modal__inputBlock}>
                <label className={styles.modal__inputLabel} htmlFor='description'>
                  Описание роли
                </label>
                <textarea
                  className={`${styles.modal__input} ${errors.description && styles.modal__input_error
                  }`}
                  cols={120}
                  rows={6}
                  id='description'
                  placeholder='Введите описание'
                  {...register('description')}
                />
                {errors.description && (
                  <span className={styles.modal__error}>
                    {errors.description.message}
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.modal__btnsWrapper}>
          <button
            className={styles.modal__btn}
            type='submit'
            disabled={!isDirty || !isValid}
          >
            Сохранить
          </button>
          <button className={styles.modal__btn} onClick={onClose}>Отмена</button>
        </div>
      </form>
      <Notification {...notificationData} />
    </>
  )
}

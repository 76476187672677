import { useDispatch, useSelector } from 'react-redux'

import './Radio.css'

import { CustomTooltip } from 'components/CustomTooltip'
import { organizations } from 'modules/organizations'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'

type Props = {
  name: keyof FormListProps;
  label: string;
  tooltipText: string;
};

export const Radio = ({ name, label, tooltipText }: Props) => {
  const dispatch = useDispatch()

  const data = useSelector(organizations.useStateFilter)

  const setValue = (value: string) => {
    dispatch(
      organizations.actions.setDataFilterOrganizations({
        [name]: data[name] === value ? undefined : value,
      })
    )
  }

  return (
    <div className='fieldset'>
      <CustomTooltip title={tooltipText}>
        <label className='fieldset__label' htmlFor={name}>
          {label}
        </label>
      </CustomTooltip>
      <div className='containerRadio'>
        <button
          className={data[name] === '1' ? 'checkedBtn' : 'btn'}
          type='button'
          onClick={() => setValue('1')}
        >
          Да
        </button>
        <button
          className={data[name] === '2' ? 'checkedBtn' : 'btn'}
          type='button'
          onClick={() => setValue('2')}
        >
          Нет
        </button>
      </div>
    </div>
  )
}

import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { downloadAll } from 'api/organizations/organizations.api'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'common/const'
import { Loader } from 'components'
import { Chips } from 'components/Chips'
import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { Dashboards } from 'components/Dashboards'
import { Notification } from 'components/Notification'
import { useNotification } from 'hooks/useNotification'
import { comparison, organizations, reports } from 'modules'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'

import { DeleteConfirmationModal } from './components/DeleteConfirmation'
import { OrganizationTable } from './components/OrganizationTable'
import { OrganizationTableProps } from './components/OrganizationTable/OrganizationTable.props'
import styles from './styles.module.css'


export const UserSpacePage = ({ setFlag, flag }: { setFlag: Dispatch<SetStateAction<boolean>>, flag: boolean }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { openNotification, notificationData } = useNotification()

  // clearing chain
  localStorage.removeItem('chain')
  // clearing graph
  localStorage.removeItem('fullscreenGraph')

  const { id: userId, fio: executorFio } = JSON.parse(localStorage.getItem('user')!)

  const searchItems = useSelector(organizations.useDetailedSearchItems)
  const searchLoading = useSelector(organizations.useDetailedSearchLoading)
  const searchSuccess = useSelector(organizations.useDetailedSearchSuccess)
  const searchError = useSelector(organizations.useDetailedSearchError)
  const searchTotal = useSelector(organizations.useDetailedSearchItemsTotalCount)
  const searchFilter = useSelector(organizations.useDetailedSearchListFilter)

  const items = useSelector(organizations.useListItems)
  const itemsLoading = useSelector(organizations.useListLoading)
  const itemsError = useSelector(organizations.useListError)
  const itemsTotal = useSelector(organizations.useListItemsTotalCount)
  const itemsFilter = useSelector(organizations.useListFilter)

  const addToComparisonSuccess = useSelector(comparison.useAddToListSuccess)
  const addToComparisonError = useSelector(comparison.useAddToListError)
  const addToComparisonErrorMessage = useSelector(comparison.useAddToListErrorMessage)

  const saveToReportsSuccess = useSelector(reports.useAddToListSuccess)
  const saveToReportsError = useSelector(reports.useAddToListError)

  const filters = useSelector(organizations.useGetFilters)

  useEffect(() => {
    dispatch(
      organizations.actions.updateListFilter({
        page: PAGE_DEFAULT,
        size: PAGE_SIZE_DEFAULT
      })
    )
  }, [dispatch, navigate])

  useEffect(() => {
    dispatch(
      organizations.actions.filters({
        'user_id': userId
      })
    )
  }, [dispatch, navigate, userId])


  // page
  const handleTablePageChange = useCallback<
    OrganizationTableProps['onPageChange']
  >(
    (page) => {
      if (searchSuccess) {
        dispatch(
          organizations.actions.updateDetailedSearchFilter({
            page,
          })
        )
      } else {
        dispatch(organizations.actions.updateListFilter({ page }))
      }
    },
    [dispatch, searchSuccess]
  )

  const handleSearchTablePageChange = useCallback<
    OrganizationTableProps['onPageChange']
  >(
    (page) => {
      dispatch(
        organizations.actions.updateDetailedSearchFilter({
          page,
        })
      )
    },
    [dispatch]
  )

  // page size
  const handleTablePageSizeChange = useCallback<
    OrganizationTableProps['onPageSizeChange']
  >(
    (size) => {
      dispatch(
        organizations.actions.updateListFilter({ page: PAGE_DEFAULT, size })
      )
    },
    [dispatch]
  )

  const handleSearchTablePageSizeChange = useCallback<
    OrganizationTableProps['onPageSizeChange']
  >(
    (size) => {
      dispatch(
        organizations.actions.updateDetailedSearchFilter({
          page: PAGE_DEFAULT, size
        })
      )
    },
    [dispatch]
  )

  // sort
  const handleTableSort = useCallback<OrganizationTableProps['onSort']>(
    (direction, sortField) => {
      dispatch(
        organizations.actions.updateListFilter({
          page: PAGE_DEFAULT,
          direction,
          sortField,
        })
      )
    },
    [dispatch]
  )

  const handleSearchTableSort = useCallback<OrganizationTableProps['onSort']>(
    (direction, sortField) => {
      dispatch(
        organizations.actions.updateDetailedSearchFilter({
          page: PAGE_DEFAULT,
          direction,
          sortField,
        })
      )
    },
    [dispatch]
  )

  // search
  const handleTableSearch = useCallback<OrganizationTableProps['onSearch']>(
    (searchParam) => {
      dispatch(organizations.actions.resetDetailedSearch())
      dispatch(organizations.actions.resetDetailedSearchFilter())
      dispatch(
        organizations.actions.updateListFilter({
          searchParam,
          page: PAGE_DEFAULT,
        })
      )
    },
    [dispatch]
  )

  // reset search
  const handleTableResetSearch = useCallback(() => {
    dispatch(organizations.actions.resetDetailedSearch())
    dispatch(organizations.actions.resetDetailedSearchFilter())
    dispatch(
      organizations.actions.updateListFilter({
        searchParam: 'belstat-code',
        page: PAGE_DEFAULT,
      })
    )
    setFlag(false)
  }, [dispatch])

  // detailed search
  const handleTableFiltersChange = useCallback<
    OrganizationTableProps['onFiltersChange']
  >(
    (filters) => {
      const copyFilters = Object.assign({}, filters)

      for (const filter in copyFilters) {
        if (typeof copyFilters[filter as keyof typeof copyFilters] === 'object' && !Array.isArray(copyFilters[filter as keyof typeof copyFilters])) {
          // @ts-ignore
          copyFilters[filter as keyof typeof copyFilters] = new Date(copyFilters[filter as unknown]).toLocaleDateString()
        }
        if(Array.isArray(copyFilters[filter as keyof typeof copyFilters])) {
          // @ts-ignore
          copyFilters[filter as keyof typeof copyFilters] = copyFilters[filter].join(',')
        }
      }

      dispatch(
        organizations.actions.updateDetailedSearchFilter({
          ...copyFilters,
          page: PAGE_DEFAULT,
        })
      )

      dispatch(
        organizations.actions.setDataFilterOrganizations({
          ...filters
        })
      )
      setFlag(true)
    },
    [dispatch]
  )

  // add to comparison
  const handleTableAddToComparison = useCallback<
    OrganizationTableProps['onAddToComparison']
  >(
    (unps) => {
      if (unps.length === 0) {
        openNotification('Не выбраны организации', 'error')
      } else {
        dispatch(
          comparison.actions.addToComparisonList({
            userId: userId,
            unps: unps,
          })
        )
      }
    },
    [dispatch, openNotification, userId]
  )

  // save to reports
  const handleTableSaveToReports = useCallback<
    OrganizationTableProps['onSave']
  >(
    (unps, fields) => {
      if (unps.length === 0) {
        openNotification('Не выбраны организации', 'error')
      } else {
        dispatch(
          reports.actions.addToList({
            executorFio,
            reportType: 'table',
            unps,
            fields,
          })
        )
      }
    },
    [dispatch, openNotification, executorFio]
  )

  // notifications
  useEffect(() => {
    if (itemsError) {
      openNotification('По вашему запросу ничего не найдено.', 'error')
    }
    if (searchError) {
      openNotification('По вашему запросу ничего не найдено.', 'error')
    }
    if (addToComparisonSuccess) {
      openNotification(
        'Организации добавлены на страницу Избранное',
        'success'
      )
      dispatch(comparison.actions.resetAddToComparisonList())
    }
    if (addToComparisonError) {
      openNotification(
        addToComparisonErrorMessage,
        'error'
      )
      dispatch(comparison.actions.resetAddToComparisonList())
    }
    if (saveToReportsSuccess) {
      openNotification(
        'Отчет добавлен на страницу Сохраненные отчеты',
        'success'
      )
      dispatch(reports.actions.resetToList())
    }

    if (saveToReportsError) {
      openNotification(
        'Не удалось добавить отчет на страницу Сохраненные отчеты',
        'error'
      )
      dispatch(reports.actions.resetToList())
    }
  }, [
    dispatch,
    openNotification,
    itemsError,
    searchError,
    addToComparisonSuccess,
    addToComparisonError,
    saveToReportsSuccess,
    saveToReportsError,
    addToComparisonErrorMessage
  ])

  const handleSearchFilters = (filters: FormListProps) => {
    dispatch(organizations.actions.resetDataFilterOrganizations())
    dispatch(organizations.actions.resetDetailedSearchFilter())
    dispatch(organizations.actions.setDataFilterOrganizations(filters))

    const copyFilters = Object.assign({}, filters)

    for (const filter in copyFilters) {
      // @ts-ignore
      if (copyFilters[filter as unknown] && copyFilters[filter as unknown].includes('-')) {
        // @ts-ignore
        copyFilters[filter as keyof typeof copyFilters] = copyFilters[filter as unknown].split('-').reverse().join('.')
      }
    }

    dispatch(
      organizations.actions.updateDetailedSearchFilter({
        ...copyFilters,
        page: PAGE_DEFAULT,
      })
    )
    setFlag(true)
  }

  const deletionLoading = useSelector(organizations.useDeletionLoading)
  const deletionSucceed = useSelector(organizations.useDeletionSuccess)
  const deletionError = useSelector(organizations.useDeletionError)
  const deletionErrorText = useSelector(organizations.useDeletionErrorMessage)

  const handleDelete = (id: number) => {
    setDeletionId(id)
  }

  useEffect(() => {
    if (deletionSucceed) {
      dispatch(organizations.actions.resetDeletion())
      dispatch(
        organizations.actions.filters({
          'user_id': userId
        })
      )
      openNotification('Фильтр удален', 'success')
    }
    if (deletionError) {
      openNotification(deletionErrorText, 'error')
      dispatch(organizations.actions.resetDeletion())
    }
  }, [dispatch, openNotification, deletionSucceed, deletionError, deletionErrorText, userId])

  const [deletionId, setDeletionId] = useState<number | undefined>()

  const handleDeletionAccept = useCallback((id: number) => {
    dispatch(organizations.actions.remove({ userId, id }))
    setDeletionId(undefined)
  }, [setDeletionId, userId, dispatch])

  const handleDeletionCancel = useCallback(() => {
    setDeletionId(undefined)
  }, [setDeletionId])



  const downloadFiles = (searchingBy: string) => {
    if (searchingBy === 'обычный поиск') {
      downloadAll({
        flagDownload: 'isExists',
        searchParam: itemsFilter.searchParam
      })
        .then((response) => {
          const headerContent = response.headers['content-disposition']
          const filename = decodeURI(headerContent.slice(headerContent.lastIndexOf('=') + 1))
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => openNotification(
          'Ошибка загрузки',
          'error'
        ))
    }
    if (searchingBy === 'расширенный поиск') {
      downloadAll({
        flagDownload: 'searchWithParams',
        params: { ...searchFilter }
      })
        .then((response) => {
          const headerContent = response.headers['content-disposition']
          const filename = decodeURI(headerContent.slice(headerContent.lastIndexOf('=') + 1))
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => openNotification(
          'Ошибка загрузки',
          'error'
        ))
    }
  }

  return (
    <>
      <Dashboards />
      <DeleteConfirmationModal
        id={deletionId}
        onAccept={handleDeletionAccept}
        onCancel={handleDeletionCancel}
      />
      {items && !flag &&
        <OrganizationTable
          loading={itemsLoading || deletionLoading}
          rows={items as unknown as OrganizationTableProps['rows']}
          total={itemsTotal}
          page={itemsFilter.page || PAGE_DEFAULT}
          pageSize={itemsFilter.size || PAGE_SIZE_DEFAULT}
          sortBy={itemsFilter.sortField}
          sortDir={itemsFilter.direction}
          onPageChange={handleTablePageChange}
          onPageSizeChange={handleTablePageSizeChange}
          onSort={handleTableSort}
          onSearch={handleTableSearch}
          onFiltersChange={handleTableFiltersChange}
          onAddToComparison={handleTableAddToComparison}
          onSave={handleTableSaveToReports}
          setFlag={setFlag}
          flag={flag}
          downloadFiles={() => downloadFiles('обычный поиск')}
          resetBtn={
            itemsFilter.searchParam !== 'belstat-code' && (
              <button
                className={styles.reset__btn}
                type='button'
                onClick={handleTableResetSearch}
              >
                Сбросить результаты поиска
              </button>
            )
          }
        >
          <Chips
            filters={filters}
            handleDelete={handleDelete}
            handleSearchFilters={handleSearchFilters}
          />
          <CustomBreadcrumbs currentPageName='Кабинет пользователя' />
        </OrganizationTable>
      }
      {flag &&
        <OrganizationTable
          loading={searchLoading || deletionLoading}
          rows={searchItems as unknown as OrganizationTableProps['rows']}
          total={searchTotal}
          page={searchFilter.page || PAGE_DEFAULT}
          pageSize={searchFilter.size || PAGE_SIZE_DEFAULT}
          sortBy={searchFilter.sortField}
          sortDir={searchFilter.direction}
          onFiltersChange={handleTableFiltersChange}
          onPageChange={handleSearchTablePageChange}
          onPageSizeChange={handleSearchTablePageSizeChange}
          onSort={handleSearchTableSort}
          onSearch={handleTableSearch}
          onAddToComparison={handleTableAddToComparison}
          onSave={handleTableSaveToReports}
          setFlag={setFlag}
          flag={flag}
          downloadFiles={() => downloadFiles('расширенный поиск')}
          resetBtn={
            (
              <button
                className={styles.reset__btn}
                type='button'
                onClick={handleTableResetSearch}
              >
                Сбросить результаты поиска
              </button>
            )
          }
        >
          <Chips
            filters={filters}
            handleDelete={handleDelete}
            handleSearchFilters={handleSearchFilters}
          />
          <CustomBreadcrumbs currentPageName='Кабинет пользователя' />
        </OrganizationTable>
      }
      {searchLoading && <Loader />}
      <Notification {...notificationData} />
    </>
  )
}

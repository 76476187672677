import { Button } from '@mui/material'
import ru from 'date-fns/locale/ru'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form'

import { AuditFormProps, SearchAuditFormProps } from './SearchAuditForm.props'
import styles from './styles.module.css'

export const SearchAuditForm = ({ onFilter }: AuditFormProps) => {
  const {
    formState: { isDirty },
    control,
    register,
    reset,
    getValues,
    handleSubmit,
  } = useForm<SearchAuditFormProps>({
    defaultValues: {
      startDate: undefined,
      endDate: undefined,
      executorFio: '',
    },
  })

  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  // change date
  const handleChangeDate = (
    callback: Function,
    date: Date | null,
    type: 'start' | 'end'
  ) => {
    type === 'start' ? setStartDate(date) : setEndDate(date)
    callback(date)
  }

  // submit
  const submitHandler: SubmitHandler<SearchAuditFormProps> = ({
    executorFio,
  }) => {
    onFilter({
      executorFio,
      startDate,
      endDate,
    })
  }

  // reset
  const resetHandler = () => {
    reset()
    onFilter({
      startDate: undefined,
      endDate: undefined,
      executorFio: '',
    })
  }

  return (
    <div>
      <div className={styles.header}>
        <span>Найти действие</span>
      </div>
      <FormProvider {...useForm()}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className={styles.form}>
            <div className={styles.wrapperInput}>
              <span>Дата/время</span>
              <div className={styles.wrapperDate}>
                <Controller
                  control={control}
                  name='startDate'
                  render={({ field }) => (
                    <DatePicker
                      className={styles.datepicker}
                      onChange={(date) =>
                        handleChangeDate(field.onChange, date, 'start')
                      }
                      selected={field.value}
                      endDate={getValues('endDate')}
                      startDate={getValues('startDate')}
                      selectsStart
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                      maxDate={getValues('endDate') || new Date()}
                      locale={ru}
                      dateFormat='dd.MM.yyyy HH:mm'
                      popperPlacement='bottom'
                      placeholderText='c'
                      showTimeInput
                      timeInputLabel='Время с:'
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='endDate'
                  render={({ field }) => (
                    <DatePicker
                      className={styles.datepicker}
                      onChange={(date) =>
                        handleChangeDate(field.onChange, date, 'end')
                      }
                      selected={field.value}
                      endDate={getValues('endDate')}
                      selectsEnd
                      startDate={getValues('startDate')}
                      minDate={getValues('startDate')}
                      maxDate={new Date()}
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                      locale={ru}
                      dateFormat='dd.MM.yyyy  HH:mm'
                      popperPlacement='bottom'
                      placeholderText='по'
                      showTimeInput
                      timeInputLabel='Время по:'
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles.wrapperInput}>
              <span>ФИО пользователя</span>
              <input
                {...register('executorFio')}
                placeholder='Введите ФИО пользователя'
              />
            </div>
          </div>
          <div className={styles.wrapperBtns}>
            <Button type='submit' variant='contained' disabled={!isDirty}>
              Начать поиск
            </Button>
            <Button variant='outlined' onClick={resetHandler}>
              Сброс параметров поиска
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

import { getInitialAsyncState } from 'modules/base'

import { OrganizationSchema } from './organizations.schema'

export const initialState: OrganizationSchema = {
  openOrganizationFilter: false,
  openOrganizationFilterModal: false,
  dataOrganizationFilter: {},
  listFilter: {
    searchParam: 'belstat-code',
    page: 1,
    size: 20,
    sortField: 'unp',
    direction: 'ASC'
  },
  list: getInitialAsyncState({
    number: 0,
    totalPages: 0,
    totalElements: 0,
    content: [],
  }),
  subsidiariesListFilter: {
    searchParam: '',
    page: 1,
    size: 20,
    sortField: 'unp',
    direction: 'ASC'
  },
  listSubsidiaries: getInitialAsyncState({
    number: 0,
    totalPages: 0,
    totalElements: 0,
    content: [],
  }),
  organization: getInitialAsyncState(null),
  detailedSearchListFilter: {
    page: 1,
    size: 20,
    sortField: 'unp',
    direction: 'ASC'
  },
  detailedSearch: getInitialAsyncState({
    number: 0,
    totalPages: 0,
    totalElements: 0,
    content: [],
  }),
  directories: getInitialAsyncState(null),
  filters: getInitialAsyncState(null),
  deletion: getInitialAsyncState(null),
  creation: getInitialAsyncState(null)
}

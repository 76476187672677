import MuiAccordion from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'
 
// eslint-disable-next-line
 export const Accordion = styled((props: any) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))


import { Modal } from '@mui/material'
import { FC } from 'react'


import { Container } from 'components/Container'
import { CloseIcon } from 'icons'

import { IManageRole } from '../ManageRoleTable/ManageRoleTable.props'

import { EditAccountForm } from './EditAccountForm'
import styles from './styles.module.css'

interface IEditAccountFormFields {
  name: string;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
}

interface CustomEditModalProps {
  mode: 'create' | 'update';
  defaultValues?: IEditAccountFormFields;
  modalIsOpened: boolean;
  closeModal: () => void;
  record?: IManageRole;
}

export const CustomEditModal: FC<CustomEditModalProps> = ({
  mode,
  defaultValues,
  modalIsOpened,
  closeModal,
  record
}) => {
  return (
    <Modal open={modalIsOpened}>
      <Container>
        <div className={styles.modal__wrapper}>
          <div className={styles.modal}>
            <div className={styles.modal__header}>
              <h2>{mode === 'create' ? 'ДОБАВЛЕНИЕ РОЛИ' : 'ПРОСМОТР И РЕДАКТИРОВАНИЕ РОЛИ'}</h2>
              <button className={styles.modal__closeBtn} onClick={closeModal}>
                <CloseIcon />
              </button>
            </div>
            <EditAccountForm mode={mode} defaultValues={defaultValues!} onClose={closeModal} record={record}/>
          </div>
        </div>
      </Container>
    </Modal>
  )
}

import { AxiosError } from 'axios'
import { spawn, takeEvery, call, put, select } from 'redux-saga/effects'

import { Base, AuditList, audit } from 'api'
import { convertDateForAudit } from 'utils/convertDateForAudit'

import { UpdateListFilter } from './audit.action'
import * as creator from './audit.creator'
import * as selector from './audit.selector'

function* updateListFilterWorker() {
  let errorMessage = undefined

  try {
    const filter: ReturnType<typeof selector.useListFilter> = yield select(
      selector.useListFilter
    )

    yield put(creator.getList())

    const payload: Base.AuditQuery = {}

    if (filter.startDate) {
      const newStartDate = convertDateForAudit(filter.startDate as Date)
      payload.startDate = newStartDate
    }
    if (filter.endDate) {
      const newEndDate = convertDateForAudit(filter.endDate as Date)
      payload.endDate = newEndDate
    }
    if (filter.executorFio !== '') {
      payload.executorFio = filter.executorFio
    }

    const {
      data: { data, meta, errorCode },
    }: AuditList.Response = yield call(audit.list, {
      page: filter.page,
      size: filter.size,
      direction: filter.direction,
      sortField: filter.sortField,
      ...payload,
    })

    if (data) {
      yield put(creator.getListSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getListError(errorMessage))
    }
  }
}

function* updateListFilterWatcher() {
  yield takeEvery<UpdateListFilter>(
    'audit.update_list_filter' as UpdateListFilter['type'],
    updateListFilterWorker
  )
}

export function* saga() {
  yield spawn(updateListFilterWatcher)
}

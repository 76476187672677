import { Base } from 'api/base'

import { ManageRole, ManageRoleQuery, TRoleBody } from './manageRole.model'

export namespace ManageRoleList {
    export type SortField = keyof ManageRole
    export type Query = Partial<Base.PaginatedQueryAgsr>
    export type DataItem = ManageRole
    export type Data = Base.PaginatedData<DataItem>
    export type Response = Base.PaginatedResponseAgsr<DataItem>

    
}

export namespace ManageRoleCreate {
    export type Body = ManageRole
    export type Data = ManageRole
    export type Response = Base.ResponseAgsrData<Data>
}

export namespace ManageRoleUpdate {
    export type Query = ManageRoleQuery
    export type Body = TRoleBody
    export type Data = ManageRole
    export type Response = Base.ResponseAgsr<Data>
}

export namespace ManageRoleRemove {
    export type Query = Pick<ManageRole, 'name'>
    export type Data = null
    export type Response = Base.ResponseAgsrData<Data>
}
import { getInitialAsyncState } from 'modules/base'

import { ReferenceSchema } from './reference.schema'

export const initialState: ReferenceSchema = {
  listFilter: {
    page: 1,
    size: 20,
    sortField: 'unp',
    direction: 'ASC',
  },
  list: getInitialAsyncState({
    number: 0,
    totalPages: 0,
    totalElements: 0,
    content: [],
  }),
  creation: getInitialAsyncState(null),
  updation: getInitialAsyncState(null),
  deletion: getInitialAsyncState(null),
  importion: getInitialAsyncState(null),
}

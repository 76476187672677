import { apiBase } from 'api/base'
import { convertToFormData } from 'utils'

import { SUBPATH } from './reference.const'
import {
  ReferenceList,
  ReferenceCreate,
  ReferenceUpdate,
  ReferenceRemove,
  ReferenceImportSource,
} from './reference.interface'

export const list = (params: ReferenceList.Query) =>
  apiBase.get<ReferenceList.Data>(SUBPATH, { params })

export const create = (body: ReferenceCreate.Body) =>
  apiBase.post<ReferenceCreate.Data>(SUBPATH, body)

export const update = ({ id, ...body }: ReferenceUpdate.Body) =>
  apiBase.put<ReferenceUpdate.Data>(`${SUBPATH}/${id}`, body)

export const remove = ({ id }: ReferenceRemove.Query) =>
  apiBase.delete<ReferenceRemove.Data>(`${SUBPATH}/${id}`)

export const importSource = (body: ReferenceImportSource.Body) =>
  apiBase.post<ReferenceImportSource.Data>(
    `${SUBPATH}/import`,
    convertToFormData(body)
  )

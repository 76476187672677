/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Notification } from 'components/Notification'
import { useNotification } from 'hooks/useNotification'
import { auth } from 'modules'
import { ROUTE_PATH } from 'routes'
import { AuthModal } from 'views/Auth'

class MetadataUserWork {
  unp: string = ''
  name: string = ''
  unitName: string = ''
  position: string = ''

  copy(copy: MetadataUserWork): MetadataUserWork {
    if (!copy) {
      copy = new MetadataUserWork()
    }
    this.unp = copy.unp
    this.name = copy.name
    this.unitName = copy.unitName
    this.position = copy.position
    return this
  }
}

class MetadataUser {
  id: string = ''
  username: string = ''
  enabled: boolean = false
  fio: string = ''
  personalNumber: string = ''
  email: string = ''
  emailVerified: boolean = false
  phone: string = ''
  work: MetadataUserWork = new MetadataUserWork()

  copy(copy: MetadataUser): MetadataUser {
    if (!copy) {
      copy = new MetadataUser()
    }
    this.id = copy.id
    this.username = copy.username
    this.enabled = copy.enabled
    this.fio = copy.fio
    this.personalNumber = copy.personalNumber
    this.email = copy.email
    this.emailVerified = copy.emailVerified
    this.phone = copy.phone
    this.work.copy(copy.work)
    return this
  }
}

export class MetadataLoginCallbackRs {
  user: MetadataUser = new MetadataUser()
  idToken: string = ''
  access_token: string = ''

  copy(copy: MetadataLoginCallbackRs): MetadataLoginCallbackRs {
    if (!copy) {
      copy = new MetadataLoginCallbackRs()
    }
    this.user.copy(copy.user)
    this.idToken = copy.idToken
    this.access_token = copy.access_token
    return this
  }

  reset() {
    this.copy(new MetadataLoginCallbackRs())
  }

  save() {
    localStorage.user_data = JSON.stringify(this)
  }

  restore() {
    this.copy(
      localStorage.user_data
        ? JSON.parse(localStorage.user_data)
        : new MetadataLoginCallbackRs()
    )
  }
}

export const metadataLoginCallbackRs: MetadataLoginCallbackRs =
  new MetadataLoginCallbackRs()

const loginRedirect = async (): Promise<any> => {
  if (!(('' + window.location.href).indexOf('?data=') >= 0)) {
    return false
  }

  const searchParams = new URLSearchParams(window.location.search)
  const data = searchParams.get('data')

  const response03: Response = await fetch(`${window.env.REACT_APP_AUTH_URL}/login_callback`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      data: data,
      clientId: window.env.REACT_APP_CLIENT_ID,
      settings: {
        client_id: window.env.REACT_APP_SETTINGS_ID,
        scope: window.env.REACT_APP_SCOPE,
        post_logout_redirect_uri: window.env.REACT_APP_LOGOUT_REDIRECT,
        redirect_uri: window.env.REACT_APP_LOGOUT_REDIRECT,
      },
    }),
    credentials: 'include',
  })
  const json03: {
    user: MetadataLoginCallbackRs;
    idToken: string;
    access_token: string;
    refresh_token: string;
  } = await response03.json()

  if (!response03.ok) {
    if (response03?.body?.locked) {
      alert('Пользователь заблокирован!')
    } else {
      alert('Ошибка авторизации!')
    }
  }

  if (json03.access_token) {
    localStorage.setItem('access_token', json03.access_token)
    localStorage.setItem('refresh_token', json03.refresh_token)
    localStorage.setItem('idToken', json03.idToken)
    localStorage.setItem('user', JSON.stringify(json03.user))
    window.location.href = '/'
  }

  if (window.env.REACT_APP_LOGOUT_REDIRECT) {
    window.location.href = window.env.REACT_APP_LOGOUT_REDIRECT
  }
  return true
}

const initializePage = async () => {
  const flagRedirect: boolean = await loginRedirect()
  if (!flagRedirect) {
    metadataLoginCallbackRs.restore()
  }
}

initializePage()

export function Auth() {
  const { openNotification, notificationData } = useNotification()
  const [step, setStep] = useState(1)
  const dispatch = useDispatch()

  const loginStart = async () => {
    const response01: Response = await fetch(`${window.env.REACT_APP_AUTH_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        clientId: window.env.REACT_APP_CLIENT_ID,
        settings: {
          client_id: window.env.REACT_APP_SETTINGS_ID,
          scope: window.env.REACT_APP_SCOPE,
          post_logout_redirect_uri: window.env.REACT_APP_LOGOUT_REDIRECT,
          redirect_uri: window.env.REACT_APP_LOGOUT_REDIRECT,
        },
      }),
      credentials: 'include',
    })

    const json01: {
      signed_data_to_check_in_cp: string;
      enveloped_and_signed_auth_url: string;
    } = await response01.json()

    if (!json01.signed_data_to_check_in_cp) {
      openNotification('Операция завершена с ошибкой!', 'error')
      return
    }
    const response02: void | Response = await fetch(
      'http://127.0.0.1:8084/select_auth',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          data: json01.signed_data_to_check_in_cp,
        }),
      }
    ).catch(() => {
      openNotification(
        'У вас не запущена клиентская программа "NTClientSoftware"!',
        'error'
      )
    })
    if (response02?.ok) {
      window.location.href = json01.enveloped_and_signed_auth_url
    }
  }

  const sendOtpToEmail = async (emailValue: string) => {
    const response01: Response = await fetch(
      `${window.env.REACT_APP_AUTH_URL}/otp`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          clientId: 'own-main-web-client',
          email: emailValue,
        }),
      }
    )
    if (response01.status === 401) {
      openNotification('Ошибка авторизации', 'error')
    } else if (response01.status === 404) {
      openNotification('E-mail не найден', 'error')
    } else if (!response01.ok) {
      openNotification('Ошибка', 'error')
    } else {
      setStep(2)
      openNotification('Временный пароль отправлен на ваш e-mail', 'success')
    }
  }

  const loginWithOtp = async (emailValue: string, otpValue: string) => {
    const response01: Response = await fetch(`${window.env.REACT_APP_AUTH_URL}/otp/validate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        clientId: 'own-main-web-client',
        email: emailValue,
        otpNum: otpValue,
      }),
    })
    const json01: {
      user: MetadataLoginCallbackRs;
      idToken: string;
      access_token: string;
      refresh_token: string;
    } = await response01.json()
    if (json01.access_token) {
      localStorage.setItem('access_token', json01.access_token)
      localStorage.setItem('refresh_token', json01.refresh_token)
      localStorage.setItem('idToken', json01.idToken)
      localStorage.setItem('user', JSON.stringify(json01.user))
      dispatch(auth.actions.loginSuccess(json01.user))
      window.location.href = ROUTE_PATH.home
    } else {
      openNotification('Ошибка! Неверный временный пароль', 'error')
    }
  }

  return (
    <div>
      <AuthModal
        step={step}
        loginStart={loginStart}
        sendOtpToEmail={sendOtpToEmail}
        loginWithOtp={loginWithOtp}
      />
      <Notification {...notificationData} />
    </div>
  )
}

import React, { FC } from 'react'

import style from '../InfoFormOrganization.module.css'
import { PropsData } from '../types'


export const Parts: FC<PropsData> = ({ data, mode }) => {
  const info = data.branchesInfo

  return (
    <div className={style.containerComponent}>
      <p className={style.generalName}>Филиалы и подразделения</p>
      {info.map((e, i) => (
        <React.Fragment key={i}>
          {e.branchName ? <p className={style.headline}>{e.branchName}</p> : null}
          {e.branchUnp ? <p className={style.about}>УНП {e.branchUnp}</p> : null}
          {mode === 'full' ?
            <>
              {e.branchRegDate ? <p className={style.about} style={{ marginBottom: 8, marginTop: '-8px' }}>Дата регистрации филиала {e.branchRegDate.split('-').reverse().join('.')}</p> : null}
              {e.branchLiquidationDate ? <p className={style.about}>Дата ликвидации филиала {e.branchLiquidationDate.split('-').reverse().join('.')}</p> : null}
            </>
            : null}
        </React.Fragment>
      ))}
    </div>
  )
}

import { Grid } from '@mui/material'
import ru from 'date-fns/locale/ru'
import { FC, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { dashboards } from 'modules'

import {
  END_DATE_DEFAULT,
  MIN_START_DATE,
  START_DATE_DEFAULT,
} from './Dashboards.const'
import styles from './styles.module.css'

export const Dashboards: FC = () => {
  const dispatch = useDispatch()

  const items = useSelector(dashboards.useGet)
  const filters = useSelector(dashboards.useFilter)

  const filterStartDate = filters.startDate
  const filterEndDate = filters.endDate

  useEffect(() => {
    dispatch(
      dashboards.actions.updateFilter({
        startDate: filters.startDate !== START_DATE_DEFAULT ? filters.startDate : START_DATE_DEFAULT,
        endDate: filters.endDate !== END_DATE_DEFAULT ? filters.endDate : END_DATE_DEFAULT,
      })
    )
  }, [dispatch, filters.startDate, filters.endDate])

  const updateFilterHandler = ({
    startDate,
    endDate,
  }: {
    startDate?: Date;
    endDate?: Date;
  }) => {
    dispatch(
      dashboards.actions.updateFilter({
        startDate: startDate ? startDate : filters.startDate,
        endDate: endDate ? endDate : filters.endDate,
      })
    )
  }

  return (
    <section className={styles.section}>
      <Grid container spacing='30px'>
        <Grid item>
          <div className={styles.card}>
            <h3 className={styles.card__title}>Общее количество госактивов</h3>
            <div className={styles['card__value-wrapper']}>
              {items?.stateAssetsNum.startNum !== 0 ?
                <NavLink
                  to={'/user-space-dashboard/SSD'}
                  style={{ textDecorationColor: '#005F78' }}
                >
                  <span className={styles.card__value}>
                    {items?.stateAssetsNum.startNum}
                  </span>
                </NavLink>
                :
                <span className={styles.card__value}>
                  {items?.stateAssetsNum.startNum}
                </span>
              }
              <span className={styles.card__value}>
                <div className={styles.block}>
                  {items?.stateAssetsNum.elevated !== 0 ?
                    <NavLink
                      to={'/user-space-dashboard/SE'}
                      className={styles.block}
                      style={{ textDecorationColor: '#05BF01' }}
                    >
                      <sup className={styles.card__increment}>
                        +{items?.stateAssetsNum.elevated}
                      </sup>
                    </NavLink>
                    :
                    <sup className={styles.card__increment}>
                      +{items?.stateAssetsNum.elevated}
                    </sup>}

                  {items?.stateAssetsNum.reduced !== 0 ?
                    <NavLink
                      to={'/user-space-dashboard/SR'}
                      className={styles.block}
                      style={{ textDecorationColor: '#F21111' }}
                    >
                      <sub className={styles.card__decrement}>
                        -{items?.stateAssetsNum.reduced}
                      </sub>
                    </NavLink>
                    :
                    <sub className={styles.card__decrement}>
                      -{items?.stateAssetsNum.reduced}
                    </sub>}
                </div>
              </span>
              {items?.stateAssetsNum.endNum !== 0 ?
                <NavLink
                  to={'/user-space-dashboard/SED'}
                  style={{ textDecorationColor: '#005F78' }}
                >
                  <span className={styles.card__value}>
                    {items?.stateAssetsNum.endNum}
                  </span>
                </NavLink>
                :
                <span className={styles.card__value}>
                  {items?.stateAssetsNum.endNum}
                </span>
              }
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className={styles.card}>
            <h3 className={styles.card__title}>
              Количество госактивов в стадии ликвидации
            </h3>
            <div className={styles['card__value-wrapper']}>
              {items?.liquidationStage.startNum !== 0 ?
                <div className={styles.card__value}>
                  <NavLink
                    to={'/user-space-dashboard/LSD'}
                    style={{ textDecorationColor: '#005F78' }}
                  >
                    <span className={styles.card__value}>
                      {items?.liquidationStage.startNum}
                    </span>
                  </NavLink>
                  <sup className={styles.card__deviation}>
                    {items?.liquidationStage.startPercent}
                  </sup>
                </div>
                :
                <span className={styles.card__value}>
                  {items?.liquidationStage.startNum}
                  <sup className={styles.card__deviation}>
                    {items?.liquidationStage.startPercent}
                  </sup>
                </span>
              }
              <span className={styles.card__value}>
                <div className={styles.block}>
                  {items?.liquidationStage.elevated !== 0 ?
                    <NavLink
                      to={'/user-space-dashboard/LE'}
                      style={{ textDecorationColor: '#05BF01' }}
                      className={styles.block}
                    >
                      <sup className={styles.card__increment}>
                        +{items?.liquidationStage.elevated}
                      </sup>
                    </NavLink>
                    :
                    <sup className={styles.card__increment}>
                      +{items?.liquidationStage.elevated}
                    </sup>}
                  {items?.liquidationStage.reduced !== 0 ?
                    <NavLink
                      to={'/user-space-dashboard/LR'}
                      style={{ textDecorationColor: '#F21111' }}
                      className={styles.block}
                    >
                      <sub className={styles.card__decrement}>
                        -{items?.liquidationStage.reduced}
                      </sub>
                    </NavLink>
                    :
                    <sub className={styles.card__decrement}>
                      -{items?.liquidationStage.reduced}
                    </sub>}
                </div>
              </span>
              {items?.liquidationStage.endNum !== 0 ?
                <div className={styles.card__value}>
                  <NavLink
                    to={'/user-space-dashboard/LED'}
                    style={{ textDecorationColor: '#005F78' }}
                  >
                    <span className={styles.card__value}>
                      {items?.liquidationStage.endNum}
                    </span>
                  </NavLink>
                  <sup className={styles.card__deviation}>
                    {items?.liquidationStage.endPercent}
                  </sup>
                </div>
                :
                <span className={styles.card__value}>
                  {items?.liquidationStage.endNum}
                  <sup className={styles.card__deviation}>
                    {items?.liquidationStage.endPercent}
                  </sup>
                </span>
              }
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className={styles.card}>
            <h3 className={styles.card__title}>
              Количество госактивов (контрольный пакет акций)
            </h3>
            <div className={styles['card__value-wrapper']}>
              {items?.controllingStake.startNum !== 0 ?
                <div className={styles.card__value}>
                  <NavLink
                    to={'/user-space-dashboard/CSSD'}
                    style={{ textDecorationColor: '#005F78' }}
                  >
                    <span className={styles.card__value}>
                      {items?.controllingStake.startNum}
                    </span>
                  </NavLink>
                  <sup className={styles.card__deviation}>
                    {items?.controllingStake.startPercent}
                  </sup>
                </div>
                :
                <span className={styles.card__value}>
                  {items?.controllingStake.startNum}
                  <sup className={styles.card__deviation}>
                    {items?.controllingStake.startPercent}
                  </sup>
                </span>
              }
              <span className={styles.card__value}>
                <div className={styles.block}>
                  {items?.controllingStake.elevated !== 0 ?
                    <NavLink
                      to={'/user-space-dashboard/CSE'}
                      style={{ textDecorationColor: '#05BF01' }}
                      className={styles.block}
                    >
                      <sup className={styles.card__increment}>
                        +{items?.controllingStake.elevated}
                      </sup>
                    </NavLink>
                    :
                    <sup className={styles.card__increment}>
                      +{items?.controllingStake.elevated}
                    </sup>}
                  {items?.controllingStake.reduced !== 0 ?
                    <NavLink
                      to={'/user-space-dashboard/CSR'}
                      style={{ textDecorationColor: '#F21111' }}
                      className={styles.block}
                    >
                      <sub className={styles.card__decrement}>
                        -{items?.controllingStake.reduced}
                      </sub>
                    </NavLink>
                    :
                    <sub className={styles.card__decrement}>
                      -{items?.controllingStake.reduced}
                    </sub>}
                </div>
              </span>
              {items?.controllingStake.endNum !== 0 ?
                <div className={styles.card__value}>
                  <NavLink
                    to={'/user-space-dashboard/CSED'}
                    style={{ textDecorationColor: '#005F78' }}
                  >
                    <span className={styles.card__value}>
                      {items?.controllingStake.endNum}
                    </span>
                  </NavLink>
                  <sup className={styles.card__deviation}>
                    {items?.controllingStake.endPercent}
                  </sup>
                </div>
                :
                <span className={styles.card__value}>
                  {items?.controllingStake.endNum}
                  <sup className={styles.card__deviation}>
                    {items?.controllingStake.endPercent}
                  </sup>
                </span>
              }
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={styles.datepicker}>
        <h3 className={styles.datepicker__headline}>Выберите дату</h3>
        <div className={styles['datepicker__btns-group']}>
          <DatePicker
            className={styles.datepicker__btn}
            onChange={(date) => updateFilterHandler({ startDate: date! })}
            startDate={filters.startDate}
            endDate={filters.endDate}
            minDate={MIN_START_DATE}
            maxDate={filters.endDate}
            locale={ru}
            dateFormat='dd.MM.yyyy'
            popperPlacement='bottom'
            // @ts-ignore
            value={new Date(filterStartDate)}
            openToDate={filters.startDate}
            selected={new Date(filterStartDate) || ''}
          />
          <DatePicker
            className={styles.datepicker__btn}
            onChange={(date) => updateFilterHandler({ endDate: date! })}
            selectsEnd
            startDate={filters.startDate}
            endDate={filters.endDate}
            minDate={filters.startDate}
            maxDate={END_DATE_DEFAULT}
            locale={ru}
            dateFormat='dd.MM.yyyy'
            popperPlacement='bottom'
            // @ts-ignore
            value={new Date(filterEndDate)}
            openToDate={filters.endDate}
            selected={new Date(filterEndDate) || ''}
          />
        </div>
      </div>
    </section>
  )
}

import AddIcon from '@mui/icons-material/Add'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'
import EditOffIcon from '@mui/icons-material/EditOff'
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import RemoveIcon from '@mui/icons-material/Remove'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { toPng } from 'html-to-image'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  ControlButton,
  Controls,
  getRectOfNodes,
  getTransformForBounds,
  useReactFlow,
} from 'reactflow'

import { graph } from 'modules'
import { CustomControlsProps } from 'pages/NewGraphPage/NewGraphPage.props'

import { Legend } from '../Legend/Legend'

function downloadImage(dataUrl: string) {
  const a = document.createElement('a')

  a.setAttribute('download', 'reactflow.png')
  a.setAttribute('href', dataUrl)
  a.click()
}

const imageWidth = 10000
const imageHeight = 10000

export const CustomControls = ({
  draggable,
  fullscreen,
  onChangeDraggability,
  onChangeFullscreen,
}: CustomControlsProps) => {
  // position
  const { zoomIn, zoomOut, fitView } = useReactFlow()

  const zoomInHandler = () => zoomIn({ duration: 1000 })

  const zoomOutHandler = () => zoomOut({ duration: 1000 })

  const fitViewHandler = () => fitView({ duration: 1000 })

  // download
  const { getNodes } = useReactFlow()

  const downloadHandler = () => {
    const nodesBounds = getRectOfNodes(getNodes())
    const transform = getTransformForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0,
      10
    )

    toPng(document.querySelector('.react-flow__viewport') as HTMLElement, {
      backgroundColor: 'white',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth.toString(),
        height: imageHeight.toString(),
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(downloadImage)
  }

  // reset
  const dispatch = useDispatch()
  const { unp } = useParams()

  const resetHandler = () => {
    dispatch(graph.actions.getGraph({ unp: unp! }))
  }

  // legend
  const [legendIsOpened, setLegendIsOpened] = useState<boolean>(false)

  const toggleLegendIsOpened = () =>
    setLegendIsOpened((currState) => !currState)

  return (
    <>
      <Controls showFitView={false} showInteractive={false} showZoom={false}>
        <ControlButton title={fullscreen? 'Свернуть' : 'Развернуть на весь экран'} onClick={onChangeFullscreen}>
          {fullscreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
        </ControlButton>
        <ControlButton title='Увеличить' onClick={zoomInHandler}>
          <AddIcon />
        </ControlButton>
        <ControlButton title='Уменьшить' onClick={zoomOutHandler}>
          <RemoveIcon />
        </ControlButton>
        <ControlButton title='Центрировать' onClick={fitViewHandler}>
          <FilterCenterFocusIcon />
        </ControlButton>
        <ControlButton
          title={`${
            draggable ? 'Заблокировать' : 'Разблокировать'
          } возможность перемещения блоков`}
          onClick={onChangeDraggability}
        >
          {draggable ? <EditOffIcon /> : <EditIcon />}
        </ControlButton>
        <ControlButton title='Сброс' onClick={resetHandler}>
          <RestartAltIcon />
        </ControlButton>
        <ControlButton title='Скачать граф' onClick={downloadHandler}>
          <DownloadIcon />
        </ControlButton>
        <ControlButton title='Открыть легенду' onClick={toggleLegendIsOpened}>
          <HelpOutlineIcon />
        </ControlButton>
      </Controls>
      {legendIsOpened && <Legend onClose={toggleLegendIsOpened} />}
    </>
  )
}

import { GraphAction } from './graph.action'
import { GraphSchema } from './graph.schema'
import { initialState } from './graph.state'

export const reducer = (
  state = initialState,
  action: GraphAction | void = undefined
): GraphSchema => {
  switch (action?.type) {
    case 'graph.get_graph': {
      return {
        ...state,
        graph: {
          ...state.graph,
          meta: {
            ...state.graph.meta,
            status: 'loading',
          },
          data: initialState.graph.data,
        },
      }
    }
    case 'graph.get_graph.success': {
      return {
        ...state,
        graph: {
          ...state.graph,
          meta: {
            ...initialState.graph.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'graph.get_graph.error': {
      return {
        ...state,
        graph: {
          ...state.graph,
          meta: {
            ...state.graph.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'graph.reset_get_graph': {
      return {
        ...state,
        graph: {
          ...initialState.graph,
        },
      }
    }
    default:
      return state
  }
}

import { getInitialAsyncState } from 'modules/base'

import { ComparisonSchema } from './comparison.schema'

export const initialState: ComparisonSchema = {
  comparisonListFilter: {
    userId: undefined,
    page: 1,
    size: 20,
    sortField: 'unp',
    direction: 'ASC',
  },
  comparisonList: getInitialAsyncState({
    number: 0,
    totalPages: 0,
    totalElements: 0,
    content: [],
  }),
  addToComparisonList: getInitialAsyncState(null),
  removeFromComparisonList: getInitialAsyncState(null),
}

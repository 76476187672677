import { apiBaseAgsr } from 'api/base'

import { SUBPATH } from './manageRole.const'
import {
  ManageRoleCreate,
  ManageRoleList,
  ManageRoleUpdate,
  ManageRoleRemove,
} from './manageRole.interface'
import { TManageRoleRequest } from './manageRole.model'

export const getManageRoleList = (
  params: ManageRoleList.Query
) => apiBaseAgsr.get<ManageRoleList.Data>(SUBPATH, { params })

export const createManageRole = (
  body: ManageRoleCreate.Body
) => apiBaseAgsr.post<ManageRoleCreate.Data>(SUBPATH, body)

export const updateManageRole = ({
  name,
  body
}: TManageRoleRequest) =>
  apiBaseAgsr.put<ManageRoleUpdate.Data>(`${SUBPATH}/${name}`, body)

export const removeManageRole = ({
  name,
}: ManageRoleRemove.Query) =>
  apiBaseAgsr.delete<ManageRoleRemove.Data>(`${SUBPATH}/${name}`)

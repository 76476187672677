import { ComparisonAction } from './comparison.action'
import { ComparisonSchema } from './comparison.schema'
import { initialState } from './comparison.state'

export const reducer = (
  state = initialState,
  action: ComparisonAction | void = undefined
): ComparisonSchema => {
  switch (action?.type) {
    // filter
    case 'comparison.update_list_filter': {
      return {
        ...state,
        comparisonListFilter: {
          ...state.comparisonListFilter,
          ...action.payload,
        },
      }
    }
    
    // list
    case 'comparison.get_list': {
      return {
        ...state,
        comparisonList: {
          ...state.comparisonList,
          meta: {
            ...state.comparisonList.meta,
            status: 'loading',
          },
          data: initialState.comparisonList.data,
        },
      }
    }
    case 'comparison.get_list.success': {
      return {
        ...state,
        comparisonList: {
          ...state.comparisonList,
          meta: {
            ...initialState.comparisonList.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'comparison.get_list.error': {
      return {
        ...state,
        comparisonList: {
          ...state.comparisonList,
          meta: {
            ...state.comparisonList.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'comparison.reset_get_list': {
      return {
        ...state,
        comparisonList: {
          ...initialState.comparisonList,
        },
      }
    }

    // add
    case 'comparison.add_to_list': {
      return {
        ...state,
        addToComparisonList: {
          ...state.addToComparisonList,
          meta: {
            ...state.addToComparisonList.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'comparison.add_to_list.success': {
      return {
        ...state,
        addToComparisonList: {
          ...state.addToComparisonList,
          meta: {
            ...initialState.addToComparisonList.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'comparison.add_to_list.error': {
      return {
        ...state,
        addToComparisonList: {
          ...state.addToComparisonList,
          meta: {
            ...state.addToComparisonList.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'comparison.reset_add_to_list': {
      return {
        ...state,
        addToComparisonList: {
          ...initialState.addToComparisonList,
        },
      }
    }

    // remove
    case 'comparison.remove_from_list': {
      return {
        ...state,
        removeFromComparisonList: {
          ...state.removeFromComparisonList,
          meta: {
            ...state.removeFromComparisonList.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'comparison.remove_from_list.success': {
      return {
        ...state,
        removeFromComparisonList: {
          ...state.removeFromComparisonList,
          meta: {
            ...initialState.removeFromComparisonList.meta,
            status: 'success',
          },
        },
      }
    }
    case 'comparison.remove_from_list.error': {
      return {
        ...state,
        removeFromComparisonList: {
          ...state.removeFromComparisonList,
          meta: {
            ...state.removeFromComparisonList.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'comparison.reset_remove_from_list': {
      return {
        ...state,
        removeFromComparisonList: {
          ...initialState.removeFromComparisonList,
        },
      }
    }

    // default
    default:
      return state
  }
}

import { Base } from './base.schema'

export const getInitialAsyncState = <Data>(
  data: Data,
): Base.AsyncField<Data> => ({
    meta: {
      status: 'idle',
      error: '',
    },
    data,
  })

import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import styles from '../../styles.module.css'

import { HeadCell } from './CustomTable.props'


const headCells: readonly HeadCell[] = [
  {
    id: 'date',
    label: 'Дата/время',
  },
  {
    id: 'name',
    label: 'Пользователь',
  },
  {
    id: 'descrition',
    label: 'Описание события',
  }
]


export const EnhancedTableHead = () => {
  
  return (
    <TableHead className={styles.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            className={styles.tableCellHead}
          >
            <b>{headCell.label}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

import { TableCell, TableRow } from '@mui/material'
import { FC, useRef, useState } from 'react'

import { Notification } from 'components/Notification'
import { useNotification } from 'hooks'
import { useClickOutside } from 'hooks/useClickOutside'

import { CustomDeleteModal } from '../CustomDeleteModal'
import { CustomEditModal } from '../CustomEditModal'

import { IManageRole } from './ManageRoleTable.props'
import styles from './styles.module.css'

interface ManageRoleTableRowProps {
  record: IManageRole;
}

export const ManageRoleTableRow: FC<
  ManageRoleTableRowProps
> = ({ record }) => {
  const { openNotification, notificationData } = useNotification()
  const toolsModal = useRef<HTMLDivElement | null>(null)

  const [toolsModalIsOpened, setToolsModalIsOpened] = useState<boolean>(false)

  const openToolsHandler = () => {
    setToolsModalIsOpened(true)
  }

  const closeToolsHandler = () => setToolsModalIsOpened(false)

  useClickOutside(toolsModal, closeToolsHandler)

  const [editModalIsOpened, setEditModalIsOpened] = useState<boolean>(false)

  const toggleEditModalIsOpened = () => {
    closeToolsHandler()
    setEditModalIsOpened((currState) => !currState)
  }

  const [deleteModalIsOpened, setDeleteModalIsOpened] =
    useState<boolean>(false)

  const toggleDeleteModalIsOpened = () => {
    if (record?.name !== 'admin' && record?.name !== 'admin_security' && record?.name !== 'administrator' && record?.name !== 'user') {
      closeToolsHandler()
      setDeleteModalIsOpened((currState) => !currState)
    }
    if (record?.name === 'admin' || record?.name === 'admin_security' || record?.name === 'administrator' || record?.name === 'user') {
      openNotification('Роли "admin", "admin_security", "administrator",  "user" запрещено удалять.', 'error')
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>{record.name}</TableCell>
        <TableCell>{record.description}</TableCell>
        <TableCell align='right'>
          <div className={styles.tools__wraper}>
            <button className={styles.tools__btn} onClick={openToolsHandler}>
              ...
            </button>
            {toolsModalIsOpened && (
              <div className={styles.tools__popupBlock} ref={toolsModal}>
                <button
                  className={styles.tools__popupBtn}
                  onClick={toggleEditModalIsOpened}
                >
                  <img src='/assets/icon-change.svg' alt='change'/>
                  Редактировать запись
                </button>
                <button
                  className={styles.tools__popupBtn}
                  onClick={toggleDeleteModalIsOpened}
                >
                  <img src='/assets/icon-delete.svg' alt='delete'/>
                  Удалить запись
                </button>
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
      <CustomEditModal
        mode='update'
        defaultValues={{
          name: record.name,
          description: record.description,
        }}
        record={record}
        modalIsOpened={editModalIsOpened}
        closeModal={toggleEditModalIsOpened}
      />
      <CustomDeleteModal
        name={record.name}
        modalIsOpened={deleteModalIsOpened}
        closeModal={toggleDeleteModalIsOpened}
      />
      <Notification {...notificationData} />
    </>
  )
}

import {
  GetUserNamesByRoleNameAction,
  GetUserNamesByRoleNameSuccessAction,
  GetUserNamesByRoleNameErrorAction,
  ResetGetUserNamesByRoleNameAction,
} from './role.actions'

export const getUserNamesByRoleName = (
  payload: GetUserNamesByRoleNameAction['payload']
): GetUserNamesByRoleNameAction => ({
  type: 'role.get_user_names_by_role_name',
  payload,
})

export const getUserNamesByRoleNameSuccess = (
  payload: GetUserNamesByRoleNameSuccessAction['payload']
): GetUserNamesByRoleNameSuccessAction => ({
  type: 'role.get_user_names_by_role_name.success',
  payload,
})

export const getUserNamesByRoleNameError = (
  payload: GetUserNamesByRoleNameErrorAction['payload']
): GetUserNamesByRoleNameErrorAction => ({
  type: 'role.get_user_names_by_role_name.error',
  payload,
})

export const getUserNamesByRoleNameReset =
  (): ResetGetUserNamesByRoleNameAction => ({
    type: 'role.reset.get_user_names_by_role_name',
    payload: undefined,
  })
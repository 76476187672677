import {
  useData,
  useMetaErrorMessage,
  useMetaErrorStatus,
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
} from 'modules/base'

import { DatesSlice } from './statisticsDates.schema'

export const useGetDatesIdle = ({ dates }: DatesSlice) =>
  useMetaIdleStatus(dates.date)

export const useGetDatesLoading = ({ dates }: DatesSlice) =>
  useMetaLoadingStatus(dates.date)

export const useGetDatesSuccess = ({ dates }: DatesSlice) =>
  useMetaSuccessStatus(dates.date)

export const useGetDatesError = ({ dates }: DatesSlice) =>
  useMetaErrorStatus(dates.date)

export const useGetDatesErrorMessage = ({ dates }: DatesSlice) =>
  useMetaErrorMessage(dates.date)

export const useGetDates = ({ dates }: DatesSlice) => useData(dates.date)

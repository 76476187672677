import { FC } from 'react'

import style from '../InfoFormOrganization.module.css'
import { PropsData } from '../types'


export const Registration: FC<PropsData> = ({ data }) => {

  return (
    <div className={style.containerComponent}>
      <p className={style.generalName}>Регистрация и текущий учет</p>
      {data.registrationAndCurrentAccountingEgrRegDate || data.decisionNumber || data.regAuthorityName ?
        <>
          <p className={style.headline}>Регистрация ЕГР</p>
          <div className={style.registrationFlex}>
            <div>
              {data.registrationAndCurrentAccountingEgrRegDate ? <p className={style.headlineReg}>Дата регистрации ЕГР</p> : null}
              {data.decisionNumber ? <p className={style.headlineReg}>Номер решения</p> : null}
              {data.regAuthorityName ? <p className={style.headlineReg}>Зарегистрировавший орган</p> : null}
            </div>
            <div>
              <p className={style.aboutReg}>{data.registrationAndCurrentAccountingEgrRegDate?.split('-').reverse().join('.')}</p>
              <p className={style.aboutReg}>{data.decisionNumber}</p>
              <p className={style.aboutReg}>{data.regAuthorityName}</p>
            </div>
          </div>
        </> : null}
      {data.registrationAndCurrentAccountingGrpRegDate ?
        <>
          <p className={style.headline}>Регистрация ГРП</p>
          <div className={style.registrationFlex}>
            <span className={style.headlineReg}>Дата регистрации ГРП</span>
            <span className={style.aboutReg}>{data.registrationAndCurrentAccountingGrpRegDate.split('-').reverse().join('.')}</span>
          </div>
        </> : null}
      {data.egrAccountingAuthorityName ?
        <>
          <p className={style.headline}>Орган учета ЕГР</p>
          <p className={style.about}>{data.egrAccountingAuthorityName}</p>
        </> : null}
      {data.imnsName ?
        <>
          <p className={style.headline}>Наименование ИМНС</p>
          <p className={style.about}>{data.imnsName}</p>
        </> : null}
    </div>
  )
}

import { FC } from 'react'

import style from '../InfoFormOrganization.module.css'
import { PropsData } from '../types'


export const ExcludeEGR: FC<PropsData> = ({ data }) => {
  return (
    <div className={style.containerComponent}>
      <p className={style.generalName}>исключение из егр и ликвидация</p>
      {data.excludeStartDate ?
        <>
          <p className={style.headline}>Дата исключения из ЕГР</p>
          <p className={style.about}>{data.excludeStartDate.split('-').reverse().join('.')}</p>
        </> : null}
      {data.excludeAuthorityName ?
        <>
          <p className={style.headline}>Исключивший орган</p>
          <p className={style.about}>{data.excludeAuthorityName}</p>
        </> : null}
      {data.exclusionDecisionNumber ?
        <>
          <p className={style.headline}>Номер решения об исключении</p>
          <p className={style.about}>{data.exclusionDecisionNumber}</p>
        </> : null}
      {data.liquidationReasonName ?
        <>
          <p className={style.headline}>Причина ликвидации</p>
          <p className={style.about}>{data.liquidationReasonName}</p>
        </> : null}
    </div>
  )
}

import CloseIcon from '@mui/icons-material/Close'
import { Button, Modal, IconButton } from '@mui/material'

import styles from '../../../views/TableMasterList/TableMasterList.module.css'


type DeleteConfirmationModalProps = {
    id: number | undefined;
    onAccept: (id: number) => void;
    onCancel: () => void;
}

export const DeleteConfirmationModal = ({
  id,
  onAccept,
  onCancel,
}: DeleteConfirmationModalProps) => {

  const handleAccept = () => {
    if (id) {
      onAccept(id)
    }
  }
  
  return (
    <Modal
      className={styles.confirmationModal}
      onClose={onCancel}
      open={!!id}
    >
      <div className={styles.confirmationModalInnerContainer}>
        <div className={styles.iconButton}>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </div>
        <p>Вы действительно хотите удалить фильтр?</p>
        <Button
          className={styles.confirmButton}
          variant='contained'
          onClick={handleAccept}
        >
          Удалить
        </Button>
      </div>
    </Modal>
  )
}

export interface AccordionState {
    [key: string]: string | boolean
}

interface AccordionAction {
    type: string
    payload: {
        panel: string,
        isExpanded?: boolean
    }
}

export const initialState = {
  panel1: false,
  panel2: false,
  panel3: false,
  panel4: false,
  panel5: false,
  panel6: false,
  panel7: false,
  panel8: false,
  panel9: false,
  panel10: false
}

export const reducer = (state: AccordionState, action: AccordionAction) => {
  const { type, payload } = action
  return { ...state, [type]: payload.isExpanded ? payload.panel : false }
}

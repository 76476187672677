import { Button, MenuItem, Select } from '@mui/material'
import ru from 'date-fns/locale/ru'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form'

import { dataNameReport } from 'pages/ReportsPage/ReportsPage.const'
import { SearchFormReportsProps, SearchReportsFormProps } from 'pages/ReportsPage/ReportsPage.props'

import styles from './SearchFormReports.module.css'

export const SearchFormReports = ({ onFilter }: SearchFormReportsProps) => {
  const { register, handleSubmit, control, reset, formState: { isDirty } } = useForm<SearchReportsFormProps>({
    defaultValues: {
      startDate: null,
      endDate: null,
      executorFio: '',
      unp: '',
      organizationName: '',
      reportType: '',
    },
  })
  

  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const handleChangeStartDate = (callback: Function, date: Date | null) => {
    setStartDate(date)
    callback(date)
  }

  const handleChangeEndDate = (callback: Function, date: Date | null) => {
    setEndDate(date)
    callback(date)
  }

  const submitHandler: SubmitHandler<SearchReportsFormProps> = ({
    startDate,
    endDate,
    executorFio,
    unp,
    organizationName,
    reportType,
  }) => {
    onFilter({
      startDate: startDate?.toLocaleDateString() || undefined,
      endDate: endDate?.toLocaleDateString() || undefined,
      executorFio: executorFio || undefined,
      unp: unp || undefined,
      organizationName: organizationName || undefined,
      reportType: reportType || undefined,
    })
  }

  const resetHandler = () => {
    reset()
    onFilter({          
      endDate: undefined,
      executorFio: undefined,
      organizationName: undefined,
      reportType: undefined,
      startDate: undefined,
      unp: undefined,
    })
  }

  return (
    <div>
      <div className={styles.header}>
        <span>Найти отчет</span>
      </div>
      <FormProvider {...useForm()}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className={styles.form}>
            <div className={styles.wrapperInput}>
              <span>Дата сохранения отчета</span>
              <div className={styles.wrapperDate}>
                <Controller
                  control={control}
                  name='startDate'
                  render={({ field }) => (
                    <DatePicker
                      onChange={(date) =>
                        handleChangeStartDate(field.onChange, date)
                      }
                      selected={field.value}
                      endDate={endDate}
                      startDate={startDate}
                      selectsStart
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                      maxDate={endDate}
                      locale={ru}
                      dateFormat='dd.MM.yyyy'
                      popperPlacement='bottom'
                      placeholderText='c'
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='endDate'
                  render={({ field }) => (
                    <DatePicker
                      onChange={(date) =>
                        handleChangeEndDate(field.onChange, date)
                      }
                      selected={field.value}
                      endDate={endDate}
                      selectsEnd
                      startDate={startDate}
                      minDate={startDate}
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                      locale={ru}
                      dateFormat='dd.MM.yyyy'
                      popperPlacement='bottom'
                      placeholderText='по'
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles.wrapperInput}>
              <span>ФИО пользователя</span>
              <input
                {...register('executorFio')}
                placeholder='Введите ФИО пользователя'
              />
            </div>
            <div className={styles.wrapperInput}>
              <span>Наименование отчета</span>
              <Controller
                name='reportType'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    displayEmpty
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    renderValue={
                      field.value !== null
                        ? undefined
                        : () => (
                          <p className={styles.placeholderSelect}>
                              Выберите значение
                          </p>
                        )
                    }
                    className={styles.selectForm}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: '0.5px solid #000000',
                        outline: 'none'
                      },
                    }}
                  >
                    <MenuItem value=''>
                      <em>Пусто</em>
                    </MenuItem>
                    {dataNameReport.map((el) => (
                      <MenuItem key={el.value} value={el.value}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
            <div className={styles.wrapperInput}>
              <span>УНП организации из отчета</span>
              <input {...register('unp')} placeholder='Введите УНП' />
            </div>
            <div className={styles.wrapperInput}>
              <span>Наименование организации из отчета</span>
              <input
                {...register('organizationName')}
                placeholder='Введите наименование'
              />
            </div>
          </div>
          <div className={styles.wrapperBtns}>
            <Button type='submit' variant='contained' disabled={!isDirty}>
              Начать поиск
            </Button>
            <Button variant='outlined' onClick={resetHandler}>
              Сброс параметров поиска
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

import {
  useData,
  useMetaErrorMessage,
  useMetaErrorStatus,
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
} from 'modules/base'

import { RoleSlice } from './role.schema'

export const useGetUserNamesByRoleName = ({ role }: RoleSlice) =>
  useData(role.getUserNamesByRoleName)
  
export const useGetUserNamesByRoleNameIdle = ({ role }: RoleSlice) =>
  useMetaIdleStatus(role.getUserNamesByRoleName)

export const useGetUserNamesByRoleNameLoading = ({ role }: RoleSlice) =>
  useMetaLoadingStatus(role.getUserNamesByRoleName)

export const useGetUserNamesByRoleNameSuccess = ({ role }: RoleSlice) =>
  useMetaSuccessStatus(role.getUserNamesByRoleName)

export const useGetUserNamesByRoleNameError = ({ role }: RoleSlice) =>
  useMetaErrorStatus(role.getUserNamesByRoleName)

export const useGetUserNamesByRoleNameErrorMessage = ({ role }: RoleSlice) =>
  useMetaErrorMessage(role.getUserNamesByRoleName)



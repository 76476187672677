export const ROUTE_PATH = {
  home: '/',
  auth: '/auth',
  role_management: '/admin/role_management',
  access_management: '/admin/access_management',
  account: '/account',
  admin: '/admin',
  aisSettings: '/admin/ais-settings',
  etalon: '/admin/etalon',
  account_management: '/admin/account_management',
  userSpace: '/user-space',
  userSpaceOrganization: '/user-space/:unp',
  userSpaceOrganizationInfo: '/user-space/:unp/info',
  userSpaceOrganizationGraph: '/user-space/:unp/graph',
  securitySpace: '/security-administrator-space/security-administrator',
  securityAdminSpace: '/security-administrator-space',
  audit: '/security-administrator-space/audit',
  comparison: '/comparison',
  reportsOrganization: '/reports/:unp',
  reportsOrganizationInfo: '/reports/:unp/info',
  reportsOrganizationGraph: '/reports/:unp/graph',
  reports: '/reports',
  userSpaceDashboard: '/user-space-dashboard/:id',
  notFound: '*',
}

import { ManageAccessAction } from './manageAccess.action'
import { ManageAccessSchema } from './manageAccess.schema'
import { initialState } from './manageAccess.state'

export const reducer = (
  state = initialState,
  action: ManageAccessAction | void = undefined
): ManageAccessSchema => {
  switch (action?.type) {
    case 'manageAccess.update_list_filter': {
      return {
        ...state,
        listFilter: {
          ...state.listFilter,
          ...action.payload,
        },
      }
    }
    case 'manageAccess.get_list': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            status: 'loading',
          },
          data: initialState.list.data,
        },
      }
    }
    case 'manageAccess.get_list_success': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...initialState.list.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'manageAccess.get_list_error': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'manageAccess.reset_get_list': {
      return {
        ...state,
        list: {
          ...initialState.list,
        },
      }
    }
    case 'manageAccess.update': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...state.updation.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'manageAccess.update.success': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...initialState.updation.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'manageAccess.update.error': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...state.updation.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'manageAccess.reset_update': {
      return {
        ...state,
        updation: {
          ...initialState.updation,
        },
      }
    }
    default:
      return state
  }
}

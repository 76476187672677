import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { HEAD_CELLS_ROLE } from './ManageRoleTable.const'
import styles from './styles.module.css'


export const ManageRoleTableHead = () => {


  return (
    <TableHead className={styles.tableHeader}>
      <TableRow>
        {HEAD_CELLS_ROLE.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            className={styles.tableCell}
          >
            <b>{headCell.label}</b>
          </TableCell>
        ))}
        <TableCell className={styles.tableCell}></TableCell>
      </TableRow>
    </TableHead>
  )
}

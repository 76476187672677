import SearchIcon from '@mui/icons-material/Search'
import { Chip, Tabs, Tooltip, tabsClasses } from '@mui/material'

import { Filters } from 'api'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'


type Props = {
  filters: Filters | null;
  handleSearchFilters: (filters: FormListProps) => void;
  handleDelete: (id: number) => void;
}

export const Chips = ({
  filters,
  handleSearchFilters,
  handleDelete
}: Props) => {
  const filtersLength = filters ? filters?.commonFilters.length + filters?.customFilters.length : 0
  return (
    <div style={{ display: 'flex', gap: 10 }}>
      {filtersLength <= 5 ?
        <>
          {filters?.commonFilters.map(e =>
            <Tooltip title={e.comment} key={e.id}>
              <Chip
                icon={<SearchIcon />}
                color='primary'
                label={e.name}
                onClick={() => handleSearchFilters(e.parameters)}
                style={{ width: 220, minWidth: 220, height: 39, fontSize: 15, marginRight: 20, background: '#1E6EBE', display: 'flex', justifyContent: 'start', borderRadius: 0 }}
              />
            </Tooltip>
          )}
          {filters?.customFilters.map(e =>
            <Tooltip title={e.comment} key={e.id}>
              <Chip
                icon={<SearchIcon />}
                color='primary'
                label={e.name}
                onClick={() => handleSearchFilters(e.parameters)}
                style={{ width: 220, minWidth: 220, height: 39, fontSize: 15, marginRight: 20, background: '#1E6EBE', display: 'grid', gridTemplateColumns: '1fr 7fr 0fr', borderRadius: 0 }}
                onDelete={() => handleDelete(e.id)}
              />
            </Tooltip>
          )}
        </>
        : <Tabs
          variant='scrollable'
          scrollButtons
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 }
            }
          }}
        >
          {filters?.commonFilters.map(e =>
            <Tooltip title={e.comment} key={e.id}>
              <Chip
                icon={<SearchIcon />}
                color='primary'
                label={e.name}
                onClick={() => handleSearchFilters(e.parameters)}
                style={{ width: 220, minWidth: 220, height: 39, fontSize: 15, marginRight: 20, background: '#1E6EBE', display: 'flex', justifyContent: 'start', borderRadius: 0 }}
              />
            </Tooltip>
          )}
          {filters?.customFilters.map(e =>
            <Tooltip title={e.comment} key={e.id}>
              <Chip
                icon={<SearchIcon />}
                color='primary'
                label={e.name}
                onClick={() => handleSearchFilters(e.parameters)}
                style={{ width: 220, minWidth: 220, height: 39, fontSize: 15, marginRight: 20, background: '#1E6EBE', display: 'grid', gridTemplateColumns: '1fr 7fr 0fr', borderRadius: 0 }}
                onDelete={() => handleDelete(e.id)}
              />
            </Tooltip>
          )}
        </Tabs>
      }
    </div>
  )
}

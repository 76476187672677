import { AxiosError } from 'axios'
import { call, put, select, spawn, takeEvery } from 'redux-saga/effects'

import {
  Base,
  ComparisonCreate,
  ComparisonList,
  ComparisonRemove,
  comparison,
} from 'api'

import {
  AddToComparisonListAction,
  RemoveFromComparisonListAction,
  UpdateComparisonListFilterAction,
} from './comparison.action'
import * as creator from './comparison.creator'
import * as selector from './comparison.selector'

function* updateListFilterWorker() {
  let errorMessage = undefined

  try {
    const filter: ReturnType<typeof selector.useComparisonListFilter> =
      yield select(selector.useComparisonListFilter)

    yield put(creator.getComparisonList())

    const {
      data: { data, meta, errorCode },
    }: ComparisonList.Response = yield call(
      comparison.getComparisonList,
      filter
    )

    if (data) {
      yield put(creator.getComparisonListSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getComparisonListError(errorMessage))
    }
  }
}

function* updateListFilterWatcher() {
  yield takeEvery<UpdateComparisonListFilterAction>(
    'comparison.update_list_filter' as UpdateComparisonListFilterAction['type'],
    updateListFilterWorker
  )
}

function* addToListWorker(action: AddToComparisonListAction) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: ComparisonCreate.Response = yield call(
      comparison.addToComparisonList,
      action.payload
    )

    if (data) {
      yield put(creator.addToComparisonListSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.addToComparisonListError(errorMessage))
    }
  }
}

function* addToListWatcher() {
  yield takeEvery<AddToComparisonListAction>(
    'comparison.add_to_list' as AddToComparisonListAction['type'],
    addToListWorker
  )
}

function* removeFromListWorker(action: RemoveFromComparisonListAction) {
  let errorMessage = undefined

  try {
    const response: ComparisonRemove.Response = yield call(
      comparison.removeFromComparisonList,
      action.payload
    )

    const { meta, errorCode } = response.data

    if (response.status === 204) {
      yield put(creator.removeFromComparisonListSuccess())
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.removeFromComparisonListError(errorMessage))
    }
  }
}

function* removeFromListWatcher() {
  yield takeEvery<RemoveFromComparisonListAction>(
    'comparison.remove_from_list' as RemoveFromComparisonListAction['type'],
    removeFromListWorker
  )
}

export function* saga() {
  yield spawn(updateListFilterWatcher)
  yield spawn(addToListWatcher)
  yield spawn(removeFromListWatcher)
}

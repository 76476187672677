import { Breadcrumbs, Typography } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './styles.module.css'

interface CustomBreadcrumbsProps {
  prevPages?: { name: string; url: string; chain?: string }[];
  currentPageName: string;
}

export const CustomBreadcrumbs: FC<CustomBreadcrumbsProps> = ({
  prevPages,
  currentPageName,
}) => {
  const navigate = useNavigate()

  const moveToPageHandler = ({
    url,
    chain,
  }: {
    url: string;
    chain: string;
  }) => {
    chain ? localStorage.setItem('chain', chain) : localStorage.removeItem('chain')
    navigate(chain ? url.concat(`?chain=${chain}`) : url)
  }
  return (
    <Breadcrumbs className={styles.breadcrumbs}>
      {prevPages?.map(({ name, url, chain }) => (
        <p
          key={url}
          className={`${styles.breadcrumbs__crumb} ${styles.breadcrumbs__crumb_prev}`}
          onClick={() => moveToPageHandler({ url, chain: chain || '' })}
        >
          {name}
        </p>
      ))}
      <Typography
        className={`${styles.breadcrumbs__crumb} ${styles.breadcrumbs__crumb_current}`}
      >
        {currentPageName}
      </Typography>
    </Breadcrumbs>
  )
}

import { ChangeEventHandler, useCallback, useMemo, useState } from 'react'

type Props = {
  total: number;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
};

export const useChangePageSize = ({
  total,
  pageSize,
  onPageSizeChange,
}: Props) => {
  const totalPages = useMemo(() => Math.ceil(total / pageSize), [pageSize, total])

  const [rowQuantity, setRowQuantity] = useState<string | number>(
    pageSize
  )

  const changePageSizeHandler = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      setRowQuantity(event.target.value)
      onPageSizeChange(parseInt(event.target.value, 10))
    },
    [setRowQuantity, onPageSizeChange]
  )

  return { totalPages, rowQuantity, changePageSizeHandler }
}

import CropFreeIcon from '@mui/icons-material/CropFree'
import { Button } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TextInput } from 'components/TextInput'
import { CloseIcon } from 'icons'
import { organizations } from 'modules'
import { OrganizationFilterModal } from 'views/OrganizationFilterModal'

import { CustomAutocomplete } from './components/CustomAutocomplete/CustomAutocomplete'
import { DatePickerRange } from './components/DatePickerRange'
import { InputsRange } from './components/InputsRange'
import { Radio } from './components/Radio'
import style from './OrganizationListFilter.module.css'
import { OrganizationListFilterProps } from './OrganizationListFilter.props'


export const OrganizationListFilter = ({ height, onSubmit, setFlag, flag }: OrganizationListFilterProps) => {
  const [scroll, setScroll] = useState(0)

  const handleScroll = () => {
    setScroll(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const dispatch = useDispatch()

  const data = useSelector(organizations.useStateFilter)

  const directoriesList = useSelector(organizations.useGetDirectories)

  useEffect(() => {
    dispatch(
      organizations.actions.directories()
    )
  }, [dispatch])

  const submitHandler = useCallback(() => onSubmit(data), [data, onSubmit])

  const resetFormHandler = () => {
    dispatch(organizations.actions.resetDataFilterOrganizations())
    dispatch(organizations.actions.resetDetailedSearchFilter())
  }

  const closeSidebarHandler = useCallback(() => {
    dispatch(organizations.actions.closeFilter())
  }, [dispatch])

  const openFiterModal = useSelector(organizations.useStateOrganizationFilterModal)

  const toggleFullscreenHandler = useCallback(() => openFiterModal ?
    dispatch(organizations.actions.closeFilterModal())
    : dispatch(organizations.actions.openFilterModal()), [openFiterModal, dispatch])

  const disabledSubmitBtn = !(Object.values(data).filter(e => e !== undefined).length)

  return (
    <>
      <div className={style.containerFilter} style={{ height: height }}>
        <div className={style.header}>
          <CropFreeIcon className={style.btnClose} fontSize='small' onClick={toggleFullscreenHandler} />
          <CloseIcon
            onClick={closeSidebarHandler}
            className={style.btnClose}
          />
        </div>
        <div className={style.form}>
          <div className={style.form__fieldsGroupWrapper}>
            <div className={style.form__fieldsGroup}>
              <h3 className={style.textMainReset}>ОБЩАЯ ИНФОРМАЦИЯ</h3>
              <div className={style.form__fields}>
                <TextInput
                  label='УНП или наименование'
                  name='genUnp'
                  placeholder='Введите УНП или наименование'
                  tooltipText='Вводится УНП или наименование искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />

                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.economicActivityTypes}
                    label='Основной вид деятельности'
                    name='genTypeWork'
                    tooltipText='Вид деятельности, который должен быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                    placeholder='Введите код или наименование'
                  />}
                <TextInput
                  label='Код государственной организации в Белстат'
                  name='genCodeOrg'
                  placeholder='Введите код'
                  tooltipText='Вводится код государственной организации в Белстат, который должен быть у искомой(ых) организации(ий).'
                />
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.propertyTypes}
                    label='Форма собственности'
                    name='genFormOwnership'
                    tooltipText='Форма собственности, которая должна быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                    placeholder='Введите текстовое значение'
                  />}
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.legalOrganizationTypes}
                    label='Организационно-правовая форма'
                    name='genFormLaw'
                    placeholder='Введите текстовое значение'
                    tooltipText='Организационно-правовая форма, которая должна быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                  />}
                <TextInput
                  label='УНП или наименование руководителя'
                  name='genUnpOwner'
                  placeholder='Введите УНП или наименование'
                  tooltipText='Вводится УНП или наименование руководителя искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.stateSubject}
                    name='genStateGRP'
                    placeholder='Введите наименование'
                    label='Состояние субъекта (ГРП)'
                    tooltipText='Состояние по данным ГРП, которое должно быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                  />}
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.stateTypes}
                    name='genStateEGR'
                    placeholder='Введите текстовое значение'
                    label='Состояние субъекта (ЕГР)'
                    tooltipText='Состояние по данным ЕГР, которое должно быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                  />}
                {/*<InputsRange
                  label='Доля участия государства'
                  name1='genShareGovernmentfrom'
                  name2='genShareGovernmentto'
                />*/}
              </div>
            </div>
            <div className={style.form__fieldsGroup}>
              <h3 className={style.textMain}>
                Подчиненность государственной организации
              </h3>
              <div className={style.form__fields}>
                <Radio
                  name='subordGO'
                  label='Подчиненные ГО'
                  tooltipText='Обязательность наличия связей подчиненности с ГО у искомой(ых) организации(ий). Выбирается одно из доступных значений: «Да», «Нет». Для отмены выбора значения повторно нажимается выбранное значение.'
                />
                <TextInput
                  label='Код ГО или наименование'
                  name='subordCodeGO'
                  placeholder='Введите код или наименование'
                  tooltipText='Вводится код Белстат или наименование ГО, которой должна(ы) подчиняться искомая(ые) организация(ии). Допускается ввод неполного наименования.'
                />
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.managementLevels}
                    name='subordLvlGO'
                    placeholder='Введите текстовое значение'
                    label='Уровень подчиненности ГО'
                    tooltipText='Уровень подчиненности, который должен быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                  />}
              </div>
            </div>
            <div className={style.form__fieldsGroup}>
              <h3 className={style.textMain}>Сведения о регистрации и учете</h3>
              <div className={style.form__fields}>
                <DatePickerRange
                  label='Дата постановки на учет'
                  name1='infoRegDate1From'
                  name2='infoRegDate1To'
                  tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата постановки на учет у искомой(ых) организации(ий).'
                />
                <TextInput
                  label='Номер решения о регистрации (ЕГР)'
                  name='infoRegNumberEGR'
                  placeholder='Введите номер'
                  tooltipText='Номер решения о регистрации (ЕГР), который должен быть у искомой(ых) организации(ий).'
                />
                <TextInput
                  label='Зарегистрировавший орган (ЕГР)'
                  name='infoRegOrganOfReg'
                  placeholder='Введите код или наименование'
                  tooltipText='Вводится код или наименование органа, который зарегистрировал искомую(ые) организацию(ии). Допускается ввод неполного наименования.'
                />
                <DatePickerRange
                  label='Дата регистрации (ГРП)'
                  name1='infoRegDate2From'
                  name2='infoRegDate2To'
                  tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата регистрации в ГРП у искомой(ых) организации(ий).'
                />
                <TextInput
                  label='Орган учета (ЕГР)'
                  name='infoRegOrgan'
                  placeholder='Введите код или наименование'
                  tooltipText='Вводится код или наименование органа учета ЕГР, который должен быть у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                <TextInput
                  label='ИМНС (ГРП)'
                  name='infoRegIMNS'
                  placeholder='Введите код или наименование'
                  tooltipText='Вводится код или наименование ИМНС, которая должна быть у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
              </div>
            </div>
            <div className={style.form__fieldsGroup}>
              <h3 className={style.textMain}>Уставной фонд</h3>
              <div className={style.form__fields}>
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.directoryFunds}
                    name='capitalType'
                    placeholder='Введите текстовое значение'
                    label='Тип уставного фонда'
                    tooltipText='Тип уставного фонда, который должен быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                  />}
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.currencies}
                    name='capitalValue'
                    placeholder='Введите код или наименование'
                    label='Валюта уставного фонда'
                    tooltipText='Валюта уставного фонда, которая должна быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                  />}
                <InputsRange
                  label='Размер уставного фонда (валюта)'
                  name1='capitalSizeFrom'
                  name2='capitalSizeTo'
                  tooltipText='Указываются значения «с.. по..», в диапазон которых должен попадать уставной фонд у искомой(ых) организации(ий).'
                />
                <InputsRange
                  label='Доля государства (валюта)'
                  name1='capitalShareGosFrom'
                  name2='capitalShareGosTo'
                  tooltipText='Указываются значения «с.. по..», в диапазон которых должна попадать сумма доли государства в уставном фонде у искомой(ых) организации(ий).'
                />
                {/*<InputsRange
                label='Доля государства (%)'
                name1='capitalShareGovFrom'
                name2='capitalShareGovTo'
              />*/}
                <InputsRange
                  label='Доля иностранных инвестиций (валюта)'
                  name1='capitalShareForeignValueFrom'
                  name2='capitalShareForeignValueTo'
                  tooltipText='Указываются значения «с.. по..», в диапазон которых должна попадать сумма доли иностранных инвестиций в уставном фонде у искомой(ых) организации(ий).'
                />
                <InputsRange
                  label='Доля иностранных инвестиций (%)'
                  name1='capitalShareForeignProcFrom'
                  name2='capitalShareForeignProcTo'
                  tooltipText='Указываются значения «с.. по..», в диапазон которых должен попадать процент доли иностранных инвестиций в уставном фонде у искомой(ых) организации(ий).'
                />
                <InputsRange
                  label='Доля белорусских инвестиций (валюта)'
                  name1='capitalShareBelValueFrom'
                  name2='capitalShareBelValueTo'
                  tooltipText='Указываются значения «с.. по..», в диапазон которых должна попадать сумма доли белорусских инвестиций в уставном фонде у искомой(ых) организации(ий).'
                />
                <InputsRange
                  label='Доля белорусских инвестиций (%)'
                  name1='capitalShareBelProcFrom'
                  name2='capitalShareBelProcTo'
                  tooltipText='Указываются значения «с.. по..», в диапазон которых должен попадать процент доли белорусских инвестиций в уставном фонде у искомой(ых) организации(ий).'
                />
              </div>
            </div>
            <div className={style.form__fieldsGroup}>
              <h3 className={style.textMain}>Адрес</h3>
              <div className={style.form__fields}>
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.countriesList}
                    label={'Страна'}
                    name='addressCountry'
                    tooltipText='Страна, которая должна быть в адресе у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                    placeholder='Введите код или наименование'
                  />
                }
                <TextInput
                  label='Область'
                  name='addressArea'
                  placeholder='Введите наименование'
                  tooltipText='Вводится наименование области, которая должна быть в адресе у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                <TextInput
                  label='Район'
                  name='addressDistrict'
                  placeholder='Введите наименование'
                  tooltipText='Вводится наименование района, который должен быть в адресе искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                <TextInput
                  label='Населенный пункт'
                  name='addressCity'
                  placeholder='Введите наименование'
                  tooltipText='Вводится наименование населенного пункта, который должен быть в адресе у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
              </div>
            </div>
            <div className={style.form__fieldsGroup}>
              <h3 className={style.textMain}>Учредители</h3>
              <div className={style.form__fields}>
                <TextInput
                  label='УНП или наименование'
                  name='foundersUNP'
                  placeholder='Введите УНП или наименование'
                  tooltipText='Вводится УНП или наименование учредителя/акционера искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.currencies}
                    name='foundersValue'
                    placeholder='Введите код или наименование'
                    label='Валюта'
                    tooltipText='Валюта уставного фонда, которая должна быть у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                  />}
                <InputsRange
                  label='Доля в уставном фонде (валюта)'
                  name1='foundersShareValueFrom'
                  name2='foundersShareValueTo'
                  tooltipText='Указываются значения «с.. по..», в диапазон которых должна попадать сумма доли учредителя/акционера в уставном фонде у искомой(ых) организации(ий).'
                />
                <InputsRange
                  label='Доля в уставном фонде (%)'
                  name1='foundersShareProcFrom'
                  name2='foundersShareProcTo'
                  tooltipText='Указываются значения «с.. по..», в диапазон которых должен попадать процент доли учредителя/акционера в уставном фонде у искомой(ых) организации(ий).'
                />
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.countriesList}
                    label='Страна инвестиций'
                    name='foundersCountry'
                    tooltipText='Страна, которая должна быть по учредителям/акционерам у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                    placeholder='Введите код или наименование'
                  />}
              </div>
            </div>
            <div className={style.form__fieldsGroup}>
              <h3 className={style.textMain}>Дочерние организации</h3>
              <div className={style.form__fields}>
                <TextInput
                  label='УНП или наименование'
                  name='subsidiariesUnp'
                  placeholder='Введите УНП или наименование'
                  tooltipText='Вводится УНП или наименование дочерней организации у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.currencies}
                    name='subsidiariesValue'
                    placeholder='Введите код или наименование'
                    label='Валюта'
                    tooltipText='Валюта уставного фонда, которая должна быть у дочерней организации искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                  />}
                <InputsRange
                  label='Доля в уставном фонде (валюта)'
                  name1='subsidiariesShareValueFrom'
                  name2='subsidiariesShareValueTo'
                  tooltipText='Указываются значения «с.. по..», в диапазон которых должна попадать сумма доли в уставном фонде у дочерней организации у искомой(ых) организации(ий).'
                />
                <InputsRange
                  label='Доля в уставном фонде (%)'
                  name1='subsidiariesShareProcFrom'
                  name2='subsidiariesShareProcTo'
                  tooltipText='Указываются значения «с.. по..», в диапазон которых должен попадать процент доли в уставном фонде у дочерней организации у искомой(ых) организации(ий).'
                />
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.countriesList}
                    label='Страна инвестиций'
                    name='subsidiariesCounty'
                    tooltipText='Страна, которая должна быть у дочерней организации у искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                    placeholder='Введите код или наименование'
                  />}
              </div>
            </div>
            <div className={style.form__fieldsGroup}>
              <h3 className={style.textMain}>
                Правопреемники и предшественники
              </h3>
              <div className={style.form__fields}>
                <Radio
                  name='assignees'
                  label='Правопреемники'
                  tooltipText='Обязательность наличия правопреемника у искомой(ых) организации(ий). Выбирается одно из доступных значений: «Да», «Нет». Для отмены выбора значения повторно нажимается выбранное значение.'
                />
                <TextInput
                  label='УНП или наименование правопреемника'
                  name='assigneesUnp'
                  placeholder='Введите УНП или наименование'
                  tooltipText='Вводится УНП или наименование правопреемника у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                <Radio
                  name='predecessors'
                  label='Предшественники'
                  tooltipText='Обязательность наличия предшественника у искомой(ых) организации(ий). Выбирается одно из доступных значений: «Да», «Нет». Для отмены выбора значения повторно нажимается выбранное значение.'
                />
                <TextInput
                  label='УНП или наименование предшественника'
                  name='predecessorsUnp'
                  placeholder='Введите УНП или наименование'
                  tooltipText='Вводится УНП или наименование предшественника у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
              </div>
            </div>
            <div className={style.form__fieldsGroup}>
              <h3 className={style.textMain}>Обособленные подразделения</h3>
              <div className={style.form__fields}>
                <Radio
                  name='divisionsBranch'
                  label='Наличие филиалов'
                  tooltipText='Обязательность наличия филиала у искомой(ых) организации(ий). Выбирается одно из доступных значений: «Да», «Нет». Для отмены выбора значения повторно нажимается выбранное значение.'
                />
                <TextInput
                  label='УНП или наименование'
                  name='divisionsUnp'
                  placeholder='Введите УНП или наименование'
                  tooltipText='Вводится УНП или наименование филиала у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                <TextInput
                  label='ИМНС филиала'
                  name='divisionsIMNS'
                  placeholder='Введите код или наименование'
                  tooltipText='Вводится код или наименование ИМНС, которая должна быть у филиала искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                {directoriesList &&
                  <CustomAutocomplete
                    data={directoriesList.stateSubject}
                    name='divisionsState'
                    placeholder='Введите наименование'
                    label='Состояние филиала'
                    tooltipText='Состояние по данным ГРП, которое должно быть у филиала искомой(ых) организации(ий). Выбирается одно из доступных значений.'
                  />}
                <DatePickerRange
                  label='Дата регистрации филиала'
                  name1='divisionsDateBranchRegFrom'
                  name2='divisionsDateBranchRegTo'
                  tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата регистрации в ГРП филиала искомой(ых) организации(ий).'
                />
                <DatePickerRange
                  label='Дата ликвидации филиала'
                  name1='divisionsDateBranchDelFrom'
                  name2='divisionsDateBranchDelTo'
                  tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата ликвидации в ГРП филиала искомой(ых) организации(ий).'
                />
              </div>
            </div>
            <div className={style.form__fieldsGroup}>
              <h3 className={style.textMain}>
                исключение юридического лица из ЕГР и ликвидация
              </h3>
              <div className={style.form__fields}>
                <DatePickerRange
                  label='Дата исключения из ЕГР'
                  name1='eliminationDateFrom'
                  name2='eliminationDateTo'
                  tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата исключения из ЕГР у искомой(ых) организации(ий).'
                />
                <TextInput
                  label='Исключивший орган'
                  name='eliminationOrgan'
                  placeholder='Введите код или наименование'
                  tooltipText='Вводится код или наименование органа, который исключил из ГРП искомую(ые) организацию(ии). Допускается ввод неполного наименования.'
                />
                <TextInput
                  label='Причина ликвидации'
                  name='eliminationReason'
                  placeholder='Введите код или наименование'
                  tooltipText='Вводится код или наименование причины ликвидации, которая должна быть у искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                <TextInput
                  label='Hомер решения об исключении'
                  name='eliminationNumber'
                  placeholder='Введите номер решения'
                  tooltipText='Номер решения об исключении (ЕГР), который должен быть у искомой(ых) организации(ий).'
                />
                <DatePickerRange
                  label='Дата ликвидации'
                  name1='eliminationDateDelFrom'
                  name2='eliminationDateDelTo'
                  tooltipText='Выбираются даты «с.. по..», в диапазон которых должна попадать дата ликвидации у искомой(ых) организации(ий).'
                />
              </div>
            </div>
            <div className={style.form__fieldsGroup}>
              <h3 className={style.textMain}>Ценные бумаги</h3>
              <div className={style.form__fields}>
                <TextInput
                  label='УНП или наименование депозитария'
                  name='securitiesUnp'
                  placeholder='Введите УНП или наименование'
                  tooltipText='Вводится УНП или наименование депозитария выпуска акций искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                <TextInput
                  label='Идентификационный код выпуска акций'
                  name='securitiesCode'
                  placeholder='Введите код'
                  tooltipText='Вводится код выпуска, который должен быть у акций у искомой(ых) организации(ий).'
                />
                <TextInput
                  label='УНП или наименование владельца акций'
                  name='securitiesUnpShare'
                  placeholder='Введите УНП или наименование'
                  tooltipText='Вводится УНП или наименование владельца акций искомой(ых) организации(ий). Допускается ввод неполного наименования.'
                />
                <TextInput
                  label='Государственный регистрационный номер выпуска акций'
                  name='securitiesNumber'
                  placeholder='Введите номер'
                  tooltipText='Вводится регистрационный номер выпуска, который должен быть у акций искомой(ых) организации(ий).'
                />
              </div>
            </div>
          </div>
          <div className={style.form__btnsGroup} style={{ position: scroll > 150 ? 'sticky' : 'static' }}>
            <Button
              type='submit'
              variant='contained'
              className={style.btnSubmit}
              onClick={submitHandler}
              disabled={disabledSubmitBtn}
            >
              Начать поиск
            </Button>
            <Button
              type='reset'
              className={style.btnReset}
              variant='outlined'
              onClick={resetFormHandler}
            >
              Сброс параметров поиска
            </Button>
          </div>
        </div>
      </div>
      {openFiterModal &&
        <OrganizationFilterModal
          disabledSubmitBtn={disabledSubmitBtn}
          onSubmit={submitHandler}
          onReset={resetFormHandler}
          onClose={toggleFullscreenHandler}
          directoriesList={directoriesList}
        />}
    </>
  )
}

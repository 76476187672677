import { Checkbox } from '@mui/material'

import { FieldsModalSectionProps } from './FieldsModalSection.props'
import styles from './styles.module.css'


export const FieldsModalSection = ({
  columns,
  section,
  selectAll,
  selectTarget,
}: FieldsModalSectionProps) => {
  let sectionFields = columns.filter((column) =>
    section.keys.includes(column.id)
  )

  const selectedFields = sectionFields.filter((field) => field.visible)

  const allSelected = sectionFields.length === selectedFields.length

  return (
    <section className={styles.form__section} key={section.title}>
      <div className={styles.form__sectionTitleWr}>
        <div className={styles.form__toggler}>
          <Checkbox
            id={section.title}
            checked={allSelected}
            onChange={selectAll}
          />
        </div>
        <label htmlFor={section.title} className={styles.form__sectionTitle}>
          {section.title}
        </label>
      </div>
      <div className={styles.form__sectionContent}>
        {sectionFields.map((sectionField) => (
          <div key={sectionField.id} className={styles.form__sectionTitleWr}>
            <div className={styles.form__toggler}>
              <Checkbox
                id={sectionField.id}
                checked={sectionField?.visible}
                disabled={sectionField?.primary}
                onChange={selectTarget}
              />
            </div>
            <label
              htmlFor={sectionField.id}
              className={styles.form__checkboxLabel}
            >
              {sectionField?.label}
            </label>
          </div>
        ))}
      </div>
    </section>
  )
}

export type TMainDashboards = {
  organizationsAmount: number;
  stocksAmount: number;
  organizations: { name: string; 'количество': string }[];
  stocks: { name: string; 'количество': string }[];
};

export type TDashboarsPayload = {
  startDate: Date;
  endDate: Date;
};
export type TStringDashboarsPayload = {
  startDate: string;
  endDate: string;
};

export type TDashboars = {
  stateAssetsNum: {
    elevated: number;
    endNum: number;
    reduced: number;
    startNum: number;
  };
  liquidationStage: {
    elevated: number;
    endNum: number;
    endPercent: string;
    reduced: number;
    startNum: number;
    startPercent: string;
  };
  controllingStake: {
    elevated: number;
    endNum: number;
    endPercent: string;
    reduced: number;
    startNum: number;
    startPercent: string;
  };
};

export enum Flag {
  STATE_START_DATE = 'SSD',
  STATE_END_DATE = 'SED',
  STATE_ELEVATED = 'SE',
  STATE_REDUCED = 'SR',
  LIQUIDATION_START_DATE = 'LSD',
  LIQUIDATION_END_DATE = 'LED',
  LIQUIDATION_ELEVATED = 'LE',
  LIQUIDATION_REDUCED = 'LR',
  CONTROLLING_STAKE_STATE_DATE = 'CSSD',
  CONTROLLING_STAKE_END_DATE = 'CSED',
  CONTROLLING_STAKE_ELEVATED = 'CSE',
  CONTROLLING_STAKE_REDUCED = 'CSR'
}

export type DashboardDataFlagQuery = {
  flag: string;
}

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

import { Loader } from 'components'
import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { breadcrumbs, graph } from 'modules'

import { Graph } from './components/Graph/Graph'
import styles from './styles.module.css'

export const NewGraphPage = () => {
  const { unp } = useParams()
  const [searchParams] = useSearchParams()
  const chain = searchParams.get('chain')

  const dispatch = useDispatch()

  const currentGraph = useSelector(graph.useGetGraph)
  const currentGraphLoading = useSelector(graph.useGetGraphLoading)


  const currentBreadcrumbs = useSelector(breadcrumbs.useGetBreadcrumbs)

  useEffect(() => {
    dispatch(graph.actions.getGraph({ unp: unp! }))
  }, [dispatch, unp])

  useEffect(() => {
    if (unp) {
      dispatch(
        breadcrumbs.actions.getBreadcrumbs({
          unp,
          chain: chain || undefined,
        })
      )
    }

    return () => {
      dispatch(breadcrumbs.actions.resetGetBreadcrumbs())
    }
  }, [dispatch, unp, chain])

  if(!currentGraph || currentGraphLoading) {
    return <Loader />
  }

  return (
    <>
      {currentBreadcrumbs && (
        <div className={styles.containerRoutes}>
          <CustomBreadcrumbs
            prevPages={currentBreadcrumbs.prevPages}
            currentPageName={`${currentBreadcrumbs.currentPageName} (просмотр графа)`}
          />
        </div>
      )}
      <Graph
        initialNodes={currentGraph!.initialNodes}
        initialEdges={currentGraph!.initialEdges}
      />
    </>
  )
}

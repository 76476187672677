import { ReportsAction } from './reports.actions'
import { ReportsSchema } from './reports.schema'
import { initialState } from './reports.state'

export const reducer = (
  state = initialState,
  action: ReportsAction | void = undefined
): ReportsSchema => {
  switch (action?.type) {
    // filter
    case 'reports.update_list_filter': {
      return {
        ...state,
        listFilter: {
          ...state.listFilter,
          ...action.payload,
        },
      }
    }

    // get list
    case 'reports.get_list': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            status: 'loading',
          },
          data: initialState.list.data,
        },
      }
    }
    case 'reports.get_list.success': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...initialState.list.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'reports.get_list.error': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'reports.reset_get_list': {
      return {
        ...state,
        list: {
          ...initialState.list,
        },
      }
    }

    // add to list
    case 'reports.add_to_list': {
      return {
        ...state,
        addToList: {
          ...state.addToList,
          meta: {
            ...state.addToList.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'reports.add_to_list.success': {
      return {
        ...state,
        addToList: {
          ...state.addToList,
          meta: {
            ...initialState.addToList.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'reports.add_to_list.error': {
      return {
        ...state,
        addToList: {
          ...state.addToList,
          meta: {
            ...state.addToList.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'reports.reset_add_to_list': {
      return {
        ...state,
        addToList: {
          ...initialState.addToList,
        },
      }
    }

    // remove from list
    case 'reports.remove_from_list': {
      return {
        ...state,
        removeFromList: {
          ...state.removeFromList,
          meta: {
            ...state.removeFromList.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'reports.remove_from_list.success': {
      return {
        ...state,
        removeFromList: {
          ...state.removeFromList,
          meta: {
            ...initialState.removeFromList.meta,
            status: 'success',
          },
        },
      }
    }
    case 'reports.remove_from_list.error': {
      return {
        ...state,
        removeFromList: {
          ...state.removeFromList,
          meta: {
            ...state.removeFromList.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'reports.reset_remove_from_list': {
      return {
        ...state,
        removeFromList: {
          ...initialState.removeFromList,
        },
      }
    }

    // download file
    case 'reports.download_file': {
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          meta: {
            ...state.downloadFile.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'reports.download_file.success': {
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          meta: {
            ...initialState.downloadFile.meta,
            status: 'success',
          },
        },
      }
    }
    case 'reports.download_file.error': {
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          meta: {
            ...state.downloadFile.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'reports.reset_download_file': {
      return {
        ...state,
        downloadFile: {
          ...initialState.downloadFile,
        },
      }
    }

    default:
      return state
  }
}

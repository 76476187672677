import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Checkbox, TableCell, TableRow, Tooltip } from '@mui/material'
import { useState, useRef } from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { DYNAMIC_COLUMN } from 'common/const'
import { TDynamicColumn } from 'common/types'
import { TableMapCell } from 'components/TableMapCell'
import { useClickOutside } from 'hooks/useClickOutside'
import { ROUTE_PATH } from 'routes'

import { OrganizationTableRowProps } from './OrganizationTable.props'
import styles from './styles.module.css'

export const OrganizationTableRow = ({
  parent = false,
  selectRow,
  isSelected,
  data,
  moveToNextPage,
  moveToPrevPage,
}: OrganizationTableRowProps) => {
  const { unp } = useParams()

  const chain = localStorage.getItem('chain')

  const modalRowRef = useRef<HTMLDivElement>(null)

  const [modalIsOpened, setModalIsOpened] = useState<boolean>(false)

  const openModalHandler = () => setModalIsOpened(true)

  const clickOutsidehandler = () => setModalIsOpened(false)

  useClickOutside(modalRowRef, clickOutsidehandler)

  const columns = localStorage.getItem('user-space-columns')

  const visibleColumns: TDynamicColumn[] = (
    columns ? JSON.parse(columns) : DYNAMIC_COLUMN
  ).filter((column: TDynamicColumn) => column.visible)

  let urlChain = ''

  if (chain && unp) {
    urlChain = `?chain=${chain}-${unp}`
  } else if (unp) {
    urlChain = `?chain=${unp}`
  } else {
    urlChain = ''
  }

  return (
    <TableRow className={parent ? styles.row_children : undefined}>
      <TableCell className={styles.cell_checkbox}>
        <Checkbox
          checked={isSelected}
          onClick={(event) => selectRow(event, data.unp)}
        />
      </TableCell>
      {visibleColumns.map((visibleColumn) => {
        switch (visibleColumn.id) {
          case 'shortName': {
            return (
              <TableCell key={visibleColumn.id}
              >
                <div className={styles.cell__wrapper_horizontal} >
                  <div className={styles.cell__wrapper}>

                    <Tooltip title={data[visibleColumn.id]} placement='left'>
                      <div className={styles.cell_ellipsis}>
                        {data[visibleColumn.id]}
                      </div>
                    </Tooltip> 
                    
                    <span className={styles.link} onClick={openModalHandler}>
                      Подробнее
                    
                      {modalIsOpened && (
                        <div
                          className={styles.containerModalRow}
                          ref={modalRowRef}
                        >
                          <NavLink
                            to={`${ROUTE_PATH.userSpace}/${data.unp}/info${urlChain}`}
                          >
                            Открыть информацию
                          </NavLink>
                          <NavLink
                            to={`${ROUTE_PATH.userSpace}/${data.unp}/graph${urlChain}`}
                          >
                            Открыть граф
                          </NavLink>
                        </div>
                      )}
                    </span>
                  </div>
                  {parent ? (
                    <KeyboardArrowDownIcon
                      className={styles.icon}
                      onClick={moveToPrevPage}
                    />
                  ) : (
                    data.subsidiariesInfo.length > 0 && (
                      <KeyboardArrowRightIcon
                        className={styles.icon}
                        onClick={moveToNextPage}
                      />
                    )
                  )}
                </div>
              </TableCell>
            )
          }
          case 'registrationAndCurrentAccountingEgrRegDate': {
            return (
              <Tooltip title={data[visibleColumn.id]} placement='left'>
                <TableCell key={visibleColumn.id}>
                  <div className={styles.cell_ellipsis}>
                    {data.registrationAndCurrentAccountingEgrRegDate?.split('-').reverse().join('.')}
                  </div>
                </TableCell>
              </Tooltip>
            )
          }
          case 'dateExcludeGRP': {
            return (
              <Tooltip title={data[visibleColumn.id]} placement='left'>
                <TableCell key={visibleColumn.id}>
                  <div className={styles.cell_ellipsis}>
                    {data.dateExcludeGRP?.split('-').reverse().join('.')}
                  </div>
                </TableCell>
              </Tooltip>
            )
          }
          case 'excludeStartDate': {
            return (
              <Tooltip title={data[visibleColumn.id]} placement='left'>
                <TableCell key={visibleColumn.id}>
                  <div className={styles.cell_ellipsis}>
                    {data.excludeStartDate?.split('-').reverse().join('.')}
                  </div>
                </TableCell>
              </Tooltip>
            )
          }
          case 'registrationAndCurrentAccountingGrpRegDate': {
            return (
              <Tooltip title={data[visibleColumn.id]} placement='left'>
                <TableCell key={visibleColumn.id}>
                  <div className={styles.cell_ellipsis}>
                    {data.registrationAndCurrentAccountingGrpRegDate?.split('-').reverse().join('.')}
                  </div>
                </TableCell>
              </Tooltip>
            )
          }
          case 'activityTypeType': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.activityTypeInfo}
                  fieldName='activityTypeType'
                />
              </Tooltip>
            )
          }
          case 'founderUnp': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.foundersInfo}
                  fieldName='founderUnp'
                />
              </Tooltip>
            )
          }
          case 'founderName': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.foundersInfo}
                  fieldName='founderName'
                />
              </Tooltip>
            )
          }
          case 'regNumSubjectFounder': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.subsidiariesInfo}
                  fieldName='regNumSubjectFounder'
                />
              </Tooltip>
              
              
            )
          }
          case 'subsidiaryName': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.subsidiariesInfo}
                  fieldName='subsidiaryName'
                />
              </Tooltip>
              
              
            )
          }
          case 'managerName': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.managersInfo}
                  fieldName='managerName'
                />
              </Tooltip>
            )
          }
          case 'managerUnp': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.managersInfo}
                  fieldName='managerUnp'
                />
              </Tooltip>
            )
          }
          case 'branchName': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.branchesInfo}
                  fieldName='branchName'
                />
              </Tooltip>
            )
          }
          case 'branchUnp': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.branchesInfo}
                  fieldName='branchUnp'
                />
              </Tooltip>
            )
          }
          case 'stockShareIdentificationCode': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.asorData}
                  fieldName='stockShareIdentificationCode'
                />
              </Tooltip>
            )
          }
          case 'stockOwnerUnp': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.asorData}
                  fieldName='stockOwnerUnp'
                />
              </Tooltip>
            )
          }
          case 'stockOwnerFullName': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.asorData}
                  fieldName='stockOwnerFullName'
                />
              </Tooltip>
            )
          }
          case 'stockOwnerType': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.asorData}
                  fieldName='stockOwnerType'
                />
              </Tooltip>
            )
          }
          case 'stockOwnerAddress': {
            return (
              <Tooltip title={visibleColumn.id} placement='left'>
                <TableMapCell
                  key={visibleColumn.id}
                  array={data.asorData}
                  fieldName='stockOwnerAddress'
                />
              </Tooltip>
            )
          }
          default: {
            return (
              <Tooltip title={data[visibleColumn.id]} placement='left'>
                <TableCell key={visibleColumn.id}
                >
                  <div className={styles.cell_ellipsis}>
                    {data[visibleColumn.id]}
                  </div>
                </TableCell>
              </Tooltip> 
            )
          }
        }
      })}
    </TableRow>
  )
}

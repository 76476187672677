import * as actions from './breadcrumbs.creator'
import { reducer } from './breadcrumbs.reducer'
import { saga } from './breadcrumbs.saga'
import * as selectors from './breadcrumbs.selector'

export const breadcrumbs = {
  ...selectors,
  actions,
  reducer,
  saga,
}

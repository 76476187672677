import * as actions from './comparison.creator'
import { reducer } from './comparison.reducer'
import { saga } from './comparison.saga'
import * as selectors from './comparison.selector'

export const comparison = {
  ...selectors,
  actions,
  reducer,
  saga,
}

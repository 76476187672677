import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'common/const'
import { Notification } from 'components/Notification'
import { useNotification } from 'hooks'
import { comparison, organizations, reports } from 'modules'
import { DeleteConfirmationModal } from 'pages/UserSpacePage/components/DeleteConfirmation'
import { ROUTE_PATH } from 'routes'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'

import { ReportsTableProps } from './ComparisonPage.props'
import { ComparisonTable } from './compoents/ComparisonTable/ComparisonTable'

export const ComparisonPage = ({ setFlag, flag } : { setFlag: Dispatch<SetStateAction<boolean>>, flag: boolean }) => {
  const navigate = useNavigate()
  const { openNotification, notificationData } = useNotification()
  const dispatch = useDispatch()

  const { id: userId, fio: executorFio } = JSON.parse(
    localStorage.getItem('user')!
  )

  const items = useSelector(comparison.useComparisonListItems)
  const itemsLoading = useSelector(comparison.useComparisonListLoading)
  const itemsTotal = useSelector(comparison.useComparisonListItemsTotalCount)
  const itemsFilter = useSelector(comparison.useComparisonListFilter)

  const removeData = useSelector(comparison.useRemoveFromListData)
  const removeSucсess = useSelector(comparison.useRemoveFromListSuccess)
  const removeError = useSelector(comparison.useRemoveFromListError)

  const saveToReportsSuccess = useSelector(reports.useAddToListSuccess)
  const saveToReportsError = useSelector(reports.useAddToListError)

  useEffect(() => {
    dispatch(
      comparison.actions.updateComparisonListFilter({
        userId: userId,
        page: PAGE_DEFAULT,
        size: PAGE_SIZE_DEFAULT,
      })
    )
  }, [dispatch, userId])

  // page
  const handleTablePageChange = useCallback<ReportsTableProps['onPageChange']>(
    (page) => {
      dispatch(comparison.actions.updateComparisonListFilter({ page }))
    },
    [dispatch]
  )

  // page size
  const handleTablePageSizeChange = useCallback<
    ReportsTableProps['onPageSizeChange']
  >(
    (size) => {
      dispatch(
        comparison.actions.updateComparisonListFilter({
          page: PAGE_DEFAULT,
          size,
        })
      )
    },
    [dispatch]
  )

  // sort
  const handleTableSort = useCallback<ReportsTableProps['onSort']>(
    (direction, sortField) => {
      dispatch(
        comparison.actions.updateComparisonListFilter({
          page: PAGE_DEFAULT,
          direction,
          sortField,
        })
      )
    },
    [dispatch]
  )

  // remove from list
  const removeOrganizationsFromListHandler = useCallback<
    ReportsTableProps['onRemove']
  >(
    (unps: string[]) => {
      dispatch(
        comparison.actions.removeFromComparisonList({
          userId: userId,
          unps: unps,
        })
      )
    },
    [dispatch, userId]
  )

  useEffect(() => {
    if (removeSucсess && removeData) {
      dispatch(comparison.actions.updateComparisonListFilter({}))
      dispatch(comparison.actions.resetRemoveFromComparisonList())
    }
  }, [dispatch, removeSucсess, removeData])

  // search
  const handleSearch = useCallback(
    (searchParam: string) => {
      dispatch(
        organizations.actions.updateListFilter({
          searchParam,
          page: PAGE_DEFAULT,
        })
      )
      navigate(ROUTE_PATH.userSpace)
    },
    [dispatch, navigate]
  )

  // detailed search
  const handleTableFiltersChange = useCallback<
    ReportsTableProps['onFiltersChange']
  >(
    (filters) => {
      const copyFilters = Object.assign({}, filters)

      for (const filter in copyFilters) {
        if (typeof copyFilters[filter as keyof typeof copyFilters] === 'object' && !Array.isArray(copyFilters[filter as keyof typeof copyFilters])) {
          // @ts-ignore
          copyFilters[filter as keyof typeof copyFilters] = new Date(copyFilters[filter as unknown]).toLocaleDateString()
        }
        if(Array.isArray(copyFilters[filter as keyof typeof copyFilters])) {
          // @ts-ignore
          copyFilters[filter as keyof typeof copyFilters] = copyFilters[filter].join(',')
        }
      }

      dispatch(
        organizations.actions.updateDetailedSearchFilter({
          ...copyFilters,
          page: PAGE_DEFAULT,
        })
      )

      dispatch(
        organizations.actions.setDataFilterOrganizations({
          ...filters
        })
      )
      setFlag(true)
      navigate(ROUTE_PATH.userSpace)
    },
    [dispatch, navigate]
  )

  // save to reports
  const handleTableSaveToReports = useCallback<ReportsTableProps['onSave']>(
    (unps, fields) => {
      dispatch(
        reports.actions.addToList({
          executorFio,
          reportType: 'table',
          unps,
          fields,
        })
      )
    },
    [dispatch, executorFio]
  )

  // notifications
  useEffect(() => {
    if (removeSucсess) {
      openNotification(
        'Организации удалены со страницы Избранное',
        'success'
      )
      dispatch(comparison.actions.resetRemoveFromComparisonList())
    }

    if (removeError) {
      openNotification(
        'Не удалось удалить организации со страницы Избранное',
        'error'
      )
      dispatch(comparison.actions.resetRemoveFromComparisonList())
    }
    if (saveToReportsSuccess) {
      openNotification(
        'Отчет добавлен на страницу Сохраненные отчеты',
        'success'
      )
      dispatch(reports.actions.resetToList())
    }

    if (saveToReportsError) {
      openNotification(
        'Не удалось добавить отчет на страницу Сохраненные отчеты',
        'error'
      )
      dispatch(reports.actions.resetToList())
    }
  }, [
    dispatch,
    openNotification,
    removeSucсess,
    removeError,
    saveToReportsSuccess,
    saveToReportsError,
  ])

  const filters = useSelector(organizations.useGetFilters)

  useEffect(() => {
    dispatch(
      organizations.actions.filters({
        'user_id': userId
      })
    )
  }, [dispatch, navigate, userId])


  const handleSearchFilters = (filters: FormListProps) => {
    dispatch(
      organizations.actions.setDataFilterOrganizations({
        ...filters
      })
    )

    const copyFilters = Object.assign({}, filters)

    for (const filter in copyFilters) {
      // @ts-ignore
      if (copyFilters[filter as unknown] && copyFilters[filter as unknown].includes('-')) {
        // @ts-ignore
        copyFilters[filter as keyof typeof copyFilters] = copyFilters[filter as unknown].split('-').reverse().join('.')
      }
    }
    dispatch(
      organizations.actions.updateDetailedSearchFilter({
        ...copyFilters,
        page: PAGE_DEFAULT,
      })
    )
    setFlag(true)
    navigate(ROUTE_PATH.userSpace)
  }

  const deletionLoading = useSelector(organizations.useDeletionLoading)
  const deletionSucceed = useSelector(organizations.useDeletionSuccess)
  const deletionError = useSelector(organizations.useDeletionError)
  const deletionErrorText = useSelector(organizations.useDeletionErrorMessage)

  const handleDelete = (id: number) => {
    setDeletionId(id)
  }

  useEffect(() => {
    if (deletionSucceed) {
      dispatch(organizations.actions.resetDeletion())
      dispatch(
        organizations.actions.filters({
          'user_id': userId
        })
      )
      openNotification('Фильтр удален', 'success')
    }
    if (deletionError) {
      openNotification(deletionErrorText, 'error')
      dispatch(organizations.actions.resetDeletion())
    }
  }, [dispatch, openNotification, deletionSucceed, deletionError, deletionErrorText, userId])

  const [deletionId, setDeletionId] = useState<number | undefined>()

  const handleDeletionAccept = useCallback((id: number) => {
    dispatch(organizations.actions.remove({ userId, id }))
    setDeletionId(undefined)
  }, [setDeletionId, userId, dispatch])

  const handleDeletionCancel = useCallback(() => {
    setDeletionId(undefined)
  }, [setDeletionId])

  return (
    <>
      <DeleteConfirmationModal
        id={deletionId}
        onAccept={handleDeletionAccept}
        onCancel={handleDeletionCancel}
      />
      <ComparisonTable
        loading={itemsLoading || deletionLoading}
        rows={items as unknown as ReportsTableProps['rows']}
        total={itemsTotal}
        page={itemsFilter.page || PAGE_DEFAULT}
        pageSize={itemsFilter.size || PAGE_SIZE_DEFAULT}
        sortBy={itemsFilter.sortField}
        sortDir={itemsFilter.direction}
        onPageChange={handleTablePageChange}
        onPageSizeChange={handleTablePageSizeChange}
        onSort={handleTableSort}
        onRemove={removeOrganizationsFromListHandler}
        onSearch={handleSearch}
        onSave={handleTableSaveToReports}
        onFiltersChange={handleTableFiltersChange}
        filters={filters}
        handleSearchFilters={handleSearchFilters}
        handleDelete={handleDelete}
        setFlag={setFlag}
        flag={flag}
      />
      <Notification {...notificationData} />
    </>
  )
}

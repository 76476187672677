import { ROUTE_PATH } from 'routes'

export const NAV_LINKS = [
  {
    label: 'Главная',
    route: ROUTE_PATH.home,
  },
  {
    label: 'Кабинет пользователя',
    route: ROUTE_PATH.userSpace,
  },
  {
    label: 'Избранное',
    route: ROUTE_PATH.comparison,
  },
  {
    label: 'Сохраненные отчеты',
    route: ROUTE_PATH.reports,
  },
]

export const NAV_LINKS_NO_AUTH = [
  {
    label: 'Главная',
    route: '/#main',
  },
  {
    label: 'Общие сведения о системе',
    route: '/#general',
  },
  {
    label: 'Инструкции',
    route: '/#instructions',
  },
  {
    label: 'Контакты',
    route: '/#contacts',
  },
]

export const NAV_LINKS_AUTH_ADMIN = [
  {
    label: 'Главная',
    route: ROUTE_PATH.home,
  },
  {
    label: 'Кабинет администратора',
    route: ROUTE_PATH.admin,
  }
]

export const NAV_LINKS_AUTH_ADMIN_SECURITY = [
  {
    label: 'Главная',
    route: ROUTE_PATH.home,
  },
  {
    label: 'Кабинет администратора безопасности',
    route: ROUTE_PATH.securityAdminSpace,
  }
]

export const adminRoleAttributes = ['aControlUsers', 'aControlRoles', 'aControlUsersProfile', 'aControlPermit', 'aControlEtalon']

export const userRoleAttributes = ['uSearch', 'uSearchAdv', 'uRepGeneration', 'uRepSaving', 'uRepUploading']

export const adminSecurityRoleAttributes = ['sControlAdmins', 'sJournal']

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useEffect } from 'react'

import { DYNAMIC_COLUMN } from 'common/const'
import { FieldModal } from 'pages/ComparisonPage/compoents/FieldsModal/FieldsModal'

import { SelectColumnsProps } from './SelectColumns.props'
import styles from './styles.module.css'

export const SelectColumns = ({
  storageName,
  isOpened,
  toggleIsOpened,
}: SelectColumnsProps) => {
  const localData = localStorage.getItem(storageName)
    ? JSON.parse(localStorage.getItem(storageName) || '{}')
    : DYNAMIC_COLUMN

  useEffect(() => {
    if (!localStorage.getItem(storageName)) {
      localStorage.setItem(storageName, JSON.stringify(DYNAMIC_COLUMN))
    }
  }, [storageName])
  return (
    <>
      <div className={styles.modalBtn__block} onClick={toggleIsOpened}>
        <span>Изменить количество полей</span>
        {!isOpened ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
      </div>
      {isOpened && <FieldModal storageName={storageName} data={localData} onClose={toggleIsOpened} />}
    </>
  )
}

import { spawn, takeEvery, call, put } from 'redux-saga/effects'

import {
  Base,
  AuthLogin,
  auth,
} from 'api'
import { metadataLoginCallbackRs } from 'pages'

import {
  Login,
} from './auth.action'
import * as creator from './auth.creator'
// import * as selector from './auth.selector'

function* loginWorker(action: Login) {
  let errorMessage = ''

  try {
    const { data: { success, data, meta } }: AuthLogin.Response = yield call(auth.login, action.payload)

    if (success && data) {
      yield call(metadataLoginCallbackRs.copy, data)
      yield put(creator.loginSuccess(data))
    } else if (meta) {
      yield call(metadataLoginCallbackRs.reset)
      errorMessage = meta
    }
    yield call(metadataLoginCallbackRs.save)
  } catch (exception) {
    yield call(metadataLoginCallbackRs.reset)
    errorMessage = (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    yield call(metadataLoginCallbackRs.save)
    if (errorMessage) {
      yield put(creator.loginError(errorMessage))
    }
  }

}

function* loginWatcher() {
  yield takeEvery<Login>(
'auth.login' as Login['type'],
loginWorker,
  )
}

export function* saga() {
  yield spawn(loginWatcher)
}

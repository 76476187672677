import {
  useDataCurrPage,
  useDataItems,
  useDataItemsTotalCount,
  useDataLastPage,
  useMetaErrorMessage,
  useMetaErrorStatus,
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
} from 'modules/base'

import { ManageRoleSlice } from './manageRole.schema'

// filter
export const useManageRoleListFilter = ({
  manageRole,
}: ManageRoleSlice) => manageRole.listFilter

// list
export const useManageRoleListIdle = ({
  manageRole,
}: ManageRoleSlice) => useMetaIdleStatus(manageRole.list)

export const useManageRoleListLoading = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaLoadingStatus(manageRole.list)

export const useManageRoleListSuccess = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaSuccessStatus(manageRole.list)

export const useManageRoleListError = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaErrorStatus(manageRole.list)

export const useManageRoleListErrorMessage = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaErrorMessage(manageRole.list)

export const useManageRoleListCurrPage = ({
  manageRole,
}: ManageRoleSlice) => useDataCurrPage(manageRole.list)

export const useManageRoleListLastPage = ({
  manageRole,
}: ManageRoleSlice) => useDataLastPage(manageRole.list)

export const useManageRoleListItemsTotalCount = ({
  manageRole,
}: ManageRoleSlice) =>
  useDataItemsTotalCount(manageRole.list)

export const useManageRoleListItems = ({
  manageRole,
}: ManageRoleSlice) => useDataItems(manageRole.list)

// create
export const useManageRoleCreateIdle = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaIdleStatus(manageRole.creation)

export const useManageRoleCreateLoading = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaLoadingStatus(manageRole.creation)

export const useManageRoleCreateSuccess = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaSuccessStatus(manageRole.creation)

export const useManageRoleCreateError = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaErrorStatus(manageRole.creation)

export const useManageRoleCreateErrorMessage = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaErrorMessage(manageRole.creation)

export const useManageRoleCreateData = ({
  manageRole,
}: ManageRoleSlice) => manageRole.creation.data

// update
export const useManageRoleUpdateIdle = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaIdleStatus(manageRole.updation)

export const useManageRoleUpdateLoading = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaLoadingStatus(manageRole.updation)

export const useManageRoleUpdateSuccess = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaSuccessStatus(manageRole.updation)

export const useManageRoleUpdateError = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaErrorStatus(manageRole.updation)

export const useManageRoleUpdateErrorMessage = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaErrorMessage(manageRole.updation)

export const useManageRoleUpdateData = ({
  manageRole,
}: ManageRoleSlice) => manageRole.updation.data

// delete
export const useManageRoleDeleteIdle = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaIdleStatus(manageRole.deletion)

export const useManageRoleDeleteLoading = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaLoadingStatus(manageRole.deletion)

export const useManageRoleDeleteSuccess = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaSuccessStatus(manageRole.deletion)

export const useManageRoleDeleteError = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaErrorStatus(manageRole.deletion)

export const useManageRoleDeleteErrorMessage = ({
  manageRole,
}: ManageRoleSlice) =>
  useMetaErrorMessage(manageRole.deletion)

export const useManageRoleDeleteData = ({
  manageRole,
}: ManageRoleSlice) => manageRole.deletion.data

import {
  useDataCurrPage,
  useDataItems,
  useDataItemsTotalCount,
  useDataLastPage,
  useMetaErrorMessage,
  useMetaErrorStatus,
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
} from 'modules/base'

import { ManageAccessSlice } from './manageAccess.schema'

// filter
export const useManageAccessListFilter = ({
  manageAccess,
}: ManageAccessSlice) => manageAccess.listFilter

// list
export const useManageAccessListIdle = ({
  manageAccess,
}: ManageAccessSlice) => useMetaIdleStatus(manageAccess.list)

export const useManageAccessListLoading = ({
  manageAccess,
}: ManageAccessSlice) =>
  useMetaLoadingStatus(manageAccess.list)

export const useManageAccessListSuccess = ({
  manageAccess,
}: ManageAccessSlice) =>
  useMetaSuccessStatus(manageAccess.list)

export const useManageAccessListError = ({
  manageAccess,
}: ManageAccessSlice) =>
  useMetaErrorStatus(manageAccess.list)

export const useManageAccessListErrorMessage = ({
  manageAccess,
}: ManageAccessSlice) =>
  useMetaErrorMessage(manageAccess.list)

export const useManageAccessListCurrPage = ({
  manageAccess,
}: ManageAccessSlice) => useDataCurrPage(manageAccess.list)

export const useManageAccessListLastPage = ({
  manageAccess,
}: ManageAccessSlice) => useDataLastPage(manageAccess.list)

export const useManageAccessListItemsTotalCount = ({
  manageAccess,
}: ManageAccessSlice) =>
  useDataItemsTotalCount(manageAccess.list)

export const useManageAccessListItems = ({
  manageAccess,
}: ManageAccessSlice) => useDataItems(manageAccess.list)


// update
export const useManageAccessUpdateIdle = ({
  manageAccess,
}: ManageAccessSlice) =>
  useMetaIdleStatus(manageAccess.updation)

export const useManageAccessUpdateLoading = ({
  manageAccess,
}: ManageAccessSlice) =>
  useMetaLoadingStatus(manageAccess.updation)

export const useManageAccessUpdateSuccess = ({
  manageAccess,
}: ManageAccessSlice) =>
  useMetaSuccessStatus(manageAccess.updation)

export const useManageAccessUpdateError = ({
  manageAccess,
}: ManageAccessSlice) =>
  useMetaErrorStatus(manageAccess.updation)

export const useManageAccessUpdateErrorMessage = ({
  manageAccess,
}: ManageAccessSlice) =>
  useMetaErrorMessage(manageAccess.updation)

export const useManageAccessUpdateData = ({
  manageAccess,
}: ManageAccessSlice) => manageAccess.updation.data

import {
  useDataCurrPage,
  useDataItems,
  useDataItemsTotalCount,
  useDataLastPage,
  useMetaErrorMessage,
  useMetaErrorStatus,
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
} from 'modules/base'

import { ComparisonSlice } from './comparison.schema'

// filter
export const useComparisonListFilter = ({ comparison }: ComparisonSlice) =>
  comparison.comparisonListFilter

// list
export const useComparisonListIdle = ({ comparison }: ComparisonSlice) =>
  useMetaIdleStatus(comparison.comparisonList)

export const useComparisonListLoading = ({ comparison }: ComparisonSlice) =>
  useMetaLoadingStatus(comparison.comparisonList)

export const useComparisonListSuccess = ({ comparison }: ComparisonSlice) =>
  useMetaSuccessStatus(comparison.comparisonList)

export const useComparisonListError = ({ comparison }: ComparisonSlice) =>
  useMetaErrorStatus(comparison.comparisonList)

export const useComparisonListErrorMessage = ({
  comparison,
}: ComparisonSlice) => useMetaErrorMessage(comparison.comparisonList)

export const useComparisonListCurrPage = ({ comparison }: ComparisonSlice) =>
  useDataCurrPage(comparison.comparisonList)

export const useComparisonListLastPage = ({ comparison }: ComparisonSlice) =>
  useDataLastPage(comparison.comparisonList)

export const useComparisonListItemsTotalCount = ({
  comparison,
}: ComparisonSlice) => useDataItemsTotalCount(comparison.comparisonList)

export const useComparisonListItems = ({ comparison }: ComparisonSlice) =>
  useDataItems(comparison.comparisonList)

// add
export const useAddToListIdle = ({ comparison }: ComparisonSlice) =>
  useMetaIdleStatus(comparison.addToComparisonList)

export const useAddToListLoading = ({ comparison }: ComparisonSlice) =>
  useMetaLoadingStatus(comparison.addToComparisonList)

export const useAddToListSuccess = ({ comparison }: ComparisonSlice) =>
  useMetaSuccessStatus(comparison.addToComparisonList)

export const useAddToListError = ({ comparison }: ComparisonSlice) =>
  useMetaErrorStatus(comparison.addToComparisonList)

export const useAddToListErrorMessage = ({ comparison }: ComparisonSlice) =>
  useMetaErrorMessage(comparison.addToComparisonList)

export const useAddToListData = ({ comparison }: ComparisonSlice) =>
  comparison.addToComparisonList.data

// remove
export const useRemoveFromListIdle = ({ comparison }: ComparisonSlice) =>
  useMetaIdleStatus(comparison.removeFromComparisonList)

export const useRemoveFromListLoading = ({ comparison }: ComparisonSlice) =>
  useMetaLoadingStatus(comparison.removeFromComparisonList)

export const useRemoveFromListSuccess = ({ comparison }: ComparisonSlice) =>
  useMetaSuccessStatus(comparison.removeFromComparisonList)

export const useRemoveFromListError = ({ comparison }: ComparisonSlice) =>
  useMetaErrorStatus(comparison.removeFromComparisonList)

export const useRemoveFromListErrorMessage = ({
  comparison,
}: ComparisonSlice) => useMetaErrorMessage(comparison.removeFromComparisonList)

export const useRemoveFromListData = ({ comparison }: ComparisonSlice) =>
  comparison.removeFromComparisonList.data

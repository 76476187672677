import { Base } from 'api/base'

import { Reference, ReferenceSource } from './reference.model'

export namespace ReferenceList {
  // TODO: waiting real name od legalAddress
  export type SortField = 'belstat_code' | 'vnaimk' | 'unp' | 'legalAddress'

  export type Query = Partial<Base.PaginatedQuery> &
    Partial<Base.SortQuery<SortField>>

  export type DataItem = Reference

  export type Data = Base.PaginatedData<DataItem>

  export type Response = Base.PaginatedResponse<DataItem>
}

export namespace ReferenceCreate {
  export type Body = Omit<Reference, keyof Pick<Reference, 'id'>>

  export type Data = Reference

  export type Response = Base.Response<Data>
}

export namespace ReferenceUpdate {
  export type Body = Reference

  export type Data = Reference

  export type Response = Base.Response<Data>
}

export namespace ReferenceRemove {
  export type Query = Pick<Reference, 'id'>

  export type Data = null

  export type Response = Base.Response<Data>
}

export namespace ReferenceImportSource {
  export type Body = ReferenceSource

  export type Data = null

  export type Response = Base.Response<Data>
}

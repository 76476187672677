import { HeadCellRole } from './ManageRoleTable.props'

export const HEAD_CELLS_ROLE: readonly HeadCellRole[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Роль',
  },
  {
    id: 'description',
    numeric: true,
    label: 'Описание роли',
  },
]
 
  
import { apiBase, apiBaseFiles } from 'api/base'

import { SUBPATH } from './reports.const'
import {
  ReportsAddToList,
  ReportsDownloadFile,
  ReportsDownloadFileFromReports,
  ReportsDownloadFileTable,
  ReportsGetList,
  ReportsRemoveFromList,
} from './reports.interface'

export const getList = ({ filters, ...params }: ReportsGetList.Query) =>
  apiBase.get<ReportsGetList.Data>(`${SUBPATH}/get`, {
    params: { ...params, ...filters },
  })

export const addToList = (body: ReportsAddToList.Body) =>
  apiBase.post<ReportsAddToList.Data>(`${SUBPATH}/save`, body)

export const removeFromList = (body: ReportsRemoveFromList.Body) =>
  apiBase.delete<ReportsRemoveFromList.Data>(`${SUBPATH}/delete`, {
    data: body,
  })

export const downloadFile = (body: ReportsDownloadFile.Payload) =>
  apiBaseFiles.post<ReportsDownloadFile.Data>('/unp-generate', body)

export const downloadTableFile = (body: ReportsDownloadFileTable.Payload) =>
  apiBaseFiles.post<ReportsDownloadFileTable.Data>('/table-generate', body)

export const downloadTableFileFromReports = (
  body: ReportsDownloadFileFromReports.Payload
) =>
  apiBaseFiles.post<ReportsDownloadFileFromReports.Data>(
    '/saved-report',
    body
  )

import { END_DATE_DEFAULT, START_DATE_DEFAULT } from 'components/Dashboards/Dashboards.const'
import { getInitialAsyncState } from 'modules/base'

import { DashboardsSchema } from './dashboards.schema'

export const initialState: DashboardsSchema = {
  getMainDashboards: getInitialAsyncState(null),
  filter: {
    startDate: START_DATE_DEFAULT,
    endDate: END_DATE_DEFAULT,
  },
  getDashboards: getInitialAsyncState(null),
  getDashboardsData: getInitialAsyncState({
    number: 0,
    totalPages: 0,
    totalElements: 0,
    content: [],
  }),
}

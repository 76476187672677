import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { PermissionContext } from 'context'

import { prevPageUrl } from './SecurityAdminPage.const'
import styles from './SecurityAdminPage.module.css'


export const SecurityAdminPage = () => {
  const { permissionJournal, permissionControlAdmins } = useContext(PermissionContext)
  return (
    <div>
      <CustomBreadcrumbs
        currentPageName={prevPageUrl.name}
      />
      <div className={styles.wrapperPages}>
        {permissionControlAdmins ?
          <Link to='security-administrator' className={styles.item}>
            <p>Управление администраторами</p>
            <img src='/assets/Rectangle.stack.person.crop.svg' alt='item' />
          </Link>
          :
          <div className={styles.itemDisabled}>
            <p>Управление администраторами</p>
            <img src='/assets/Rectangle.stack.person.crop.svg' alt='item' />
          </div>
        }
        {permissionJournal ?
          <Link to='audit' className={styles.item}>
            <p>Журнал аудита</p>
            <img src='/assets/Server.rack.svg' alt='item' />
          </Link>
          :
          <div className={styles.itemDisabled}>
            <p>Журнал аудита</p>
            <img src='/assets/Server.rack.svg' alt='item' />
          </div>
        }
      </div>
    </div>
  )
}

import {
  Login, LoginSuccess, LoginError, ResetCreation,
} from './auth.action'
  
  
export const login = (
  payload: Login['payload'],
): Login => ({
  type: 'auth.login',
  payload,
})
  
export const loginSuccess = (
  payload: LoginSuccess['payload'],
): LoginSuccess => ({
  type: 'auth.login.success',
  payload,
})
  
export const loginError = (
  payload: LoginError['payload'],
): LoginError => ({
  type: 'auth.login.error',
  payload,
})
  
export const resetCreation = (): ResetCreation => ({
  type: 'auth.reset_creation',
  payload: undefined,
})

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Base } from 'api/base'

import { AuthOtp } from './auth.model'


export namespace AuthLogin {
export type Body = Omit<AuthOtp, keyof Pick<AuthOtp, 'id'>>

export type Data = AuthOtp

export type Response = Base.Response<any>
}

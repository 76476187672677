
import {  Modal } from '@mui/material'
import { FC } from 'react'

import { Container } from 'components/Container'

import { IManageAccess } from '../ManageAccessTable/ManageAccessTable.props'

import { EditAccountForm } from './EditAccountForm'
import styles from './styles.module.css'

interface CustomEditModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValues?: any;
  modalIsOpened: boolean;
  closeModal: () => void;
  record: IManageAccess;
}

export const CustomEditModal: FC<CustomEditModalProps> = ({
  defaultValues,
  modalIsOpened,
  closeModal,
  record
}) => {
  return (
    <Modal open={modalIsOpened}>
      <Container>
        <div className={styles.modal__wrapper}>
          <div className={styles.modal}>
            <div className={styles.modal__header}>
              <h2 className={styles.modal__title}>Редактирование разрешений</h2>
              <button className={styles.modal__closeBtn} onClick={closeModal}>
                <img src='/assets/Closed.svg' alt='close' />
              </button>
            </div>
            <EditAccountForm defaultValues={defaultValues!} onClose={closeModal} record={record}/>
          </div>
        </div>
      </Container>
    </Modal>
  )
}

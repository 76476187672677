import { TDynamicColumn } from './types'

export const PAGE_DEFAULT = 1
export const PAGE_SIZE_DEFAULT = 20

export const COLUMNS_MODAL_CONTENT = {
  TITLE: 'Выберите столбцы для отображения в таблице',
  SAVE: 'Сохранить',
  SELECT_ALL: 'Выбрать все',
  RESET_ALL: 'Сбросить все',
  GENERAL: 'Общая информация о субъекте',
  ADDRESS: 'Адрес',
  REGISTRATION: 'Сведения о регистрации и учете',
  FUNDING: 'Уставной фонд',
  OWNERS: 'Учредители',
  SUBSIDIARIES: 'Дочерние организации',
  HEAD: 'Руководители',
  PARTS: 'Филиалы и подразделения',
  ACTIONS: 'Акции',
  WINDINGUP: 'Ликвидация и исключение из ЕГР',
}

export const COLUMNS_MODAL_SECTIONS = [
  {
    title: COLUMNS_MODAL_CONTENT.GENERAL,
    keys: [
      'fullName',
      'unp',
      'organizationBaseInfoBelstatCode',
      'egrCurrentState',
      'grpCurrentState',
      'activityTypeType',
      'ownershipTypesInfoOwnershipType',
    ],
  },
  {
    title: COLUMNS_MODAL_CONTENT.ADDRESS,
    keys: ['region', 'district', 'localityName', 'address'],
  },
  {
    title: COLUMNS_MODAL_CONTENT.REGISTRATION,
    keys: [
      'registrationAndCurrentAccountingEgrRegDate',
      'registrationAndCurrentAccountingGrpRegDate',
      'imnsName',
      'regAuthorityName',
      'egrAccountingAuthorityName',
      'decisionNumber',
    ],
  },
  {
    title: COLUMNS_MODAL_CONTENT.FUNDING,
    keys: [
      'statFundType',
      'belPortionInvestmentsCurrencyName',
      'statFundSizeTotal',
      'statePortionTotal',
      'foreignPortionInvestments',
      'foreignPortionInvestmentsPercent',
      'belPortionInvestments',
      'belPortionInvestmentsPercent',
    ],
  },
  {
    title: COLUMNS_MODAL_CONTENT.OWNERS,
    keys: ['founderUnp', 'founderName'],
  },
  {
    title: COLUMNS_MODAL_CONTENT.SUBSIDIARIES,
    keys: ['regNumSubjectFounder', 'subsidiaryName'],
  },
  {
    title: COLUMNS_MODAL_CONTENT.HEAD,
    keys: ['managerUnp', 'managerName'],
  },
  {
    title: COLUMNS_MODAL_CONTENT.PARTS,
    keys: ['branchUnp', 'branchName'],
  },
  {
    title: COLUMNS_MODAL_CONTENT.ACTIONS,
    keys: [
      'stockShareIdentificationCode',
      'stockOwnerUnp',
      'stockOwnerFullName',
      'stockOwnerType',
      'stockOwnerAddress',
    ],
  },
  {
    title: COLUMNS_MODAL_CONTENT.WINDINGUP,
    keys: [
      'excludeStartDate',
      'excludeAuthorityName',
      'dateExcludeGRP',
      'exclusionDecisionNumber',
    ],
  },
]

export const DYNAMIC_COLUMN: TDynamicColumn[] = [
  {
    id: 'shortName',
    sortable: false,
    visible: true,
    primary: true,
    label: 'Наименование',
  },
  {
    id: 'fullName',
    sortable: true,
    visible: false,
    label: 'Полное наименование',
  },
  {
    id: 'unp',
    sortable: true,
    visible: false,
    label: 'УНП',
  },
  {
    id: 'organizationBaseInfoBelstatCode',
    sortable: true,
    visible: false,
    label: 'Код Белстат',
  },
  {
    id: 'egrCurrentState',
    sortable: true,
    visible: false,
    label: 'Состояние субъекта (ЕГР)',
  },
  {
    id: 'grpCurrentState',
    sortable: true,
    visible: false,
    label: 'Состояние субъекта (ГРП)',
  },
  {
    id: 'activityTypeType',
    sortable: false,
    visible: true,
    label: 'Основной вид деятельности',
  },
  {
    id: 'ownershipTypesInfoOwnershipType',
    sortable: false,
    visible: false,
    label: 'Форма собственности',
  },
  //----------------------------------------------------------
  {
    id: 'region',
    sortable: true,
    visible: false,
    label: 'Область',
  },
  {
    id: 'district',
    sortable: false,
    visible: false,
    label: 'Район',
  },
  {
    id: 'localityName',
    sortable: false,
    visible: false,
    label: 'Населенный пункт',
  },
  {
    id: 'address',
    sortable: false,
    visible: true,
    label: 'Адрес полный',
  },
  //----------------------------------------------------------
  {
    id: 'registrationAndCurrentAccountingEgrRegDate',
    sortable: false,
    visible: false,
    label: 'Дата регистрации (ЕГР)',
  },
  {
    id: 'registrationAndCurrentAccountingGrpRegDate',
    sortable: false,
    visible: false,
    label: 'Дата регистрации (ГРП)',
  },
  {
    id: 'imnsName',
    sortable: false,
    visible: false,
    label: 'ИМНС',
  },
  {
    id: 'regAuthorityName',
    sortable: false,
    visible: false,
    label: 'Зарегистрировавший орган',
  },
  {
    id: 'egrAccountingAuthorityName',
    sortable: false,
    visible: false,
    label: 'Орган учета',
  },
  {
    id: 'decisionNumber',
    sortable: false,
    visible: false,
    label: 'Номер решения о государственной регистрации',
  },
  //----------------------------------------------------------
  {
    id: 'statFundType',
    sortable: false,
    visible: false,
    label: 'Тип уставного фонда',
  },
  {
    id: 'belPortionInvestmentsCurrencyName',
    sortable: false,
    visible: false,
    label: 'Валюта уставного фонда',
  },
  {
    id: 'statFundSizeTotal',
    sortable: false,
    visible: true,
    label: 'Размер уставного фонда',
  },
  {
    id: 'statePortionTotal',
    sortable: false,
    visible: false,
    label: 'Доля государства (сумма)',
  },
  {
    id: 'foreignPortionInvestments',
    sortable: false,
    visible: false,
    label: 'Доля иностранных инвестиций (сумма)',
  },
  {
    id: 'foreignPortionInvestmentsPercent',
    sortable: false,
    visible: false,
    label: 'Доля иностранных инвестиций (%)',
  },
  {
    id: 'belPortionInvestments',
    sortable: false,
    visible: false,
    label: 'Доля белорусских инвестиций (сумма)',
  },
  {
    id: 'belPortionInvestmentsPercent',
    sortable: false,
    visible: false,
    label: 'Доля белорусских инвестиций (%)',
  },
  //----------------------------------------------------------
  {
    id: 'founderUnp',
    sortable: false,
    visible: false,
    label: 'УНП учредителя',
  },
  {
    id: 'founderName',
    sortable: false,
    visible: false,
    label: 'Наименование учредителя',
  },
  //----------------------------------------------------------
  {
    id: 'regNumSubjectFounder',
    sortable: false,
    visible: false,
    label: 'УНП дочерней организации',
  },
  {
    id: 'subsidiaryName',
    sortable: false,
    visible: false,
    label: 'Наименование дочерней организации',
  },
  //----------------------------------------------------------
  {
    id: 'managerUnp',
    sortable: false,
    visible: false,
    label: 'УНП руководителя',
  },
  {
    id: 'managerName',
    sortable: false,
    visible: false,
    label: 'Наименование руководителя',
  },
  //----------------------------------------------------------
  {
    id: 'branchUnp',
    sortable: false,
    visible: false,
    label: 'УНП филиала',
  },
  {
    id: 'branchName',
    sortable: false,
    visible: false,
    label: 'Наименование филиала',
  },
  //----------------------------------------------------------
  {
    id: 'stockShareIdentificationCode',
    sortable: false,
    visible: false,
    label: 'Номера акций',
  },
  {
    id: 'stockOwnerUnp',
    sortable: false,
    visible: false,
    label: 'УНП владельца акций',
  },
  {
    id: 'stockOwnerFullName',
    sortable: false,
    visible: false,
    label: 'Наименование владельца акций',
  },
  {
    id: 'stockOwnerType',
    sortable: false,
    visible: false,
    label: 'Тип владельца акций',
  },
  {
    id: 'stockOwnerAddress',
    sortable: false,
    visible: false,
    label: 'Адрес владельца акций',
  },
  //----------------------------------------------------------
  {
    id: 'excludeStartDate',
    sortable: false,
    visible: false,
    label: 'Дата исключения из ЕГР',
  },
  {
    id: 'excludeAuthorityName',
    sortable: false,
    visible: false,
    label: 'Исключивший орган ЕГР',
  },
  {
    id: 'dateExcludeGRP',
    sortable: false,
    visible: false,
    label: 'Дата ликвидации (ГРП)',
  },
  {
    id: 'exclusionDecisionNumber',
    sortable: false,
    visible: false,
    label: 'Номер решения об исключении (ЕГР)',
  },
]
import * as actions from './reference.creator'
import { reducer } from './reference.reducer'
import { saga } from './reference.saga'
import * as selectors from './reference.selector'

export const reference = {
  ...selectors,
  actions,
  reducer,
  saga,
}

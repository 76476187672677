// @ts-nocheck
import ru from 'date-fns/locale/ru'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'

import './DatePickerRange.css'

import { CustomTooltip } from 'components/CustomTooltip'
import { organizations } from 'modules/organizations'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'

import {
  END_DATE_DEFAULT,
} from '../../../../components/Dashboards/Dashboards.const'


type Props = {
  label: string;
  name1: keyof FormListProps;
  name2: keyof FormListProps;
  tooltipText: string;
};

export const DatePickerRange = ({
  label,
  name1,
  name2,
  tooltipText,
}: Props) => {
  const dispatch = useDispatch()

  const data = useSelector(organizations.useStateFilter)

  const setValue = (date: Date, field: keyof FormListProps) => {
    dispatch(
      organizations.actions.setDataFilterOrganizations({
        [field]:
          date
      })
    )
  }
  
  return (
    <div className='fieldset'>
      <CustomTooltip title={tooltipText}>
        <label className='fieldset__label' htmlFor={name1}>
          {label}
        </label>
      </CustomTooltip>
      <div className='containerDatePickerRange'>
        <DatePicker
          selected={data[name1] ? new Date(data[name1]) : ''}
          onChange={(date: Date) => setValue(date, name1)}
          selectsStart
          dateFormat='dd.MM.yyyy'
          calendarClassName='calendarDatePicker'
          value={data[name1] ? new Date(data[name1]) : ''}
          popperPlacement='bottom'
          className={data[name2] || data[name1] ? 'fieldDatePickerActive' : 'fieldDatePicker'}
          placeholderText='с'
          locale={ru}
          maxDate={data[name2] ? new Date(data[name2]) : new Date()}
        />
        <DatePicker
          selected={data[name2] ? new Date(data[name2]) : ''}
          onChange={(date: Date) => setValue(date, name2)}
          selectsEnd
          placeholderText='по'
          locale={ru}
          dateFormat='dd.MM.yyyy'
          popperPlacement='bottom'
          className={data[name2] || data[name1] ? 'fieldDatePickerActive' : 'fieldDatePicker'}
          value={data[name2] ? new Date(data[name2]) : ''}
          calendarClassName='calendarDatePicker'
          minDate={data[name1] ? new Date(data[name1]) : ''}
          maxDate={END_DATE_DEFAULT}
        />
      </div>
    </div>
  )
}

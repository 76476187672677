import { useDispatch, useSelector } from 'react-redux'

import { CustomTooltip } from 'components/CustomTooltip'
import { organizations } from 'modules/organizations'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'

import styles from './InputsRange.module.css'

type Props = {
  label: string;
  name1: keyof FormListProps;
  name2: keyof FormListProps;
  tooltipText: string;
};

export const InputsRange = ({ label, name1, name2, tooltipText }: Props) => {
  const dispatch = useDispatch()

  const data = useSelector(organizations.useStateFilter)

  const setValue = (value: string, field: string) => {
    dispatch(
      organizations.actions.setDataFilterOrganizations({
        [field]: value === '' ? undefined : value,
      })
    )
  }

  return (
    <div className={styles.fieldset}>
      <CustomTooltip title={tooltipText}>
        <label className={styles.fieldset__label} htmlFor={name1}>
          {label}
        </label>
      </CustomTooltip>
      <div className={styles.containerInputsRange}>
        <input
          className={styles.fieldset__input}
          placeholder='от'
          onChange={(e) => setValue(e.target.value, name1)}
          value={data[name1] || ''}
          style={{borderColor: data[name2] || data[name1] ? '#1E6EBE' : 'inherit', borderWidth: data[name1] || data[name2] ? '2px' : '0.5px'}}
          type='number'
        />
        <input
          className={styles.fieldset__input}
          placeholder='до'
          onChange={(e) => setValue(e.target.value, name2)}
          value={data[name2] || ''}
          style={{borderColor: data[name2] || data[name1] ? '#1E6EBE' : 'inherit', borderWidth: data[name2] || data[name1] ? '2px' : '0.5px'}}
          type='number'
        />
      </div>
    </div>
  )
}

import { Box, Button, TextField } from '@mui/material'
import { ChangeEventHandler, useCallback, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PermissionContext } from 'context'
import { SearchFilterIcon, SearchLoupeIcon } from 'icons'
import { organizations } from 'modules'

import { content } from './SearchBar.content'
import styles from './SearchBar.module.css'
import { SearchBarProps } from './SearchBar.props'


export const SearchBar = ({
  onSearch,
  placeholderSearch,
}: SearchBarProps) => {
  const dispatch = useDispatch()
  const { permissionSearch, permissionSearchAdv } = useContext(PermissionContext)
  const [query, setQuery] = useState('')

  const isOpenFilter = useSelector(organizations.useStateOrganizationFilter)

  const handleQueryChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setQuery(event.target.value)
  }, [setQuery])

  const handleSearchClick = useCallback(() => {
    // @ts-ignore
    onSearch(query ? query : 'belstat-code')
  }, [onSearch, query])

  const handleFilterClick = () => {
    isOpenFilter ? dispatch(organizations.actions.closeFilter()) : dispatch(organizations.actions.openFilter())
    dispatch(organizations.actions.openFilterModal())
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if(event.key === 'Enter'){
      handleSearchClick()
    }
  }

  return (
    <Box className={styles.searchBar}>
      <Box className={styles.query}>
        <SearchLoupeIcon />
        <TextField
          className={styles.queryField}
          size='small'
          placeholder={placeholderSearch ? placeholderSearch : content.QUERY_FIELD_PLACEHOLDER}
          value={query}
          onChange={handleQueryChange}
          disabled={!permissionSearch}
          onKeyDown={handleKeyPress}
        />
        <Button
          className={styles.querySearch}
          disabled={!query || !permissionSearch}
          onClick={handleSearchClick}
        >
          {content.QUERY_SEARCH_LABEL}
        </Button>
      </Box>
      {!placeholderSearch ?
        <Button
          className={isOpenFilter ? styles.filterActive : styles.filter}
          onClick={handleFilterClick}
          disabled={!permissionSearchAdv}
        >
          <SearchFilterIcon />
          {content.FILTER_LABEL}
        </Button>
        : null
      }
    </Box>
  )
}

import { apiBase } from 'api/base'

import { SUBPATH } from './dashboards.const'
import { GetDashboards, GetMainDashboards, GetDashboardsData } from './dashboards.interface'

export const getMainDashBoards = () => apiBase.get<GetMainDashboards.Data>('/schedule-change')

export const getDashboards = (params: GetDashboards.StringPayload) =>
  apiBase.get<GetDashboards.Data>(SUBPATH, { params })

export const getDashboardsData = (params: GetDashboardsData.Query) =>
  apiBase.get<GetDashboardsData.Data>(
    `${SUBPATH}/dashboard-full-info`,
    { params }
  )

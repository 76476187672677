import { Checkbox, TableCell, TableRow } from '@mui/material'
import { useContext, useRef, useState } from 'react'

import { FileType } from 'api'
import { downloadTableFileFromReports } from 'api/reports/reports.api'
import { Notification } from 'components/Notification'
import { PermissionContext } from 'context'
import { useNotification } from 'hooks'
import { useClickOutside } from 'hooks/useClickOutside'
import { ReportsTableRowProps } from 'pages/ReportsPage/ReportsPage.props'

import styles from '../../styles.module.css'

export const ReportsTableRow = ({
  data,
  isSelected,
  selectRow,
}: ReportsTableRowProps) => {
  const { notificationData, openNotification } = useNotification()
  const { permissionRepUploading } = useContext(PermissionContext)
  const popupRef = useRef<HTMLDivElement | null>(null)
  const [popupIsOpened, setPopupIsOpened] = useState<boolean>(false)

  const togglePopupIsOpenedHandler = () =>
    setPopupIsOpened((currState) => !currState)

  const clickOutsidehandler = () => {
    setPopupIsOpened(false)
  }

  useClickOutside(popupRef, clickOutsidehandler)

  let name = ''
  if (data.reportType === 'table') {
    name = 'Список организаций'
  } else if (data.reportType === 'report-short') {
    name = 'Краткая форма'
  } else if (data.reportType === 'report-detailed') {
    name = 'Полная форма'
  }

  const date = new Date(data.executionDate).toLocaleDateString()

  // download file
  const handleTableDownloadFile = (type: FileType) => {
    if (data.reportType === 'table' && (type === 'docx')) {
      openNotification('Выгрузка списка организаций доступна только в форматах XLS и PDF', 'error')
    } else  {
      downloadTableFileFromReports({
        id: data.id,
        type,
      }).then((response) => {
        const headerContent = response.headers['content-disposition']
        const filename = decodeURI(headerContent.slice(headerContent.lastIndexOf('\'') + 1))
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
    }

    togglePopupIsOpenedHandler()
  }

  return (
    <>
      <TableRow>
        <TableCell className={styles.cell_checkbox}>
          <Checkbox
            checked={isSelected}
            onClick={(event) => selectRow(event, data.id)}
          />
        </TableCell>
        <TableCell>{date}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{data.executorFio}</TableCell>
        <TableCell>{data.orgNames.map(name => <p key={name}>{name}</p>)}</TableCell>
        <TableCell>{data.unps.map(unp => <p key={unp}>{unp}</p>)}</TableCell>
        <TableCell>
          <div className={styles.popup__container}>
            <button
              className={styles.popup__btn}
              type='button'
              onClick={togglePopupIsOpenedHandler}
            >
              ...
            </button>
            {popupIsOpened && (
              <div className={styles.popup__modal} ref={popupRef}>
                <button 
                  className={styles.popup__modalBtn} 
                  type='button' 
                  onClick={() => handleTableDownloadFile('pdf')}
                  disabled={!permissionRepUploading}
                >
                  Выгрузить в PDF
                </button>
                <button 
                  className={styles.popup__modalBtn} 
                  type='button' 
                  onClick={() => handleTableDownloadFile('xlsx')}
                  disabled={!permissionRepUploading}
                >
                  Выгрузить в XLS
                </button>
                <button 
                  className={styles.popup__modalBtn} 
                  type='button' 
                  onClick={() => handleTableDownloadFile('docx')}
                  disabled={!permissionRepUploading}
                >
                  Выгрузить в DOC
                </button>
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
      <Notification {...notificationData} />
    </>
  )
}

export const concatArrays = (arrayFirst: string[] | null[], arraySecond: string[] | null[]) => {
  let s = Math.max(arrayFirst.length, arraySecond.length) * 2,
    d = [arrayFirst, arraySecond],
    r = [],
    v, i = -1

  while (s - i++) {
    v = d[i % 2][(i - i % 2) / 2]
    if (v !== undefined) r.push(v)
  }
  return r
}

import { Modal } from '@mui/material'

import { Container } from 'components/Container'
import { CloseIcon } from 'icons'
import { ModalWindowProps } from 'pages/SecurityPage/SecurityPage.interface'

import styles from './styles.module.css'

export const ModalWindow = ({
  isOpened,
  headerText,
  children,
  onClose,
}: ModalWindowProps) => {
  return (
    <Modal open={isOpened}>
      <Container>
        <div className={styles.modal__wrapper}>
          <div className={styles.modal}>
            <div className={styles.modal__header}>
              <h2 className={styles.modal__headline}>{headerText}</h2>
              <button className={styles.modal__closeBtn} onClick={onClose}>
                <CloseIcon />
              </button>
            </div>
            {children}
          </div>
        </div>
      </Container>
    </Modal>
  )
}

import { ManageRoleAction } from './manageRole.action'
import { ManageRoleSchema } from './manageRole.schema'
import { initialState } from './manageRole.state'

export const reducer = (
  state = initialState,
  action: ManageRoleAction | void = undefined
): ManageRoleSchema => {
  switch (action?.type) {
    case 'manageRole.update_list_filter': {
      return {
        ...state,
        listFilter: {
          ...state.listFilter,
          ...action.payload,
        },
      }
    }
    case 'manageRole.get_list': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            status: 'loading',
          },
          data: initialState.list.data,
        },
      }
    }
    case 'manageRole.get_list_success': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...initialState.list.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'manageRole.get_list_error': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'manageRole.reset_get_list': {
      return {
        ...state,
        list: {
          ...initialState.list,
        },
      }
    }
    case 'manageRole.create': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...state.creation.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'manageRole.create.success': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...initialState.creation.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'manageRole.create.error': {
      return {
        ...state,
        creation: {
          ...state.creation,
          meta: {
            ...state.creation.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'manageRole.reset_create': {
      return {
        ...state,
        creation: {
          ...initialState.creation,
        },
      }
    }
    case 'manageRole.update': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...state.updation.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'manageRole.update.success': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...initialState.updation.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'manageRole.update.error': {
      return {
        ...state,
        updation: {
          ...state.updation,
          meta: {
            ...state.updation.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'manageRole.reset_update': {
      return {
        ...state,
        updation: {
          ...initialState.updation,
        },
      }
    }
    case 'manageRole.remove': {
      return {
        ...state,
        deletion: {
          ...state.deletion,
          meta: {
            ...state.deletion.meta,
            status: 'loading',
          },
          data: action.payload,
        },
      }
    }
    case 'manageRole.remove.success': {
      return {
        ...state,
        deletion: {
          ...state.deletion,
          meta: {
            ...initialState.deletion.meta,
            status: 'success',
          },
        },
      }
    }
    case 'manageRole.remove.error': {
      return {
        ...state,
        deletion: {
          ...state.deletion,
          meta: {
            ...state.deletion.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'manageRole.reset_remove': {
      return {
        ...state,
        deletion: {
          ...initialState.deletion,
        },
      }
    }
    default:
      return state
  }
}

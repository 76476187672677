import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Handle, Position } from 'reactflow'

import { CloseIcon } from 'icons'
import { GraphNodeProps } from 'pages/NewGraphPage/NewGraphPage.props'
import { ROUTE_PATH } from 'routes'

import styles from '../../styles.module.css'

export const GraphNode = ({
  data: { shortname, unp, type, parents, children },
}: GraphNodeProps) => {
  const navigate = useNavigate()

  const [popupIsOpened, setPopupIsOpened] = useState<boolean>(false)

  const togglePopupHandler = () => setPopupIsOpened((currState) => !currState)

  const openInfoHandler = () => {
    navigate(`${ROUTE_PATH.userSpace}/${unp}/info`)
    togglePopupHandler()
  }

  const openGraphHandler = () => {
    navigate(`${ROUTE_PATH.userSpace}/${unp}/graph`)
    togglePopupHandler()
  }

  return (
    <>
      <div
        draggable={false}
        className={`${styles.block} ${
          type === 'target' && styles.block_target
        } ${type === 'parent' && styles.block_parent} ${
          type === 'parent' && !parents && styles.block_parent_empty
        }  ${type === 'child' && styles.block_child}  ${
          type === 'child' && !children && styles.block_childEmpty
        }`}
      >
        <div className={styles.block__nameWr}>

          <p className={styles.block__shortname}>{shortname}</p>
          <button
            className={styles.block__selectBtn}
            onClick={togglePopupHandler}
          >
            =
          </button>
        </div>
        <p className={styles.block__unp}>УНП: {unp}</p>

        {popupIsOpened && (
          <div className={styles.block__popup}>
            <button
              className={styles.block__popupCloseBtn}
              type='button'
              onClick={togglePopupHandler}
            >
              <CloseIcon />
            </button>
            <button
              className={styles.block__popupBtn}
              type='button'
              onClick={openInfoHandler}
            >
              Открыть информацию
            </button>
            <button
              className={styles.block__popupBtn}
              type='button'
              onClick={openGraphHandler}
            >
              Открыть граф
            </button>
          </div>
        )}
      </div>
      {parents && <Handle type='target' position={Position.Top} />}
      {children && <Handle type='source' position={Position.Bottom} />}
    </>
  )
}

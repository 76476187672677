import { Base } from 'api/base'
import { TOrganizationColumnId, TOrganizationTable } from 'common/types'
import { FormListProps } from 'views/OrganizationListFilter/OrganizationListFilter.props'

import {
  Directory,
  Filter,
  Filters,
  OrganizationInfo,
  OrganizationWithSubsidiariesData,
} from './organizations.model'

export namespace OrganizationList {
  export type SortField = TOrganizationColumnId;
  export type Query = Partial<Base.SearchParam> &
    Partial<Base.PaginatedQuery> &
    Partial<Base.SortQuery<SortField>>;
  export type DataItem = TOrganizationTable;
  export type Data = Base.PaginatedData<DataItem>;
  export type Response = Base.PaginatedResponse<DataItem>;
}

export namespace OrganizationWithSubsidiariesList {
  export type SortField = TOrganizationColumnId;
  export type Query = Partial<Base.UnpParam> &
    Partial<Base.PaginatedQuery> &
    Partial<Base.SortQuery<SortField>>;
  export type DataItem = OrganizationWithSubsidiariesData;
  export type Data = Base.PaginatedData<DataItem>;
  export type Response = Base.PaginatedResponse<DataItem>;
}

export namespace OrganizationFullInfo {
  export type Query = Base.OrganizationFullInfoQuery;
  export type DataItem = OrganizationInfo;
  export type Data = DataItem;
  export type Response = Base.Response<Data>;
}

export namespace DetailedSearch {
  export type SortField = TOrganizationColumnId;
  export type Query = Partial<FormListProps> &
    Partial<Base.PaginatedQuery> &
    Partial<Base.SortQuery<SortField>>;
  export type DataItem = TOrganizationTable;
  export type Data = Base.PaginatedData<DataItem>;
  export type Response = Base.PaginatedResponse<DataItem>;
}
export namespace Directories {
  export type Data = Directory;
  export type Response = Base.Response<Data>;
}
export namespace FiltersSearch {
  export type Data = Filters;
  export type Query = Base.UserIdParamFilters;
  export type Response = Base.Response<Data>;
}
export namespace FilterRemove {
  export type Query = Pick<Filter, 'id'> & Base.UserIdParam

  export type Data = null

  export type Response = Base.Response<Data>
}

type CreateFilterQuery = {
  'user_id': string;
  name: string;
  comment: string;
  body: FormListProps;
}
export namespace FilterCreate {
  export type Body = FormListProps
  export type Query = CreateFilterQuery 
  export type Data = Filter
  export type Response = Base.Response<Data>
}

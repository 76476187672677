import { AxiosError } from 'axios'
import { call, put, spawn, takeEvery } from 'redux-saga/effects'

import { Base, GetBreadcrumbs, breadcrumbs } from 'api'

import { GetBreadcrumbsAction } from './breadcrumbs.action'
import * as creator from './breadcrumbs.creator'

function* getBreadcrumbsWorker(action:GetBreadcrumbsAction) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: GetBreadcrumbs.Response = yield call(breadcrumbs.getBreadcrumbs, action.payload)

    if (data) {
      yield put(creator.getBreadcrumbsSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  }finally {
    if (errorMessage) {
      yield put(creator.getBreadcrumbsError(errorMessage))
    }
  }
}

function* getBreadcrumbsWatcher() {
  yield takeEvery<GetBreadcrumbsAction>(
    'breadcrumbs.get_breadcrumbs' as GetBreadcrumbsAction['type'],
    getBreadcrumbsWorker
  )
}

export function* saga() {
  yield spawn(getBreadcrumbsWatcher)
}

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { Pagination, PaginationItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChangeEvent, FC } from 'react'

import styles from './CustomPagination.module.css'

type Props = {
  page: number;
  rowQuantity: number | string;
  totalPages: number;
  handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
  totalItems: number;
};

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: 'inherit',
        color: 'inherit',
        textDecoration: 'underline',
      },
    },
  },
}))

export const CustomPagination: FC<Props> = ({
  page,
  rowQuantity,
  totalPages,
  handlePageChange,
  totalItems,
}) => {
  const classes = useStyles()
  const fromItems = (page - 1) * Number(rowQuantity) + 1
  const toItems = page * Number(rowQuantity)

  return (
    <div>
      <Pagination
        className={styles.nav}
        count={totalPages}
        showFirstButton={page !== 1}
        showLastButton={page !== totalPages}
        onChange={handlePageChange}
        page={page}
        siblingCount={0}
        size='small'
        renderItem={(item) => (
          <PaginationItem
            slots={{
              first: KeyboardDoubleArrowLeftIcon,
              last: KeyboardDoubleArrowRightIcon,
            }}
            {...item}
          />
        )}
        classes={{
          root: classes.ul,
        }}
      />
      <p className={styles.text}>
        Страница {totalPages !== 0 ? page : 0} из {totalPages} | Запись {totalItems !== 0 ? fromItems: 0}-
        {totalItems > toItems ? toItems : totalItems} из {totalItems}
      </p>
    </div>
  )
}

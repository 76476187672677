import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'components'
import { Notification } from 'components/Notification'
import { useNotification } from 'hooks/useNotification'
import { manageRole } from 'modules'

import { ManageRoleTable } from './components/ManageRoleTable'
import { ManageRoleTableProps } from './components/ManageRoleTable/ManageRoleTable.props'
import {
  DEFAULT_STATUS,
  PAGE_DEFAULT,
} from './ManageRolePage.const'

export const ManageRolePage: FC = () => {
  const { openNotification, notificationData } = useNotification()
  const dispatch = useDispatch()
  // create modal
  const [createModalIsOpened, setCreateModalIsOpened] =
    useState<boolean>(false)

  const toggleCreateModalIsOpened = useCallback(() => {
    setCreateModalIsOpened((currState) => !currState)
  }, [])

  const records = useSelector(
    manageRole.useManageRoleListItems
  )
  const recordsLoading = useSelector(
    manageRole.useManageRoleListLoading
  )
  const recordsTotal = useSelector(
    manageRole.useManageRoleListItemsTotalCount
  )
  const recordsFilter = useSelector(
    manageRole.useManageRoleListFilter
  )

  const creationLoading = useSelector(
    manageRole.useManageRoleCreateLoading
  )
  const creationSucceed = useSelector(
    manageRole.useManageRoleCreateSuccess
  )
  const creationError = useSelector(
    manageRole.useManageRoleCreateError
  )
  const creationErrorText = useSelector(
    manageRole.useManageRoleCreateErrorMessage
  )

  const updationLoading = useSelector(
    manageRole.useManageRoleUpdateLoading
  )
  const updationSucceed = useSelector(
    manageRole.useManageRoleUpdateSuccess
  )
  const updationError = useSelector(
    manageRole.useManageRoleUpdateError
  )
  const updationErrorText = useSelector(
    manageRole.useManageRoleUpdateErrorMessage
  )

  const deletionLoading = useSelector(
    manageRole.useManageRoleDeleteLoading
  )
  const deletionSucceed = useSelector(
    manageRole.useManageRoleDeleteSuccess
  )
  const deletionError = useSelector(
    manageRole.useManageRoleDeleteError
  )
  const deletionErrorText = useSelector(
    manageRole.useManageRoleDeleteErrorMessage
  )

  useEffect(() => {
    dispatch(
      manageRole.actions.updateManageRoleListFilter({
        _page: PAGE_DEFAULT,
        _limit: 10,
      })
    )

    return () => {
      dispatch(
        manageRole.actions.resetGetManageRoleList()
      )
    }
  }, [dispatch])


  useEffect(() => {
    if (creationSucceed) {
      toggleCreateModalIsOpened()
      dispatch(
        manageRole.actions.resetCreateManageRole()
      )
      dispatch(
        manageRole.actions.updateManageRoleListFilter({})
      )
      openNotification('Запись создана', 'success')
    }

    if (updationSucceed) {
      dispatch(
        manageRole.actions.resetUpdateManageRole()
      )
      dispatch(
        manageRole.actions.updateManageRoleListFilter({})
      )
      openNotification('Запись обновлена', 'success')
    }

    if (creationError) {
      openNotification('Запись не сохранена. Роль с указанными данными уже существует', 'error')
    }

    if (updationError) {
      openNotification('Запись не обновлена. Роль с указанными данными уже существует', 'error')
    }
  }, [dispatch, creationSucceed, updationSucceed, creationError, updationError, creationErrorText, updationErrorText, openNotification, toggleCreateModalIsOpened])

  useEffect(() => {
    if (deletionSucceed) {
      openNotification('Запись удалена', 'success')
      dispatch(
        manageRole.actions.resetDeleteManageRole()
      )
      dispatch(
        manageRole.actions.updateManageRoleListFilter({})
      )
    }
    if (deletionError) {
      openNotification(deletionErrorText, 'error')
    }
  }, [dispatch, openNotification, deletionSucceed, deletionError, deletionErrorText])

  // change page
  const handleTablePageChange = useCallback<
  ManageRoleTableProps['onPageChange']
  >(
    (_page) => {
      dispatch(
        manageRole.actions.updateManageRoleListFilter({
          _page,
        })
      )
    },
    [dispatch]
  )

  // change page size
  const handleTablePageSizeChange = useCallback<
  ManageRoleTableProps['onPageSizeChane']
  >(
    (_limit) => {
      dispatch(
        manageRole.actions.updateManageRoleListFilter({
          _page: PAGE_DEFAULT,
          _limit,
        })
      )
    },
    [dispatch]
  )

  return (
    <>
      <ManageRoleTable
        loading={recordsLoading}
        records={records}
        status={DEFAULT_STATUS}
        page={recordsFilter._page || PAGE_DEFAULT}
        pageSize={10}
        total={recordsTotal}
        onPageChange={handleTablePageChange}
        onPageSizeChane={handleTablePageSizeChange}
        createModalIsOpened={createModalIsOpened}
        toggleCreateModalIsOpened={toggleCreateModalIsOpened}

      />
      <Notification {...notificationData} />
      {deletionLoading && <Loader />} 
      {creationLoading || (updationLoading && <Loader />)}
    </>
  )
}

import { ReportType } from 'api'
import { ROUTE_PATH } from 'routes'

import { ReportsTableHeadCell } from './ReportsPage.props'


export const dataNameReport: { name: string; value: ReportType }[] = [
  { name: 'Полная форма', value: 'report-detailed' },
  { name: 'Краткая форма', value: 'report-short' },
  { name: 'Список организаций', value: 'table' },
]

export const REPORTS_PAGE_BREADCRUMBS = {
  prevPages: [{ name: 'Кабинет пользователя', url: ROUTE_PATH.userSpace }],
  currentPageName: 'Сохраненные отчеты',
}
export const PAGE_DEFAULT = 1

export const PAGE_SIZE_DEFAULT = 20

export const REPORTS_TABLE_COLUMNS: ReportsTableHeadCell[] = [
  {
    id: 'executionDate',
    sortable: true,
    visible: true,
    label: 'Дата отчета',
  },
  {
    id: 'reportType',
    sortable: true,
    visible: true,
    label: 'Наименование отчета',
  },
  {
    id: 'executorFio',
    sortable: true,
    visible: true,
    label: 'ФИО пользователя',
  },
  {
    id: 'orgNames',
    sortable: false,
    visible: true,
    label: 'Наименование организаций в отчете',
  },
  {
    id: 'unps',
    sortable: false,
    visible: true,
    label: 'УНП организаций в отчете',
  },
]

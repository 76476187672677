import { Table, TableBody, TableContainer } from '@mui/material'
import {
  MouseEvent,
  ChangeEventHandler,
  useCallback,
  useState,
  ChangeEvent,
  useRef,
  useEffect,
} from 'react'
import { useSelector } from 'react-redux'

import { Loader } from 'components'
import { CustomPagination } from 'components/CustomPagination/CustomPagination'
import { SelectRowQuantity } from 'components/SelectRowQuantity/SelectRowQuantity'
import { organizations } from 'modules'
import { ReportsTableProps } from 'pages/ReportsPage/ReportsPage.props'
import { OrganizationListFilter } from 'views/OrganizationListFilter'

import styles from '../../styles.module.css'
import { ReportsTableHead } from '../ReportsTableHead/ReportsTableHead'
import { ReportsTableRow } from '../ReportsTableRow/RetportsTableRow'

export const ReportsTable = ({
  loading,
  rows,
  total,
  page,
  pageSize,
  sortBy,
  sortDir,
  onPageChange,
  onPageSizeChange,
  onSort,
  onRemove,
  onFiltersChange,
  setFlag,
  flag
}: ReportsTableProps) => {
  // sorting
  const handleSort = (property: typeof sortBy) => {
    if (property) {
      const isAsc = sortBy === property && sortDir === 'ASC'

      onSort(isAsc ? 'DESC' : 'ASC', property)
    }
  }
  // page size
  const totalPages = Math.ceil(total / pageSize)

  const [rowQuantity, setRowQuantity] = useState<string | number>(20)

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setRowQuantity(event.target.value)
      onPageSizeChange(parseInt(event.target.value, 10))
    },
    [setRowQuantity, onPageSizeChange]
  )

  // page
  const handlePageChange = (event: unknown, newPage: number) => {
    onPageChange(newPage)
  }

  // select rows
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const selectAllRowsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const unpArray = rows.map((row) => row.id)
      setSelectedRows(unpArray)
    } else {
      setSelectedRows([])
    }
  }

  const selectRowHandler = (event: MouseEvent<unknown>, id: number) => {
    const selectedIndex = selectedRows.indexOf(id)

    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1))
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      )
    }

    setSelectedRows(newSelected)
  }

  const isSelected = (id: number) => selectedRows.includes(id)

  // remove from list
  const removeFromListHandler = () => {
    onRemove(selectedRows)
    setSelectedRows([])
  }

  // search filter
  const isOpenFilter = useSelector(organizations.useStateOrganizationFilter)

  const tableRef = useRef<HTMLDivElement | null>(null)

  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    if(tableRef.current) {
      setHeight(tableRef.current.clientHeight < 500 ? 500 : tableRef.current.clientHeight)
    }
  }, [tableRef,tableRef.current?.clientHeight, rows])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <SelectRowQuantity
        rowQuantity={rowQuantity}
        handleChange={handleChange}
        width={290}
      />
      <div className={styles.filterTable__container}>
        {isOpenFilter && (
          <OrganizationListFilter
            height={height}
            onSubmit={onFiltersChange}
            setFlag={setFlag}
            flag={flag}
          />
        )}
        <div
          ref={tableRef}
          className={styles.cont}
          style={{
            width: isOpenFilter ? 'calc(100% - 270px - 18px' : '100%',
          }}
        >
          <TableContainer>
            <Table>
              <ReportsTableHead
                isSelected={selectedRows.length !== 0 && selectedRows.length === rows.length}
                orderBy={sortBy}
                order={sortDir}
                selectAllRows={selectAllRowsHandler}
                onRequestSort={handleSort}
              />
              <TableBody>
                {rows.map((row) => (
                  <ReportsTableRow
                    key={row.id}
                    data={row}
                    isSelected={isSelected(row.id)}
                    selectRow={(event) => selectRowHandler(event, row.id)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.table__footer}>
            <button className={styles.table__saveBtn} type='button' onClick={removeFromListHandler} disabled={!selectedRows.length}>
              Удалить отчет
            </button>
            <CustomPagination
              totalPages={totalPages}
              page={page}
              rowQuantity={rowQuantity}
              handlePageChange={handlePageChange}
              totalItems={total}
            />
          </div>
        </div>
      </div>
    </>
  )
}

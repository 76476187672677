import { Button } from '@mui/material'
import { useState, ChangeEventHandler, useEffect } from 'react'

import styles from './TableMasterList.module.css'
import { HeadDataProps } from './TableMasterList.props'

export const ACCEPT_FILE_TYPE = 'csv'

export const HeadData = ({
  fileError = '',
  onCreate,
  onFileChange,
}: HeadDataProps) => {
  const [error, setError] = useState('')

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const fileType = file.type.split('/')[1]

      if (fileType !== ACCEPT_FILE_TYPE) {
        setError(`Файл должен быть в формате .${ACCEPT_FILE_TYPE}`)
      } else {
        setError('')
        onFileChange(file)
      }
    }
    event.target.value = ''
  }

  useEffect(() => {
    if (fileError && error !== fileError) {
      setError(fileError)
    }
  }, [setError, error, fileError])

  return (
    <div className='flex-distribution-edge'>
      <div className={styles.buttonContainer}>
        <Button  onClick={onCreate} variant='contained'>
            Создать новую запись
        </Button>
        <Button variant='contained' component='label'>
          Загрузить csv файл
          <input
            hidden
            type='file'
            accept={`.${ACCEPT_FILE_TYPE}`}
            onChange={handleFileChange}
          />
        </Button>
      </div>
    </div>
  )
}

import { AuditAction } from './audit.action'
import { AuditSchema } from './audit.schema'
import { initialState } from './audit.state'

export const reducer = (
  state = initialState,
  action: AuditAction | void = undefined
): AuditSchema => {
  switch (action?.type) {
    case 'audit.update_list_filter': {
      return {
        ...state,
        listFilter: {
          ...state.listFilter,
          ...action.payload,
        },
      }
    }

    case 'audit.get_list': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            status: 'loading',
          },
          data: initialState.list.data,
        },
      }
    }

    case 'audit.get_list.success': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...initialState.list.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }

    case 'audit.get_list.error': {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }

    case 'audit.reset_list': {
      return {
        ...state,
        list: {
          ...initialState.list,
        },
      }
    }

    default:
      return state
  }
}

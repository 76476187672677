import { apiBase } from 'api/base'

import { SUBPATH } from './comparison.const'
import {
  ComparisonList,
  ComparisonRemove,
  ComparisonCreate,
} from './comparison.interface'

export const getComparisonList = (params: ComparisonList.Query) =>
  apiBase.get<ComparisonList.Data>(`${SUBPATH}/users-organizations-info`, {
    params,
  })

export const addToComparisonList = (body: ComparisonCreate.Body) =>
  apiBase.post<ComparisonCreate.Data>(`${SUBPATH}/save`, body)

export const removeFromComparisonList = (body: ComparisonRemove.Body) =>
  apiBase.delete<ComparisonRemove.Data>(
    `${SUBPATH}/delete-users-organizations`,
    { data: body }
  )

import { BreadcrumbsAction } from './breadcrumbs.action'
import { BreadcrumbsSchema } from './breadcrumbs.schema'
import { initialState } from './breadcrumbs.state'

export const reducer = (
  state = initialState,
  action: BreadcrumbsAction | void = undefined
): BreadcrumbsSchema => {
  switch (action?.type) {
    case 'breadcrumbs.get_breadcrumbs': {
      return {
        ...state,
        breadcrumbs: {
          ...state.breadcrumbs,
          meta: {
            ...state.breadcrumbs.meta,
            status: 'loading',
          },
          data: initialState.breadcrumbs.data,
        },
      }
    }
    case 'breadcrumbs.get_breadcrumbs.success': {
      return {
        ...state,
        breadcrumbs: {
          ...state.breadcrumbs,
          meta: {
            ...initialState.breadcrumbs.meta,
            status: 'success',
          },
          data: action.payload,
        },
      }
    }
    case 'breadcrumbs.get_breadcrumbs.error': {
      return {
        ...state,
        breadcrumbs: {
          ...state.breadcrumbs,
          meta: {
            ...state.breadcrumbs.meta,
            error: action.payload,
            status: 'error',
          },
        },
      }
    }
    case 'breadcrumbs.reset_get_breadcrumbs': {
      return {
        ...state,
        breadcrumbs: {
          ...initialState.breadcrumbs,
        },
      }
    }
    default:
      return state
  }
}

import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { CustomBreadcrumbs } from 'components/CustomBreadcrumbs'
import { PermissionContext } from 'context'
import { ROUTE_PATH } from 'routes'

import styles from './Admin.module.css'


export const Admin = () => {
  const { permissionControlEtalon,  permissionControlPermit, permissionControlRoles, permissionControlUsers} = useContext(PermissionContext)

  return (
    <>
      <CustomBreadcrumbs currentPageName={'Кабинет администратора'} />
      <div className={styles.containerUp}>
        <Link to={ROUTE_PATH.aisSettings} className={styles.item}>
          <p>Настройка компонентов АИС</p>
          <img src='/assets/Rectangle.on.rectangle.svg' alt='item'/>
        </Link>
        {permissionControlUsers ?
          <Link to='account_management' className={styles.item}>
            <p>Управление учетными записями</p>
            <img src='/assets/Rectangle.stack.person.crop.svg' alt='item'/>
          </Link>
          :
          <div className={styles.itemDisabled}>
            <p>Управление учетными записями</p>
            <img src='/assets/Rectangle.stack.person.crop.svg' alt='item'/>
          </div>
        }
        {permissionControlRoles ?
          <Link to='role_management' className={styles.item}>
            <p>Управление ролями</p>
            <img src='/assets/Person.crop.circle.badge.plus.svg' alt='item'/>
          </Link>
          :
          <div className={styles.itemDisabled}>
            <p>Управление ролями</p>
            <img src='/assets/Person.crop.circle.badge.plus.svg' alt='item'/>
          </div>}
      </div>
      <div className={styles.containerBottom}>
        {permissionControlPermit ?
          <Link to='access_management' className={styles.item}>
            <p>Управление разрешениями</p>
            <img src='/assets/Rectangle.stack.badge.person.svg' alt='item'/>
          </Link>
          :
          <div className={styles.itemDisabled}>
            <p>Управление разрешениями</p>
            <img src='/assets/Rectangle.stack.badge.person.svg' alt='item'/>
          </div>
        }

        {permissionControlEtalon ?
          <Link to='etalon' className={styles.item}>
            <p>Управление эталонным перечнем</p>
            <img src='/assets/Server.rack.svg' alt='item'/>
          </Link>
          :
          <div className={styles.itemDisabled}>
            <p>Управление эталонным перечнем</p>
            <img src='/assets/Server.rack.svg' alt='item'/>
          </div>
        }

      </div>
    </>
  )
}

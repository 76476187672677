import { call, put, select, spawn, takeEvery } from 'redux-saga/effects'

import {
  Base,
  ManageRoleCreate,
  ManageRoleList,
  ManageRoleRemove,
  ManageRoleUpdate,
  manageRole,
} from 'api'

import {
  CreateManageRole,
  DeleteManageRole,
  UpdateManageRole,
  UpdateManageRoleListFilter,
} from './manageRole.action'
import * as creator from './manageRole.creator'
import * as selector from './manageRole.selector'

// list
function* updateManageRoleListFilterWorker() {
  let errorMessage = ''

  try {
    const filter: ReturnType<
      typeof selector.useManageRoleListFilter
    > = yield select(selector.useManageRoleListFilter)

    yield put(creator.getManageRoleList())

    const data:ManageRoleList.Response = yield call(
      manageRole.getManageRoleList,
      filter
    )
    
    if (data.status === 200 ) {
      yield put(creator.getManageRoleListSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.getManageRoleListError(errorMessage))
    }
  }
}

function* updateManageRoleListFilterWatcher() {
  yield takeEvery<UpdateManageRoleListFilter>(
    'manageRole.update_list_filter' as UpdateManageRoleListFilter['type'],
    updateManageRoleListFilterWorker
  )
}

// create
function* createManageRoleWorker(
  action: CreateManageRole
) {
  let errorMessage = ''

  try {
    const data: ManageRoleCreate.Response = yield call(
      manageRole.createManageRole,
      action.payload
    )

    if (data?.status === 201) {
      yield put(creator.createManageRoleSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.createManageRoleError(errorMessage))
    }
  }
}

function* createManageRoleWatcher() {
  yield takeEvery<CreateManageRole>(
    'manageRole.create' as CreateManageRole['type'],
    createManageRoleWorker
  )
}

// update
function* updateManageRoleWorker(
  action: UpdateManageRole
) {
  let errorMessage = ''

  try {
    const data: ManageRoleUpdate.Response = yield call(
      manageRole.updateManageRole,
      action.payload
    )

    if (data?.status === 200) {
      yield put(creator.updateManageRoleSuccess(data.data))
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.updateManageRoleError(errorMessage))
    }
  }
}

function* updateManageRoleWatcher() {
  yield takeEvery<UpdateManageRole>(
    'manageRole.update' as UpdateManageRole['type'],
    updateManageRoleWorker
  )
}

// delete
function* deleteManageRoleWorker(
  action: DeleteManageRole
) {
  let errorMessage = ''

  try { 
    const data:  ManageRoleRemove.Response = yield call(
      manageRole.removeManageRole,
      action.payload
    )
    if (data?.status === 204) {
      yield put(creator.deleteManageRoleSuccess())
    }
  } catch (exception) {
    errorMessage =
      (exception as Base.Error)?.message || Base.ErrorMessage.UNKNOWN
  } finally {
    if (errorMessage) {
      yield put(creator.deleteManageRoleError(errorMessage))
    }
  }
}

function* deleteManageRoleWatcher() {
  yield takeEvery<DeleteManageRole>(
    'manageRole.remove' as DeleteManageRole['type'],
    deleteManageRoleWorker
  )
}

export function* saga() {
  yield spawn(updateManageRoleListFilterWatcher)
  yield spawn(createManageRoleWatcher)
  yield spawn(updateManageRoleWatcher)
  yield spawn(deleteManageRoleWatcher)
}

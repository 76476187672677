// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNodeColor = (node: any) => {
  if (node.data.type === 'target') {
    return '#CCDFE4'
  } else if (node.data.type === 'parent'&& !node.data.parents) {
    return '#5661ff'

  } else if (node.data.type === 'parent' && node.data.parents) {
    return '#9ce1f4'

  } else if (node.data.type === 'child' && node.data.children) {
    return '#00ff44'

  } else if (node.data.type === 'child' && !node.data.children) {
    return '#fea494'
  } else {
    return '#fff'
  }
}
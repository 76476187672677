import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, MenuItem, Select } from '@mui/material'
import { ChangeEvent, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { user } from 'modules'
import {
  EditFormFields,
  EditFormProps,
} from 'pages/SecurityPage/SecurityPage.interface'

import { EditFormSchema } from './EditForm.schema'
import styles from './styles.module.css'


export const EditForm = ({
  roleCustom,
  defaultValues,
  onSubmit,
  onCancel,
  setSelectRoleEdit,
  selectRoleEdit,
  roles,
  rolesWithoutDefaultRole
}: EditFormProps) => {
  const { personalNumber, ...formDefaultValues } = defaultValues

  const editUserSuccess = useSelector(user.useUpdateUserSuccess)

  const {
    formState: { errors, isValid, isDirty },
    register,
    handleSubmit,
  } = useForm<EditFormFields>({
    defaultValues: formDefaultValues,
    resolver: yupResolver(EditFormSchema),
    mode: 'all',
  })

  const editFormHandler: SubmitHandler<EditFormFields> = (values) => {
    onSubmit(values)
  }

  useEffect(() => {
    if (editUserSuccess) {
      onCancel()
      setSelectRoleEdit('')
    }
  }, [editUserSuccess, onCancel, setSelectRoleEdit])

  const handleChangeSelect = (event: ChangeEvent<{ value: string }>) => {
    const requestRole = roles.filter(e => e.name === event.target.value)[0]
    setSelectRoleEdit({id: requestRole.id, name: requestRole.name})
  }

  const isDisabledBtn = (!(rolesWithoutDefaultRole?.length ? rolesWithoutDefaultRole[0].name === selectRoleEdit.name : selectRoleEdit.name === '') || isDirty) && isValid

  return (
    <form onSubmit={handleSubmit(editFormHandler)}>
      <div className={styles.modal__inputsWrapper}>
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <div className={styles.modal__inputBlock}>
              <label className={styles.modal__inputLabel} htmlFor='fullname'>
                ФИО
              </label>
              <input
                className={`${styles.modal__input} ${errors.fio && styles.modal__input_error
                }`}
                type='text'
                id='fio'
                placeholder='Введите ФИО'
                {...register('fio')}
              />
              {errors.fio && (
                <span className={styles.modal__error}>
                  {errors.fio.message}
                </span>
              )}
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={styles.modal__inputBlock}>
              <label className={styles.modal__inputLabel} htmlFor='role'>
                Роль
              </label>
              {roleCustom !== 'user' ?
                <input
                  className={styles.modal__input}
                  type='text'
                  id='role'
                  value={'Администратор'}
                  disabled={true}
                />
                :
                <Select
                  value={selectRoleEdit.name}
                  className={styles.selectForm}
                  onChange={handleChangeSelect as VoidFunction}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                >
                  <MenuItem value=''>
                    <em>Пусто</em>
                  </MenuItem>
                  {roles.map(e => 
                    <MenuItem 
                      value={e.name}
                      key={e.id}
                    >
                      {e.name}
                    </MenuItem>)}
                </Select>}
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={styles.modal__inputBlock}>
              <label className={styles.modal__inputLabel} htmlFor='email'>
                E-mail организации
              </label>
              <input
                className={`${styles.modal__input} ${errors.email && styles.modal__input_error
                }`}
                type='email'
                id='email'
                placeholder='Введите е-mail'
                {...register('email')}
              />
              {errors.email && (
                <span className={styles.modal__error}>
                  {errors.email.message}
                </span>
              )}
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={styles.modal__inputBlock}>
              <label className={styles.modal__inputLabel} htmlFor='phone'>
                Телефон
              </label>
              <input
                className={`${styles.modal__input} ${errors.phone && styles.modal__input_error
                }`}
                type='tel'
                id='phone'
                placeholder='+375 (__) ___-__-__'
                {...register('phone')}
              />
              {errors.phone && (
                <span className={styles.modal__error}>
                  {errors.phone.message}
                </span>
              )}
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className={styles.modal__inputBlock}>
              <label className={styles.modal__inputLabel} htmlFor='id'>
                Идентификационный номер / логин
              </label>
              <input
                className={styles.modal__input}
                type='text'
                id='id'
                placeholder='Введите идентификационный номер / логин'
                value={personalNumber}
                disabled
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={styles.modal__btnsWrapper}>
        <button
          className={styles.modal__btn}
          type='submit'
          disabled={!isDisabledBtn}
        >
          Сохранить
        </button>
        <button className={styles.modal__btn} onClick={onCancel}>
          Отмена
        </button>
      </div>
    </form>
  )
}

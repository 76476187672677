import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import style from '../InfoFormOrganization.module.css'
import { PropsData } from '../types'


export const Successors: FC<PropsData> = ({ data }) => {
  const navigate = useNavigate()
  const info = data.successorsInfo

  const moveToOrganization = (unp:string) => navigate(`/user-space/${unp}/info`)
  return (
    <div className={style.containerComponent}>
      <p className={style.generalName}>правопреемники</p>
      {info.map((e, i) =>
        <React.Fragment key={i}>
          {e.successorName ? <p className={style.headline}>{e.successorName}</p> : null}
          {e.successorUnp ? <p className={style.about}>УНП {<span className={style.link} onClick={() => moveToOrganization(e.successorUnp)}>{e.successorUnp}</span>}</p> : null}
        </React.Fragment>)}
    </div>
  )
}

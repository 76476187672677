import { Base } from 'layouts'

import { PublicProps } from './Public.props'

export const Public = ({
  ...baseProps
}: PublicProps) => {
  return (
    <>
      <Base {...baseProps} />
    </>
  )
}

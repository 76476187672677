import { useEffect, useState } from 'react'

import styles from './styles.module.css'

export const ScrollButton = () => {
  const [scroll, setScroll] = useState(0)

  const handleScroll = () => {
    setScroll(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const sctollToTopHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <button
      className={`${styles.btn} ${scroll === 0 && styles.btn_disabled}`}
      type='button'
      onClick={sctollToTopHandler}
    >
      Вверх
      <img src='/assets/svg/scroll_arrow.svg' alt='scroll_arrow' />
    </button>
  )
}

import { getInitialAsyncState } from 'modules/base'

import { ReportsSchema } from './reports.schema'

export const initialState: ReportsSchema = {
  listFilter: {
    filters: {
      reportType: undefined,
      executorFio: undefined,
      organizationName: undefined,
      unp: undefined,
      startDate: undefined,
      endDate: undefined,
    },
    page: 1,
    size: 20,
    sortField: 'id',
    direction: 'ASC',
  },
  list: getInitialAsyncState({
    
    number: 0,
    totalPages: 0,
    totalElements: 0,
    content: [],
  }),
  addToList: getInitialAsyncState(null),
  removeFromList: getInitialAsyncState(null),
  downloadFile: getInitialAsyncState(null),
}

import { getInitialAsyncState } from 'modules/base'

import { UserSchema } from './user.schema'

export const initialState: UserSchema = {
  user: getInitialAsyncState({
    id: '',
    enabled: false,
    username: '',
    fio: '',
    personalNumber: '',
    email: '',
    emailVerified: false,
    isNew: null,
    phone: '',
    work: {
      unp: null,
      name: null,
      unitName: null,
      position: null,
    },
  }),
  updation: getInitialAsyncState(null),
  createUser: getInitialAsyncState(null),
  addRoleToUser: getInitialAsyncState(null),
  blockUser: getInitialAsyncState(null),
  getUserByUserName: getInitialAsyncState(null),
  getUserRoles: getInitialAsyncState(null),
  updateRoleToUser: getInitialAsyncState(null),
  updateUserEmail: getInitialAsyncState(null),
  getCount: getInitialAsyncState(null)
}

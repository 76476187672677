import { Base } from 'api/base'

import {
  AddRoleToUserData,
  AddRoleToUserPath,
  BlockUserPayload,
  TUser,
  TUserCreate,
  UserCreateBody,
  UserName,
  TUserCreateData,
  GetUserRolesPayload,
  UserRoleInfo,
  UpdateUserRoleBody,
  TUserUpdateEmail,
  TUserCount,
} from './user.model'

export namespace GetUser {
  export type Query = Base.UserQuery;
  export type Data = TUser;
  export type Response = Base.ResponseAgsr<Data>;
}

export namespace UserUpdate {
  export type Body = TUserCreate;
  export type UserUpdateQuery = UserName;
  export type Data = TUser;
  export type Response = Base.ResponseAgsr<Data>;
}

export namespace CreateUser {
  export type Body = UserCreateBody;
  export type Data = TUserCreateData;
  export type Response = Base.ResponseAgsr<Data>;
}

export namespace AddRoleToUser {
  export type Path = AddRoleToUserPath;
  export type Data = AddRoleToUserData;
  export type Response = Base.ResponseAgsr<Data>;
}

export namespace BlockUser {
  export type Payload = BlockUserPayload;
  export type Data = TUser;
  export type Response = Base.ResponseAgsr<Data>;
}

export namespace GetUsersByUsername {
  export type Payload = string[];
  export type Data = TUser;
  export type Response = Base.ResponseAgsr<Data>[];
}

export namespace GetUserRoles {
  export type Payload = GetUserRolesPayload;
  export type Data = UserRoleInfo[];
  export type Response = Base.ResponseAgsr<Data>;
}
export namespace UserUpdateRole {
  export type Body = { roles: UpdateUserRoleBody[] };
  export type UserUpdateQuery = UserName;
  export type Data = { roles: UpdateUserRoleBody[] };
  export type Response = Base.ResponseAgsr<Data>;
}
export namespace UserUpdateEmail {
  export type Body = TUserUpdateEmail;
  export type Data = TUser;
  export type Response = Base.ResponseAgsr<Data>;
}

export namespace GetUserCount {
  export type Data = TUserCount;
  export type Response = Base.ResponseAgsr<Data>;
}

import * as actions from './manageRole.creator'
import { reducer } from './manageRole.reducer'
import { saga } from './manageRole.saga'
import * as selectors from './manageRole.selector'

export const manageRole = {
  ...selectors,
  actions,
  reducer,
  saga,
}
import { FC, ReactNode } from 'react'

import { Header } from 'components'
import { Footer } from 'components/Footer'
import { ScrollButton } from 'components/ScrollButton'

import styles from './styles.module.css'

type HomeLayoutProps = {
  children: ReactNode;
};
export const HomeLayout: FC<HomeLayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <main className={styles.main}>
        {children}
        <ScrollButton />
      </main>
      <Footer />
    </>
  )
}

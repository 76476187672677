import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, IconButton } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { ErrorMessage } from 'components'

import styles from './ModalCreateFilter.module.css'


export type Values = {
  nameFilter: string,
  commentFilter: string
}

type Props = {
  onSubmit: (fields: Values) => void;
  onCancel: () => void;
  isOpen: boolean;
}

const validationSchema = Yup.object().shape({
  nameFilter: Yup.string()
    .required('Обязательное поле'),
  commentFilter: Yup.string()
    .required('Обязательное поле')
})

export const ModalCreateFilter = ({ onSubmit, onCancel, isOpen }: Props) => {
  const { register, handleSubmit, formState: { errors, isDirty } } = useForm<Values>({
    defaultValues: {
      nameFilter: '',
      commentFilter: ''
    },
    resolver: yupResolver(validationSchema)
  })

  return (
    <Dialog
      onClose={onCancel}
      open={isOpen}
    >
      <form className={styles.modalForm} onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ fontSize: 26, fontWeight: 600 }}>СОЗДАНИЕ ФИЛЬТРА</div>
          <div>
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: 500, gap: 30, marginTop: 20, marginBottom: 40 }}>
          <div className={styles.field}>
            <p>Наименование фильтра</p>
            <input placeholder='Введите наименование' {...register('nameFilter')} />
            <ErrorMessage message={errors?.nameFilter?.message} />
          </div>
          <div className={styles.field}>
            <p>Описание фильтра</p>
            <input placeholder='Введите описание' {...register('commentFilter')} />
            <ErrorMessage message={errors?.commentFilter?.message} />
          </div>
        </div>
        <div className={styles.btns}>
          <button type='submit' disabled={!isDirty}>Сохранить</button>
          <button onClick={onCancel}>Отмена</button>
        </div>
      </form>
    </Dialog>
  )
}

import { Modal } from '@mui/material'
import { FC } from 'react'
import { useDispatch } from 'react-redux'

import { Container } from 'components/Container'
import { manageRole } from 'modules'

import styles from './styles.module.css'


interface CustomDeleteModalProps {
  name: string;
  modalIsOpened: boolean;
  closeModal: () => void;
}

export const CustomDeleteModal: FC<CustomDeleteModalProps> = ({
  name,
  modalIsOpened,
  closeModal,
}) => {
  const dispatch = useDispatch()

  const handleTableDelete = () => {
    dispatch(manageRole.actions.deleteManageRole({ name }))
    closeModal()
  }


  return (
    <>
      <Modal open={modalIsOpened}>
        <Container>
          <div className={styles.modal__wrapper}>
            <div className={styles.modal}>
              <button className={styles.modal__closeBtn} onClick={closeModal}>
                X
              </button>
              <h2 className={styles.modal__headline}>
                Вы действительно хотите удалить запись?
              </h2>
              <button className={styles.modal__btn} onClick={handleTableDelete}>
                Удалить
              </button>
            </div>
          </div>
        </Container>
      </Modal> 
    </>
  )
}

import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import style from '../InfoFormOrganization.module.css'
import { PropsData } from '../types'


export const Predecessors: FC<PropsData> = ({ data }) => {
  const navigate = useNavigate()
  const info = data.predecessorsInfo

  const moveToOrganization = (unp: string) => navigate(`/user-space/${unp}/info`)
  return (
    <div className={style.containerComponent}>
      <p className={style.generalName}>Предшественники</p>
      {info.map((e, i) =>
        <React.Fragment key={i}>
          {e.predecessorName ? <p className={style.headline}>{e.predecessorName}</p> : null}
          {e.predecessorUnp ? <p className={style.about}>УНП <span className={style.link} onClick={() => moveToOrganization(e.predecessorUnp)}>{e.predecessorUnp}</span></p> : null}
        </React.Fragment>
      )}
    </div>
  )
}

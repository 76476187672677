import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
} from '@mui/material'
import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FileType } from 'api'
import { downloadTableFile, downloadFile } from 'api/reports/reports.api'
import { DYNAMIC_COLUMN } from 'common/const'
import { TDynamicColumn } from 'common/types'
import { Loader } from 'components'
import { CustomPagination } from 'components/CustomPagination/CustomPagination'
import { CustomTableHead } from 'components/CustomTableHead/CustomTableHead'
import { Notification } from 'components/Notification'
import { SearchBar } from 'components/SearchBar/SearchBar'
import { SelectColumns } from 'components/SelectColumns'
import { SelectRowQuantity } from 'components/SelectRowQuantity/SelectRowQuantity'
import { PermissionContext } from 'context'
import { useNotification } from 'hooks'
import { useChangePageSize } from 'hooks/useChangePageSize'
import { useSelectRows } from 'hooks/useSelectRows'
import { useTableNavigation } from 'hooks/useTableNavigation'
import { organizations, reports } from 'modules'
import { OrganizationListFilter } from 'views/OrganizationListFilter'

import { OrganizationTableProps } from './OrganizationTable.props'
import { OrganizationTableRow } from './OrganizationTableRow'
import styles from './styles.module.css'

import type { DropResult } from 'react-beautiful-dnd'

export const OrganizationTable = ({
  parentRow,
  children,
  loading,
  rows,
  total,
  page,
  pageSize,
  sortDir,
  sortBy,
  resetBtn,
  onFiltersChange,
  onPageChange,
  onPageSizeChange,
  onSort,
  onSearch,
  onAddToComparison,
  onSave,
  setFlag,
  flag,
  downloadFiles
}: OrganizationTableProps) => {
  const { notificationData, openNotification } = useNotification()
  const {
    permissionRepSaving,
    permissionRepUploading,
    permissionRepGeneration,
  } = useContext(PermissionContext)

  // table routing
  const { moveToNextOrganizationHandler, moveToPrevOrganizationHandler } =
    useTableNavigation()

  // sorting
  const handleSort = (event: MouseEvent<unknown>, property: typeof sortBy) => {
    if (property) {
      const isAsc = sortBy === property && sortDir === 'ASC'

      onSort(isAsc ? 'DESC' : 'ASC', property)
    }
  }

  // page
  const handlePageChange = (event: unknown, newPage: number) => {
    onPageChange(newPage)
  }

  // page size
  const { totalPages, rowQuantity, changePageSizeHandler } = useChangePageSize({
    total,
    pageSize,
    onPageSizeChange,
  })

  // select columns
  const [fieldsModalIsOpened, setFieldModalIsOpened] = useState<boolean>(false)

  const toggleFieldsModalIsOpened = () =>
    setFieldModalIsOpened((currState) => !currState)

  // select rows
  const { selectedRows, selectAllRowsHandler, selectRowHandler, isSelected } =
    useSelectRows({ rows })

  // search
  const isOpenFilter = useSelector(organizations.useStateOrganizationFilter)

  const tableRef = useRef<HTMLDivElement | null>(null)

  const handleSearch = useCallback(
    (searchParam: string) => {
      onSearch(searchParam)
    },
    [onSearch]
  )

  // add to comparison
  const addToComparisonHandler = () => {
    onAddToComparison(selectedRows)
  }

  // button
  const [open, setOpen] = useState(false)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const anchorRef = useRef<HTMLButtonElement>(null)
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // download table
  //eslint-disable-next-line
  const downloadTableHandler = (type: FileType) => {
    if (selectedRows.length === 0) {
      openNotification('Не выбраны организации', 'error')
      setOpen(false)
      return
    }

    if (type === 'docx') {
      openNotification(
        'Выгрузка списка организаций доступна только в форматах XLS и PDF',
        'error'
      )
      setOpen(false)
      return
    }

    const visibleColumns = JSON.parse(
      localStorage.getItem('user-space-columns')!
    ).reduce((acc: { [key: string]: boolean }, curr: TDynamicColumn) => {
      acc[curr.id] = curr.visible
      return acc
    }, {})

    downloadTableFile({
      unp: selectedRows,
      type,
      show: visibleColumns,
    }).then((response) => {
      const headerContent = response.headers['content-disposition']
      const filename = decodeURI(headerContent.slice(headerContent.lastIndexOf('\'') + 1))
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })

    setOpen(false)
  }

  // save report
  const saveReportHandler = () => {
    const columns = (
      JSON.parse(
        localStorage.getItem('user-space-columns')!
      ) as TDynamicColumn[]
    )
      .filter((column) => column.visible)
      .map((column) => column.id)

    onSave(selectedRows, columns)
    handleToggle()
  }

  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    if (tableRef && tableRef.current) {
      setHeight(
        tableRef.current.clientHeight < 500
          ? 500
          : tableRef.current.clientHeight
      )
    }
    //eslint-disable-next-line
  }, [tableRef, tableRef.current?.clientHeight, rows, parentRow, localStorage.getItem('user-space-columns'), downloadTableHandler])

  // dnd
  const columns = localStorage.getItem('user-space-columns')

  const [draggableColumns, setDraggableColumns] = useState<TDynamicColumn[]>([])

  useEffect(() => {
    const visibleColumns = (columns ? JSON.parse(columns) : DYNAMIC_COLUMN).filter(
      (column: TDynamicColumn) => column.visible
    )

    setDraggableColumns(visibleColumns)
  }, [columns])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const arrFromResult: TDynamicColumn[] = Array.from(draggableColumns)
    const [removed] = arrFromResult.splice(result.source.index, 1)
    arrFromResult.splice(result.destination.index, 0, removed)

    const notVisibleColumns: TDynamicColumn[] = (
      columns ? JSON.parse(columns) : DYNAMIC_COLUMN
    ).filter((column: TDynamicColumn) => !column.visible)

    localStorage.setItem(
      'user-space-columns',
      JSON.stringify(arrFromResult.concat(notVisibleColumns))
    )

    setDraggableColumns([...arrFromResult])
  }


  const handleTableDownloadFile = (type: FileType) => {
    downloadFile({
      unp: selectedRows,
      type,
      template: 'report-detailed',
    }).then((response) => {
      const headerContent = response.headers['content-disposition']
      const filename = decodeURI(headerContent.slice(headerContent.lastIndexOf('\'') + 1))
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })

    handleToggle()
  }

  const dispatch = useDispatch()

  const { fio: executorFio } = JSON.parse(
    localStorage.getItem('user')!
  )

  const saveToReportsHandler = () => {
    dispatch(
      reports.actions.addToList({
        executorFio,
        reportType: 'report-detailed',
        unps: selectedRows,
      })
    )
    handleToggle()
  }

  return (
    <>
      <section className={styles.section}>
        <SearchBar onSearch={handleSearch} />
        {children}
        <Box>
          <div className={styles.sort__container}>
            {resetBtn}
            <SelectRowQuantity
              rowQuantity={rowQuantity}
              handleChange={changePageSizeHandler}
              width={290}
            />
            <SelectColumns
              storageName='user-space-columns'
              isOpened={fieldsModalIsOpened}
              toggleIsOpened={toggleFieldsModalIsOpened}
            />
          </div>
          <div className={styles.filterTable__container}>
            {isOpenFilter && (
              <OrganizationListFilter
                height={height}
                onSubmit={onFiltersChange}
                setFlag={setFlag}
                flag={flag}
              />
            )}
            <div
              ref={tableRef}
              className={styles.cont}
              style={{
                width: isOpenFilter ? 'calc(100% - 270px - 18px' : '100%',
              }}
            >
              <TableContainer className={styles.TableContainer}>
                <Table className={styles.table} stickyHeader>
                  <CustomTableHead
                    isSelected={
                      selectedRows.length !== 0 &&
                      selectedRows.length ===
                      (parentRow ? rows.length + 1 : rows.length)
                    }
                    orderBy={sortBy}
                    order={sortDir}
                    selectAllRows={selectAllRowsHandler}
                    onRequestSort={handleSort}
                    columns={draggableColumns}
                    onDragEnd={onDragEnd}
                  />
                  <TableBody>
                    {parentRow && (
                      <OrganizationTableRow
                        parent={true}
                        selectRow={(event) =>
                          selectRowHandler(event, parentRow.unp)
                        }
                        isSelected={isSelected(parentRow.unp)}
                        data={parentRow}
                        moveToPrevPage={moveToPrevOrganizationHandler}
                      />
                    )}

                    {rows.map((row) => (
                      <OrganizationTableRow
                        key={row.unp}
                        data={row}
                        isSelected={isSelected(row.unp)}
                        selectRow={(event) => selectRowHandler(event, row.unp)}
                        moveToNextPage={() =>
                          moveToNextOrganizationHandler(row.unp)
                        }
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={styles.footer}>
                <div className={styles.footer__btnsContainer}>
                  <button
                    className={styles.footer__btn}
                    type='button'
                    onClick={addToComparisonHandler}
                    disabled={!permissionRepGeneration || !selectedRows.length}
                  >
                    Добавить в избранное
                  </button>
                  <button
                    className={styles.footer__btn}
                    type='button'
                    ref={anchorRef}
                    onClick={handleToggle}
                    style={{ width: 230 }}
                    disabled={!selectedRows.length}
                  >
                    Выгрузить информацию{' '}
                    {!open ? (
                      <KeyboardArrowRightIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement='bottom-end'
                    transition
                    disablePortal
                    style={{ width: 230 }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom-start'
                              ? 'left top'
                              : 'left bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id='composition-menu'
                              aria-labelledby='composition-button'
                              onKeyDown={handleListKeyDown}
                            >
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 20 }}>
                                <p style={{ fontWeight: 400, fontSize: 16, paddingLeft: 15, marginTop: 5, color: 'gray', marginBottom: 5, whiteSpace: 'nowrap', marginRight: 5 }}>Табличная форма</p>
                                <hr style={{ height: 2, background: 'red', width: '100%' }} />
                              </div>
                              <MenuItem
                                disabled={!permissionRepUploading}
                                onClick={() => downloadTableHandler('xlsx')}
                                style={{ paddingLeft: 25 }}
                              >
                                Выгрузить в XLS
                              </MenuItem>
                              <MenuItem
                                disabled={!permissionRepSaving}
                                onClick={saveReportHandler}
                                style={{ paddingLeft: 25 }}
                              >
                                Сохранить отчет
                              </MenuItem>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 20 }}>
                                <p style={{ fontWeight: 400, fontSize: 16, paddingLeft: 15, marginTop: 5, color: 'gray', marginBottom: 5, whiteSpace: 'nowrap', marginRight: 5 }}>Подробная форма</p>
                                <hr style={{ height: 2, background: 'red', width: '100%' }} />
                              </div>
                              <MenuItem
                                disabled={!permissionRepUploading}
                                onClick={() => handleTableDownloadFile('pdf')}
                                style={{ paddingLeft: 25 }}
                              >
                                Выгрузить в PDF
                              </MenuItem>
                              <MenuItem
                                disabled={!permissionRepUploading}
                                onClick={() => handleTableDownloadFile('xlsx')}
                                style={{ paddingLeft: 25 }}
                              >
                                Выгрузить в XLS
                              </MenuItem>
                              <MenuItem
                                disabled={!permissionRepUploading}
                                onClick={() => handleTableDownloadFile('docx')}
                                style={{ paddingLeft: 25 }}
                              >
                                Выгрузить в DOC
                              </MenuItem>
                              <MenuItem
                                disabled={!permissionRepSaving}
                                onClick={saveToReportsHandler}
                                style={{ paddingLeft: 25 }}
                              >
                                Сохранить отчет
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <div style={{ marginLeft: 120 }}>
                    <CustomPagination
                      totalPages={totalPages}
                      page={page}
                      rowQuantity={rowQuantity}
                      handlePageChange={handlePageChange}
                      totalItems={total}
                    />
                  </div>
                </div>
                <Tooltip title='Скачать таблицу в csv'>
                  <IconButton>
                    <SimCardDownloadOutlinedIcon
                      onClick={downloadFiles}
                      fontSize='large'
                      color='primary'
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          {loading && <Loader />}
        </Box>
      </section>
      <Notification {...notificationData} />
    </>
  )
}
